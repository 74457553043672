import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Message, Modal, Table } from 'semantic-ui-react';

export const StopErrors = ({ plan, show }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState([]);

  useEffect(() => {
    let auxText = [];
    if (plan.events) {
      for (const item of plan.events) {
        if (item.status === 'lender') {
          auxText.push('You have to contact support');
        }
        if (item.status === 'balance') {
          auxText.push('We could not make the payment from your account bank.');
        }
      }
    }
    setText(auxText);
    setOpen(show);
  }, [])


  return (
    <Modal size="small" open={open} closeOnEscape={false} closeOnDimmerClick={false}
      trigger={
        plan.events && plan.events.length > 0 && (
          <Button color="red" style={{ display: 'inline-block' }} onClick={() => setOpen(true)} >Error Descriptions</Button>
        )
      }>
      <Modal.Header>
        Error Descriptions
      </Modal.Header>
      <Modal.Content className='payment-plans' scrolling>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              plan.events.map(item => (
                <Table.Row>
                  <Table.Cell>{moment(item.created_at).format('dddd, MMMM D, YYYY')}</Table.Cell>
                  <Table.Cell>{item.description}</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
        {
          plan.status === 3 && (
            <Message negative>
              <Message.Header>You have before {moment(plan.paymentDate).format('dddd, MMMM D, YYYY')} to fix the problem, otherwise your plan will be cancelled 
                and we will transfer the remaining balance to your account.
              </Message.Header>
              {
                text.map(item => (
                  <p>{item}</p>
                ))
              }
            </Message>
          )
        }
      </Modal.Content>
      <Modal.Actions>
        <Button secondary className='close' onClick={() => setOpen(false)} ><h3>Close</h3></Button>
      </Modal.Actions>
    </Modal>
  );
};