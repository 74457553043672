import React, { useState, useEffect } from "react";
import { Modal, Form } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import Input from "../../../global/Input";
import Button from "../../../global/Button";
import Dropdown from "../../../global/DropDown";
import { GoogleAutocomplete } from "../../../utilities/googleAutocomplete/GoogleAutocomplete";
import AccountModal from "./AccountModal";

import { get700Credit } from "../../../utilities/requests";

import { states } from "../../../helpers/usaStates";
import { useTheme } from "../../../helpers/theme.js";

import { schema } from "./config.js";

const usaState = states().map((item) => {
  return {
    text: item.name,
    value: item.isoCode,
  };
});

const SimpleRegisterModalWrapper = ({
  form,
  openSession,
  retailer,
  car,
  children,
  trade_in,
  money_owed,
  finalQuots,
  setQuotationState,
  updateUser,
}) => {
  const { theme } = useTheme();

  const {
    trigger,
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [fieldsData, setFieldsData] = useState({});
  const [user, setUser] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coxData, setCoxData] = useState({});
  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(false);

  const open = () => {
    reset();
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleOnSelectAddress = ({
    address,
    locationCity,
    locationState,
    postalCode,
  }) => {
    const stateValue = usaState.find((item) => item.text === locationState);

    setValue("address", address);
    setValue("city", locationCity);
    setValue("state", stateValue.value);
    setValue("zipCode", postalCode);

    trigger(["address", "city", "state", "zipCode"]);
  };

  const handleOnSubmit = (data) => {
    setFieldsData((prevState) =>
      _.omit(
        {
          ...prevState,
          ...data,
          postalCode: data.zipCode,
          zip_code: data.zipCode,
        },
        "zipCode"
      )
    );

    setIsRegisterFormVisible(true);
  };

  const initializeForm = () => {
    let _form = { ...form };
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (currentUser && currentUser.customer) {
      _form.firstName = currentUser.customer.name;
      _form.lastName = currentUser.customer.second_name;
      _form.postalCode = currentUser.customer.zip_code;
      _form.zip_code = currentUser.customer.zip_code;
      _form.address = currentUser.customer.address;
      _form.state = currentUser.customer.state;
      _form.city = currentUser.customer.city;
      _form.customerId = currentUser.customer.id;

      setIsLogged(true);
      setUser(currentUser);
    }

    setFieldsData((prevState) => ({
      ...prevState,
      ..._form,
    }));
  };

  const handleOnContinue = (isRegister) => {
    setIsRegisterFormVisible(false);

    if (_.isBoolean(isRegister) && !isRegister) return;

    let state = fieldsData.state;

    for (const item of usaState) {
      if (fieldsData.state === item.isoCode) {
        state = item.name;
        break;
      }
    }

    const parameters = {
      name: fieldsData.firstName + " " + fieldsData.lastName,
      zip_code: fieldsData.postalCode,
      address: fieldsData.address,
      city: fieldsData.city,
      state: state,
      retailer_id: retailer.id,
      customer_id: isRegister.customer.id,
    };

    setIsLoading(true);

    get700Credit(parameters)
      .then((response) => {
        let auxForm = fieldsData;

        auxForm["score"] = response.data.Score;
        auxForm["creditScore"] = response.data.Score;

        buildCoxProps(auxForm);

        setIsLoading(false);
        setFieldsData({ ...auxForm });
        setQuotationState({
          preQualifiedForm: {
            ...auxForm,
          },
        });
      })
      .catch((error) => {
        let auxForm = fieldsData;

        auxForm["score"] = null;
        auxForm["creditScore"] = null;

        buildCoxProps(auxForm);

        setIsLoading(false);

        setQuotationState({
          preQualifiedForm: {
            ...auxForm,
          },
        });
      });
    close();
  };

  const buildCoxProps = (auxForm) => {
    let auxCoxData = { ...coxData };

    auxCoxData["user"] = auxForm;
    auxCoxData["quote"] = finalQuots.monthly;
    auxCoxData["retailer"] = retailer;
    auxCoxData["car"] = car;
    auxCoxData["show"] = true;
    auxCoxData["tradeIn"] = trade_in;
    auxCoxData["moneyOwed"] = money_owed;

    setCoxData({ ...auxCoxData });
  };

  useEffect(initializeForm, []);

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal open={isOpen} closeIcon onClose={close}>
        <Modal.Header>Customer Details</Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Group widths={"equal"}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <Form.Field required>
                    <label>First Name</label>

                    <Input
                      placeholder={"First Name"}
                      fluid
                      error={errors.firstName?.message}
                      theme={theme?.input}
                      {...field}
                    />
                  </Form.Field>
                )}
              />

              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <Form.Field required>
                    <label>Last Name</label>

                    <Input
                      placeholder={"Last Name"}
                      fluid
                      error={errors.lastName?.message}
                      theme={theme?.input}
                      {...field}
                    />
                  </Form.Field>
                )}
              />
            </Form.Group>

            <Form.Group>
              <Controller
                name={"address"}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <Form.Field width={16} required>
                    <GoogleAutocomplete
                      onChangeSelect={(data) => {
                        if (_.isObject(data)) {
                          handleOnSelectAddress(data);
                        } else {
                          onChange(data);
                        }
                      }}
                      error={errors.address?.message}
                      validateTyping={true}
                      theme={theme?.input}
                      {...rest}
                    />
                  </Form.Field>
                )}
              />
            </Form.Group>

            <Form.Group>
              <Controller
                name={"state"}
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <Form.Field width={6} required>
                    <label>State</label>

                    <Dropdown
                      id={"state"}
                      value={value}
                      placeholder={"State"}
                      search
                      options={usaState}
                      selection
                      error={errors.state?.message}
                      onChange={(e, { value }) => {
                        onChange(value);
                      }}
                      theme={theme?.input}
                      {...rest}
                    />
                  </Form.Field>
                )}
              />

              <Controller
                name={"city"}
                control={control}
                render={({ field }) => (
                  <Form.Field width={6} required>
                    <label>City</label>

                    <Input
                      error={errors.city?.message}
                      placeholder={"City"}
                      theme={theme?.input}
                      {...field}
                    />
                  </Form.Field>
                )}
              />

              <Controller
                name={"zipCode"}
                control={control}
                render={({ field }) => (
                  <Form.Field width={4} required>
                    <label>Zip Code</label>

                    <Input
                      placeholder={"Zip Code"}
                      error={errors.zipCode?.message}
                      theme={theme?.input}
                      {...field}
                    />
                  </Form.Field>
                )}
              />
            </Form.Group>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            buttonStyle="danger"
            className="actionButton"
            onClick={close}
            theme={theme?.button}
          >
            Close
          </Button>

          <Button
            className="actionButton"
            onClick={handleSubmit(handleOnSubmit)}
            theme={theme?.button}
          >
            Continue
          </Button>
        </Modal.Actions>
      </Modal>

      <AccountModal
        data={fieldsData}
        open={isRegisterFormVisible}
        retailer={retailer}
        CreditSolutionRequest={handleOnContinue}
        onClose={() => setIsRegisterFormVisible(false)}
        updateUser={updateUser}
        isEnabled={false}
      />
    </>
  );
};

export default SimpleRegisterModalWrapper;
