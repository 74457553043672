import axiosApiInstance from "./interceptor";
const API_URL = `${process.env.REACT_APP_API_URL}/`;

const api = {
  get: (endpoint, params = null) => {
    const url = new URL(`${API_URL}${endpoint}`);
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key))
          url.searchParams.append(key, params[key]);
      }
    }
    return axiosApiInstance.get(url.href);
  },
  post: (endpoint, body) =>
    axiosApiInstance.post(`${API_URL}${endpoint}`, body),
  put: (endpoint, body) => axiosApiInstance.put(`${API_URL}${endpoint}`, body),
  delete: (endpoint, body) => axiosApiInstance.delete(`${API_URL}${endpoint}`),
  patch: (endpoint, body) =>
    axiosApiInstance.patch(`${API_URL}${endpoint}`, body),
};

export default api;
