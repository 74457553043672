import React, { useState, useEffect } from "react";
import { Modal, Form, Loader, Dimmer } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import Input from "../../../../global/Input";
import Button from "../../../../global/Button";
import RichTextEditor from "../../../../global/RichTextEditor";

import EmptyImage from "../../../../../images/no-image-available.png";

import {
  setAttributes,
  deleteAttribute,
  updateAttribute,
} from "../../../../utilities/requests";

import { schema, editorModules, editorFormats } from "./config";

import "./AttributeModal.sass";
import "react-quill/dist/quill.snow.css";

const { Field } = Form;

const OneMB = 1048576;

const AttributeModal = ({ retailerId, item, update, children }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(EmptyImage);
  const [description, setDescription] = useState("");
  const [imageError, setImageError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleOnChangeImage = (e) => {
    const _file = _.first(e.target.files);

    if (_.isUndefined(_file)) return;

    if (_file.size / OneMB > 20) {
      setError("image", "Size of file is too large");

      return;
    }

    const url = window.URL.createObjectURL(_file);

    setFile(_file);
    setImage(url);
    setImageError(null);
  };

  const handleOnSubmit = async (data) => {
    if (_.isNull(file)) {
      setImageError("Image field is required");

      return;
    }

    setIsLoading(true);

    const formData = new FormData();

    formData.append("image", file);
    formData.append("name", data.name);
    formData.append("cost", data.cost);
    formData.append("description", description);

    await setAttributes(retailerId, formData);

    setIsLoading(false);

    update();
    onClose();
  };

  const handleOnDescriptionChange = (content) => {
    setDescription(content);
  };

  const handleOnDelete = async (id) => {
    setIsLoading(true);

    await deleteAttribute(item.id);

    setIsLoading(false);

    update();
    onClose();
  };

  const handleOnUpdate = async (data) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("cost", data.cost);
    formData.append("description", description);
    file && formData.append("image", file);

    await updateAttribute(item.id, formData);

    setIsLoading(false);

    update();
    onClose();
  };

  const init = () => {
    reset();
    setImageError(null);

    if (item) {
      setDescription(item.description);
      setValue("name", item.name);
      setValue("cost", item.cost);
      setImage(item.image_url);
    } else {
      setDescription("");
      setImage(EmptyImage);
    }
  };

  useEffect(() => {
    init();
  }, [open]);

  return (
    <>
      {React.cloneElement(children, {
        onClick: onOpen,
      })}

      <Modal open={open} onClose={onClose} closeIcon>
        <Modal.Header>{item ? "Edit" : "Create"} Attribute</Modal.Header>

        <Form className={"attributeModalWrapper"}>
          <Modal.Content scrolling>
            <div className={"attributeModalContent"}>
              <div className={"photoWrapper"}>
                <img src={image} alt="Attribute" />
              </div>

              <div className={"fieldsWrapper"}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Field required>
                      <label>Attribute Name</label>

                      <Input
                        error={errors.name && errors.name.message}
                        fluid
                        {...field}
                      />
                    </Field>
                  )}
                />

                <Controller
                  name="cost"
                  control={control}
                  render={({ field }) => (
                    <Field required>
                      <label>Price</label>

                      <Input
                        error={errors.cost && errors.cost.message}
                        label={{ basic: true, content: "$" }}
                        labelPosition={"right"}
                        {...field}
                        fluid
                      />
                    </Field>
                  )}
                />

                <Field required>
                  <label>Attribute Image</label>

                  <Input
                    onChange={(e) => {
                      e.persist();

                      handleOnChangeImage(e);
                    }}
                    type="file"
                    accept="image/png,image/jpg,image/jpeg"
                    error={imageError}
                    value={undefined}
                    fluid
                  />
                </Field>
              </div>
            </div>

            <div className={"textEditorWrapper"}>
              <Field required>
                <label>Description</label>

                <RichTextEditor
                  value={description}
                  onChange={handleOnDescriptionChange}
                  modules={editorModules}
                  formats={editorFormats}
                  placeholder="Description..."
                />
              </Field>
            </div>

            <div className={"bottomActions"}>
              {item ? (
                <>
                  <Button buttonStyle={"danger"} onClick={handleOnDelete}>
                    Delete
                  </Button>

                  <Button onClick={handleSubmit(handleOnUpdate)}>Update</Button>
                </>
              ) : (
                <Button onClick={handleSubmit(handleOnSubmit)}>Create</Button>
              )}
            </div>
          </Modal.Content>
        </Form>

        {isLoading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </Modal>
    </>
  );
};

export default AttributeModal;
