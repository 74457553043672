import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';

export const InventoryHeadTable = (props) => {
  const [sortSelected, setSortSelected] = useState("");
  const [direction, setDirection] = useState("");

  // -------------------------------- sort method ---------------------------------
  const createSortHandler = (id, componentMount) => {
    setSortSelected(id);
    if (id !== sortSelected) {
      setDirection('ascending')
      if (!componentMount) {
        props.handleSortUpdate('ascending', id)
      }
    } else {
      if (direction === 'descending') {
        setSortSelected("");
        setDirection('')
        if (!componentMount) {
          props.handleSortUpdate('', '');
        }
      } else {
        setDirection('descending');
        if (!componentMount) {
          props.handleSortUpdate('descending', id);
        }
      }
    }
  };

  useEffect(() => {
  }, [])

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={sortSelected === 'stock_number' ? direction : null}
          onClick={() => createSortHandler('stock_number')}
        >
          STOCK #
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'stock_date' ? direction : null}
          onClick={() => createSortHandler('stock_date')}
        >
          ADD
          DATE</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'year' ? direction : null}
          onClick={() => createSortHandler('year')}
        >
          YEAR
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'make' ? direction : null}
          onClick={() => createSortHandler('make')}
        >
          MAKE
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'model' ? direction : null}
          onClick={() => createSortHandler('model')}
        >
          MODEL
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'trim' ? direction : null}
          onClick={() => createSortHandler('trim')}
        >
          TRIM
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'exterior_color' ? direction : null}
          onClick={() => createSortHandler('exterior_color')}
        >
          EXTERIOR COLOR
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'interior_color' ? direction : null}
          onClick={() => createSortHandler('interior_color')}
        >
          INTERIOR COLOR
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'miles' ? direction : null}
          onClick={() => createSortHandler('miles')}
        >
          MILEAGE
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortSelected === 'price' ? direction : null}
          onClick={() => createSortHandler('price')}
        >
          PRICE
        </Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};