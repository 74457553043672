import React from 'react';
import { Header } from 'semantic-ui-react';

export const Footer = () => {
  return (
    <div className='footer'>
      <Header as="h3" textAlign='center'>
        <span>800 N. Magnolia Avenue Suite 1275 · Orlando, FL 32803 · Phone: (407) 897-8688 · <a href="https://autopayplus.com/" target="_blank" rel="noopener noreferrer">www.autopayplus.com</a></span>
        <span>Fax: (407) 897-8553 · Email: customercare@autopayplus.com• Speak to a Representative: (800) 894-5000</span>
      </Header>
    </div>
  );
};