import React, { useEffect, useState } from "react";
import { Container, Header, Dimmer, Loader, Tab } from "semantic-ui-react";
import { get700Credit } from "../requests";
import {
  FormatNumberToPrice,
  validAddress,
  validZipCode,
} from "../../helpers/general";
import { states, zipCodesStates } from "../../helpers/usaStates";
import { useMediaPredicate } from "react-media-hook";

// IMPORT COMPONENTS
import PreQualifiedForm from "./PreQualifiedForm";
import SlidersForm from "./SlidersForm";
import CustomCarousel from "../carousel/carousel";
import AlertModal from "../alertModal/modal";
import Video from "../../customer/quotation/video/Video";
import Cox from "../../customer/cox/Cox";
import { WeeklyTooltip } from "../tooltips/WeeklyTooltip";
import { MonthlyTooltip } from "../tooltips/MonthlyTooltip";

import { useTheme } from "../../helpers/theme";

// IMPORT CSS STYLE
import "./PreQualified.sass";

const defualtForm = {
  firstName: null,
  lastName: null,
  address: null,
  zip_code: null,
  state: null,
  city: null,
};

const usaState = states();

const PreQualified = (props) => {
  const { theme } = useTheme();

  const {
    finalQuots,
    retailer,
    money_owed,
    trade_in,
    isRandomFlow,
    isSlidersFlow,
    setUserFlow,
    recalculateValues,
    interestRange,
    fetchRebates,
  } = props;

  const [car] = useState(props.car);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(props.preQualifiedForm);
  const [isTypingDown, setIsTypingDown] = useState(false);
  const [errors, setErrors] = useState(defualtForm);
  const [disabled, setDisabled] = useState(true);
  const [logged, setLogged] = useState(false);
  const [user, setUser] = useState({});
  const [coxData, setCoxData] = useState({ show: false });

  const is576 = useMediaPredicate("(max-width: 576px)");

  // ----------------------- get form values
  const onChangeState = (value, label) => {
    const auxForm = { ...form };
    let auxErrors = { ...errors };
    if (label === "annualIncome" && value.target) {
      let { min, max } = value.target;
      let val = value.target.value;
      value = Math.max(Number(min), Math.min(Number(max), Number(val)));
      setIsTypingDown(!isTypingDown);
    } else if (label === "fullAddress") {
      auxForm.address = value.address;
      auxForm.state = value.state;
      auxForm.city = value.city;
      auxForm.postalCode = value.zip_code;
      auxForm["zip_code"] = value.zip_code;
      auxErrors = typeData("address", value.address, auxErrors, auxForm);
      auxErrors = typeData("zip_code", value.zip_code, auxErrors, auxForm);
      auxErrors = typeData("postalCode", value.zip_code, auxErrors, auxForm);
      auxErrors = typeData("city", value.city, auxErrors, auxForm);
      auxErrors = typeData("state", value.state, auxErrors, auxForm);
    } else if (label === "zip_code") {
      auxForm.postalCode = value;
      auxForm["zip_code"] = value;
      auxErrors = typeData(label, value, auxErrors, auxForm);
    } else {
      auxForm[label] = value;
      auxErrors = typeData(label, value, auxErrors, auxForm);
    }
    formValidate(auxForm);
    setForm({ ...auxForm });
    setErrors({ ...auxErrors });
  };

  // ------------------------------------------------- validate form
  const formValidate = (auxForm) => {
    let auxErrors = { ...defualtForm },
      auxDisabled = false;
    for (const property in auxErrors) {
      auxErrors = typeData(property, auxForm[property], auxErrors, auxForm);
      if (auxErrors[property] !== null) {
        auxDisabled = true;
      }
    }
    setDisabled(auxDisabled);
  };

  const typeData = (label, value, auxErrors, form) => {
    switch (label) {
      case "firstName":
        auxErrors.firstName =
          value.length < 3
            ? "First Name must be at least 3 characters long"
            : null;
        break;
      case "lastName":
        auxErrors.lastName =
          value.length < 3
            ? "Full Name must be at least 3 characters long"
            : null;
        break;
      case "address":
        auxErrors.address = validAddress(value);
        break;
      case "zip_code":
        auxErrors.zip_code = !validZipCode(value)
          ? "Zip Code must be 5 characters long"
          : zipCodesStates(form.state, value);
        break;
      case "state":
        auxErrors.state = value === "" ? "State is required" : null;
        auxErrors.zip_code = zipCodesStates(value, form.zip_code);
        break;
      case "city":
        auxErrors.city = value === "" ? "City is required" : null;
        break;
      default:
        break;
    }
    return auxErrors;
  };

  // --------------------- hide modal
  const hideModal = () => {
    setModal({ modal: {} });
  };

  const CreditSolutionRequest = (auxUser = user) => {
    if (!isRandomFlow) setUserFlow("form");

    setUser(auxUser);

    let state = form.state;

    for (const item of usaState) {
      if (form.state === item.isoCode) {
        state = item.name;
        break;
      }
    }

    setDisabled(true);

    const parameters = {
      name: form.firstName + " " + form.lastName,
      zip_code: form.postalCode,
      address: form.address,
      city: form.city,
      state: state,
      retailer_id: retailer.id,
      customer_id: form.customerId ? form.customerId : null,
    };

    setIsLoading(true);

    get700Credit(parameters)
      .then((response) => {
        setIsLoading(false);
        let auxForm = form;
        auxForm["score"] = response.data.Score;
        auxForm["creditScore"] = response.data.Score;
        buildCoxProps(auxForm);
        setForm({ ...auxForm });
      })
      .catch((error) => {
        let auxForm = form;
        auxForm["score"] = null;
        auxForm["creditScore"] = null;
        buildCoxProps(auxForm);
        setIsLoading(false);
      });
  };

  const buildCoxProps = (auxForm) => {
    let auxCoxData = { ...coxData };
    auxCoxData["user"] = auxForm;
    auxCoxData["quote"] = finalQuots.monthly;
    auxCoxData["retailer"] = retailer;
    auxCoxData["car"] = car;
    auxCoxData["show"] = true;
    auxCoxData["tradeIn"] = trade_in;
    auxCoxData["moneyOwed"] = money_owed;
    setCoxData({ ...auxCoxData });
  };

  const continueProcess = (coxQuotes) => {
    let auxForm = form;
    auxForm["coxQuotes"] = coxQuotes;
    props.next(auxForm, user);
  };

  useEffect(() => {
    let auxForm = props.preQualifiedForm;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    setLogged(false);

    if (currentUser && currentUser.customer) {
      auxForm.firstName =
        currentUser.customer.name || currentUser.customer.first_name;
      auxForm.lastName =
        currentUser.customer.second_name || currentUser.customer.last_name;
      auxForm.postalCode = currentUser.customer.zip_code;
      auxForm.zip_code = currentUser.customer.zip_code;
      auxForm.address = currentUser.customer.address;
      auxForm.state = currentUser.customer.state;
      auxForm.city = currentUser.customer.city;
      auxForm.customerId = currentUser.customer.id;

      setLogged(true);
      setUser(currentUser);
    }

    setForm(auxForm);
    formValidate(auxForm);
    setErrors(defualtForm);
  }, []);

  const panes = [
    {
      menuItem: "Build My Deal Prequalified",
      render: () => (
        <Tab.Pane>
          <PreQualifiedForm
            form={form}
            onChangeState={onChangeState}
            setQuotationState={props.onChangeProps}
            errors={errors}
            logged={logged}
            openSession={props.openSession}
            CreditSolutionRequest={CreditSolutionRequest}
            retailer={retailer}
            disabled={disabled}
            setNewCustomerId={props.setNewCustomerId}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Build My Deal Using A Self-Selected Credit Profile",
      render: () => (
        <SlidersForm
          quotationState={props.quotationState}
          onChangeState={props.onChangeState}
          setQuotationState={props.setQuotationState}
          finalQuots={finalQuots}
          car={props.car}
          setStep={props.setStep}
          setUserFlow={setUserFlow}
          isRandomFlow={isRandomFlow}
          isSlidersFlow={true}
          recalculateValues={recalculateValues}
          interestRange={interestRange}
          fetchRebates={fetchRebates}
        />
      ),
    },
  ];

  return (
    <Container className="prequalified-container">
      <div className={"top"}>
        <Header as="h1">Let's See Payments Specific To You...</Header>

        <Video newVideos={true} video={"prequalified"} videos={props.videos} />
      </div>

      <div className={"infoSection"}>
        <div>
          <Header
            as="h2"
            content={car.year + " " + car.make + " " + car.model}
            style={{ marginBottom: 0 }}
          ></Header>

          <Header
            as="h3"
            disabled
            style={{ marginTop: 0 }}
            className={"subtitle"}
          >
            Get a more transparent & personalized shopping experience.
          </Header>
        </div>

        <div className="title-a2u">
          <p className="title">As Low As</p>
          <h2
            className="a2uWeekly"
            style={{
              color: theme?.typography.primary,
            }}
          >
            {FormatNumberToPrice(finalQuots.weekly.payment)}/Week
            <WeeklyTooltip
              finalQuots={finalQuots}
              position={is576 ? "bottom right" : "left center"}
              offset={is576 ? "0, 0" : "0, 120px"}
              iconStyle={{ color: theme?.typography?.primary }}
            />
          </h2>
          <h4 className="orText">OR</h4>
          <h4 className="a2uMonthly">
            {FormatNumberToPrice(finalQuots.monthly.payment)}/Month
            <MonthlyTooltip
              finalQuots={finalQuots}
              annual_interest_rate={finalQuots.monthly.annual_interest_rate}
              position={is576 ? "bottom center" : ""}
            />
          </h4>
        </div>
      </div>

      <div className={"actionSection"}>
        <div className={"leftSideWrapper"}>
          {isRandomFlow ? (
            <>
              {isSlidersFlow ? (
                <SlidersForm
                  quotationState={props.quotationState}
                  onChangeState={props.onChangeState}
                  setQuotationState={props.setQuotationState}
                  finalQuots={finalQuots}
                  car={props.car}
                  setStep={props.setStep}
                  setUserFlow={setUserFlow}
                  isRandomFlow={isRandomFlow}
                  isSlidersFlow={true}
                  fetchRebates={fetchRebates}
                  recalculateValues={recalculateValues}
                />
              ) : (
                <PreQualifiedForm
                  form={form}
                  onChangeState={onChangeState}
                  setQuotationState={props.onChangeProps}
                  errors={errors}
                  logged={logged}
                  openSession={props.openSession}
                  CreditSolutionRequest={CreditSolutionRequest}
                  retailer={retailer}
                  disabled={disabled}
                  setNewCustomerId={props.setNewCustomerId}
                />
              )}
            </>
          ) : (
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={panes}
              style={{
                "--tab-primary": theme?.typography.primary,
              }}
            />
          )}
        </div>

        <div className={"rightSideWrapper"}>
          <div className={"carouselWrapper"}>
            <CustomCarousel images={car.images} carId={car.id} theme={theme} />
          </div>
        </div>
      </div>

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}

      {modal.message && (
        <AlertModal hideModal={() => hideModal()} modal={modal} />
      )}

      {coxData.show && <Cox data={coxData} continue={continueProcess} />}
    </Container>
  );
};

export default PreQualified;
