import React from "react";
import { Popup } from "semantic-ui-react";

import InfoCircleIcon from "../../../images/infoCircle.svg";

import "./SectionTitle.sass";

const Title = ({ size, align, popup, children }) => {
  return (
    <div className={"sectionTitleWrapper"}>
      <h3 style={{ fontSize: size, textAlign: align }}>{children}</h3>

      {popup && (
        <Popup
          trigger={<img src={InfoCircleIcon} className={'infoButton'} />}
          content={popup}
          style={{ border: 0, color: '#4a4a4a' }}
          position="top right"
          basic
        />
      )}
    </div>
  );
};

export default Title;
