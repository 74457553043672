import React from "react";
import cn from "classnames";

import { YearInput as SemanticYearInput } from "semantic-ui-calendar-react";

import "./styles.sass";

const YearInput = ({ className, ...rest }) => {
  return (
    <div className={cn("yearInputWrapper", className)}>
      <SemanticYearInput {...rest} />
    </div>
  );
};

export default YearInput;
