import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { createDwollaProfile } from '../requests';

export const DwollaProfile = (props) => {
  const { customer } = props;
  const [form, setForm] = useState({ agree: false });
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm({ ...auxForm });
  }

  const createProfile = () => {
    setIsLoading(true);
    setDisabled(true);
    createDwollaProfile({}).then(response => {
      setDisabled(false);
      setIsLoading(false);
      setModal({ status: 201, message: "Dwolla profile was crated", customer: response.data });
    }).catch(error => {
      setDisabled(false);
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }

  // ---------------------------- hide modal -----------------------------------
  const hideModal = () => {
    if (modal.status === 201) {
      let auxCustomer = { ...customer };
      auxCustomer.dwolla_terms_accepted = true;
      auxCustomer.dwolla_id = modal.customer.dwolla_id;
      props.updateCustomer(auxCustomer, true);
    }
    setModal({ modal: {} });
  }

  useEffect(() => {
    let auxForm = { ...form };
    auxForm.agree = customer.dwolla_terms_accepted;
    setForm({ ...auxForm });
    setDisabled(false);
  }, [customer])


  return (
    <Grid>
      <Grid.Row >
        <Header as={"h4"}>
          You do not have a Dwolla Profile
        </Header>
      </Grid.Row>
      <Grid.Row >
        <Form>
          <Form.Group>
            <Form.Checkbox className="agree-check"
              label={
                <label>I agree to the terms and conditions. Please view our Privacy Policy and Terms of Use.
                  As well as our Vendor
                  <a className="dwolla-link" href="https://www.dwolla.com/legal/tos/" rel="noopener noreferrer" target="_blank"> Dwolla's Terms of Service </a>
                  and
                  <a className="dwolla-link" href="https://www.dwolla.com/legal/privacy/" rel="noopener noreferrer" target="_blank"> Privacy Policy</a>
                </label>
              }
              checked={form.agree}
              onChange={(e, value) => onEditInput('agree', value.checked)}
            />
          </Form.Group>
        </Form>
        <Button style={{ marginBottom: "1em", minWidth: "150px" }} positive onClick={createProfile} disabled={!form.agree || disabled}>+ Dwolla Profile</Button>
      </Grid.Row>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent hideModal={hideModal} modal={modal} />
      }
    </Grid>
  );
};