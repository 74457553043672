import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { CoApplicantForm } from './CoApplicantForm';

export const CoApplicantData = (props) => {
  const { coApplicant, type } = props;
  const [open, setOpen] = useState(false);

  const finalStep = (response, action) => {
    props.updateList(response, action);
    setOpen(false);
  }

  const openForm = () => {
    setOpen(true)
  }

  return (
    <Modal size="small" open={open} closeOnEscape={false} closeOnDimmerClick={false}
      trigger={
        <Button color={coApplicant && coApplicant.id ? 'blue' : 'green'} style={{marginBottom: "1em", minWidth: "100px"}} icon onClick={openForm} >
          {
            coApplicant && coApplicant.id ? 'Edit' : type === 'guarantor' ? 'Add Guarantor' : type === 'driver' ? 'Add Driver' : 'Add Co-Applicant'
          }
        </Button>
      }>
      <Modal.Header>
        {type === 'guarantor' ? 'Guarantor' : type === 'driver' ? 'Driver' : 'Co-Applicant'}
      </Modal.Header>
      <Modal.Content>
        <CoApplicantForm coApplicant={coApplicant} finalStep={finalStep} type={type} />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setOpen(false)} >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};