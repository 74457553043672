import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Grid, Header } from 'semantic-ui-react';
import { Calendar } from './Calendar';

import "./DebitAgreement.sass";
import { BuyerInformation } from './BuyerInformation';
import { LoanInformation } from './LoanInformation';
import { DebitInformation } from './DebitInformation';
import { FeeInformation } from './FeeInformation';
import { Footer } from './Footer';
import { Text1 } from './Text1';
import { Text2 } from './Text2';
import { Text3 } from './Text3';
import { Summary } from './Summary';
import { BankInformation } from './BankInformation';
import { VehicleInformation } from './VehicleInformation';

export const DebitAgreement = ({ customer, plan, payments, amortization, fee, quote, retailer, data, step }) => {
  const [calendars, setCalendars] = useState([])

  const groupDates = () => {
    let auxArray = [], month = '', year = '', objects = [];
    if (payments) {
      for (const item of payments) {
        if (month === moment(item.date).month() && year === moment(item.date).year()) {
          if (item.type !== 'monthly') {
            objects.push(moment(item.date).format("MM-DD-YYYY"));
          }
        } else {
          if (objects.length > 0) auxArray.push(objects);
          objects = [];
          objects.push(moment(item.date).format("MM-DD-YYYY"));
          month = moment(item.date).month();
          year = moment(item.date).year();
        }
      }
    }
    if (objects.length > 0) auxArray.push(objects);
    setCalendars(auxArray);
  }
  useEffect(() => {
    groupDates();
  }, [plan, payments])

  const title = () => {
    return (
      <Grid columns={2} stackable>
        <Grid.Column textAlign='right'>
          <img src={require("../../../../../images/MyPayPow.png")} style={{ width: "250px" }} />
        </Grid.Column>
        <Grid.Column>
          <Header as="h2" className='title' textAlign='center' content='ACH DEBIT AGREEMENT' />
          <div className='sub-title'>
            <small>REV. 7/20 Send</small>
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <div className='debit-agreement'>
      {
        (step === 5) && (
          <div >
            <Grid columns={2} stackable>
              <Grid.Column textAlign='right'>
                <img src={require("../../../../../images/MyPayPow.png")} style={{ width: "250px" }} />
              </Grid.Column>
              <Grid.Column>
                <Header as="h2" className='title' textAlign='center' content='ACH DEBIT AGREEMENT' />
                <div className='sub-title'>
                  <small>REV. 7/20 Send</small>
                  <span>Send to: <a href="mailto: orders@advantage2u.com">orders@advantage2u.com</a></span>
                  <span>Fax: (xxx) xxx-xxxx</span>
                </div>
              </Grid.Column>
            </Grid>
            <Grid columns={2} stackable>
              <Grid.Column>
                <BuyerInformation customer={customer} plan={plan} />
                <BuyerInformation empty />
                <BankInformation customer={customer} />
                <FeeInformation plan={plan} fee={fee} />
              </Grid.Column>
              <Grid.Column>
                <LoanInformation plan={plan} amortization={amortization} data={data} />
                <DebitInformation customer={customer} plan={plan} data={data} />
                <VehicleInformation car={quote?.car || null} retailer={retailer} />
              </Grid.Column>
            </Grid>
            <Footer />
          </div>
        )
      }
      {
        (step === 6) && (
          <div>
            {title()}
            <Text1 />
            <Footer />
          </div>
        )
      }
      {
        (step === 7) && (
          <div>
            {title()}
            <Text2 />
            <Footer />
          </div>
        )
      }
      {
        (step === 8) && (
          <div>
            {title()}
            <Text3 />
            <Footer />
          </div>
        )
      }
      {
        (step === 9) && (
          <Summary plan={plan} amortization={amortization} fee={fee} data={data} />
        )
      }
      {
        (step === 10) && (
          <div>
            <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Grid.Column mobile={16} tablet={8} computer={8} textAlign="right">
                <img src={require("../../../../../images/MyPayPow.png")} style={{ width: "250px", marginRight: "30px" }} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={6} textAlign="left">
                <small>You must contact My Payment Power 7 days prior
                  to your scheduled debit date to make any changes to that debit</small>
              </Grid.Column>
            </Grid>
            <Grid columns={4} stackable>
              {
                calendars.map(item => (
                  <Grid.Column className="calendar">
                    <Calendar days={item} />
                  </Grid.Column>
                ))
              }
            </Grid>
          </div>
        )
      }
    </div>
  );
};