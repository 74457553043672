import moment from 'moment';
import React, { useState } from 'react';
import { Button, Dimmer, Grid, Icon, Loader, Message, Modal, Pagination, Popup, Table } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { ConfirmModal } from '../confirmModal/ConfirmModal';
import { deleteLender, getLenders } from '../requests';
import { CreateLenderAccount } from './CreateLenderAccount';

export const LenderAccounts = (props) => {
  const { customer } = props;
  const sizePagination = 8;
  const [pagination, setPagination] = useState({ totalPages: 0, currentPage: 1, startPage: 0, endPage: sizePagination })
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [lenders, setLenders] = useState([]);
  const [confirmModal, setConfirmModal] = useState({});

  // ------------------------------ delete account
  const confirmDelete = (flag) => {
    setConfirmModal(false);
    if (flag) {
      deleteAccount();
    }
  }

  const deleteAccount = () => {
    setIsLoading(true);
    const lenderId = confirmModal.lenderId;
    deleteLender(lenderId).then(response => {
      setIsLoading(false);
      let auxLenders = [...lenders];
      let i = 0;
      for (const item of lenders) {
        if (item.id === lenderId) {
          auxLenders.splice(i, 1);
          break;
        }
        i++;
      }
      setLenders(auxLenders);
      calculatePages(auxLenders);
      setModal({ status: 204, message: "Lender account was deleted" });
    }).catch(error => {
      console.log(error);
      setIsLoading(false);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }

  // ----------------------------- update lenders listas after add new one -----------
  const updateLenders = (lenders) => {
    setLenders(lenders);
    calculatePages(lenders);
  }

  // ------------------ get page -------------------------
  const paginationClick = (currentPage, totalPages) => {
    let startPage = (currentPage - 1) * sizePagination;
    let endPage = startPage + sizePagination;
    let auxPagination = { ...pagination };
    auxPagination.currentPage = currentPage;
    auxPagination.startPage = startPage;
    auxPagination.endPage = endPage;
    if (totalPages) {
      auxPagination.totalPages = totalPages;
    }
    setPagination({ ...auxPagination });
  }

  // ---------------------------- open modal -----------------------------------------
  const openModal = () => {
    setIsLoading(true);
    getLenders().then(response => {
      setIsLoading(false);
      console.log(response);
      setOpen(true);
      setLenders(response.data);
      calculatePages(response.data);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }

  const calculatePages = (lenders) => {
    let totalPages = Math.ceil(lenders.length / sizePagination);
    paginationClick(1, totalPages);
  }

  return (
    <Grid>
      <Button style={{marginBottom: "1em", minWidth: "150px"}} primary onClick={openModal}>Lender Accounts</Button>
      <Modal size="small" open={open} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>
          Lender Accounts
        </Modal.Header>
        <Modal.Content className='loanDetails'>
          <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Account</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                lenders.slice(pagination.startPage, pagination.endPage).map(item => (
                  <Table.Row>
                    <Table.Cell>{item.name + " - #" + item.routingNumber}</Table.Cell>
                    <Table.Cell>{moment(item.created_at).format('LLL')}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {
                        item.payment_plan && item.payment_plan.status === 1 ?
                          <Popup content='You are not able to delete. The lender account is in a activated payment plan' trigger={
                            <div>
                              <Button color='red' disabled>
                                Delete
                              </Button>
                            </div>
                          } />
                          :
                          <Button color='red'
                            onClick={() => setConfirmModal({
                              title: "Delete Lender Account",
                              message: (item.payment_plan && (item.payment_plan.status === 0 || item.payment_plan.status === 1) ? "This loand account belong to a payment plan." : "") + "Are you sure you want to delete the lender account?", lenderId: item.id
                            })}
                          >
                            Delete
                          </Button>
                      }
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
          {
            lenders.length === 0 && (
              <Message>
                <Message.Header style={{ textAlign: "center" }}>You don't have lender accounts created</Message.Header>
              </Message>
            )
          }
          <br />
          {
            lenders.length > 0 && (
              <Grid centered>
                <Pagination onPageChange={(e, data) => paginationClick(data.activePage)} size='mini'
                  activePage={pagination.currentPage} defaultActivePage={1} totalPages={pagination.totalPages}
                />
              </Grid>
            )
          }
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)} >
            Close
          </Button>
          {
            customer.account && customer.account.verified ?
              <CreateLenderAccount lenders={lenders} updateLenders={updateLenders} />
              :
              <Popup content="You can't create a lender account. You don't hava a verified funding source account."
                trigger={
                  <div style={{ display: "inline-block" }}>
                    <Button positive disabled>
                      <Icon name='add' /> Lender Account
                    </Button>
                  </div>
                }
              />
          }
        </Modal.Actions>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      }
      {
        confirmModal.message &&
        <ConfirmModal modal={confirmModal} afterEvent={confirmDelete} />
      }
    </Grid>
  );
};