import React, { useState, useEffect } from "react";
import { Form, Header, Icon, Message } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import cn from "classnames";

import ProductManagerModal from "./ProductManagerModal";
import Button from "../../../global/Button";
import Input from "../../../global/Input";

import { allowedCategoryNames } from "../../../helpers/general";

import { schema } from "./config";

import "./PackageConfigurator.sass";

const PackageConfigurator = ({
  selectedPackage,
  products,
  providers,
  categories,
  onCancel,
  onCreatePackage,
  onUpdatePackage,
  onDeletePackage,
}) => {
  const [selected, setSelected] = useState({});

  const filteredProviders = providers.filter((provider) => {
    const allowed =
      provider.products.filter((p) => p.status !== "disabled").length > 0;

    return allowed;
  });

  const isAllProductsDisabled = Object.values(products).every((p) =>
    _.isEmpty(p)
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOnAddProducts = ({ category, products, customTitle }) => {
    setSelected((prev) => ({
      ...prev,
      [category]: {
        products,
        customTitle,
      },
    }));
  };

  const handleOnRemoveProducts = (category) => {
    setSelected((prev) => _.omit(prev, category));
  };

  const handleOnSubmit = ({ title }) => {
    if (selectedPackage) {
      onUpdatePackage({ title, products: selected, id: selectedPackage.id });
    } else {
      onCreatePackage({ title, products: selected });
    }
  };

  useEffect(() => {
    if (selectedPackage) {
      const allProducts = providers.reduce((all, provider) => {
        return [...all, ...provider.products];
      }, []);

      const filling = {};

      for (const category in selectedPackage.filling) {
        const { products, customTitle } = selectedPackage.filling[category];

        const updated = products.map((p) => {
          const _product = allProducts.find(
            (item) => Number(item.ex_1_product_id) === p.product_id
          );

          const _provider = providers.find((provider) =>
            provider.products.find(
              (providerProduct) =>
                Number(providerProduct.ex_1_product_id) === p.product_id
            )
          );

          return {
            priority: p.priority,
            productId: _product.ex_1_product_id,
            providerId: _provider.ex_1_provider_id,
            name: _product.product_name,
            value: p.value,
            type: p.type,
            conditions: p.conditions,
          };
        });

        filling[category] = {
          customTitle,
          products: updated,
        };
      }

      setValue("title", selectedPackage.title);
      setSelected(filling);
    }
  }, []);

  return (
    <>
      <Header as={"h3"}>Package Menu Configurator</Header>

      {!isAllProductsDisabled ? (
        <>
          <div className={"packageConfiguratorActions"}>
            <Form>
              <Controller
                name={"title"}
                control={control}
                render={({ field }) => (
                  <Form.Field required>
                    <Input
                      {...field}
                      placeholder={"Package Menu Name"}
                      error={errors?.title && errors?.title.message}
                    />
                  </Form.Field>
                )}
              />
            </Form>

            <div className={"actionsButtonsWrapper"}>
              <Button onClick={onCancel} buttonStyle="skyblue">
                Back
              </Button>

              {selectedPackage && (
                <Button
                  onClick={() => onDeletePackage(selectedPackage.id)}
                  buttonStyle="danger"
                >
                  Delete
                </Button>
              )}

              <Button
                onClick={handleSubmit(handleOnSubmit)}
                disabled={_.isEmpty(selected)}
              >
                {selectedPackage ? "Save" : "Create"}
              </Button>
            </div>
          </div>

          <div className={"packageConfiguratorWrapper"}>
            <div className={"packageConfiguratorTableWrapper"}>
              <table className={"packageConfiguratorTable"}>
                <thead className={"packageConfiguratorTableHead"}>
                  <tr>
                    <th />

                    {filteredProviders.map((provider) => (
                      <th key={provider.ex_1_provider_id}>
                        {provider.provider_name}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className={"packageConfiguratorTableBody"}>
                  {categories.map((category, index) => {
                    const productsByCategory = products[category];
                    const providerIds = _.uniq(
                      productsByCategory.map((p) => p.provider_id)
                    );
                    const separatedProductsByProviderId = providerIds.reduce(
                      (all, id) => {
                        const filtered = productsByCategory.filter(
                          (p) => p.provider_id === id
                        );

                        return {
                          ...all,
                          [id]: filtered,
                        };
                      },
                      {}
                    );
                    const selectedProducts = selected[category]?.products || [];

                    if (_.isEmpty(productsByCategory)) return;

                    return (
                      <tr key={category + index}>
                        <td>{allowedCategoryNames[category]}</td>

                        {filteredProviders.map((provider) => {
                          const isCurrentProvider = selectedProducts.every(
                            (p) => p.providerId === provider.ex_1_provider_id
                          );
                          const isSelected =
                            isCurrentProvider && !!selectedProducts.length;
                          const products =
                            separatedProductsByProviderId[
                              provider.ex_1_provider_id
                            ];
                          const isEveryPending = products?.every(
                            (p) => p.status === "pending"
                          );
                          const enabledProducts = products?.filter(
                            (p) => p.status === "enabled"
                          );

                          return (
                            <td
                              className={cn(
                                !isCurrentProvider && "disabledProductCell",
                                isSelected && "selectedProductCell"
                              )}
                            >
                              {products && (
                                <ProductManagerModal
                                  products={enabledProducts}
                                  provider={provider}
                                  category={category}
                                  isSelected={isSelected}
                                  selectedProducts={selected[category]}
                                  onAdd={handleOnAddProducts}
                                  onRemove={handleOnRemoveProducts}
                                >
                                  <button
                                    className={cn(
                                      "productButton",
                                      isEveryPending && "pendingProductButton"
                                    )}
                                  >
                                    {products.map((product) => (
                                      <span
                                        className={cn(
                                          product.status === "pending" &&
                                            "pendingProduct"
                                        )}
                                      >
                                        {product.status === "pending" && (
                                          <Icon name="clock outline" />
                                        )}

                                        {product.product_name}
                                      </span>
                                    ))}
                                  </button>
                                </ProductManagerModal>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: "fit-content" }}>
            <Button onClick={onCancel} buttonStyle="skyblue">
              Back
            </Button>
          </div>

          <Message info>
            <Message.Header>Warning</Message.Header>

            <Message.Content>
              Retailer doesn't have enabled products from the providers
            </Message.Content>
          </Message>
        </>
      )}
    </>
  );
};

export default PackageConfigurator;
