import React, { useState, useEffect } from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import Slider from "rc-slider";

import Input from "../../../global/Input";
import Button from "../../../global/Button";

import { calculatePayments, getMonthlyPayment } from "../../../helpers/general";
import { useTheme } from "../../../helpers/theme";

import "./SlidersForm.sass";

const Handle = Slider.Handle;

const SlidersForm = (props) => {
  const { theme } = useTheme();

  const {
    quotationState,
    finalQuots: quot,
    car,
    onChangeState: setState,
    setStep,
    setUserFlow,
    isRandomFlow,
    isSlidersFlow,
    recalculateValues,
    interestRange,
    fetchRebates,
  } = props;

  const [slidersState, setSlidersState] = useState({});

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;

    return (
      <Handle value={value} {...restProps}>
        <div className="inner">
          <div className={`wdc-tooltip${dragging ? " active" : ""}`}>
            <span className="wdc-tooltip-inner">{value}</span>
          </div>
        </div>
      </Handle>
    );
  };

  const onChangeState = (value, label) => {
    setState(value, String(label));
    setSlidersState((prevState) => ({
      ...prevState,
      [label]: value,
    }));
  };

  const handleOnChangeInput = (event, label) => {
    event.persist();

    setState(event.target.value, label);
    setSlidersState((prevState) => ({
      ...prevState,
      [label]: event.target.value,
    }));
  };

  const setDefaultValues = () => {
    let downMarks = {};

    for (
      let i = quotationState.min_loan_length;
      i <= quotationState.max_loan_length;
      i++
    ) {
      if (i % 12 == 0) {
        downMarks[i] = i + " mo";
      }
    }

    setSlidersState((prevState) => ({
      ...prevState,
      loan_length: quotationState.loan_length,
      min_loan_length: quotationState.min_loan_length,
      max_loan_length: quotationState.max_loan_length,
      downMarks,
    }));
  };

  const handleStateUpdate = () => {
    setSlidersState((prevState) => ({
      ...prevState,
      credit_score: quotationState.credit_score,
      loan_length: quotationState.loan_length,
    }));
  };

  const handleOnClickContinue = () => {
    const myDiv = document.getElementsByClassName("quot-content")[0];
    myDiv.scrollTop = 0;

    setStep(0);
    if (!isRandomFlow) setUserFlow("sliders");

    fetchRebates();
  };

  const handleOnBlurSlider = (field, value) => {
    const dto = {
      amountFinanced: quot.monthly.amount,
      downPayment: quotationState.down_payment,
      loanTerm: slidersState.loan_length,
    };

    const {
      loan_length,
      formula,
      interestRage,
      insurance_protection,
      trade_in,
      money_owed,
      min_down_payment,
      max_down_payment,
      credit_score,
    } = quotationState;

    if (field === "credit_score") {
      Object.assign(dto, { interestRate: interestRange[value] });

      const dataForCalculations = {
        car: car,
        loanLength: loan_length,
        formula: formula,
        annualInterestRate: interestRage[value],
        isInsuranceProtection: insurance_protection,
        tradeIn: trade_in,
        moneyOwed: money_owed,
      };

      const maxMonthlyPayment = getMonthlyPayment({
        ...dataForCalculations,
        downPayment: min_down_payment,
      });

      const minMonthlyPayment = getMonthlyPayment({
        ...dataForCalculations,
        downPayment: max_down_payment,
      });

      const finalQuots = calculatePayments(
        car,
        quotationState.loan_length,
        quotationState.min_down_payment,
        quotationState.formula,
        quotationState.interestRage[value],
        quotationState.insurance_protection,
        quotationState.trade_in,
        quotationState.money_owed
      );

      setState(maxMonthlyPayment, "max_monthly_payment");
      setState(minMonthlyPayment, "min_monthly_payment");
      setState(finalQuots, "finalQuots");
    }

    if (field === "loan_length") {
      Object.assign(dto, { loanTerm: +value });

      const dataForCalculations = {
        car: car,
        loanLength: +value,
        formula: formula,
        annualInterestRate: interestRage[credit_score],
        isInsuranceProtection: insurance_protection,
        tradeIn: trade_in,
        moneyOwed: money_owed,
      };

      const maxMonthlyPayment = getMonthlyPayment({
        ...dataForCalculations,
        downPayment: min_down_payment,
      });

      const minMonthlyPayment = getMonthlyPayment({
        ...dataForCalculations,
        downPayment: max_down_payment,
      });

      setState(maxMonthlyPayment, "max_monthly_payment");
      setState(minMonthlyPayment, "min_monthly_payment");

      recalculateValues(dto);
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, []);

  useEffect(() => {
    handleStateUpdate();
  }, [quotationState]);

  return (
    <Grid
      className={"slidersWrapper"}
      style={{
        "--slider-primary": theme?.typography.primary,
        "--slider-secondary": theme?.typography.secondary,
      }}
    >
      <Grid.Column mobile={16} tablet={16} className="colum-slider">
        <Segment
          className="segmentGrapper"
          style={{ backgroundColor: "white" }}
        >
          <div className="header-slider">
            <Header as="h3">Loan Length</Header>

            <Input
              min={slidersState.min_loan_length}
              max={slidersState.max_loan_length}
              value={slidersState.loan_length}
              onChange={(event) => handleOnChangeInput(event, "loan_length")}
              onBlur={(e) => handleOnBlurSlider("loan_length", e.target.value)}
              type="number"
              noMargin
              theme={theme?.input}
            />
          </div>

          <div className="sliderWrapper" style={{ backgroundColor: "white" }}>
            <Slider
              min={slidersState.min_loan_length}
              max={slidersState.max_loan_length}
              step={12}
              value={slidersState.loan_length}
              onChange={(value) => onChangeState(value, "loan_length")}
              onAfterChange={(value) => {
                handleOnBlurSlider("loan_length", value);
              }}
              handleStyle={{
                backgroundColor: "white",
              }}
              handle={handle}
              disabled={props.disabled ? true : false}
              tipProps={{
                placement: "bottom",
                overlayClassName: "foo",
                prefixCls: "rc-slider-tooltip",
              }}
              marks={slidersState.downMarks}
            />
          </div>
        </Segment>
      </Grid.Column>

      <Grid.Column mobile={16} tablet={16} className="colum-slider">
        <Segment className="segmentGrapper">
          <div className="header-slider">
            <Header as="h3">Credit Score Range</Header>

            <span className="only-text">
              {quotationState.credit_range.ranges[quotationState.credit_score]}
            </span>
          </div>

          <div className="sliderWrapper sliderScore">
            <Slider
              min={0}
              max={4}
              step={quotationState.step_credit_range}
              value={quotationState.credit_score}
              onChange={(value) => onChangeState(value, "credit_score")}
              onAfterChange={(value) =>
                handleOnBlurSlider("credit_score", value)
              }
              disabled={props.disabled ? true : false}
              tipProps={{
                placement: "bottom",
                overlayClassName: "foo",
                prefixCls: "rc-slider-tooltip",
              }}
              marks={quotationState.credit_range.ranges}
            />
          </div>
        </Segment>
      </Grid.Column>

      <div className={"confirmButtonWrapper"}>
        <Button onClick={handleOnClickContinue} theme={theme?.button}>
          Continue: Add Trade, Protection & Personalized Options
        </Button>
      </div>
    </Grid>
  );
};

export default SlidersForm;
