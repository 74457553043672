import React, { useEffect, useState } from "react";
import { Grid, Modal, Divider } from "semantic-ui-react";
import { FormatCashDownToPrice } from "../../helpers/general";

import Input from "../../global/Input";
import Button from "../../global/Button";

import TradeIn from "../../../images/tradeInPlaceholder.jpg";

import { defaultTheme, useTheme } from "../../helpers/theme";

// styles
import "./TradeModal.sass";

export const TradeModal = (props) => {
  const { theme } = useTheme();

  const { trade_in, money_owed, disabledTrade, detail, image } = props;
  const [form, setForm] = useState({ trade_in: 0, money_owed: 0 });
  const [tradeText, setTradeText] = useState(false);
  const [open, setOpen] = useState(false);

  const isTradeInAdded = trade_in !== 0 || money_owed !== 0;

  // ----------------------- change trade in value
  const changeTrade = (label, value, flag) => {
    let auxForm = { ...form };
    if (flag) {
      // let car_price = this.state.car.price * (this.state.formula.tax_rate / 100) + this.state.car.price;
      // if (this.state.formula.dealer_fees) {
      //     car_price += this.state.formula.dealer_fees;
      // }
      // let max_down_payment = this.state.max_down_payment;
      // if (this.props.retailer.credit_range.max_down_payment != null) {
      //     max_down_payment = (this.props.retailer.credit_range.max_down_payment / 100) * car_price;
      // } else {
      //     max_down_payment = car_price;
      // }
      // let down_payment = this.state.quots.monthly.down_payment;
      // if (((+value) + down_payment) > max_down_payment) {
      //     value = parseInt(max_down_payment - down_payment);
      // }
      if (
        value === "" ||
        value.split(" ").join("") === "" ||
        isNaN(value) ||
        +value < 0
      ) {
        value = 0;
      }
      props.tradeChange(value, label);
    }
    auxForm[label] = value;
    setForm(auxForm);
  };

  const parseValue = (value) => {
    if (isNaN(value) || value === "") {
      return "";
    }
    return parseInt(value);
  };

  useEffect(() => {
    setForm({
      trade_in: +trade_in === 0 ? "" : +trade_in,
      money_owed: +money_owed === 0 ? "" : +money_owed,
    });
  }, [props]);

  return (
    <Modal
      size="small"
      className="trade-modal"
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={true}
      onClose={() => setOpen(false)}
      closeIcon
      trigger={
        <div className={"optionColumnWrapper"}>
          {!disabledTrade && (
            <>
              <h5>Trade-In Added</h5>

              <div>
                <img src={image || TradeIn} alt={"Trade-In Icon"} />

                <div className={"optionActionWrapper"}>
                  <span>{FormatCashDownToPrice(+trade_in - +money_owed)}</span>

                  <Button
                    className={"addOptionButtonWrapper"}
                    buttonStyle="secondary"
                    onClick={() => setOpen(true)}
                    theme={theme?.button}
                  >
                    {isTradeInAdded ? "Edit" : "Add"}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      }
    >
      <Modal.Header>Add a Trade-In</Modal.Header>

      <Modal.Content scrolling open={open}>
        <Grid style={{ padding: "1rem" }}>
          <Grid.Column
            mobile={16}
            tablet={7}
            computer={7}
            className={"tradeModalInputWrapper"}
          >
            <h4
              className="title-info"
              style={{ color: theme?.typography?.primary }}
            >
              Consider My Trade/Sell Value
            </h4>

            <Input
              value={parseValue(form.trade_in)}
              type="number"
              onChange={(e) => changeTrade("trade_in", e.target.value)}
              onBlur={(e) => changeTrade("trade_in", e.target.value, true)}
              label={{ basic: true, content: "$" }}
              labelPosition="left"
              maxLength="50"
              className={"tradeModalInput"}
              noMargin
              theme={theme?.input}
            />
            <small style={{ display: "block" }}>
              {!tradeText ? (
                "Enter any verified trade-in offer amount"
              ) : tradeText === "tradeByMe" ? (
                "Use my preferred trade-in value"
              ) : (
                <span>
                  3<sup>rd</sup> Party Instant wholesale offer
                </span>
              )}
            </small>
          </Grid.Column>

          <Grid.Column
            mobile={16}
            tablet={9}
            computer={9}
            verticalAlign="middle"
            className={"tradeModalInputWrapper"}
          >
            <h4
              className="title-info"
              style={{ color: theme?.typography?.primary }}
            >
              Money Still Owed On Trade/Sell Vehicle
            </h4>

            <Input
              value={parseValue(form.money_owed)}
              type="number"
              onChange={(e) => changeTrade("money_owed", e.target.value)}
              onBlur={(e) => changeTrade("money_owed", e.target.value, true)}
              label={{ basic: true, content: "$" }}
              labelPosition="left"
              maxLength="50"
              className={"tradeModalInput"}
              noMargin
              theme={theme?.input}
            />

            <small style={{ display: "block" }}>
              Enter amount you still owe (Payoff $$)
            </small>
          </Grid.Column>
        </Grid>

        <div className={"updateButtonWrapper"}>
          <Button
            buttonStyle={"primary"}
            className="btn-detail"
            onClick={() => {
              setOpen(false);
              props.onClose && props.onClose();
            }}
            theme={theme?.button}
          >
            Update & View Prequalification Status
          </Button>
        </div>
        <Divider />

        <div className={"tradeModalFooter"}>
          <div className={"imageContainer"}>
            <a
              href={"https://c.maxallowance.com/d/rbsouthbend"}
              target="_blanc"
            >
              <img
                src={require("../../../images/max-allowance.jpg")}
                alt=""
                className="logo"
              />
            </a>
          </div>

          <div className={"description"}>
            <span>
              Where vehicle photos & video from your driveway reveal today's
              highest local <strong>trade or sell</strong> dealer offer near you
            </span>
            <a
              className="button-green-outline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://c.maxallowance.com/d/ewaldauto"
              style={{
                "--success-button-color":
                  theme?.button?.success?.color ||
                  defaultTheme.button.success.color,
                "--success-button-background":
                  theme?.button?.success?.background ||
                  defaultTheme.button.success.background,
                "--button-border-radius":
                  (theme?.button?.borderRadius ||
                    defaultTheme.button.borderRadius) + "px",
              }}
            >
              <h3>GET ME THE MAX*</h3>
            </a>
          </div>
        </div>

        <div className={"note"}>
          <small>
            *A transparency focused driveway-to-dealer trade or sell your car
            experience. A better than CarMax, trust building service to bridge
            the gaps between local shoppers & local dealers.
          </small>
        </div>
      </Modal.Content>
    </Modal>
  );
};
