import React, { useEffect, useState } from 'react';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import { getCoxAPR } from '../../utilities/requests';

const Cox = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { type, data } = { ...props };

    const request = () => {
        let params = {
            zip_code: data.user.postalCode,
            creditScore: data.user.creditScore,
            loanTerm: data.quote.contracted_term,
            dealer_id: data.retailer.dealer_id,
            vin: data.car.vin,
            carPrice: data.quote.final_price,
            coxPrice: data.quote.coxPrice,
            tradeIn: data.tradeIn,
            moneyOwed: data.moneyOwed,
            // carPrice: data.quote.amount,
        }
        setIsLoading(true);
        getCoxAPR(params).then(response => {
            setIsLoading(false);
            response = response.data;
            // console.log(JSON.stringify(response));
            let result = null, validateResponse = true, quotes = null;
            if (response.programQuotesResults && response.programQuotesResults.length === 5) {
                result = { ...response };
                for (let i = 0; i < result.programQuotesResults.length; i++) {
                    const element = result.programQuotesResults[i].quotes;
                    result.programQuotesResults[i].quotes = element.sort(((a, b) => a.rate.apr - b.rate.apr));
                }
                for (const quote of result.programQuotesResults[4].quotes) {
                    if (quote.rate?.apr > 0 && quote.taxSummary?.taxItems[0] && quote.payment?.paymentAmount > 0) {
                        quotes = [];
                        quotes.push({ ...quote });
                        break;
                    }
                }
                if (quotes.length > 0) {
                    result.programQuotesResults.pop();
                    let j = 0;
                    for (let i = result.programQuotesResults.length - 1; i >= 0; i--) {
                        const element = result.programQuotesResults[i];
                        for (const quote of element.quotes) {
                            if (quote.rate?.apr > 0 && quote.taxSummary?.taxItems[0] 
                                && quote.payment?.paymentAmount > 0 && quote.rate.apr > quotes[j].rate.apr) {
                                quotes.push({ ...quote });
                                break;
                            }
                        }
                        j++;
                    }
                    quotes = quotes.reverse();
                }
                if (!validateResponse || response.programQuotesResults < 5 || quotes.length < 5) {
                    quotes = null;
                }
            }
            props.continue(quotes);
        }).catch(error => {
            setIsLoading(false);
            props.continue(null);
            console.log(error);
        });

    }

    useEffect(() => {
        request();
    }, []);

    return (
        <Container>
            {
                isLoading && (
                    <Dimmer className="loading" active>
                        <Loader />
                    </Dimmer>
                )
            }
        </Container>
    )
}

export default Cox;