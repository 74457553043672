import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import {
  returnMonthlySummary,
  returnQuoteSummary,
} from "../../helpers/general";
import { useTheme } from "../../helpers/theme";

import "./WeeklyTooltip.sass";

export const WeeklyTooltip = ({ finalQuots, iconStyle, ...rest }) => {
  const { theme } = useTheme();

  const [seeMore, setSeeMore] = useState(false);

  return (
    <Popup
      hoverable
      size="small"
      onClose={() => setSeeMore(false)}
      content={
        <div className={"tooltipWrapper"}>
          {returnQuoteSummary(finalQuots)}
          {!seeMore ? (
            <span
              className="tooltip-button"
              onClick={(e) => {
                e.stopPropagation();
                setSeeMore(true);
              }}
              style={{ color: theme?.typography?.primary }}
            >
              See more...
            </span>
          ) : (
            <div>
              {returnMonthlySummary(
                finalQuots,
                finalQuots.monthly.annual_interest_rate
              )}
              <span
                className="tooltip-button"
                onClick={() => setSeeMore(false)}
                style={{ color: theme?.typography?.primary }}
              >
                See less...
              </span>
            </div>
          )}
        </div>
      }
      trigger={
        <Icon
          className="infoIcon"
          link
          name="info circle"
          onClick={(e) => e.stopPropagation()}
          style={iconStyle}
        />
      }
      style={{ paddingRight: 0 }}
      {...rest}
    />
  );
};
