import React, { useContext } from "react";
import { useMediaPredicate } from "react-media-hook";

import Title from "../SectionTitle";
import PaymentsTable from "./PaymentsTable";

import { AgreementCtx } from "../context";

import "./PaymentOptions.sass";

const PaymentOptions = () => {
  const { data } = useContext(AgreementCtx);

  const is768 = useMediaPredicate("(max-width: 768px)");

  return (
    <div className={"paymentOptionsWrapper"}>
      <Title
        size={is768 ? 16 : 20}
        align={"center"}
        popup={
          <div className={"popupWrapper"}>
            <span>
              All figures listed are estimates. These figures are subject to
              credit approval, and are subject to change
            </span>
          </div>
        }
      >
        Payment Options
      </Title>

      <div className={"infoWrapper"}>
        <div className={"imageWrapper"}>
          <img src={data.car_logo} alt="Car Brand" />
        </div>

        <div className={"paymentsWrapperTable"}>
          <PaymentsTable />
        </div>
      </div>
    </div>
  );
};

export default PaymentOptions;
