import React, { useState, useEffect } from "react";
import { Dimmer, Loader, Message, Header } from "semantic-ui-react";
import _ from "lodash";

import ProtectivePackages from "./ProtectivePackages";
import PackageConfigurator from "./PackageConfigurator";
import EnabledProviders from "./EnabledProviders";

import {
  fetchEnabledProviders,
  fetchPackages,
  fetchCategories,
  createPackage,
  updatePackage,
  deletePackage,
} from "../requests";

import { allowedCategoryNames } from "../../helpers/general";

import "./ProtectiveCoverageSettings.sass";

const ProtectiveCoverageSettings = ({ retailer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfiguratorView, setIsConfiguratorView] = useState(false);
  const [error, setError] = useState("");

  const [separatedProductsByCategory, setSeparatedProductsByCategory] =
    useState(null);
  const [enabledProviders, setEnabledProviders] = useState([]);
  const [packages, setPackages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handleOnClickCreatePackage = () => {
    setIsConfiguratorView(true);
  };

  const handleOnCancelCreating = () => {
    setIsConfiguratorView(false);
    setSelectedPackage(null);
  };

  const handleOnClickPackage = (item) => {
    const filling = {};

    for (const key in item.filling) {
      const values = item.filling[key];

      filling[key.toUpperCase()] = {
        products: values.products,
        customTitle: values.custom_title,
      };
    }

    setSelectedPackage({
      ...item,
      filling,
    });
    setIsConfiguratorView(true);
  };

  const getPreparedProducts = (products) => {
    const filling = {};

    for (const key in products) {
      const values = products[key].products.map((p) => ({
        productId: Number(p.productId),
        priority: p.priority,
        value: Number(p.value),
        type: p.type,
        conditions: p.conditions,
      }));

      filling[key.toLowerCase()] = {
        customTitle: products[key].customTitle,
        products: values,
      };
    }

    return filling;
  };

  const handleOnCreatePackage = async ({ title, products }) => {
    setIsLoading(true);

    const filling = getPreparedProducts(products);

    const dto = {
      filling,
      title,
      retailer_id: retailer.id,
    };

    try {
      await createPackage(dto);

      setIsConfiguratorView(false);
      await init();
    } catch (e) {
      setError("Something went wrong. Please, try again");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnUpdatePackage = async ({ id, title, products }) => {
    setIsLoading(true);

    const filling = getPreparedProducts(products);
    const dto = {
      filling,
      title,
      retailer_id: retailer.id,
    };

    try {
      await updatePackage(id, dto);

      setIsConfiguratorView(false);
      await init();
    } catch (e) {
      setError("Something went wrong. Please, try again");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDeletePackage = async (id) => {
    setIsLoading(true);

    try {
      await deletePackage(id);

      setIsConfiguratorView(false);
      await init();
    } catch (e) {
      setError("Something went wrong. Please, please, please");
    } finally {
      setIsLoading(false);
    }
  };

  const buildProvidersProductList = ({ providers, categories }) => {
    const products = providers.reduce((allProducts, provider) => {
      const providerProducts = provider.products.map((product) => ({
        ...product,
        provider_id: provider.ex_1_provider_id,
      }));

      return [...allProducts, ...providerProducts];
    }, []);

    const separatedProvidersProducts = categories.reduce(
      (separatedProducts, category) => {
        const productsByCategory = products
          .filter((product) => product.product_code === category)
          .filter((p) => p.status !== "disabled");

        return {
          ...separatedProducts,
          [category]: separatedProducts[category]
            ? separatedProducts[category].push(productsByCategory)
            : [...productsByCategory],
        };
      },
      {}
    );

    setSeparatedProductsByCategory(separatedProvidersProducts);
  };

  const getEnabledProvidersAndCategories = async () => {
    try {
      const _enabledProviders = await fetchEnabledProviders(retailer.id);
      const _categories = await fetchCategories();
      const allowedCategories = Object.keys(allowedCategoryNames);

      setEnabledProviders(_enabledProviders);
      setCategories(
        _categories
          .filter((c) => allowedCategories.includes(c))
          .sort((prev, curr) => (prev > curr ? 1 : -1))
      );

      buildProvidersProductList({
        providers: _enabledProviders,
        categories: _categories,
      });
    } catch (e) {
      setError("Couldn't to fetch a list of enabled providers");
    }
  };

  const getProtectionPackages = async () => {
    try {
      const _packages = await fetchPackages(retailer.id);

      setPackages(_packages);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnUpdateProviders = async () => {
    await init();
  };

  const init = async () => {
    setIsLoading(true);

    await getEnabledProvidersAndCategories();
    await getProtectionPackages();

    setIsLoading(false);
    setSelectedPackage(null);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {!_.isEmpty(error) && (
        <Message
          negative
          onDismiss={() => {
            setError("");
          }}
        >
          <Message.Header>Error</Message.Header>

          <Message.Content>{error}</Message.Content>
        </Message>
      )}

      {isConfiguratorView ? (
        <>
          {!_.isNull(separatedProductsByCategory) && (
            <PackageConfigurator
              selectedPackage={selectedPackage}
              categories={categories}
              products={separatedProductsByCategory}
              providers={enabledProviders}
              onCancel={handleOnCancelCreating}
              onCreatePackage={handleOnCreatePackage}
              onUpdatePackage={handleOnUpdatePackage}
              onDeletePackage={handleOnDeletePackage}
            />
          )}
        </>
      ) : (
        <>
          <Header as={"h3"}>Protective Coverage Settings</Header>

          <div className={"packagesAndProviders"}>
            <ProtectivePackages
              packages={packages}
              onCreatePackage={handleOnClickCreatePackage}
              onClickPackage={handleOnClickPackage}
            />

            <EnabledProviders
              enabledProviders={enabledProviders}
              retailer={retailer}
              onUpdate={handleOnUpdateProviders}
            />
          </div>
        </>
      )}

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </>
  );
};

export default ProtectiveCoverageSettings;
