import React from "react";
import { Header, Icon, Popup } from "semantic-ui-react";

import ProvidersModal from "./ProvidersModal";

import { ReactComponent as PlusIcon } from "../../../../images/plusIcon.svg";

import "./EnabledProviders.sass";

const EnabledProviders = ({ enabledProviders, retailer, onUpdate }) => {
  return (
    <div>
      <Header as={"h4"}>Enabled Providers</Header>

      <ul className={"enabledProvidersList"}>
        <ProvidersModal
          enabled={enabledProviders}
          retailer={retailer}
          onUpdate={onUpdate}
        >
          <button className={"addNewProducts"}>
            <PlusIcon className={"enabledProvidersPlusIcon"} /> Add New Products
          </button>
        </ProvidersModal>

        {enabledProviders.map((provider) => {
          const enabledProducts = provider.products.filter(
            (p) => p.status === "enabled"
          ).length;
          const pendingProducts = provider.products.filter(
            (p) => p.status === "pending"
          );

          return (
            <ProvidersModal
              provider={provider}
              enabled={enabledProviders}
              retailer={retailer}
              onUpdate={onUpdate}
            >
              <li
                key={provider.ex_1_provider_id}
                className={"enabledProviderCard"}
              >
                <div className={"enabledProviderName"}>
                  {provider.provider_name}
                </div>

                <div className={"enabledProductsTagsWrapper"}>
                  <Popup
                    position="top center"
                    content={
                      <span>Amount of all products from the provider</span>
                    }
                    trigger={
                      <div
                        className={"enabledProductsTag all"}
                      >{`All: ${provider.products.length}`}</div>
                    }
                  />

                  <Popup
                    position="top center"
                    content={<span>Amount of all enabled products</span>}
                    trigger={
                      <div className={"enabledProductsTag enabled"}>
                        <Icon name={"checkmark"} />

                        {enabledProducts}
                      </div>
                    }
                  />

                  <Popup
                    position="top right"
                    content={
                      <div className={"pendingProductsWrapper"}>
                        {pendingProducts.length ? (
                          <>
                            <span>List of all pending products:</span>

                            <ul>
                              {pendingProducts.map((p) => (
                                <li key={p.ex_1_product_id}>
                                  {p.product_name}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <span>Amount of all pending products</span>
                        )}
                      </div>
                    }
                    trigger={
                      <div className={"enabledProductsTag pending"}>
                        <Icon name={"clock outline"} />

                        {pendingProducts.length}
                      </div>
                    }
                  />
                </div>
              </li>
            </ProvidersModal>
          );
        })}
      </ul>
    </div>
  );
};

export default EnabledProviders;
