import React from "react";
import { Header } from "semantic-ui-react";

import Package from "./Package";

import "./ProtectivePackages.sass";

const ProtectivePackages = ({
  packages,
  onCreatePackage,
  onClickPackage,
}) => {
  return (
    <div>
      <Header as={"h4"}>Protection Packages</Header>

      <ul className={"protectivePackagesList"}>
        <Package isNew onCreate={onCreatePackage} />

        {packages.map((p) => (
          <Package item={p} key={p.id} onSelect={onClickPackage} />
        ))}
      </ul>
    </div>
  );
};

export default ProtectivePackages;
