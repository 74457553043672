import * as yup from "yup";
import _ from "lodash";

import { validAddress, validZipCode } from "../../../helpers/general";

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(3, "First Name must be at least 3 characters  long")
    .required("First Name is required field"),
  lastName: yup
    .string()
    .trim()
    .min(3, "Last Name must be at least 3 characters  long")
    .required("Last Name is required field"),
  address: yup
    .string()
    .test(
      "is-correct-address",
      "Address must be number + street name",
      (value) => _.isNull(validAddress(value))
    )
    .required("Address is required field"),
  city: yup.string().trim().required("City is required field"),
  state: yup.string().trim().required("State is required field"),
  zipCode: yup
    .string()
    .trim()
    .test("is-correct-zip", "Zip code must be 5 characters long", (value) =>
      validZipCode(value)
    )
    .required("Zip Code is required field"),
});
