import React, { useEffect } from "react";
import { Form, Grid } from "semantic-ui-react";

import Input from "../../global/Input";
import { useTheme } from "../../helpers/theme";

export const WorkContactInformation = (props) => {
  const { theme } = useTheme();
  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {}, [form, msnErrors, flagEdit]);

  return (
    <Form.Group>
      <Form.Field width={8}>
        <label>Work Contact Number</label>

        <Input
          value={form.workContactNumber}
          placeholder={"Work Contact Number"}
          onChange={(e) =>
            props.onEditInput("workContactNumber", e.target.value)
          }
          fluid
          error={
            msnErrors.contactNumber.length > 0 ? msnErrors.contactNumber : null
          }
          maxLength="50"
          disabled={flagEdit}
          theme={theme?.input}
        />
      </Form.Field>

      <Form.Field width={8}>
        <label>Work Extension</label>

        <Input
          value={form.workExtension}
          placeholder={"Work Extension"}
          onChange={(e) => props.onEditInput("workExtension", e.target.value)}
          fluid
          maxLength="50"
          disabled={flagEdit}
          theme={theme?.input}
        />
      </Form.Field>
    </Form.Group>
  );
};
