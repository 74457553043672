import React from "react";
import { Button, Modal } from "semantic-ui-react";

import './ConfirmModal.sass'

export const ConfirmModal = ({ modal, afterEvent }) => {
  return (
    <Modal
      size="mini"
      className="buyer-modal"
      open={true}
      closeOnEscape={false}
      closeOnDimmerClick={true}
      onClose={() => afterEvent(false)}
    >
      <Modal.Content>
        <h2 className="title-info">{modal.title}</h2>
        <h3 dangerouslySetInnerHTML={{ __html: modal.message }}></h3>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => afterEvent(false)}>
          <h3>Cancel</h3>
        </Button>
        <Button primary onClick={() => afterEvent(true)}>
          <h3>Accept</h3>
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
