//IMPORT OBJECTS/LIBRARIES
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'rc-slider/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";

// IMPORT COMPONENTS
import ProtectedRoute from "./components/session/protectedRoute";
import {Role} from "./components/helpers/roles";
import Login from "./components/session/login/login";
import Quotation from "./components/customer/quotation/quotation";
import Admin from "./components/admin/admin";
import Retailer from "./components/retailer/retailer";
import Customer from "./components/customer/customer";
import NotFound from "./components/utilities/notFound/NotFound";

// IMPORT CSS STYLES
import 'semantic-ui-css/semantic.min.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './App.sass';
import QuotationDetails from "./components/utilities/quotationDetails/quotationDetails";


function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <ProtectedRoute path={"/quote/:id"} roles={[Role.Retailer, Role.Customer, Role.Admin]} component={QuotationDetails}>
                    </ProtectedRoute>
                    <ProtectedRoute path="/admin" roles={[Role.Admin]}>
                        <Admin />
                    </ProtectedRoute>
                    <ProtectedRoute path="/retailer" roles={[Role.Retailer]}>
                        <Retailer />
                    </ProtectedRoute>
                    <ProtectedRoute path={"/customer"} roles={[Role.Customer]}>
                        <Customer/>
                    </ProtectedRoute>
                    <Route path="/cars/:retailer" render={(props) => <Quotation {...props}/>}>
                    </Route>
                    <Route path="/quotation/:vin" render={(props) => <Quotation {...props}/>}>
                    </Route>
                    <Route path="*" component={() => <NotFound />}/>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
