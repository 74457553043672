import React from "react";
import cn from "classnames";

import "./StatisticCard.sass";

const StatisticCard = ({ label, value, icon, className, onClick }) => {
  return (
    <div
      className={cn("cardWrapper", onClick && "hoverableCard", className)}
      onClick={(e) => {
        e.stopPropagation();

        onClick && onClick();
      }}
    >
      <div className={"infoTab"}>
        <span className={"cardLabel"}>{label}</span>
        <span className={"cardValue"}>{value}</span>
      </div>

      <div className={"imageContainer"}>{icon}</div>
    </div>
  );
};

export default StatisticCard;
