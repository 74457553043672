import React, { useState } from "react";
import { Grid, Pagination, Message } from "semantic-ui-react";

import "./listBuyers.sass";

// IMPORT COMPONENTS
import BuyerInformation from "../buyerInformation/buyerInformation";
import BuyersCard from "./BuyersCard";
/*
 *  PROPS:
 *   - buyers:       List of buyers to show.
 *   - totalPages:   Total of the search pages.
 *   - changePage:   Function to change the current page.
 *
 * */

const ListBuyers = (props) => {
  const [state, setState] = useState({
    detail: {},
    buyerModal: false,
    buyer: {},
  });

  return (
    <div
      className={"body buyers"}
      style={{ display: props.isActive ? "block" : "none" }}
    >
      <Grid stackable style={{ marginTop: "1rem" }}>
        <h3 className={"header"}>Buyers List</h3>
      </Grid>

      {!!props.buyers.length ? (
        <>
          <ul className={"buyersList"}>
            {props.buyers.map((buyer) => {
              return (
                <BuyersCard
                  buyer={buyer}
                  key={buyer.id}
                  onClick={() => setState({ buyerModal: true, buyer })}
                  role={props.role}
                />
              );
            })}
          </ul>

          {!!props.totalPages && props.changePage && (
            <div style={{ textAlign: "Center" }}>
              <Pagination
                style={{ marginTop: "1rem" }}
                defaultActivePage={1}
                totalPages={props.totalPages}
                onPageChange={(e, { activePage }) =>
                  props.changePage(activePage)
                }
              />
            </div>
          )}
        </>
      ) : (
        <Message info>
          <Message.Header>Info</Message.Header>

          <Message.Content>The list of buyers is empty</Message.Content>
        </Message>
      )}

      {state.buyerModal && (
        <BuyerInformation
          customer={state.buyer}
          close={() => setState({ buyerModal: false })}
        />
      )}
    </div>
  );
};

export default ListBuyers;
