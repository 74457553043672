import React from "react";

import AttributeModal from "./AttributeModal";

import { FormatCashDownToPrice } from "../../../helpers/general";

import EmptyImage from "../../../../images/no-image-available.png";

import "./AttributeCard.sass";

const AttributeCard = ({ retailerId, item, update }) => {
  return (
    <AttributeModal item={item} update={update} retailerId={retailerId}>
      <button className={"attributeCardButton"}>
        <li>
          <div className="attributeCardButtonImage">
            <img
              src={item.image_url}
              alt="Attribute"
              onError={({ currentTarget }) => {
                currentTarget.src = EmptyImage;
              }}
            />
          </div>

          <div className={"attributeCardButtonInfo"}>
            <h4>{item.name}</h4>

            <span>{FormatCashDownToPrice(item.cost)}</span>
          </div>
        </li>
      </button>
    </AttributeModal>
  );
};

export default AttributeCard;
