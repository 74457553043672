import React from "react";

import RichTextEditor from "../../../../../global/RichTextEditor";

import { FormatCashDownToPrice } from "../../../../../helpers/general";

import "./AttributeDescription.sass";

const AttributeDescription = ({ description, item, withPrice = true }) => {
  return (
    <div className={"descriptionContainer"}>
      <RichTextEditor
        value={description}
        modules={{ toolbar: false }}
        theme="bubble"
        readOnly={true}
      />

      {withPrice && (
        <span className={"attributeCostValue"}>
          {FormatCashDownToPrice(item.cost)}
        </span>
      )}
    </div>
  );
};

export default AttributeDescription;
