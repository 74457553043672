import React, { useEffect, useState } from 'react';
import { Form, Grid, Icon, Popup } from 'semantic-ui-react';

export const AccountFields = (props) => {
  const [form, setForm] = useState({ name: "", accountNumber: "", routingNumber: "", bankAccountType: "checking", bankName: "" });
  const { errors, accountType, disabled, data } = props;

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm({ ...auxForm });
    props.onEditInput(value, label);
  }

  useEffect(() => {
    setForm(data ? data : form);
  }, [])

  return (
    <Grid>
      <Form.Group></Form.Group>
      <Form.Input
        label={<label>{!accountType && "Lender "}Bank Nickname:<span className="required-inut"> *</span></label>}
        onChange={(e) => onEditInput(e.target.value, "name")}
        placeholder={'Bank nickname'}
        width={16}
        maxLength={50}
        value={form.name}
        disabled={disabled}
        error={form.name.length > 0 || errors.name ? errors.name : null}
      />
      {
        accountType && (
          <Form.Input
            label={<label>{!accountType && "Lender "}Bank name:<span className="required-inut"> *</span></label>}
            onChange={(e) => onEditInput(e.target.value, "bankName")}
            placeholder={'Bank name'}
            width={16}
            maxLength={50}
            value={form.bankName}
            disabled={disabled}
            error={form.bankName.length > 0 || errors.bankName ? errors.bankName : null}
          />
        )
      }
      <Form.Input
        label={<label>{!accountType && "Loan "}Account Number:<span className="required-inut"> *</span></label>}
        onChange={(e) => onEditInput(e.target.value, "accountNumber")}
        placeholder={'Account number'}
        width={16}
        value={form.accountNumber}
        disabled={disabled}
        error={form.accountNumber.length > 0 || errors.accountNumber ? errors.accountNumber : null}
      />
      <Form.Input
        label={<label>
          <Popup
            trigger={<Icon name='info circle' color='blue' size='large' />}
            content="A routing number is a nine-digit code used by financial institutions to identify other financial institutions. It's also known as an RTN (routing transit number) and an ABA (American Bankers Association) routing number. Combined with your account number, it allows insitutions to locate your individual account."
            position='top center'
          />
          {!accountType && "Loan "}Routing Number:<span className="required-inut"> *</span>
        </label>}
        onChange={(e) => onEditInput(e.target.value, "routingNumber")}
        placeholder={'Routing number'}
        width={16}
        value={form.routingNumber}
        disabled={disabled}
        error={form.routingNumber.length > 0 || errors.routingNumber ? errors.routingNumber : null}
      />
      {
        accountType && (
          <Form.Group inline>
            <label>Bank Account Type:<span className="required-inut"> *</span></label>
            <Form.Radio
              label='Checking'
              value='checking'
              checked={form.bankAccountType === 'checking'}
              onChange={(e, { value }) => onEditInput(value, "bankAccountType")}
            />
            <Form.Radio
              label='Saving'
              value='savings'
              checked={form.bankAccountType === 'savings'}
              onChange={(e, { value }) => onEditInput(value, "bankAccountType")}
            />
          </Form.Group>
        )
      }
    </Grid>
  );
};