import React from "react";

import { WeeklyTooltip } from "../../../utilities/tooltips/WeeklyTooltip";
import { MonthlyTooltip } from "../../../utilities/tooltips/MonthlyTooltip";

import { FormatNumberToPrice } from "../../../helpers/general";
import { useTheme } from "../../../helpers/theme";

import "./WeeklyBar.sass";

const WeeklyBar = ({ quotes, interest, quotationState, extra }) => {
  const { theme } = useTheme();

  return (
    <div className={"weeklyBarWrapper"}>
      <div className={"barExtraContent"}>{extra}</div>

      <div className={"weeklyValueBox"}>
        <div className={"weeklyValueInfoWrapper"}>
          <span className={"weeklyValue"}>
            {`${FormatNumberToPrice(quotes.monthly.payment)} Monthly`}

            <MonthlyTooltip
              finalQuots={quotes}
              annual_interest_rate={interest}
              position={"bottom right"}
            />
          </span>

          <span
            className={"weeklyValue"}
          >{`For ${quotationState.loan_length} Months`}</span>
        </div>

        <div
          className={"weeklyBox"}
          style={{
            "--box-background": theme?.weekly?.background,
            "--box-color": theme?.weekly?.color,
            "--box-border-radius": theme?.weekly.borderRadius + "px",
          }}
        >
          <WeeklyTooltip finalQuots={quotes} position={"top right"} />

          <span>{`${FormatNumberToPrice(quotes.weekly.payment)} Weekly`}</span>
        </div>
      </div>
    </div>
  );
};

export default WeeklyBar;
