import React from "react";
import { useMediaPredicate } from "react-media-hook";

import Title from "../SectionTitle";
import EffectiveENDTermsTable from "./EffectiveENDTermsTable";

import "./ProgramBuilder.sass";

const ProgramBuilder = () => {
  const is768 = useMediaPredicate("(max-width: 768px)");

  return (
    <div className={"builderWrapper"}>
      <Title
        size={is768 ? 16 : 20}
        popup={
          <div className={"popupContentWrapper"}>
            <span>
              Term Reductions are estimated and are determined by Payment Plan.
              Term reduction is acquired when the customer makes weekly
              payments.
            </span>
          </div>
        }
      >
        GTX Equity Builder Program
      </Title>

      <EffectiveENDTermsTable />
    </div>
  );
};

export default ProgramBuilder;
