import React, { useContext } from "react";
import { Icon } from "semantic-ui-react";

import Customer from "./Customer";
import Trade from "./Trade";
import Vehicle from "./Vehicle";

import Button from "../../global/Button";

import { AgreementCtx } from "../context";

import MPP from "../../../images/MyPayPow.png";

import "./AgreementDetails.sass";

const AgreementDetails = () => {
  const { onBack } = useContext(AgreementCtx);

  return (
    <div className={"agreementDetails"}>
      <div className={"imageWrapper"}>
        <Button buttonStyle="secondary" icon onClick={onBack}>
          <Icon name="angle left" />
        </Button>

        <img src={MPP} />

        <div />
      </div>

      <Customer />

      <Trade />

      <Vehicle />
    </div>
  );
};

export default AgreementDetails;
