import React from 'react';
import { Checkbox, Table } from 'semantic-ui-react';
import { FormatNumberToPrice } from '../../../../helpers/general';

export const DebitInformation = ({ plan, data }) => {
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              DEBIT INFORMATION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan='2'>
              REPAYMENT PLAN SELECTED
              <Checkbox label='13 MONTHLY PAYMENTS PER YEAR' checked={plan.paymentType === 'weekly'} disabled style={{ marginRight: "10px" }} />
              <Checkbox label='14 MONTHLY PAYMENTS PER YEAR' checked={plan.paymentType === 'weeklyPlus'} disabled style={{ marginRight: "10px" }} />
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Day of the week ACH/Debits Occur:
            </Table.Cell>
            <Table.Cell>
              {plan.transferDay}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Day of First Debit:
            </Table.Cell>
            <Table.Cell>
              {data.firstDebit}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              First Debit Amount:
            </Table.Cell>
            <Table.Cell>
              {FormatNumberToPrice(plan.payment)}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Estimated Date of Last Weekly ACH Debit
            </Table.Cell>
            <Table.Cell>
              {data.lastWeekly}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Total Recurring ACH/Debit Amount:
            </Table.Cell>
            <Table.Cell>
              {FormatNumberToPrice(plan.payment)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <p>
        My Payment Power debits your account according to your election
        above. At a minimum we make a monthly payment to your lender on or
        before your payment due date. If you have chosen a “round it up to pay
        it down” option, we will use your Plaid integration to monitor your
        weekly checking account spend and when you make a purchase with any
        linked debit card, we round up that purchase to the nearest dollar.
        Round-Ups made from your linked cards are set aside until they hit a $5
        threshold and are then transferred into your My Payment Power Escrow
        account. This money is always transferred from the bank account you've
        indicated as your primary checking account.
        Round-Ups from your enrolled debit cards are pulled from your checking
        account and are invested into your MPP escrow account as soon as your
        transaction clears. Paying down additional loan principal monthly can
        significantly improve your equity position over time. Each month we'll
        add your Round-Ups to your on-time monthly payment.
        The average My Payment Power user pays more than $30 a month against
        loan principal through Round-Ups!
      </p>
    </div>
  );
};