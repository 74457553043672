import React, { Component } from "react";
import { Modal, Grid, Form, Dimmer, Loader } from "semantic-ui-react";
import {
  validAddress,
  validEmail,
  validPhoneNumber,
  validZipCode,
} from "../../helpers/general";

// REQUEST FUNCTIONS.
import { editRetailer } from "../requests";

// IMPORT COMPONENTS
import Contact from "./ContactForm";
import ModalError from "../alertModal/modal";
import "./retailerDetails.sass";
import Address from "../address/Address";
import Input from "../../global/Input";
import Section from "../../global/Section";
import Button from "../../global/Button";

import EmptyImagePlaceholder from "../../../images/no-image-available.png";
import { ReactComponent as UserCardIcon } from "../../../images/user-card-icon.svg";
import { ReactComponent as LocationIcon } from "../../../images/location-pin.svg";

class RetailerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeForm: true,
      flagEdit: true,
      modal: {},
      retailer: props,
      email: props.user.email,
      phone_number: props.user.phone_number,
      zip_code: props.zip_code,
      address: props.address,
      state: props.state,
      city: props.city,
      name: props.name,
      dealer_id: props.dealer_id,
      partnerdealerID: props.partnerdealerID,
      logo: props.logo,
      previewImg: props.logo,
      msnErrors: {
        name: "",
        zip_code: null,
        dealer_id: "",
        logo: "",
        email: "",
        phone_number: "",
        partnerdealerID: null,
        address: null,
        state: null,
        city: null,
      },
    };
  }

  // -------------------------------------- cancel edit ---------------------------
  cancelEdit = () => {
    this.setState({
      completeForm: true,
      name: this.state.retailer.name,
      zip_code: this.state.retailer.zip_code
        ? this.state.retailer.zip_code
        : "",
      dealer_id: this.state.retailer.dealer_id,
      email: this.state.retailer.user.email,
      phone_number: this.state.retailer.user.phone_number,
      logo: this.state.retailer.logo,
      previewImg: this.state.retailer.logo,
      partnerdealerID: this.state.retailer.partnerdealerID,
      address: this.state.retailer.address,
      state: this.state.retailer.state,
      city: this.state.retailer.city,
      logoFile: null,
      msnErrors: {
        name: "",
        zip_code: null,
        dealer_id: "",
        logo: "",
        email: "",
        phone_number: "",
        partnerdealerID: null,
        address: null,
        state: null,
        city: null,
      },
      flagEdit: true,
    });
  };

  // ------------------------------------ change input values --------------------
  onEditInput = (label, value) => {
    let msnErrors = this.state.msnErrors;
    if (label == "logo") {
      msnErrors = this.switchLabel(label, value, msnErrors);
    } else if (label === "fullAddress") {
      msnErrors = this.switchLabel("address", value.address, msnErrors);
      msnErrors = this.switchLabel("state", value.state, msnErrors);
      msnErrors = this.switchLabel("city", value.city, msnErrors);
      msnErrors = this.switchLabel("zip_code", value.zip_code, msnErrors);
      this.setState(
        {
          address: value.address,
          state: value.state,
          city: value.city,
          zip_code: value.zip_code,
          msnErrors,
        },
        () => this.validateForm()
      );
    } else {
      msnErrors = this.switchLabel(label, value, msnErrors);
      this.setState({ [label]: value, msnErrors }, () => this.validateForm());
    }
  };

  // ------------------------------------ valid inputs ---------------------------
  switchLabel(label, value, msnErrors) {
    switch (label) {
      case "name":
        msnErrors.name =
          value.length < 3 ? "Name must be at least 3 characters long" : "";
        break;
      case "address":
        msnErrors[label] = validAddress(value);
        break;
      case "state":
      case "city":
      case "partnerdealerID":
        msnErrors[label] =
          value === "" || value.split(" ").join("") === ""
            ? "The field must be required"
            : null;
        break;
      case "zip_code":
        msnErrors.zip_code = !validZipCode(value)
          ? "Zip Code must be 5 characters long"
          : null;
        break;
      case "dealer_id":
        msnErrors.dealer_id =
          value.length < 3
            ? "Dealer ID must be at least 3 characters long"
            : "";
        break;
      case "email":
        msnErrors.email = validEmail(value) ? "" : "Email is not valid";
        break;
      case "phone_number":
        msnErrors.phone_number = validPhoneNumber(value)
          ? ""
          : "Phone number must be 10 digits";
        break;
      case "logo":
        if (value.value != "") {
          var fuData = value;
          var FileUploadPath = fuData.value;
          var Extension = FileUploadPath.substring(
            FileUploadPath.lastIndexOf(".") + 1
          ).toLowerCase();
          //The file uploaded is an image
          if (Extension == "png" || Extension == "jpeg" || Extension == "jpg") {
            msnErrors.logo = "";
            if (fuData.files && fuData.files[0]) {
              let previewImg = URL.createObjectURL(fuData.files[0]);
              this.setState({
                [label]: FileUploadPath,
                logoFile: fuData.files[0],
                msnErrors,
                previewImg,
              });
            }
          } else {
            msnErrors.logo = "Logo must be a PNG, JPG or JPEG";
            this.setState({ [label]: "", msnErrors });
          }
        } else {
          let previewImg = this.state.retailer.logo;
          msnErrors.logo = "";
          this.setState({ [label]: "", logoFile: null, msnErrors, previewImg });
        }
        break;
      default:
        break;
    }
    return msnErrors;
  }

  validateForm = () => {
    let valid = false;
    let msnErrors = this.state.msnErrors;
    let array = [
      "name",
      "zip_code",
      "dealer_id",
      "logo",
      "email",
      "phone_number",
      "address",
      "state",
      "city",
      "partnerdealerID",
    ];
    for (let i = 0; i < array.length; i++) {
      if (msnErrors[array[i]] != "" && msnErrors[array[i]] != null) {
        valid = true;
      }
    }
    this.setState({ completeForm: !valid });
    return valid;
  };

  // ---------------------------------------- save retailer data ------------------------
  save = () => {
    if (this.validateForm()) {
      return;
    }
    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("zip_code", this.state.zip_code);
    formData.append("partnerdealerID", this.state.partnerdealerID);
    formData.append("address", this.state.address);
    formData.append("state", this.state.state);
    formData.append("city", this.state.city);
    formData.append("dealer_id", this.state.dealer_id);
    formData.append("email", this.state.email);
    formData.append("phone_number", this.state.phone_number);
    formData.append("logo", this.state.logo);
    this.setState({ loading: true });
    editRetailer(this.state.retailer.id, formData)
      .then((response) => {
        let retailer = { ...this.state.retailer };
        retailer["name"] = this.state.name;
        retailer["partnerdealerID"] = this.state.partnerdealerID;
        retailer["address"] = this.state.address;
        retailer["state"] = this.state.state;
        retailer["city"] = this.state.city;
        retailer["zip_code"] = this.state.zip_code;
        retailer["dealer_id"] = this.state.dealer_id;
        retailer["logo"] = response.data.logo;
        retailer["user"]["email"] = this.state.email;
        retailer["user"]["phone_number"] = this.state.phone_number;
        this.setState({
          loading: false,
          retailer,
          modal: {
            status: 200,
            message: "The retailer was updated",
          },
          flagEdit: true,
          previewImg: response.data.logo,
          logo: response.data.logo,
        });
        this.props.updateRetailer(retailer);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            this.setState({
              loading: false,
              modal: {
                status: 422,
                message: "Sorry. Field is missing to save the retailer",
              },
            });
          } else if (error.response.status === 406) {
            this.setState({
              loading: false,
              modal: {
                status: 406,
                message: error.response.data.message,
              },
            });
          } else {
            this.setState({
              loading: false,
              modal: {
                status: 500,
                message: "Sorry. An error occurred, please try again later",
              },
            });
          }
        }
      });
  };

  // ---------------------------- hide modal -----------------------------------
  hideModal = () => {
    this.setState({ modal: {} });
  };

  onSetFile(event) {
    let that = this;
    if (event.files && event.files[0]) {
      this.setState({ logo: event.files[0] });
      let render = new FileReader();
      render.onload = function (e) {
        that.setState({ img: e.target.result });
      };
      render.readAsDataURL(event.files[0]);
    } else {
      that.setState({ img: "", logo: "" });
    }
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Modal
          open={true}
          onClose={() => {
            this.props.hideModal("retailerDetail", false);
          }}
          closeIcon
        >
          <Modal.Header>Retailer Details</Modal.Header>

          <Modal.Content className={"customModal"} scrolling>
            <div className={"modalContent"}>
              <Grid stackable>
                <Grid.Column width={16}>
                  <Form className={"customForm"}>
                    <Section className={"contactSectionWrapper"}>
                      <div className={"headerWrapper"}>
                        <UserCardIcon />

                        <h3>Contact</h3>
                      </div>

                      <div className={"contactFormWrapper"}>
                        <div>
                          <Contact
                            state={this.state}
                            onEditInput={this.onEditInput}
                          />
                        </div>

                        <>
                          {this.state.flagEdit ? (
                            <div className="newRetailerImage">
                              {this.state.logo != null &&
                              this.state.logo !== "" ? (
                                <img src={this.state.logo} />
                              ) : (
                                <img
                                  src={require("../../../images/no-image-available.png")}
                                />
                              )}
                            </div>
                          ) : (
                            <>
                              <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={8}
                                className={"imageContainer"}
                              >
                                <Grid.Column className={"newRetailerImage"}>
                                  <img
                                    src={
                                      this.state.logo || EmptyImagePlaceholder
                                    }
                                    alt={"current"}
                                  />
                                </Grid.Column>

                                <Form.Field>
                                  <label>Retailer logo</label>

                                  <Input
                                    id={"logo"}
                                    type="file"
                                    onChange={(e) => this.onSetFile(e.target)}
                                    accept="image/png,image/jpg,image/jpeg"
                                    className={"imageInput"}
                                  />
                                </Form.Field>
                              </Grid.Column>
                            </>
                          )}
                        </>
                      </div>
                    </Section>

                    <Section className={"locationWrapper"}>
                      <div className={"headerWrapper"}>
                        <LocationIcon />

                        <h3>Location</h3>
                      </div>

                      <Address
                        address={{
                          address: this.state.address,
                          city: this.state.city,
                          zip_code: this.state.zip_code,
                          state: this.state.state,
                        }}
                        onChangeState={this.onEditInput}
                        flagEdit={this.state.flagEdit}
                        msnErrors={this.state.msnErrors}
                      />
                    </Section>
                  </Form>
                </Grid.Column>
              </Grid>
            </div>
          </Modal.Content>

          <Modal.Actions>
            <>
              <Button
                buttonStyle={"danger"}
                className="actionButton"
                onClick={() => this.props.hideModal("retailerDetail", false)}
              >
                Close
              </Button>

              {this.state.flagEdit ? (
                <Button
                  buttonStyle={"secondary"}
                  className="actionButton"
                  onClick={() => this.setState({ flagEdit: false })}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    buttonStyle={"secondary"}
                    className="actionButton"
                    onClick={() => this.cancelEdit()}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="actionButton"
                    onClick={() => this.save()}
                    disabled={!this.state.completeForm}
                  >
                    Save
                  </Button>
                </>
              )}
            </>
          </Modal.Actions>

          {this.state.loading && (
            <Dimmer className="loading" active>
              <Loader />
            </Dimmer>
          )}
        </Modal>

        {this.state.modal.message && (
          <ModalError hideModal={this.hideModal} modal={this.state.modal} />
        )}
      </div>
    );
  }
}

export default RetailerDetails;
