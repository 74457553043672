import React, { useContext } from "react";
import { useMediaPredicate } from "react-media-hook";

import InfoRecord from "../../InfoRecord";
import Title from "../../SectionTitle";

import { AgreementCtx } from "../../context";

const Vehicle = () => {
  const { data } = useContext(AgreementCtx);

  const is768 = useMediaPredicate("(max-width: 768px)");

  return (
    <div>
      <Title size={is768 ? 14 : 16}>Vehicle Information</Title>

      <div className={"detailsWrapper"}>
        <ul className={"list"}>
          <InfoRecord
            label={"Year"}
            value={data.vehicle_year}
            wrapOnBreakpoint={1180}
          />

          <InfoRecord
            label={"Make"}
            value={data.vehicle_make}
            wrapOnBreakpoint={1180}
          />

          <InfoRecord
            label={"Model"}
            value={data.vehicle_model}
            wrapOnBreakpoint={1180}
          />
        </ul>

        <ul className={"list"}>
          <InfoRecord
            label={"VIN"}
            value={data.vehicle_vin}
            wrapOnBreakpoint={1180}
          />

          <InfoRecord
            label={"Miles"}
            value={data.vehicle_miles}
            wrapOnBreakpoint={1180}
          />

          <InfoRecord
            label={"Color"}
            value={data.vehicle_color}
            wrapOnBreakpoint={1180}
          />
        </ul>
      </div>
    </div>
  );
};

export default Vehicle;
