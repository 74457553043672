import React from "react";
import { Popup } from "semantic-ui-react";

import InfoCircleIcon from "../../../images/infoCircle.svg";

import "./SignInput.sass";

const SignInput = ({ popup }) => {
  return (
    <div className={"signWrapper"}>
      {popup && (
        <Popup
          style={{ border: 0, color: '#4a4a4a' }}
          content={popup}
          trigger={<img className={"icon"} src={InfoCircleIcon} />}
          basic
        />
      )}
    </div>
  );
};

export default SignInput;
