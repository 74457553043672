import React, { useState } from "react";
import { Grid, Image, Card, Pagination, Breadcrumb } from "semantic-ui-react";

import { AddRetailer } from "../../utilities/retailerDetails/AddRetailer";
import { CreditSolutionModal } from "../../utilities/creditSolutionRequests/CreditSolutionModal";
import VariablesModal from "../../utilities/variablesModal/variablesModal";
import PersonalizedAttributesSettings from "../../retailer/PersonalizedAttributesSettings";
import Rebates from "../../utilities/rebates";
import ProtectiveCoverageSettings from "../../utilities/ProtectiveCoverageSettings";

import Button from "../../global/Button";

import EmptyImage from "../../../images/no-image-available.png";

import "./Retailers.sass";
import AppearanceConfigurator from "../../utilities/AppearanceConfigurator";

const { Section, Divider } = Breadcrumb;

const Retailers = (props) => {
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [selectedRetailerForRebates, setSelectedRetailerForRebates] =
    useState(null);
  const [selectedRetailerForProtection, setSelectedRetailerForProtection] =
    useState(null);
  const [selectedRetailerForAppearance, setSelectedRetailerForAppearance] =
    useState(null);

  return (
    <>
      {selectedRetailer ? (
        <div
          className={"retailerAttributesPageWrapper body"}
          style={{
            display: props.activeItem == "retailers" ? "block" : "none",
          }}
        >
          <Breadcrumb className={"navigation"}>
            <Section link>
              <a onClick={() => setSelectedRetailer(null)}>Retailers List</a>
            </Section>

            <Divider icon="right chevron" />

            <Section active>{`Retailer ${
              props.state.retailers.find((item) => item.id === selectedRetailer)
                .name
            }`}</Section>
          </Breadcrumb>

          <PersonalizedAttributesSettings
            retailer={props.state.retailers.find(
              (r) => r.id === selectedRetailer
            )}
          />
        </div>
      ) : (
        <>
          {selectedRetailerForRebates ||
          selectedRetailerForProtection ||
          selectedRetailerForAppearance ? (
            <>
              {selectedRetailerForRebates && (
                <div
                  className={"retailerAttributesPageWrapper body"}
                  style={{
                    display: props.activeItem == "retailers" ? "block" : "none",
                  }}
                >
                  <Breadcrumb className={"navigation"}>
                    <Section link>
                      <a onClick={() => setSelectedRetailerForRebates(null)}>
                        Retailers List
                      </a>
                    </Section>

                    <Divider icon="right chevron" />

                    <Section active>{`Retailer ${
                      props.state.retailers.find(
                        (item) => item.id === selectedRetailerForRebates
                      ).name
                    }`}</Section>
                  </Breadcrumb>

                  <Rebates
                    retailer={props.state.retailers.find(
                      (r) => r.id === selectedRetailerForRebates
                    )}
                  />
                </div>
              )}

              {selectedRetailerForProtection && (
                <div
                  className={"body"}
                  style={{
                    display: props.activeItem == "retailers" ? "block" : "none",
                  }}
                >
                  <Breadcrumb className={"navigation"}>
                    <Section link>
                      <a onClick={() => setSelectedRetailerForProtection(null)}>
                        Retailers List
                      </a>
                    </Section>

                    <Divider icon="right chevron" />

                    <Section
                      active
                    >{`Retailer ${selectedRetailerForProtection.name}`}</Section>
                  </Breadcrumb>

                  <ProtectiveCoverageSettings
                    retailer={selectedRetailerForProtection}
                  />
                </div>
              )}

              {selectedRetailerForAppearance && (
                <div
                  className={"body"}
                  style={{
                    display: props.activeItem == "retailers" ? "block" : "none",
                  }}
                >
                  <Breadcrumb className={"navigation"}>
                    <Section link>
                      <a onClick={() => setSelectedRetailerForAppearance(null)}>
                        Retailers List
                      </a>
                    </Section>

                    <Divider icon="right chevron" />

                    <Section
                      active
                    >{`Retailer ${selectedRetailerForAppearance.name}`}</Section>
                  </Breadcrumb>

                  <AppearanceConfigurator
                    retailer={selectedRetailerForAppearance}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={"body"}
                style={{
                  display: props.activeItem == "retailers" ? "block" : "none",
                }}
              >
                <Grid className="ui-mobile">
                  <AddRetailer addNewRetailer={props.addNewRetailer} />
                </Grid>

                <ul className={"retailersListWrapper"}>
                  {props.state.retailers.map((retailer, k) => (
                    <li>
                      <Card>
                        {retailer.logo == null || retailer.logo == "" ? (
                          <Image src={EmptyImage} wrapped />
                        ) : (
                          <Image
                            src={retailer.logo}
                            wrapped
                            ui={false}
                            onError={({ currentTarget }) => {
                              currentTarget.src = EmptyImage;
                            }}
                          />
                        )}

                        <Card.Content>
                          <Card.Header>{retailer.name}</Card.Header>

                          <Card.Meta>
                            <span className="date">
                              Number of deals: {retailer.quots_count || 0}
                            </span>
                          </Card.Meta>
                        </Card.Content>

                        <Card.Content extra className={"cardExtraContent"}>
                          <Button
                            fluid
                            onClick={() => props.showVariables(retailer)}
                            buttonStyle={"secondary"}
                          >
                            System Variables
                          </Button>

                          <CreditSolutionModal retailer={retailer} />

                          <Button
                            buttonStyle={"skyblue"}
                            fluid
                            onClick={() => setSelectedRetailer(retailer.id)}
                          >
                            Attributes
                          </Button>

                          <Button
                            fluid
                            buttonStyle={"skyblue"}
                            onClick={() =>
                              setSelectedRetailerForRebates(retailer.id)
                            }
                          >
                            Rebates
                          </Button>

                          <Button
                            fluid
                            buttonStyle={"skyblue"}
                            onClick={() => {
                              setSelectedRetailerForProtection(retailer);
                            }}
                          >
                            Protective Coverage
                          </Button>

                          <Button
                            fluid
                            buttonStyle="skyblue"
                            onClick={() => {
                              setSelectedRetailerForAppearance(retailer);
                            }}
                          >
                            Widget Appearance
                          </Button>

                          <Button
                            fluid
                            buttonStyle={"skyblue"}
                            onClick={() => props.showRetailer(retailer)}
                          >
                            See Details
                          </Button>
                        </Card.Content>
                      </Card>
                    </li>
                  ))}
                </ul>

                <div style={{ textAlign: "Center" }}>
                  <Pagination
                    defaultActivePage={1}
                    totalPages={props.state.retailersTotal}
                    onPageChange={(e, { activePage }) =>
                      props.chageRetailerPage(activePage)
                    }
                  />
                </div>

                {props.state.variablesModal && (
                  <VariablesModal
                    {...props.state.retailer}
                    hideModal={props.hideModal}
                    updateRetailer={props.updateRetailer}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Retailers;
