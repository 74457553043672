import React, { useContext } from "react";
import { useMediaPredicate } from "react-media-hook";

import Title from "../SectionTitle";
import InfoRecord from "../InfoRecord";

import { AgreementCtx } from "../context";

import "./PricingRecap.sass";

const PricingRecap = () => {
  const { data } = useContext(AgreementCtx);

  const is768 = useMediaPredicate("(max-width: 768px)");

  return (
    <div className={"pricingRecapWrapper"}>
      <Title
        align={"center"}
        size={is768 ? 16 : 20}
        popup={
          <div className={"popupContent"}>
            <span>
              The terms and figures listed are approximate. Lenders may have
              additional fees and charges that vary for each lender. Final
              itemization will be available on the Bill of Sale at the time of
              purchase.
            </span>
          </div>
        }
      >
        Pricing Recap
      </Title>

      <ul className={"list"}>
        <InfoRecord
          label={"Sale Price"}
          value={data.pricing_sales_price}
          wrapOnBreakpoint={false}
        />

        <InfoRecord
          label={
            <>
              Taxes{" "}
              <span className={"percent"}>{data.pricing_sales_percentage}</span>
            </>
          }
          value={data.pricing_taxes_usd}
          wrapOnBreakpoint={false}
        />

        <InfoRecord
          label={"Trade Offer"}
          value={data.pricing_trade_offer}
          wrapOnBreakpoint={false}
        />

        <div className={"lowerSectionWrapper"}>
          <div>
            <InfoRecord
              label={"Doc Fee"}
              value={data.pricing_doc_fee}
              wrapOnBreakpoint={1180}
            />

            <InfoRecord
              label={"Title Transfer"}
              value={data.pricing_title_transfer}
              wrapOnBreakpoint={1180}
            />

            <InfoRecord
              label={"Temp Tag"}
              value={data.pricing_temp_tag}
              wrapOnBreakpoint={1180}
            />
          </div>

          <div>
            <InfoRecord
              label={"VSC"}
              value={data.pricing_vsc}
              wrapOnBreakpoint={1180}
            />

            <InfoRecord
              label={"GAP"}
              value={data.pricing_gap}
              wrapOnBreakpoint={1180}
            />

            <InfoRecord
              label={"Finance Products"}
              value={data.pricing_sub_total}
              wrapOnBreakpoint={1180}
            />
          </div>
        </div>
      </ul>

      <InfoRecord
        label={"Amount Financed"}
        value={data.pricing_amount_financed}
        className={"total"}
        wrapOnBreakpoint={false}
      />
    </div>
  );
};

export default PricingRecap;
