import React, { Component } from "react";
import { Grid, Form, Dimmer, Loader, Message, Header } from "semantic-ui-react";
import {
  validEmail,
  validZipCode,
  validPhoneNumber,
  validateSSN,
  validAddress,
  validCodeNumber,
} from "../../helpers/general";
import moment from "moment";

// IMPORT COMPONENTS
import AlertModal from "../alertModal/modal";
import CodeModal from "../codeModal/codeModal";
import Section from "../../global/Section";
import Button from "../../global/Button";

import "./contact.sass";
import { editContactData } from "../requests";
import Address from "../address/Address";
import { BasicInformation } from "./BasicInformation";
import { ContactInformation } from "./ContactInformation";
import { UserInformation } from "./UserInformation";
import { UniqueIdentifiers } from "./UniqueIdentifiers";
import Video from "../../customer/quotation/video/Video";
import { WorkContactInformation } from "./WorkContactInformation";
import { zipCodesStates } from "../../helpers/usaStates";
import { TermsConditions } from "../termsConditions/TermsConditions";

class Contact extends Component {
  constructor(props) {
    super(props);

    const data = {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      workContactNumber: "",
      zip_code: "",
      address: "",
      state: "",
      city: "",
      ssn: "",
      password: "",
      confirmPassword: "",
      phone_number: "",
      code: "",
    };
    this.state = {
      ...data,
      contactMethod: 1,
      agreement: false,
      birthDate: moment().subtract(18, "years").format("MM-DD-YYYY"),
      phoneType: null,
      extension: "",
      workExtension: "",
      bestTimeCall: null,
      termsAccepted: false,
      termsAcceptedRegister: false,
      completeForm: false,
      error: {},
      loading: false,
      validCode: false,
      dwolla: false,
      msnErrors: {
        ...data,
        contactMethod: true,
        agreement: true,
        birthDate: "",
        address: null,
        state: null,
        city: null,
        zip_code: null,
      },
      validateForm: false,
      modal: {},
      flagEdit: false,
      step: 0,
      disabled: false,
    };
    this.onEditInput = this.onEditInput.bind(this);
  }

  setDefaultValues() {
    let email = "",
      firstName = "",
      lastName = "",
      contactNumber = "",
      extension = "",
      phoneType = null,
      workContactNumber = "",
      workExtension = "",
      bestTimeCall = null,
      zip_code = "",
      address = "",
      city = "",
      state = "",
      ssn = "",
      contactMethod = 1,
      termsAccepted = false,
      flagEdit = false,
      completeForm = true,
      phone_number = "",
      birthDate = moment().subtract(18, "years").format("MM-DD-YYYY"),
      dwolla = false,
      termsAcceptedRegister = false,
      validateSsn = false;
    if (this.props.user.user_id) {
      email = this.props.user.email;
      phone_number = this.props.user.phone_number;
      if (this.props.user.customer) {
        // email = this.props.user.customer.email;
        firstName =
          this.props.user.customer.name || this.props.user.customer.first_name;
        lastName =
          this.props.user.customer.second_name ||
          this.props.user.customer.last_name;
        birthDate = this.props.user.customer.birthDate
          ? moment(this.props.user.customer.birthDate, "YYYY-MM-DD").format(
              "MM-DD-YYYY"
            )
          : this.state.birthDate;
        contactNumber = this.props.user.customer.contact_number;
        extension = this.props.user.customer.extension;
        workContactNumber = this.props.user.customer.work_contact_number;
        workExtension = this.props.user.customer.work_extension;
        bestTimeCall = this.props.user.customer.time_call;
        zip_code = this.props.user.customer.zip_code;
        address = this.props.user.customer.address
          ? this.props.user.customer.address
          : "";
        city = this.props.user.customer.city
          ? this.props.user.customer.city
          : "";
        state = this.props.user.customer.state
          ? this.props.user.customer.state
          : "";
        ssn = this.props.user.customer.ssn
          ? "*****" + this.props.user.customer.ssn
          : "";
        contactMethod =
          this.props.user.customer.contact_method == 0
            ? 1
            : this.props.user.customer.contact_method;
        termsAccepted =
          this.props.user.customer.terms_accepted == 1 ? true : false;
        dwolla = this.props.user.customer.ssn ? true : false;
        validateSsn = false;
        flagEdit = true;

        if (!termsAccepted) {
          flagEdit = false;
          completeForm = false;
          termsAcceptedRegister = false;
        }
        if (this.props.quotationProccess && !this.props.user.customer.ssn) {
          flagEdit = false;
          validateSsn = true;
        }
      }
    } else if (this.props.user.prequalified) {
      firstName = this.props.user.firstName;
      lastName = this.props.user.lastName;
      zip_code = this.props.user.postalCode;
      address = this.props.user.address;
      city = this.props.user.city;
      state = this.props.user.state;
      dwolla = this.props.acceptedPlan;
    }

    this.setState(
      {
        email,
        firstName,
        lastName,
        contactNumber,
        extension,
        workContactNumber,
        workExtension,
        phoneType,
        phone_number,
        birthDate,
        bestTimeCall,
        zip_code,
        address,
        city,
        state,
        ssn,
        contactMethod,
        termsAccepted,
        agreement: termsAccepted,
        flagEdit,
        completeForm,
        dwolla,
        termsAcceptedRegister,
        validateSsn,
      },
      () => this.validateForm2()
    );

    if (document.querySelector(".readonly")) {
      document
        .querySelector(".readonly")
        .querySelector("input")
        .setAttribute("readonly", "true");
    }
  }

  componentDidMount() {
    this.setDefaultValues();
  }

  switchLabel(label, value, msnErrors, form = this.state) {
    switch (label) {
      case "firstName":
        msnErrors.firstName =
          value.length < 3 || value.length > 15
            ? "First Name must be at least 3 characters and a maximum of 15 characters long"
            : "";
        break;
      case "lastName":
        msnErrors.lastName =
          value.length < 3 || value.length > 25
            ? "Full Name must be at least 3 characters and a maximum of 25 characters long"
            : "";
        break;
      case "email":
        msnErrors.email = validEmail(value) ? "" : "Email is not valid";
        break;
      case "birthDate":
        msnErrors.birthDate = moment(value, "MM-DD-YYYY", true).isValid()
          ? ""
          : "Date is not valid. Must be between 18 to 100 years of age";
        break;
      // case 'contactNumber':
      //   msnErrors.contactNumber = value.length < 8 ? 'Contact Number must be at least 8 characters long' : '';
      //   break;
      case "zip_code":
        msnErrors.zip_code = !validZipCode(value)
          ? "Zip Code must be 5 characters long"
          : zipCodesStates(form.state, value);
        break;
      case "state":
        msnErrors[label] =
          value === "" || value.split(" ").join("") === ""
            ? "The field must be required"
            : null;
        msnErrors.zip_code = zipCodesStates(value, form.zip_code);
        break;
      case "city":
        msnErrors[label] =
          value === "" || value.split(" ").join("") === ""
            ? "The field must be required"
            : null;
        break;
      case "address":
        msnErrors[label] = validAddress(value);
        break;
      case "ssn":
        msnErrors.ssn = "";
        if (
          (this.state.validateSsn && !this.state.flagEdit) ||
          (!this.state.validateSsn && value && value.split(" ").join("") !== "")
        ) {
          msnErrors.ssn = !validateSSN(value) ? "SSN must be 9 digits." : "";
        }
        break;
      case "contactMethod":
        msnErrors.contactMethod = value != null ? true : false;
        break;
      case "agreement":
        msnErrors.agreement = value;
        break;
      case "password":
        msnErrors.password =
          value.length < 8 ? "Password must be at least 8 characters long" : "";
        break;
      case "confirmPassword":
        msnErrors.confirmPassword =
          value != this.state.password ? "Passwords do not match" : "";
        break;
      case "phone_number":
        msnErrors.phone_number = !validPhoneNumber(value)
          ? "Phone number must be 10 digits"
          : !validCodeNumber(value)
          ? "Invalid phone number"
          : "";
        break;
      case "contactNumber":
        if (value && value.length > 0) {
          msnErrors.contactNumber = !validPhoneNumber(value)
            ? "Contact number must be 10 digits"
            : !validCodeNumber(value)
            ? "Invalid contact number"
            : "";
        }
        break;
      default:
        break;
    }
    return msnErrors;
  }

  onEditInput(label, value) {
    let msnErrors = this.state.msnErrors;
    msnErrors = this.switchLabel(label, value, msnErrors);
    if (label === "fullAddress") {
      let form = {
        state: value.state,
        zip_code: value.zip_code,
      };
      msnErrors = this.switchLabel("address", value.address, msnErrors);
      msnErrors = this.switchLabel("state", value.state, msnErrors, form);
      msnErrors = this.switchLabel("city", value.city, msnErrors);
      msnErrors = this.switchLabel("zip_code", value.zip_code, msnErrors, form);
      this.setState(
        {
          address: value.address,
          state: value.state,
          city: value.city,
          zip_code: value.zip_code,
          msnErrors,
        },
        () => this.validateForm2()
      );
    } else {
      if (label === "birthDate") {
        console.log(
          moment(value, "MM-DD-YYYY").format("MM-DD-YYYY") >
            moment().subtract(18, "years").format("MM-DD-YYYY")
        );
        if (moment(value, "MM-DD-YYYY", true).isValid()) {
          if (
            moment(moment(value, "MM-DD-YYYY").format("YYYY-MM-DD")).isAfter(
              moment().subtract(18, "years").format("YYYY-MM-DD")
            )
          ) {
            value = moment().subtract(18, "years").format("MM-DD-YYYY");
          } else if (
            moment(moment(value, "MM-DD-YYYY").format("YYYY-MM-DD")).isBefore(
              moment().subtract(100, "years").format("YYYY-MM-DD")
            )
          ) {
            value = moment().subtract(100, "years").format("MM-DD-YYYY");
          }
          this.setState({ [label]: value, msnErrors }, () =>
            this.validateForm2()
          );
        }
      } else {
        this.setState({ [label]: value, msnErrors }, () =>
          this.validateForm2()
        );
      }
    }
    if (this.props.register) {
      this.props.validateRegister(label, value);
    }
  }

  hideModal = () => {
    this.setState({ modal: {} });
    if (this.state.modal.status == 403) {
      localStorage.removeItem("currentUser");
      if (this.props.updateUser) {
        this.props.updateUser({}, 3);
      }
    }
  };

  validateForm = () => {
    let valid = true;
    let msnErrors = this.state.msnErrors;
    let array = [
      "email",
      "firstName",
      "lastName",
      "contactNumber",
      "zip_code",
      "address",
      "city",
      "state",
      "ssn",
      "phone_number",
      "birthDate",
      "contactMethod",
      "agreement",
    ];
    for (const iterator of array) {
      msnErrors = this.switchLabel(iterator, this.state[iterator], msnErrors);
    }
    this.setState({ msnErrors });
    for (let i = 0; i < array.length - 2; i++) {
      if (msnErrors[array[i]] !== "" && msnErrors[array[i]] != null) {
        valid = false;
      }
    }
    if (valid) {
      valid = msnErrors.contactMethod;
    }
    if (valid) {
      valid = msnErrors.agreement;
    }

    return valid;
  };

  validateForm2 = () => {
    let valid = true;
    let errors = {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      zip_code: "",
      ssn: "",
      contactMethod: true,
      agreement: true,
    };
    let array = [
      "email",
      "firstName",
      "lastName",
      "contactNumber",
      "zip_code",
      "address",
      "city",
      "state",
      "ssn",
      "phone_number",
      "birthDate",
      "contactMethod",
      "agreement",
    ];
    if (this.props.register) {
      array = [
        "email",
        "firstName",
        "lastName",
        "contactNumber",
        "zip_code",
        "address",
        "city",
        "state",
        "ssn",
        "password",
        "confirmPassword",
        "phone_number",
        "birthDate",
        "contactMethod",
        "agreement",
      ];
    }
    for (const iterator of array) {
      errors = this.switchLabel(iterator, this.state[iterator], errors);
    }
    for (let i = 0; i < array.length - 2; i++) {
      if (errors[array[i]] !== "" && errors[array[i]] !== null) {
        valid = false;
      }
    }
    if (valid) {
      valid = errors.contactMethod;
    }
    if (valid) {
      valid = errors.agreement;
    }
    if (this.props.register) {
      this.props.validateRegister("completeForm", valid);
    }
    this.setState({ completeForm: !valid });
    return valid;
  };

  // -------------------------------------- function to dit the contact data ----------------------
  saveData = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ loading: true, error: {}, modal: {}, disabled: true });
    let dwolla = false;
    if (this.props.quotationProccess) {
      dwolla = this.props.acceptedPlan;
    } else {
      dwolla = this.props.user.customer.dwolla_id ? true : false;
    }

    let data = {
      email: this.state.email,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.phone_number,
      contact_number: this.state.contactNumber,
      extension: this.state.extension,
      work_contact_number: this.state.workContactNumber,
      work_extension: this.state.workExtension,
      time_call: this.state.bestTimeCall,
      zip_code: this.state.zip_code,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      ssn: this.state.ssn,
      contact_method: this.state.contactMethod,
      birthDate: moment(this.state.birthDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      ),
      terms_accepted: 1,
      dwolla: dwolla,
    };

    if (this.state.validCode) {
      data["code"] = this.state.code;
    }

    editContactData(data)
      .then((response) => {
        this.setState({ loading: false });
        if (response.status === 200) {
          this.setState({
            validCode: true,
            user: response.data,
            disabled: false,
          });
        } else {
          let currentUser = JSON.parse(
            localStorage.getItem("currentUser") || "{}"
          );
          currentUser.customer = response.data;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
          if (this.props.quotationProccess) {
            this.props.continue(
              response.data,
              this.state.email,
              this.state.phone_number
            );
          } else {
            this.setState({ ssn: "*****" + response.data.ssn });
            this.setState({
              flagEdit: true,
              validCode: false,
              modal: {
                status: 200,
                message: "Contact data was updated",
              },
            });
            this.props.updateCustomer(
              response.data,
              this.state.email,
              this.state.phone_number
            );
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        if (error.response) {
          if (error.response.status === 422) {
            this.setState({
              loading: false,
              modal: {
                status: 422,
                message: "Sorry. Fields are missing to save your information",
              },
            });
          } else if (error.response.status == 406) {
            this.setState({
              loading: false,
              modal: { message: error.response.data.message, status: 406 },
            });
          } else if (error.response.status == 400) {
            this.setState({
              loading: false,
              modal: { message: error.response.data.message, status: 400 },
            });
          } else if (error.response.status === 403) {
            if (this.props.quotationProccess) {
              this.setState({
                loading: false,
                modal: {
                  status: 403,
                  message: "Sorry. You don't have permissions",
                },
              });
            }
          } else {
            this.setState({
              loading: false,
              validCode: false,
              modal: {
                status: 500,
                message: "Sorry. An error occurred, please try again later",
              },
            });
          }
        } else {
          this.setState({
            loading: false,
            validCode: false,
            modal: {
              status: 500,
              message: "Sorry. An error occurred, please try again later",
            },
          });
        }
        console.log(error.response);
      });
  };

  editForm = () => {
    this.setState({ ssn: "" });
    this.setState({ flagEdit: false, completeForm: false }, () => {
      if (document.querySelector(".readonly")) {
        document
          .querySelector(".readonly")
          .querySelector("input")
          .setAttribute("readonly", "true");
      }
    });
  };

  cancelFormEdit = () => {
    const data = {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      workContactNumber: "",
      zip_code: "",
      address: "",
      state: "",
      city: "",
      ssn: "",
      password: "",
      confirmPassword: "",
      phone_number: "",
      code: "",
    };

    this.editForm();
    this.setDefaultValues();
    this.setState({
      msnErrors: {
        ...data,
        contactMethod: true,
        agreement: true,
        birthDate: "",
        address: null,
        state: null,
        city: null,
        zip_code: null,
      },
    });
  };

  render() {
    const { termsAccepted } = this.state;

    return (
      <Grid stackable>
        <Grid.Column width={16} className={"contactForm customForm"}>
          {this.state.error.message && (
            <Message error content={this.state.error.message} />
          )}

          <Form>
            <div className={"contactSectionWrapper"}>
              {this.props.quotationProccess && (
                <div className="ui-mobile">
                  <Video
                    flag={true}
                    video={"approval"}
                    videos={this.props.videos}
                  />
                </div>
              )}

              <Section>
                <Header>Contact Info</Header>

                <BasicInformation
                  form={this.state}
                  msnErrors={this.state.msnErrors}
                  flagEdit={this.state.flagEdit}
                  onEditInput={this.onEditInput}
                  noPadding={this.props.quotationProccess ? false : true}
                />

                <Address
                  address={{
                    address: this.state.address,
                    city: this.state.city,
                    zip_code: this.state.zip_code,
                    state: this.state.state,
                  }}
                  onChangeState={this.onEditInput}
                  flagEdit={this.state.flagEdit}
                  msnErrors={this.state.msnErrors}
                  noPadding={this.props.quotationProccess ? false : true}
                  theme={this.props.theme}
                />
              </Section>
            </div>

            <div className={"contactSectionWrapper"}>
              <Section>
                <Header>Personal Contact Number</Header>

                <ContactInformation
                  form={this.state}
                  msnErrors={this.state.msnErrors}
                  flagEdit={this.state.flagEdit}
                  onEditInput={this.onEditInput}
                  noPadding={this.props.quotationProccess ? false : true}
                />
              </Section>
            </div>

            <div className={"contactSectionWrapper"}>
              <Section>
                <Header>Work Contact Number</Header>

                <WorkContactInformation
                  form={this.state}
                  msnErrors={this.state.msnErrors}
                  flagEdit={this.state.flagEdit}
                  onEditInput={this.onEditInput}
                  noPadding={this.props.quotationProccess ? false : true}
                />
              </Section>
            </div>

            <div className={"contactSectionWrapper"}>
              <Section>
                <Header>Unique Identifiers</Header>

                <UniqueIdentifiers
                  form={this.state}
                  msnErrors={this.state.msnErrors}
                  flagEdit={this.state.flagEdit}
                  onEditInput={this.onEditInput}
                  noPadding={this.props.quotationProccess ? false : true}
                />
              </Section>
            </div>

            <div className={"contactSectionWrapper"}>
              <Section>
                <Header>User Info</Header>

                <UserInformation
                  form={this.state}
                  msnErrors={this.state.msnErrors}
                  flagEdit={this.state.flagEdit}
                  onEditInput={this.onEditInput}
                  register={this.props.register}
                  noPadding={this.props.quotationProccess ? false : true}
                />
              </Section>
            </div>

            {termsAccepted == 0 && (
              <Grid>
                <Grid.Column>
                  <Form.Checkbox
                    className="agree-check"
                    id={"agreement"}
                    value={this.state.agreement}
                    label={
                      <label>
                        I agree to the terms and conditions and to receive text
                        messages and phone calls from Advantage2U™ and Ewald
                        Kia. I understand that no consent to texting or calling
                        is required for a purchase of a product or a service.
                        Please <TermsConditions show={true} /> of Use.{" "}
                      </label>
                    }
                    onChange={(e, value) =>
                      this.onEditInput("agreement", value.checked)
                    }
                    error={
                      !this.state.msnErrors.agreement
                        ? "Please, accept the terms and conditions"
                        : null
                    }
                  />
                </Grid.Column>
              </Grid>
            )}
          </Form>

          <Grid>
            <Grid.Column floated={"right"} className={"customButton"}>
              <div className={"contactBottomActions"}>
                <div className={"editButtons"}>
                  {!this.state.flagEdit ? (
                    <Button
                      className="btn-continue"
                      onClick={this.cancelFormEdit}
                      buttonStyle="danger"
                      theme={this.props.theme?.button}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className="btn-continue"
                      onClick={this.editForm}
                      buttonStyle="secondary"
                      theme={this.props.theme?.button}
                    >
                      Edit
                    </Button>
                  )}
                </div>

                {!this.state.flagEdit ? (
                  !this.props.register && (
                    <Button
                      className="btn-continue"
                      onClick={this.saveData}
                      disabled={this.state.completeForm}
                      theme={this.props.theme?.button}
                    >
                      Save
                    </Button>
                  )
                ) : (
                  <>
                    {this.props.quotationProccess ? (
                      <Button
                        className="btn-continue"
                        onClick={() =>
                          this.props.continue(
                            this.props.user.customer,
                            this.state.email,
                            this.state.phone_number
                          )
                        }
                        theme={this.props.theme?.button}
                      >
                        Approve My Credit & Update My Vault
                      </Button>
                    ) : (
                      <Button
                        className="btn-continue"
                        onClick={this.saveData}
                        disabled={this.state.completeForm}
                        theme={this.props.theme?.button}
                      >
                        Save
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Grid.Column>
          </Grid>
        </Grid.Column>

        {this.state.validCode && (
          <CodeModal
            logged={true}
            phone={this.props.user.phone_number}
            updateCode={this.onEditInput}
            action={this.saveData}
            hideModalCode={() =>
              this.setState({ validCode: false, disabled: false })
            }
            disabled={this.state.disabled}
            theme={this.props.theme}
          />
        )}

        {this.state.loading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}

        {this.state.modal.message && (
          <AlertModal hideModal={this.hideModal} modal={this.state.modal} />
        )}
      </Grid>
    );
  }
}

export default Contact;
