// IMPORT OBJECTS/LIBRARIES
import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import _ from "lodash";

import EmptyImage from "../../../images/no-image-available.png";
import { ReactComponent as ChevronIcon } from "../../../images/chevronIcon.svg";

// IMPORT CSS STYLE
import "./carousel.sass";

class CustomCarousel extends Component {
  constructor(props) {
    super(props);
    // -------------------------------------------------- validate extension ------------------------
    this.state = {
      player: [],
      images: [],
    };
    this.onSlide = this.onSlide.bind(this);
    this.onReady = this.onReady.bind(this);
    this.images = [];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.carId == this.props.carId) {
      return false;
    }
    return true;
  }

  proccessImages(imgs) {
    let images = [];
    let propImg = imgs ? JSON.parse(imgs) : [];
    // propImg.push('http://fs.ebait.biz/3vv47dk14/2009-Ford-Taurus_KN2070A-61.mp4');
    const types = [
      { ex: "jpg", type: "image" },
      { ex: "png", type: "image" },
      { ex: "3gp", type: "image" },
      { ex: "mp4", type: "video" },
      { ex: "ogg", type: "video" },
      { ex: "webm", type: "video" },
    ];
    for (const image of propImg) {
      if (image != "") {
        const url = new URL(image);
        let obj = {
          original: image,
          thumbnail: image,
          type: "img",
        };
        for (const type of types) {
          if (url.pathname.substr(url.pathname.length - 3).includes(type.ex)) {
            if (type.type == "video") {
              obj["original"] = require("../../../images/video.png");
              obj["thumbnail"] = require("../../../images/video.png");
              obj["type"] = "video";
              obj["original"] = image;
              obj["renderItem"] = this.renderVideo.bind(this);
            }
          }
        }
        if (!url.pathname.substr(url.pathname.length - 3).includes("flv")) {
          images.push(obj);
        }
      }
    }
    if (images.length == 0) {
      images.push({
        original: require("../../../images/no-image-available.png"),
        thumbnail: require("../../../images/no-image-available.png"),
        type: "img",
      });
    }
    return images;
  }

  // ------------------------------- puse video in each slide ---------------------------------------------
  onSlide(e, lol) {
    this.state.player.forEach((player) => {
      player.pause();
    });
  }

  // ------------------------------- get video tag --------------------------------------------------------
  onReady(event) {
    const player = this.state.player;
    player.push(event.target);
    this.setState({
      player: player,
    });
  }

  // ------------------------------- show video tag --------------------------------------------------------
  renderVideo(item) {
    return (
      <video
        class="image-gallery-image"
        src={item.embedUrl}
        controls
        onLoadStart={(e) => this.onReady(e)}
      ></video>
    );
  }

  render() {
    let images = this.proccessImages(this.props.images);
    return (
      <div
        style={{
          "--carousel-selected-color": this.props.theme?.typography.primary,
        }}
      >
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          showIndicators={false}
          showThumbs={this.props.showThumbs ? false : true}
          swipeable={true}
          className={"carouselImageList"}
          showStatus={false}
          onChange={(e, lol) => this.onSlide(e, lol)}
          renderArrowPrev={(clickHandler) => (
            <button
              className={"carousel-control prev-arrow"}
              onClick={clickHandler}
            >
              <ChevronIcon />
            </button>
          )}
          renderArrowNext={(clickHandler) => (
            <button
              className={"carousel-control next-arrow"}
              onClick={clickHandler}
            >
              <ChevronIcon />
            </button>
          )}
        >
          {images.map((itm, ix) => {
            return itm.type == "img" ? (
              <div>
                <img
                  src={itm.original}
                  key={ix + new Date()}
                  onError={({ currentTarget }) => {
                    currentTarget.src = EmptyImage;
                  }}
                />
              </div>
            ) : (
              <div className="grapper-video" key={ix}>
                <img
                  className="img-video"
                  src={require("../../../images/video.png")}
                />
                <video
                  width="100%"
                  className="image-gallery-image"
                  src={itm.original}
                  controls
                  onLoadStart={(e) => this.onReady(e)}
                ></video>
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  }
}

export default CustomCarousel;
