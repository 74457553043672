import React, { useEffect, useState } from 'react';
import { Form, Header, Icon, Message, Popup, Table } from 'semantic-ui-react';
import { calculatePaymentsWeekly, FormatNumberToPrice } from '../../helpers/general';
import Paydown from 'paydown';
import moment from 'moment';
import { PaymentSchedule } from './PaymentSchedule';

export const PaymentsTable = ({ data, errors, getAmortization, payment, onChangeForm, disabled, fee, getPaymentList }) => {
  const [quote, setQuote] = useState({ weekly: 0, weeklyPlus: 0 });
  const [payments, setPayments] = useState([])
  const [error, setError] = useState("")
  const [result, setResult] = useState({ actual_end_date: "", days_calculated: 0, latest_payment_date: '', remaining_principal: '' })

  // ----------------------------- show weekly and weekly plus
  const buildTable = () => {
    const formula = { debit_fee_week: fee }
    let quotes = calculatePaymentsWeekly(null, data.loanMonthlyPayment, formula, +data.loanTerm, 0, data.loanAmount);
    setQuote({ weekly: quotes.weekly.payment, weeklyPlus: quotes.weeklyPlus.payment });
  }

  useEffect(() => {
    if (data.loanMonthlyPayment && data.loanTerm && data.loanAmount && !errors.loanMonthlyPayment && !errors.loanTerm && !errors.loanAmount) {
      buildTable();
    }
    const day = data.firstDate.split('-');
    var init_data = {
      "start_date": moment(data.startDate, "MM-DD-YYYY").format("DD.MM.YYYY"),
      "end_date": moment(data.endDate, "MM-DD-YYYY").format("DD.MM.YYYY"),
      "principal": +data.principal,
      "rate": +data.rate,
      "recurring":
      {
        "amount": +data.loanMonthlyPayment,
        "first_payment_date": moment(data.firstDate, "MM-DD-YYYY").format("DD.MM.YYYY"),
        "payment_day": +day[1],
      }
    }

    var payments_array = []
    var rval_obj
    var calculator = new Paydown();
    try {
      rval_obj = calculator.calculate(init_data, "", payments_array)
      setResult(rval_obj);
      setPayments(payments_array);
      setError('');
      let schedule = {
        total: rval_obj,
        schedule: payments_array
      }
      console.log('====================================');
      console.log(schedule);
      console.log('====================================');
      getAmortization(schedule);
      onChangeForm(true, 'amount')
    } catch (err) {
      onChangeForm(false, 'amount')
      setError(err.message);
      setPayments([]);
      getAmortization(null);
    }
  }, [data])


  return (
    <div>
      <Header as='h2'>Weekly Power Payment vs Weekly Power Payment PLUS</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Weekly Power Payment</Table.HeaderCell>
            <Table.HeaderCell>Weekly Power Payment PLUS</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {
                quote?.weekly > 0 && (
                  <Form.Checkbox
                    label={
                      <label>
                        {FormatNumberToPrice(quote.weekly)}
                        <Popup wide position={"top center"}
                          content={FormatNumberToPrice(quote.weekly) + " = " + FormatNumberToPrice(quote.weekly - fee) + " (Loan)  + " + FormatNumberToPrice(fee) + " (Program Fee)"}
                          trigger={<Icon link name="info circle" />}
                        />
                      </label>
                    }
                    checked={FormatNumberToPrice(payment) === FormatNumberToPrice(quote.weekly)}
                    onChange={(e, value) => onChangeForm(quote.weekly, 'payment', 'weekly')}
                    disabled={disabled}
                  />
                )
              }
            </Table.Cell>
            <Table.Cell>
              {
                quote?.weeklyPlus > 0 && (
                  <Form.Checkbox
                    label={
                      <label>
                        {FormatNumberToPrice(quote.weeklyPlus)}
                        <Popup wide position={"top center"}
                          content={FormatNumberToPrice(quote.weeklyPlus) + " = " + FormatNumberToPrice(quote.weeklyPlus - fee) + " (Loan)  + " + FormatNumberToPrice(fee) + " (Program Fee)"}
                          trigger={<Icon link name="info circle" />}
                        />
                      </label>
                    }
                    checked={FormatNumberToPrice(payment) === FormatNumberToPrice(quote.weeklyPlus)}
                    onChange={(e, value) => onChangeForm(quote.weeklyPlus, 'payment', 'weeklyPlus')}
                    disabled={disabled}
                  />
                )
              }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <PaymentSchedule paymentPlan={data} payments={payments} result={result} disabled ={error} fee={fee} getPaymentList={getPaymentList}/>
      {
        error && (
          <Message negative>
            <Message.Header>We're sorry you can't start the plan</Message.Header>
            <p>{error}</p>
          </Message>
        )
      }
    </div>
  );
};