import React, { useEffect, useState } from 'react';
import { Dimmer, Loader, Message } from 'semantic-ui-react';
import { PlaidLink } from 'react-plaid-link';
import ModalComponent from '../alertModal/modal';
import { createFundingAccount, getPlaidToken } from '../requests';

const AddAccount = (props) => {
  const [errors, setErrors] = useState(false);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("")
  const [loadingToken, setLoadingToken] = useState(false);

  // ---------------------------- plaid events -----------------
  const onSuccess = (public_token, metadata) => {
    sendData(metadata);
  }

  const error = () => {
    setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later" });
  }

  // -------------------------- sen data to backend
  const sendData = (data) => {
    setIsLoading(true);
    let params = {
      publicToken: data.public_token,
      accountId: data.account_id
    }
    createFundingAccount(params).then(response => {
      setIsLoading(false);
      setModal({ status: 201, message: "Funding source account was added", account: response.data });
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later" });
        }
      }
    });
  }

  // ---------------------------- hide modal -----------------------------------
  const hideModal = () => {
    if (modal.status === 201) {
      props.closeModal(modal.account);
    }
    setModal({ modal: {} });
  }

  // --------------------- get token ------------------------------------------
  const getToken = (isMounted) => {
    setLoadingToken(true);
    getPlaidToken().then(response => {
      setLoadingToken(false);
      if (isMounted) {
        setLink(response.data.link_token);
      }
    }).catch(error => {
      setLoadingToken(false);
      if (error.response) {
        setErrors(true);
      }
    })
  }

  useEffect(() => {
    console.log(props);
    let isMounted = true;
    getToken(isMounted);
    return () => { isMounted = false };
  }, [props.customer.account])


  return (
    <div>
      {
        (link && !errors) && (
          <PlaidLink
            className='ui green button'
            token={link}
            onSuccess={onSuccess}
            error={error}
            style={{
              padding: "invlaid",
              outline: "invlaid",
              background: "invlaid",
              border: "invlaid",
              borderRadius: "invlaid",
            }}
          >
            + Funding Source Account
          </PlaidLink>

        )
      }
      {
        errors && (
          <Message negative style={{marginRight: "0.5em"}}>
            <Message.Header>An error occurred to create the Plaid button.</Message.Header>
          </Message>
        )
      }
      <Dimmer className="button-loading" active={loadingToken} >
        <Loader />
      </Dimmer>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent hideModal={hideModal} modal={modal} />
      }
    </div>
  );
}

export default AddAccount;