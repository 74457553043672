import React from "react";
import { Popup } from "semantic-ui-react";
import moment from "moment";
import cn from "classnames";

import Button from "../../../../global/Button";
import { defaultTheme, useTheme } from "../../../../helpers/theme";

import DisabledTooltip from "./DisabledTooltip";

import { ReactComponent as InfoIcon } from "../../../../../images/infoCircle.svg";
import { ReactComponent as RebateIcon } from "../../../../../images/rebateIcon.svg";

import "./RebateCard.sass";

const RebateCard = ({
  id,
  name,
  value,
  until,
  from,
  details,
  isSelected,
  isDisabled,
  onClick,
}) => {
  const { theme } = useTheme();

  return (
    <button
      className={cn(
        "rebateCardButton",
        isSelected && "rebateCardButtonSelected",
        isDisabled && "rebateCardButtonDisabled"
      )}
      style={{
        "--selected-card-border-color":
          theme?.button?.secondary?.background ||
          defaultTheme.button.secondary.background,
      }}
    >
      <li>
        <div>
          <div className={"rebateCardIcon"}>
            <RebateIcon />
          </div>

          <p>{name}</p>

          {details && (
            <Popup
              position="top right"
              trigger={<InfoIcon className={"rebateCardInfoIcon"} />}
              content={<span>{details}</span>}
            />
          )}
        </div>

        <div className={"rebateCardInfo"}>
          <span>{value}</span>

          <span>
            {from
              ? `${moment(from).format("MMM DD, YYYY")} - ${moment(
                  until
                ).format("MMM DD, YYYY")}`
              : "Until " + moment(until).format("MMM DD, YYYY")}
          </span>

          <DisabledTooltip isVisible={isDisabled}>
            <div>
              <Button
                buttonStyle={"secondary"}
                onClick={() => onClick(id)}
                fluid
                theme={theme?.button}
              >
                {isSelected ? "Remove" : "Add"}
              </Button>
            </div>
          </DisabledTooltip>
        </div>
      </li>
    </button>
  );
};

export default RebateCard;
