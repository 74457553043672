import React from "react";
import moment from "moment";

import { Table, Icon } from "semantic-ui-react";

import { FormatNumberToPrice } from "../../../helpers/general";
import { EditCarButton } from "../editCarButton/EditCarButton";
import { InventoryHeadTable } from "../InventoryHeadTable";
import Button from "../../../global/Button";

const InventoryTable = ({
  handleSortUpdate,
  inventory,
  pagination,
  updateIventoryListEdit,
  inventoryBackUp,
}) => {
  return (
    <Table sortable celled unstackable>
      <InventoryHeadTable handleSortUpdate={handleSortUpdate} />

      <Table.Body>
        {inventory.slice(pagination.startPage, pagination.endPage).map(
          (item) =>
            !item.hide && (
              <Table.Row>
                <Table.Cell>{item.stock_number}</Table.Cell>
                <Table.Cell>{moment(item.stock_date).format("LL")}</Table.Cell>
                <Table.Cell>{item.year}</Table.Cell>
                <Table.Cell>{item.make}</Table.Cell>
                <Table.Cell>{item.model}</Table.Cell>
                <Table.Cell>{item.trim}</Table.Cell>
                <Table.Cell>{item.exterior_color}</Table.Cell>
                <Table.Cell>{item.interior_color}</Table.Cell>
                <Table.Cell>{item.miles}</Table.Cell>
                <Table.Cell>{FormatNumberToPrice(item.price)}</Table.Cell>
                <Table.Cell>
                  <EditCarButton
                    car={item}
                    updateIventoryList={updateIventoryListEdit}
                    inventory={inventory}
                    inventoryBackUp={inventoryBackUp}
                  />

                  <br />

                  <Button buttonStyle="brown" icon>
                    <Icon name="file pdf" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
};

export default InventoryTable;
