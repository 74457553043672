import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Container, Dimmer, Grid, Header, Loader, Popup } from 'semantic-ui-react';
import ModalComponent from '../alertModal/modal';
import { ConfirmModal } from '../confirmModal/ConfirmModal';
import { LenderAccounts } from '../lender/LenderAccounts';
import { deleteFundingAccount } from '../requests';
import AddAccount from './AddAccount';
import { DwollaProfile } from './DwollaProfile';
import ValidateAccount from './ValidateAccount';

const Dwolla = (props) => {
  const [customer, setCustomer] = useState(props);
  const [verifyModal, setVerifyModal] = useState(false);
  const [modal, setModal] = useState({});
  const [confirmModal, setConfirmModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // ------------------------------ delete account
  const confirmDelete = (flag) => {
    setConfirmModal(false);
    if (flag) {
      deleteAccount();
    }
  }
  const deleteAccount = () => {
    setIsLoading(true);
    deleteFundingAccount().then(response => {
      setIsLoading(false);
      let auxCustomer = { ...customer };
      auxCustomer['account'] = null;
      setCustomer({ ...auxCustomer });
      setModal({ status: 204, message: "Funding source account was deleted" });
      updateDwollaCustomer(auxCustomer);
    }).catch(error => {
      console.log(error);
      setIsLoading(false);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please try again later" });
        }
      }

    });
  }

  // ---------------------------- update customer --------
  const updateDwollaCustomer = (customer, profile) => {
    console.log(customer);
    if (profile) {
      setCustomer({ ...customer });
    }
    props.updateCustomerData(customer, profile ? '' : "dwolla");
  }

  // ---------------------------- update customer account
  const closeAddAccount = (account) => {
    if (account) {
      let auxCustomer = { ...customer };
      auxCustomer['account'] = account;
      setCustomer({ ...auxCustomer });
      updateDwollaCustomer(auxCustomer);
    }
  }

  // ---------------------------- hide modal -----------------------------------
  const hideModal = () => {
    setModal({ modal: {} });
  }

  return (
    <Container>
      <Header size='huge'>Directory</Header>
      {
        customer.dwolla_id ?
          !customer.account ?
            <Grid>
              <Grid.Row >
                <Header as={"h4"}>
                  You do not have a funding source account
                </Header>
              </Grid.Row>
              <Grid.Row style={{position: "inherit"}}>
                <AddAccount closeModal={closeAddAccount} customer={customer} />
                <LenderAccounts customer={customer} />
              </Grid.Row>
            </Grid>
            :
            <Grid>
              <Grid.Row >
                {
                  customer.account.verified ?
                    <Header as={"h4"}>
                      Your funding source account has been verified
                    </Header>
                    :
                    <Header as={"h4"}>You have a funding source account</Header>
                }
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={16}>
                  <Header as={"h3"}>
                    {/* {customer.account.name + " - #" + customer.account.routingNumber} */}
                  </Header>

                </Grid.Column>
                <Grid.Column width={8}>
                  <small>Created: {moment(customer.account.created_at).format('LLL')}</small>
                </Grid.Column>
                <Grid.Column width={8}>
                  {
                    customer.account.verified ?
                      <small>Verified: {moment(customer.account.updated_at).format('LLL')}</small>
                      : null
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                {
                  !customer.account.verified && (
                    <span className='verify-msn'><span>*</span> <small>After 1-2 days deposits will be made, you have to verify your bank account by providing the two amounts of the microdeposits ($ 0.01 - $ 0.99).</small></span>
                  )
                }
                <Button style={{ marginBottom: "1em", minWidth: "150px" }} negative disabled={customer.activatedPlans > 0 ? true : false} onClick={() => setConfirmModal({ title: "Delete Funding Source Account", message: "Are you sure you want to delete the funding source account?" })}>Delete</Button>
                {
                  !customer.account.verified && (
                    <Button style={{ marginBottom: "1em", minWidth: "150px" }} positive onClick={() => setVerifyModal(true)}>Verify</Button>
                  )
                }
                <LenderAccounts customer={customer} />
              </Grid.Row>
              {
                customer.activatedPlans > 0 && (
                  <Grid.Row >
                    <Header disabled as={"h5"}>
                      You have an activated payment plan, you cannot delete the account
                    </Header>
                  </Grid.Row>
                )
              }
            </Grid>
          :
          <DwollaProfile customer={customer} updateCustomer={updateDwollaCustomer}/>
      }
      {
        verifyModal && (
          <ValidateAccount closeModal={closeAddAccount} />
        )
      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent hideModal={hideModal} modal={modal} />
      }
      {
        confirmModal.message &&
        <ConfirmModal modal={confirmModal} afterEvent={confirmDelete} />
      }
    </Container >
  );
}

export default Dwolla;