import React, { useContext } from "react";

import { AgreementCtx } from "../../context";

import "./PaymentsTable.sass";

const PaymentsTable = () => {
  const { data } = useContext(AgreementCtx);

  return (
    <table className={"paymentsTableWrapper"}>
      <thead>
        <tr>
          <th colSpan={2} className={"tableHeader"}>
            Down Payment
          </th>

          <th colSpan={3} className={"tableHeader"}>
            Terms in Months
          </th>
        </tr>
      </thead>

      <tbody>
        <tr className={"tableRow"}>
          <td />

          <td />

          <td>{data.payment_options_term_in_months_1}</td>

          <td>{data.payment_options_term_in_months_2}</td>

          <td>{data.payment_options_term_in_months_3}</td>
        </tr>

        <tr className={"tableRow"}>
          <td />

          <td>{data.payment_options_down_payment_usd_1}</td>

          <td>{data.payment_options_payment_option_1}</td>

          <td>{data.payment_options_payment_option_2}</td>

          <td>{data.payment_options_payment_option_3}</td>
        </tr>

        <tr className={"tableRow"}>
          <td>{data.payment_options_down_payment_percentage_2}</td>

          <td>{data.payment_options_down_payment_usd_2}</td>

          <td>{data.payment_options_payment_option_4}</td>

          <td>{data.payment_options_payment_option_5}</td>

          <td>{data.payment_options_payment_option_6}</td>
        </tr>

        <tr className={"tableRow"}>
          <td>{data.payment_options_down_payment_percentage_3}</td>

          <td>{data.payment_options_down_payment_usd_3}</td>

          <td>{data.payment_options_payment_option_7}</td>

          <td>{data.payment_options_payment_option_8}</td>

          <td>{data.payment_options_payment_option_9}</td>
        </tr>

        <tr className={"tableRow"}>
          <td>{data.payment_options_down_payment_percentage_4}</td>

          <td>{data.payment_options_down_payment_usd_4}</td>

          <td>{data.payment_options_payment_option_10}</td>

          <td>{data.payment_options_payment_option_11}</td>

          <td>{data.payment_options_payment_option_12}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PaymentsTable;
