import React from "react";
import { Message } from "semantic-ui-react";
import moment from "moment";

import RebateModal from "../RebateModal";

import { getRebateValue } from "../../../helpers/general";

import "./RebatesList.sass";

const RebatesList = ({ retailer, rebates, listOfAllRebates, update }) => {
  return (
    <>
      {!!listOfAllRebates.length ? (
        <ul className={"rebatesList"}>
          {rebates.map((rebate) => (
            <RebateModal
              key={rebate.id}
              rebate={rebate}
              retailer={retailer}
              update={update}
              rebates={rebates}
              allRebates={listOfAllRebates}
            >
              <button className={"rebateItem"}>
                <li>
                  <h4>{rebate.title}</h4>

                  <div className={"rebateItemPeriod"}>{`${moment(
                    rebate.start_date
                  ).format("DD/MM/YYYY")} - ${moment(rebate.end_date).format(
                    "DD/MM/YYYY"
                  )}`}</div>

                  <div className={"rebateItemValue"}>
                    {getRebateValue(rebate.value, rebate.type)}
                  </div>
                </li>
              </button>
            </RebateModal>
          ))}
        </ul>
      ) : (
        <Message info>
          <Message.Header>Info</Message.Header>

          <Message.Content>The list of rebates is empty</Message.Content>
        </Message>
      )}
    </>
  );
};

export default RebatesList;
