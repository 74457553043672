import React, { useState } from "react";
import { Modal, Form, Divider, Message } from "semantic-ui-react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

import Button from "../../../../global/Button";
import Input from "../../../../global/Input";
import Dropdown from "../../../../global/DropDown";

import { allowedCategoryNames } from "../../../../helpers/general";

import { schema, valueOptions } from "./config";

import "./ProductManager.sass";

const ProductManagerModal = ({
  products,
  category,
  provider,
  isSelected,
  selectedProducts,
  onAdd,
  onRemove,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    mode: "onBlur",
  });

  const { fields } = useFieldArray({
    control,
    name: "products",
  });

  const priorityOptions = products.map((product, index) => ({
    key: product.ex_1_product_id,
    text: index + 1,
    value: index + 1,
  }));

  const open = () => {
    setIsOpen(true);

    setValue(
      "products",
      isSelected
        ? selectedProducts.products
        : products.map((product, index) => ({
            name: product.product_name,
            priority: index + 1,
            productId: product.ex_1_product_id,
            providerId: provider.ex_1_provider_id,
            type: "usd",
            conditions: product.conditions,
          }))
    );
    setValue(
      "category",
      isSelected ? selectedProducts.customTitle : allowedCategoryNames[category]
    );
  };

  const close = () => {
    setIsOpen(false);
    setError("");
    reset();
  };

  const handleOnSubmit = ({ products, category: customTitle }) => {
    if (_.isEmpty(error)) {
      onAdd({ category, products, customTitle });
      close();
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal open={isOpen} onClose={close} closeIcon style={{ maxWidth: 600 }}>
        <Modal.Header>Product Manager</Modal.Header>

        <Modal.Content scrolling>
          <Form>
            <div className={"providerName"}>
              Provider: <strong>{provider.provider_name}</strong>
            </div>

            <Controller
              control={control}
              name={"category"}
              render={({ field }) => (
                <Form.Field>
                  <label>Category Name</label>

                  <Input
                    {...field}
                    error={errors?.category && errors.category.message}
                  />
                </Form.Field>
              )}
            />

            <Divider />

            {fields.map((product, index, arr) => {
              const productId = product.ex_1_product_id;
              const errorsOfItem = errors?.products && errors?.products[index];
              const isLast = arr.length === index + 1;

              return (
                <div key={productId}>
                  <div className={"productTitle"}>
                    <span>{product.name}</span>
                  </div>

                  <div className={"productFieldsWrapper"}>
                    <div className={"productFieldsLeft"}>
                      <Controller
                        name={`products.${index}.priority`}
                        control={control}
                        render={({ field: { onChange, ...rest } }) => (
                          <Form.Field>
                            <label>Product Priority</label>

                            <Dropdown
                              options={priorityOptions}
                              {...rest}
                              onChange={(e, { value }) => {
                                onChange(value);

                                const updatedList = getValues(
                                  "products"
                                ).reduce((all, item) => {
                                  const existed = all.find(
                                    (p) => p.priority === item.priority
                                  );

                                  return !existed ? [...all, item] : all;
                                }, []);

                                if (
                                  updatedList.length !==
                                  getValues("products").length
                                ) {
                                  setError(
                                    "The value of the product priority must be unique"
                                  );
                                } else {
                                  setError("");
                                }
                              }}
                              fluid
                              error={
                                !_.isEmpty(errorsOfItem) &&
                                errorsOfItem.priority?.message
                              }
                            />
                          </Form.Field>
                        )}
                      />

                      <div className={"productRangeWrapper"}>
                        <label>Miles Range</label>

                        <div className={"productRangeFieldsWrapper"}>
                          <Controller
                            name={`products.${index}.conditions.miles.from`}
                            control={control}
                            render={({ field }) => (
                              <Form.Field>
                                <Input
                                  placeholder={"From"}
                                  error={
                                    !_.isEmpty(errorsOfItem) &&
                                    errorsOfItem.conditions?.miles?.from
                                      ?.message
                                  }
                                  {...field}
                                />
                              </Form.Field>
                            )}
                          />

                          <Controller
                            name={`products.${index}.conditions.miles.to`}
                            control={control}
                            render={({ field }) => (
                              <Form.Field>
                                <Input
                                  placeholder={"To"}
                                  error={
                                    !_.isEmpty(errorsOfItem) &&
                                    errorsOfItem.conditions?.miles?.to?.message
                                  }
                                  {...field}
                                />
                              </Form.Field>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={"productFieldsRight"}>
                      <div className={"productManagerModalAmountInput"}>
                        <Controller
                          name={`products.${index}.value`}
                          control={control}
                          render={({ field }) => (
                            <Form.Field required>
                              <label>Retailer Markup</label>

                              <Input
                                {...field}
                                error={errorsOfItem?.value?.message}
                                placeholder={"100"}
                                label={
                                  <Controller
                                    name={`products.${index}.type`}
                                    control={control}
                                    render={({
                                      field: { value, onChange, ...rest },
                                    }) => (
                                      <Dropdown
                                        value={value}
                                        onChange={(e, { value }) => {
                                          onChange(value);
                                        }}
                                        options={valueOptions}
                                        {...rest}
                                      />
                                    )}
                                  />
                                }
                                {...field}
                                fluid
                              />
                            </Form.Field>
                          )}
                        />
                      </div>

                      <div className={"productRangeWrapper"}>
                        <label>Months Range</label>

                        <div className={"productRangeFieldsWrapper"}>
                          <Controller
                            name={`products.${index}.conditions.months.from`}
                            control={control}
                            render={({ field }) => (
                              <Form.Field>
                                <Input
                                  error={
                                    !_.isEmpty(errorsOfItem) &&
                                    errorsOfItem.conditions?.months?.from
                                      ?.message
                                  }
                                  placeholder={"From"}
                                  {...field}
                                />
                              </Form.Field>
                            )}
                          />

                          <Controller
                            name={`products.${index}.conditions.months.to`}
                            control={control}
                            render={({ field }) => (
                              <Form.Field>
                                <Input
                                  error={
                                    !_.isEmpty(errorsOfItem) &&
                                    errorsOfItem.conditions?.months?.to?.message
                                  }
                                  placeholder={"To"}
                                  {...field}
                                />
                              </Form.Field>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {!isLast && <Divider />}
                </div>
              );
            })}

            {!_.isEmpty(error) && (
              <Message negative>
                <Message.Header>Error</Message.Header>

                <Message.Content>{error}</Message.Content>
              </Message>
            )}
          </Form>
        </Modal.Content>

        <Modal.Actions className={"actionButtons"}>
          {isSelected ? (
            <Button
              buttonStyle="danger"
              onClick={() => {
                onRemove(category);
                close();
              }}
            >
              Remove
            </Button>
          ) : (
            <Button buttonStyle="danger" onClick={close}>
              Close
            </Button>
          )}

          <Button
            onClick={handleSubmit(handleOnSubmit)}
            disabled={!_.isEmpty(error)}
          >
            {isSelected ? "Edit" : "Add"}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProductManagerModal;
