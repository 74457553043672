import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToolBar } from './ToolBar';

import "./Editor.sass"

const formats = ["header", "size", "bold", "italic", "underline", "strike", "list", "link", "image", "background", "align", "script", "indent", "clean", "color"]

export const Editor = ({ text, show, updateText }) => {

  const handleChange = (value) => {
    updateText(value);
  }

  return (
    <div>
      <ToolBar show={show}/>
      <ReactQuill className="scrollingEditor" readOnly={show} value={text} onChange={handleChange} formats={formats}
        modules={{
          toolbar: {
            container: "#toolbarA2U",
          },
          clipboard: {
            matchVisual: false,
          }
        }}
      />
    </div>
  );
};