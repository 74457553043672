import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';

const housingStatusOptions = [
  { value: "Mortgage", text: "Mortgage" },
  { value: "Rent", text: "Rent" },
  { value: "Family", text: "Family" },
  { value: "OwnOutright", text: "Own Outright" },
  { value: "Military", text: "Military" },
  { value: "Other", text: "Other" },
];

export const AddressInformation = (props) => {
  const {form, errors} = props;

  useEffect(() => {
    
  }, [form, errors])

  return (
    <Form.Group>
      <Form.Input
        value={form.monthsAtCurrentAddress}
        label={<label>Months At Current Address (months) <span className="required-inut">*</span></label>}
        placeholder={'Months At Current Address'}
        onChange={(e) => props.onEditInput('monthsAtCurrentAddress', e.target.value)}
        width={6}
        fluid
        error={form.monthsAtCurrentAddress || errors.monthsAtCurrentAddress ? errors.monthsAtCurrentAddress : null}
        disabled={props.flagEdit}
      />
      <Form.Select
        value={form.housingStatus}
        label={<label>Housing Status <span className="required-inut">*</span></label>}
        onChange={(e, { value }) => props.onEditInput('housingStatus', value)}
        width={6}
        fluid
        options={housingStatusOptions}
        error={form.housingStatus || errors.housingStatus ? errors.housingStatus : null}
        disabled={props.flagEdit}
      />
      <Form.Input
        value={form.mortgageOrRentAmount}
        label={<label>Mortgage Or Rent Amount ($) <span className="required-inut">*</span></label>}
        placeholder={'Mortgage Or Rent Amount'}
        onChange={(e) => props.onEditInput('mortgageOrRentAmount', e.target.value)}
        width={6}
        fluid
        error={form.mortgageOrRentAmount || errors.mortgageOrRentAmount ? errors.mortgageOrRentAmount : null}
        disabled={props.flagEdit}
      />
    </Form.Group>
  );
};