//Formater for Price
import moment from "moment";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import isInt from "validator/lib/isInt";
import isLength from "validator/lib/isLength";
import { isValidPhoneNumber } from "react-phone-number-input";
import { zipCodesStates } from "./usaStates";
import _ from "lodash";

import "./general.sass";

const WEEKLY_FEE = 1.25;
const MONTHLY_FEE = 2.45;

export const formatterPrice = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});

export const currencyFormatter = (
  value,
  minimumFractionDigits = 2,
  isWhiteSpace = false
) => {
  const instance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
  });
  const result = instance.format(value);

  return isWhiteSpace ? result.replace(",", " ") : result;
};

//Format a Number to Price
export const FormatNumberToPrice = (number) => {
  return formatterPrice.format(number).toString();
};

//Format a CASH DOWN to Price
export const formatterCash = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});
export const FormatCashDownToPrice = (number) => {
  return formatterCash.format(number).toString();
};

export const FormatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US");
};

export const dateToLabel = (month, year) => {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${months[month - 1]} ${year}`;
};

export const getAttributeWeeklyPayment = (quote, attributePrice) => {
  const { contracted_term } = quote;
  const totalYearsOfContract = contracted_term / 12;
  const totalWeeksOfContract = totalYearsOfContract * 52;
  const weeklyPayment = Number(
    (attributePrice / totalWeeksOfContract).toFixed(2)
  );

  return weeklyPayment;
};

// -------------------------- calculate payments ----------------------------
export const calculatePayments = (
  car,
  loan_length,
  down_payment,
  formula,
  annual_interest_rate,
  insurance_protection,
  trade_in,
  money_owed,
  attributes = [],
  protection = {}
) => {
  const attributesTotalAmount = attributes.reduce(
    (sum, item) => +item.cost + sum,
    0
  );
  const protectionAmount =
    calculateTotalPriceOfProtection({
      coverage: protection?.selectedProducts,
      categories: protection?.categories,
    }).total || 0;

  let car_price = car.price,
    coxPrice = car.price,
    carNoIncentives = +car.price;

  if (insurance_protection) {
    let price = car_price * (formula.insurance_protection / 100) + car_price;
    car_price = price;
    coxPrice = price;
    carNoIncentives = price;
  }

  if (formula.incentives) {
    car_price -=
      (formula.incentives.totalRebate > 0
        ? formula.incentives.totalRebate
        : 0) +
      (formula.incentives.totalDealerCash > 0
        ? formula.incentives.totalDealerCash
        : 0);
  }

  let tax = mathOmit(car_price * (formula.tax_rate / 100));
  let tax2 = mathOmit(carNoIncentives * (formula.tax_rate / 100));

  if (formula.tax_rate && formula.tax_rate != 0) {
    car_price += tax;
    carNoIncentives += tax2;
  }

  if (formula.dealer_fees) {
    car_price += formula.dealer_fees;
    carNoIncentives += formula.dealer_fees;
  }

  let quots = {
      monthly: {},
      weekly: {},
      weeklyPlus: {},
    },
    interest = annual_interest_rate / 100 / 12,
    payment = 0,
    weekly_payment = 0,
    amount = 0;

  if (isNaN(trade_in) || trade_in == "") {
    trade_in = 0;
  } else {
    trade_in = parseFloat(trade_in);
  }

  let new_trade_in = +trade_in - +money_owed,
    finish_down = down_payment;

  if (new_trade_in < 0) {
    // ----------------- add diference trade in to cr price
    car_price = car_price - new_trade_in;
    carNoIncentives = carNoIncentives - new_trade_in;
  } else {
    finish_down = finish_down + new_trade_in;
  }

  car_price += attributesTotalAmount;
  carNoIncentives += attributesTotalAmount;

  car_price += protectionAmount;
  carNoIncentives += protectionAmount;

  amount = mathOmit(car_price - finish_down);
  carNoIncentives = carNoIncentives - finish_down;
  payment = mathOmit(
    (car_price - finish_down) *
      ((interest * Math.pow(1 + interest, loan_length)) /
        (Math.pow(1 + interest, loan_length) - 1))
  );

  if (/*car_price <= down_payment || */ car_price == 0 || payment < 0) {
    payment = 0;
  }

  amount = amount < 0 ? 0 : mathOmit(amount);

  // -------------------------- payments -------------------------------
  quots = calculatePaymentsWeekly(
    quots,
    payment,
    formula,
    loan_length,
    down_payment,
    amount,
    tax,
    car_price,
    formula.dealer_fees,
    car.id,
    annual_interest_rate,
    coxPrice,
    carNoIncentives
  );

  return quots;
};

export const mathOmit = (value) => Number(value.toFixed(2));

export const getMonthlyPayment = ({
  car,
  loanLength,
  downPayment,
  formula,
  annualInterestRate,
  isInsuranceProtection,
  tradeIn,
  moneyOwed,
  attributes = [],
}) => {
  const attributesTotalAmount = attributes.reduce(
    (sum, item) => +item.cost + sum,
    0
  );

  let carPrice = +car.price;
  let coxPrice = +car.price;
  let carNoIncentives = +car.price;

  // if protection insurance enabled
  if (isInsuranceProtection) {
    const updatedPrice = mathOmit(
      carPrice * (formula.insurance_protection / 100) + carPrice
    ).toFixed(2);

    carPrice = updatedPrice;
    coxPrice = updatedPrice;
    carNoIncentives = updatedPrice;
  }

  if (formula.incentives) {
    const rebatesTotal =
      formula.incentives.totalRebate > 0 ? formula.incentives.totalRebate : 0;
    const dealerCash =
      formula.incentives.totalDealerCash > 0
        ? formula.incentives.totalDealerCash
        : 0;

    carPrice -= rebatesTotal + dealerCash;
  }

  // amount of taxes
  let tax = mathOmit(carPrice * (formula.tax_rate / 100));
  let taxNoIncentives = mathOmit(carNoIncentives * (formula.tax_rate / 100));

  if (formula.tax_rate && formula.tax_rate != 0) {
    carPrice += tax;
    carNoIncentives += taxNoIncentives;
  }

  if (formula.dealer_fees) {
    carPrice += formula.dealer_fees;
    carNoIncentives += formula.dealer_fees;
  }

  const interest = annualInterestRate / 100 / 12;
  const tradeInValue =
    isNaN(tradeIn) || tradeIn == "" ? 0 : parseFloat(tradeIn);
  let tradeInValueUpdated = Number(tradeInValue) - Number(moneyOwed);
  let totalCashDown = downPayment;

  if (tradeInValueUpdated < 0) {
    carPrice -= tradeInValueUpdated;
    carNoIncentives = carNoIncentives - tradeInValueUpdated;
  } else {
    totalCashDown += tradeInValueUpdated;
  }

  carPrice += attributesTotalAmount;
  carNoIncentives += attributesTotalAmount;

  const estimatedAmountFinanced = carPrice - totalCashDown;
  carNoIncentives -= totalCashDown;

  const payment = mathOmit(
    (carPrice - totalCashDown) *
      ((interest * Math.pow(1 + interest, loanLength)) /
        (Math.pow(1 + interest, loanLength) - 1))
  );

  return payment;
};

export const calculateDownPayment = (
  car,
  loan_length,
  formula,
  annual_interest_rate,
  insurance_protection,
  payment,
  trade_in,
  money_owed
) => {
  let car_price = +car.price;

  if (insurance_protection) {
    car_price = mathOmit(
      car_price * (formula.insurance_protection / 100) + car_price
    );
  }

  if (formula.incentives) {
    car_price -=
      (formula.incentives.totalRebateFlag
        ? formula.incentives.totalRebate
        : 0) +
      (formula.incentives.totalDealerCashFlag
        ? formula.incentives.totalDealerCash
        : 0);
  }

  if (formula.tax_rate && formula.tax_rate != 0) {
    car_price = mathOmit(car_price * (formula.tax_rate / 100) + car_price);
  }

  if (formula.dealer_fees) {
    car_price += formula.dealer_fees;
  }

  let new_trade_in = +trade_in - +money_owed;

  if (new_trade_in < 0) {
    car_price -= new_trade_in;
  }

  let interest = annual_interest_rate / 100 / 12;

  let down_payment = mathOmit(
    -(
      payment /
        ((interest * Math.pow(1 + interest, loan_length)) /
          (Math.pow(1 + interest, loan_length) - 1)) -
      car_price
    )
  );

  return down_payment;
};

const defaultQuotes = {
  monthly: {},
  weekly: {},
  weeklyPlus: {},
};

export const calculatePaymentsWeekly = (
  quots = defaultQuotes,
  payment,
  formula,
  loan_length,
  down_payment,
  amount,
  tax,
  car_price,
  dealer_fees,
  carId,
  annual_interest_rate,
  coxPrice,
  carNoIncentives
) => {
  // -------------------------- monthly payment -------------------------------
  if (quots == null) {
    quots = defaultQuotes;
  }

  quots["monthly"]["id"] = new Date().getTime();
  quots["monthly"]["carid"] = carId;
  quots["monthly"]["payment"] = payment;
  quots["monthly"]["formatPayment"] = parseFloat(payment).toFixed(2);
  quots["monthly"]["purchasing_power"] = mathOmit(payment * loan_length);
  quots["monthly"]["term_reduction"] = 0;
  quots["monthly"]["contracted_term"] = loan_length;
  quots["monthly"]["effective_term"] = loan_length;
  quots["monthly"]["payment_benefit"] = 0;
  quots["monthly"]["interest_charges"] = 0;
  quots["monthly"]["down_payment"] = down_payment;
  quots["monthly"]["amount"] = amount;
  quots["monthly"]["amountNoIncentives"] = carNoIncentives;
  quots["monthly"]["tax"] = tax;
  quots["monthly"]["final_price"] = car_price;
  quots["monthly"]["dealer_fees"] = dealer_fees ? dealer_fees : 0;
  quots["monthly"]["debit_fee_week"] = formula.debit_fee_week;
  quots["monthly"]["annual_interest_rate"] = annual_interest_rate;
  quots["monthly"]["coxPrice"] = coxPrice;
  // -------------------------- weekly payment --------------------------------
  let weekly_payment = mathOmit(payment * (13 / 52));
  let weekly_payment_first = weekly_payment;
  if (!isNaN(formula.debit_fee_week) && payment != 0) {
    weekly_payment = mathOmit(weekly_payment + formula.debit_fee_week);
  }
  let term_reduction_first = mathOmit(
    loan_length - ((payment * loan_length) / weekly_payment_first / 52) * 12
  );
  let weeks =
    payment != 0
      ? Math.ceil(((payment * loan_length) / weekly_payment / 52) * 12)
      : 0;
  quots["weekly"]["payment"] = weekly_payment;
  quots["weekly"]["debitFeeWeek"] = formula.debit_fee_week
    ? formula.debit_fee_week
    : 0;
  quots["weekly"]["paymentNoFeeWeek"] = weekly_payment_first;
  quots["weekly"]["purchasing_power"] = mathOmit(
    weekly_payment * ((loan_length / 12) * 52)
  );
  quots["weekly"]["term_reduction"] = mathOmit(loan_length - weeks);
  quots["weekly"]["contracted_term"] = loan_length;
  quots["weekly"]["effective_term"] = weeks;
  quots["weekly"]["payment_benefit"] = mathOmit(
    quots["weekly"]["purchasing_power"] - quots.monthly.purchasing_power
  );
  // quots['weekly']['interest_charges'] = quots['weekly']['term_reduction'] * weekly_payment;
  if (annual_interest_rate) {
    quots["weekly"]["interest_charges"] =
      weekly_payment_first > 0
        ? mathOmit(
            weekly_payment_first *
              (annual_interest_rate / 100) *
              ((term_reduction_first / 12) * 52)
          )
        : 0;
  }
  // -------------------------- weekly plus payment --------------------------------
  weekly_payment = mathOmit(payment * (14 / 52));
  weekly_payment_first = weekly_payment;
  if (!isNaN(formula.debit_fee_week) && payment != 0) {
    weekly_payment += formula.debit_fee_week;
  }
  // weekly_payment = weekly_payment + (payment / 52);
  term_reduction_first = mathOmit(
    loan_length - ((payment * loan_length) / weekly_payment_first / 52) * 12
  );
  weeks =
    payment != 0
      ? Math.ceil(((payment * loan_length) / weekly_payment / 52) * 12)
      : 0;
  quots["weeklyPlus"]["payment"] = weekly_payment;
  quots["weeklyPlus"]["debitFeeWeek"] = formula.debit_fee_week
    ? formula.debit_fee_week
    : 0;
  quots["weeklyPlus"]["paymentNoFeeWeek"] = weekly_payment_first;
  quots["weeklyPlus"]["purchasing_power"] = mathOmit(
    weekly_payment * ((loan_length / 12) * 52)
  );
  quots["weeklyPlus"]["term_reduction"] = loan_length - weeks;
  quots["weeklyPlus"]["contracted_term"] = loan_length;
  quots["weeklyPlus"]["effective_term"] = weeks;
  quots["weeklyPlus"]["payment_benefit"] =
    quots["weeklyPlus"]["purchasing_power"] - quots.monthly.purchasing_power;
  // quots['weeklyPlus']['interest_charges'] = quots['weeklyPlus']['term_reduction'] * weekly_payment;
  if (annual_interest_rate) {
    quots["weeklyPlus"]["interest_charges"] =
      weekly_payment_first > 0
        ? mathOmit(
            weekly_payment_first *
              (annual_interest_rate / 100) *
              ((term_reduction_first / 12) * 52)
          )
        : 0;
  }
  return quots;
};

// -------------------------------------- change monthly payment ------------------
export const onchangeMonthly = (payment) => {
  let max_monthly_payment = payment;
  let steps_monthly_payment = {
    0: "$0",
    // [max_monthly_payment / 2]: FormatNumberToPrice(max_monthly_payment / 2),
    [max_monthly_payment]: FormatNumberToPrice(max_monthly_payment),
  };
  let monthly_payment = payment;
  return { max_monthly_payment, steps_monthly_payment, monthly_payment };
};

export const validEmail = (string) => {
  let regex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(string);
};

export const validZipCode = (string) => {
  let regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return regex.test(string);
};

export const validateSSN = (string = "") => {
  if (string == null) {
    string = "";
  }
  let flag = true;
  flag = isInt(string);
  if (!flag) {
    return flag;
  }
  flag = isLength(string, { min: 9, max: 9 });
  // if (!flag) {
  //     flag = isLength(string, { min: 9, max: 9 });
  // }
  return flag;
};

export const validateRoutingNumber = (string = "") => {
  if (string == null) {
    string = "";
  }
  let flag = true;
  flag = isInt(string);
  if (!flag) {
    return flag;
  }
  flag = isLength(string, { min: 9, max: 9 });
  return flag;
};

export const validateBankNumber = (string = "") => {
  if (string == null) {
    string = "";
  }
  let flag = true;
  flag = isInt(string);
  if (!flag) {
    return flag;
  }
  flag = isLength(string, { min: 4, max: 17 });
  return flag;
};

export const CustomDatePicker = ({ value, onClick }) => (
  <Button className={"customDatePicker"} onClick={onClick}>
    <span>{value}&nbsp;</span>
  </Button>
);

// ----------------- validate phone numbers
export const validPhoneNumber = (string) => {
  if (!string || isNaN(string) || string.length !== 10) {
    return false;
  }
  return true;
};

export const validCodeNumber = (string) => {
  var strFirstThree = string.substring(0, 3);
  if (
    strFirstThree === "800" ||
    strFirstThree === "866" ||
    strFirstThree === "888" ||
    strFirstThree === "877"
  ) {
    return false;
  }
  if (!isValidPhoneNumber("+1" + string)) {
    return false;
  }
  return true;
};

// -------------------- validate address
export const validAddress = (value) => {
  if (value === null || value === "" || value.split(" ").join("") === "") {
    return "Address is required";
  }
  if (!checkFirstLetterNumber(value)) {
    return "Address must be number + street name";
  }
  return null;
};

const checkFirstLetterNumber = (string) => {
  return string.match(new RegExp(/^\d/)) !== null;
};

export const getMonths = (age) => {
  var startDate = moment(age, "YYYY-MM-DD");
  var endDate = moment(moment(), "YYYY-MM-DD");
  var monthDiff = endDate.diff(startDate, "months");
  return monthDiff;
};

export const validateTotalMonthsEmployed = (
  value,
  occupationStatus,
  birthDate
) => {
  let text = null;
  if (occupationStatus !== "Unemployed") {
    text = null;
    if (!value || isNaN(value) || +value < 0 || +value > 1199) {
      text = "The field must be a positive number maximum of 1199";
    } else {
      text =
        getMonths(birthDate) < +value
          ? "The field must be under the age"
          : null;
    }
  }
  return text;
};

export const validateMonthsAtCurrentAddress = (value, birthDate) => {
  let text = null;
  if (!value || isNaN(value) || +value < 1 || +value > 1199) {
    text = "The field must be a integer minimum 1 and a maximum of 1199";
  } else {
    text =
      getMonths(birthDate) < +value ? "The field must be under the age" : null;
  }
  return text;
};

// ----------------------------------- valid empty string for spouse
export const validateSpouse = (spouse) => {
  if (
    !spouse ||
    !validateEmpty(spouse.firstName, "i") ||
    !validateEmpty(spouse.lastName, "i") ||
    !validateEmpty(spouse.address, "i") ||
    !validateEmpty(spouse.city, "i") ||
    !validateEmpty(spouse.state, "s") ||
    !validateEmpty(spouse.zip_code, "z", spouse.state)
  ) {
    return false;
  }
  return true;
};

export const validateEmpty = (string, type, state) => {
  if (type === "i") {
    if (!string || string.split(" ").join("") === "") {
      return false;
    }
  } else if (type === "z") {
    if (
      !string ||
      string.split(" ").join("") === "" ||
      !validZipCode(string) ||
      zipCodesStates(state, string)
    ) {
      return false;
    }
  } else {
    if (string === "" || !string) {
      return false;
    }
  }
  return true;
};

// ------------------------------------ contact option ------------------------
export const phoneOptions = [
  {
    key: 1,
    text: "Mobile",
    value: 1,
  },
  {
    key: 2,
    text: "Home",
    value: 2,
  },
  {
    key: 3,
    text: "Work",
    value: 3,
  },
];

export const callOptions = [
  {
    key: 1,
    text: "Morning",
    value: 1,
  },
  {
    key: 2,
    text: "Afternoon",
    value: 2,
  },
  {
    key: 3,
    text: "Evening",
    value: 3,
  },
];

export const inspectionDistanceOptions = [
  {
    key: 1,
    text: "10 miles",
    value: 1,
  },
  {
    key: 2,
    text: "30 miles",
    value: 2,
  },
  {
    key: 3,
    text: "50 miles",
    value: 3,
  },
];

export const contactOptions = [
  {
    key: 1,
    text: "SMS",
    value: 1,
  },
  {
    key: 2,
    text: "Email",
    value: 2,
  },
  {
    key: 3,
    text: "No Communication",
    value: 3,
  },
];

// ------------------------------------ Request data processed for bar chart ------------------------

export const FormatChartData = (data) => {
  let procesed = data.reduce((result, element) => {
    const ix = result.findIndex(
      (e) => e["month"] === element["month"] && e["year"] === element["year"]
    );
    if (ix === -1) {
      result.push({
        Month: element["month"],
        Year: element["year"],
        authorized: element["status"] === 2 ? element["count_status"] : 0,
        pending:
          element["status"] === 0 || element["status"] === 1
            ? element["count_status"]
            : 0,
        cancelled:
          element["status"] === 3 || element["status"] === 4
            ? element["count_status"]
            : 0,
      });
      return result;
    } else {
      result[ix]["authorized"] +=
        element["status"] === 2 ? element["count_status"] : 0;
      result[ix]["pending"] +=
        element["status"] === 0 || element["status"] === 1
          ? element["count_status"]
          : 0;
      result[ix]["cancelled"] +=
        element["status"] === 3 || element["status"] === 4
          ? element["count_status"]
          : 0;
      return result;
    }
  }, []);

  return procesed.reduce(
    (result, itm) => {
      result["labels"].push(dateToLabel(itm["Month"], itm["Year"]));
      result["datasets"][0]["data"].push(itm["authorized"]);
      result["datasets"][1]["data"].push(itm["pending"] + itm["cancelled"]);
      return result;
    },
    {
      labels: [],
      datasets: [
        {
          label: "Closed",
          backgroundColor: "#00d367",
          data: [],
        },
        {
          label: "Pending",
          backgroundColor: "#cecece",
          data: [],
        },
      ],
    }
  );
};

// --------------------------------------- return info inside popup payments ------------------------------
export const returnInfoPayments = (payment) => {
  if (payment == "monthly") {
    return (
      <span>
        <span style={{ textAlign: "justify" }}>
          Conventional and lender-preferred monthly loan/lease repayment plans
          can keep you from driving the vehicle you want with the options and
          protection you need. Monthly payments work for the bank but can
          eliminate important options for you. Consider one of our weekly bank
          account drafting repayment plans below to:
        </span>
        <ul>
          <li>Afford more vehicle and/or options</li>
          <li>Pay your loan off faster</li>
          <li>Reduce or reverse negative equity</li>
          <li>Improve monthly cash flow</li>
        </ul>
      </span>
    );
  } else if (payment == "weekly") {
    return (
      <span>
        <span style={{ textAlign: "justify" }}>
          Weekly bank account drafting puts your interests ahead of the car
          finance companies. Consider one of our weekly bank account drafting
          repayment plans below to:
        </span>
        <ul>
          <li>Afford more vehicle and/or options</li>
          <li>Pay your loan off faster</li>
          <li>Reduce or reverse negative equity</li>
          <li>Improve monthly cashflow</li>
        </ul>
      </span>
    );
  } else {
    return (
      <span>
        <span style={{ textAlign: "justify" }}>
          Are you really upside down on your trade-in or know you'll want to
          trade in your vehicle well before the loan payoff? By adding just 8%
          of your regular weekly payment on top of the weekly amount we draft
          from you bank account weekly, Power Payments Plus will:
        </span>
        <ul>
          <li>
            Help you make 14 full monthly payments a year against your vehicle
            loan
          </li>
          <li>Help you afford the vehicle and options you want or need</li>
          <li>Pay your loan up to 14 months faster</li>
          <li>
            Reduce, reverse or even eliminate negative equity by the end of this
            next loan
          </li>
          <li>
            Still Improves monthly cash flow while getting you into our best
            repayment program (for less per day than a cup of coffee)
          </li>
        </ul>
      </span>
    );
  }
};

export const DealerHelpText = () => {
  return (
    `Attention: You are about to be connected
  to a live video chat with a consumer
  finance representative. Please note that
  your mobile phone camera will be
  disabled until you activate it. This is to
  protect your privacy and ensure the
  security of your personal information.
  Please click 'Accept' to proceed with the
  Dealer Help Live` +
    "™ " +
    "video chat."
  );
};

export const ScheduleText = () =>
  `
Attention: Please select from our vehicle
finance department’s available Dealer
Help Live` +
  `™ ` +
  `calendar dates and times. We
will immediately email you a confirmed
appointment time so you may add it to
your own personal calendar. At the
appointed time, you will be connected to a
Dealer Help Live` +
  `™ ` +
  `video chat session
with one of our available finance and
insurance experts. Please note that your
mobile phone camera will be disabled
until you activate it. This is to protect your
privacy and ensure the security of your
personal information. Please click 'Accept'
to proceed to the appointment calendar
`;

export const getAPPROVED = () => {
  return `Disclaimer: This pre-approval offer is based on a hard
  credit file inquiry, which may impact your credit score.
  The information provided is for informational purposes
  only and does not constitute a loan approval or
  commitment to lend. Actual loan terms and interest
  rates may vary based on your credit history, income, and
  other factors. Final loan approval and terms are subject
  to verification of your creditworthiness, employment,
  income, and other factors. By accepting this
  pre-approval offer, you authorize us to obtain your credit
  report and use the information provided to determine
  your eligibility for credit. Please note that multiple hard
  credit inquiries within a short period may impact your
  credit score`;
};

// -------------------------------------------- return quote summary in car info --------------------
export const returnQuoteSummary = (quote, likeText = false) => {
  const valueStyle = {
    fontWeight: "600",
  };
  // let moreWeekly = quote.weeklyPlus.payment - (quote.monthly.payment / 4)
  let moreWeekly =
    (quote.weekly.purchasing_power - quote.monthly.purchasing_power) /
    quote.monthly.contracted_term /
    4;

  return likeText ? (
    "Weekly: The My Payment Power™ loan repayment plan can add a 13th payment to your annual number of payments by splitting your monthly payment into 52 (easier to budget) weekly payments annually. My Payment Power™ charges a " +
      FormatNumberToPrice(quote.monthly.debit_fee_week) +
      " per transaction convenience fee each time a weekly loan payment amount is pulled and added to your escrow account. " +
      "My Payment Power makes a guaranteed on-time payment from your escrow account to your lender each month. In this example you'll eliminate lender late payment fees & be paying an extra " +
      FormatNumberToPrice(moreWeekly) +
      " every week which will pay off your loan in " +
      quote.weekly.effective_term +
      " months instead of the typical " +
      quote.weekly.contracted_term +
      " months. " +
      "This will also have the effect of eliminating approximately " +
      FormatNumberToPrice(quote.weekly.interest_charges) +
      " in loan interest over the life of this estimated loan amount!"
  ) : (
    <>
      <div className={"sectionTitle"}>Weekly</div>
      The My Payment Power™ loan repayment plan can add a{" "}
      <span style={valueStyle}>13th</span> payment to your annual number of
      payments by splitting your monthly payment into{" "}
      <span style={valueStyle}>52 (easier to budget)</span> weekly payments
      annually. My Payment Power™ charges a small{" "}
      <span style={valueStyle}>
        {FormatNumberToPrice(quote.monthly.debit_fee_week)}
      </span>{" "}
      per transaction convenience fee each time a weekly loan payment amount is
      pulled and added to your escrow account. My Payment Power makes a
      guaranteed on-time payment from your escrow account to your lender each
      month. In this example you'll eliminate lender late payment fees & be
      paying an extra{" "}
      <span style={valueStyle}>{FormatNumberToPrice(moreWeekly)}</span> every
      week which will pay off your loan in{" "}
      <span style={valueStyle}>{quote.weekly.effective_term} months</span>{" "}
      instead of the typical{" "}
      <span style={valueStyle}>{quote.weekly.contracted_term} months</span>.
      This will also have the effect of eliminating approximately{" "}
      <span style={valueStyle}>
        {FormatNumberToPrice(quote.weekly.interest_charges)}
      </span>{" "}
      in loan interest over the life of this estimated loan amount!
    </>
  );
};

export const returnMonthlySummary = (quote, interest, likeText = false) => {
  let auxmonthtly = quote.monthly.payment / (quote.monthly.amount / 1000);
  const valueStyle = {
    fontWeight: "600",
  };

  return likeText ? (
    "Monthly: " +
      FormatNumberToPrice(quote.monthly.down_payment) +
      " down. " +
      interest +
      "% for " +
      quote.monthly.contracted_term +
      " months " +
      FormatNumberToPrice(quote.monthly.amount) +
      " total amount financed. Includes " +
      FormatNumberToPrice(quote.monthly.dealer_fees) +
      " dealer doc fee " +
      quote.monthly.contracted_term +
      " monthly payments of " +
      FormatNumberToPrice(auxmonthtly) +
      " for each" +
      " $1,000 borrowed. Plus goverment fees and taxes, any finance charges , any dealer document processing charges, any electronic filing charges," +
      " and any emission testing charges. APR available from select lenders as of today. Not all buyers will qualify. Please contact us for complete details."
  ) : (
    <>
      <div className={"sectionTitle"}>Monthly</div>
      Monthly
      <span style={valueStyle}>
        {" "}
        {FormatNumberToPrice(quote.monthly.down_payment)}
      </span>{" "}
      down.
      <span style={valueStyle}> {interest}</span>% for{" "}
      <span style={valueStyle}>{quote.monthly.contracted_term} months</span>{" "}
      <span style={valueStyle}>
        {FormatNumberToPrice(quote.monthly.amount)}
      </span>{" "}
      total amount financed. Includes{" "}
      <span style={valueStyle}>
        {FormatNumberToPrice(quote.monthly.dealer_fees)}
      </span>{" "}
      dealer doc fee{" "}
      <span style={valueStyle}>{quote.monthly.contracted_term} monthly </span>
      payments of{" "}
      <span style={valueStyle}>{FormatNumberToPrice(auxmonthtly)}</span> for
      each <span style={valueStyle}>$1,000</span> borrowed. Plus goverment fees
      and taxes, any finance charges, any dealer document processing charges,
      any electronic filing charges, and any emission testing charges. APR
      available from select lenders as of today. Not all buyers will qualify.
      Please contact us for complete details.
    </>
  );
};

// --------------------------------------------- text in Payment Benefit Accelerated by -----------
export const infoPaymentBenefit = () => {
  return (
    <span>
      Your accelerated payment benefit can help improve your equity position
      through term reduction and interest charge reduction
      <p>-OR-</p>
      You can use your accelerated payment benefit to afford more vehicle
      (protection, accessories & equipment options).
    </span>
  );
};

export const infoWeeklyPayment = () => {
  return "Sends Your Finance Company/Bank the equivalent of 13 Monthly Payments/Yr";
};
export const infoWeeklyPlusPayment = () => {
  return "Sends Your Finance Company/Bank the equivalent of 14 Monthly Payments/Yr";
};

export const purchasingPowerInfo = (quote, payment) => {
  if (payment === "weekly") {
    return (
      "Paying off this loan in " +
      quote[payment].effective_term +
      " months instead of " +
      quote[payment].contracted_term +
      " months would result in " +
      (quote[payment].contracted_term - quote[payment].effective_term) +
      " months of " +
      FormatNumberToPrice(quote.monthly.payment) +
      " payments you'll " +
      "never need to make. You could elect to simply enjoy that early pay off and keep driving with no more payments. " +
      "Another way to work our weekly payments system is to resign to contract a <span class='dobleLine'>longer term</span> loan (on a vehicle with " +
      "more safety and performance options). For example, with MyPaymentPower's weekly payments, an <span class='dobleLine'>84 month</span> new car term loan " +
      "can be set up (with weekly payments) for pay off in just over 72 months. Providing you qualify for the higher loan amount, " +
      "you'll be able to drive a nicer vehicle and or add more protection options like ding and dent, tire and wheel coverage " +
      "or even paint protection without busting the monthly budget."
    );
  } else {
    return (
      "Paying off this loan in " +
      quote[payment].effective_term +
      " months instead of " +
      quote[payment].contracted_term +
      " months would result in " +
      (quote[payment].contracted_term - quote[payment].effective_term) +
      " months of " +
      FormatNumberToPrice(quote.monthly.payment) +
      " payments you'll never need to make. " +
      "You could elect to simply enjoy that early pay off and keep driving with no more payments. Another way to work our weekly payments " +
      "system is to resign to contract a <span class='dobleLine'>longer term</span> loan (on a vehicle with more safety and performance options) knowing you'll barely " +
      "notice the few additional dollars we pull from your checking account weekly. Power Payments Plus is also a great option if you are " +
      "rolling negative equity from a previous car loan into a loan for this vehicle. Imagine getting yourself right side up by the end of " +
      "your " +
      quote[payment].effective_term +
      "th monthly loan payment on this vehicle."
    );
  }
};

// ------------------------ build score range values
export const interestRageBuild = (formula) => {
  return {
    [0]: formula.annual_interest_rate_poor,
    [1]: formula.annual_interest_rate_fair,
    [2]: formula.annual_interest_rate_good,
    [3]: formula.annual_interest_rate_very_good,
    [4]: formula.annual_interest_rate_exceptional,
  };
};

export const getCarPrice = (car, formula, insurance) => {
  let car_price = car.price;
  if (insurance) {
    car_price = car_price * (formula.insurance_protection / 100) + car_price;
  }
  if (formula.tax_rate && formula.tax_rate != 0) {
    car_price = car_price * (formula.tax_rate / 100) + car_price;
  }
  if (formula.dealer_fees) {
    car_price += formula.dealer_fees;
  }
  return car_price;
};

export const calculateMinDownPayment = (carPrice, retailer_credit_range) => {
  let min_down_payment = 0;
  if (
    retailer_credit_range.min_down_payment != null &&
    retailer_credit_range.min_down_payment >= 0
  ) {
    min_down_payment =
      (retailer_credit_range.min_down_payment / 100) * carPrice;
  }
  return min_down_payment;
};

export const calculateMaxDownPayment = (carPrice, retailer_credit_range) => {
  let max_down_payment = carPrice;
  if (
    retailer_credit_range.max_down_payment != null &&
    retailer_credit_range.max_down_payment > 0
  ) {
    max_down_payment =
      (retailer_credit_range.max_down_payment / 100) * carPrice;
  }
  return max_down_payment;
};

// get pay day friday or monday
export const getAllDaysInWeek = (f, starDate, days, day) => {
  if (days > 0) {
    if (starDate.getDay() === day) {
      f.push(new Date(starDate));
    }
    starDate.setDate(starDate.getDate() + 1);
    f = getAllDaysInWeek(f, starDate, days - 1, day);
  }
  return f;
};

// -------------- get next first payment date
export const dayNames = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
};

export const paymentDate = (data) => {
  const startDate = moment(data.startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
  const firstDate = moment(data.firstDate, "MM-DD-YYYY").format("YYYY-MM-DD");
  const days = moment(moment(firstDate).add(1, "days")).diff(
    moment(startDate),
    "days"
  );
  let auxPayDay = getAllDaysInWeek(
    [],
    new Date(startDate + " 00:00:00"),
    days,
    dayNames[data.transferDay]
  );
  auxPayDay = [
    { date: auxPayDay[auxPayDay.length - 5], type: "newBalance", number: 1 },
  ];
  return auxPayDay;
};

export const isOptionsAdded = ({
  attributes,
  tradeIn: { moneyOwed, trade },
}) => {
  const isAttributesAdded =
    attributes.reduce((sum, item) => +item.cost + sum, 0) !== 0;
  const isTradeInAdded = moneyOwed !== 0 || trade !== 0;

  return isAttributesAdded || isTradeInAdded;
};

export const getFormattedCalculationsFromAPP = (
  { monthlyPayment, weeklyPayment },
  quots
) => {
  const weekly = weeklyPayment;
  const monthly = monthlyPayment;

  if (_.isNaN(weekly) || _.isNaN(monthly)) {
    throw new Error("Weekly or monthly is NaN");
  }

  const weeklyWithFee = weekly + quots.monthly.debit_fee_week;
  const monthlyWithFee = monthly + quots.monthly.debit_fee_week;

  const correctQuotData = {
    ...quots,
    monthly: {
      ...quots.monthly,
      payment: monthlyWithFee,
      formatPayment: monthlyWithFee.toFixed(2),
    },
    weekly: {
      ...quots.weekly,
      payment: weeklyWithFee,
    },
  };

  return correctQuotData;
};

export const getRebateValue = (amount, type = "usd") => {
  const isUSD = type === "usd";

  return isUSD ? `-$${amount}` : `-${amount}%`;
};

export const getRebatesTotal = ({
  selectedRebates,
  car,
  excludedRebates = [],
}) => {
  const selected = _.omit(
    _.pickBy(selectedRebates, (item) => !_.isEmpty(_.identity(item))),
    excludedRebates
  );

  if (_.isEmpty(selected)) return 0;

  const entries = Object.entries(selected);

  const total = entries.reduce((total, entry) => {
    const key = entry[0];
    const _rebates = entry[1];

    const rebatesTotal = (
      Array.isArray(_rebates) ? _rebates : Array(_rebates)
    ).reduce((total, rebate) => {
      if (!rebate) return total;

      switch (key) {
        case "programRebates":
          return total + rebate.cash;

        case "rebatesForNewCar":
        case "dealerRebates":
          if (rebate.type === "usd") {
            return total + rebate.value;
          } else {
            const price = mathOmit(car.price * mathOmit(rebate.value / 100));

            return total + price;
          }

        case "quotesWithRebates":
          return total + Number(rebate.rebate_detail.rebate_entry.rebate_value);

        default:
          return total;
      }
    }, 0);

    return total + rebatesTotal;
  }, 0);

  return total;
};

export const getRebatesNames = (selectedRebates, excludedRebates = []) => {
  const selected = _.omit(
    _.pickBy(selectedRebates, (item) => !_.isEmpty(_.identity(item))),
    excludedRebates
  );

  if (_.isEmpty(selected)) return [];

  const entries = Object.entries(selected);

  const titles = entries.reduce((names, entry) => {
    const key = entry[0];
    const _rebates = entry[1];

    const _titles = _rebates.reduce((arr, rebate) => {
      switch (key) {
        case "programRebates":
          return [
            ...arr,
            {
              title: rebate.special_program_name,
              amount: `-${FormatCashDownToPrice(rebate.cash)}`,
            },
          ];

        case "rebatesForNewCar":
        case "dealerRebates":
          return [
            ...arr,
            {
              title: rebate.title,
              amount: getRebateValue(rebate.value, rebate.type),
            },
          ];

        case "quotesWithRebates":
          return [
            ...arr,
            {
              title: rebate.rebate_detail.rebate_entry.rebate_name,
              amount: `-${FormatCashDownToPrice(
                rebate.rebate_detail.rebate_entry.rebate_value
              )}`,
            },
          ];

        default:
          return arr;
      }
    }, []);

    return [...names, ..._titles];
  }, []);

  return titles;
};

export const categoryNames = {
  APP: "Appearance Protection",
  CLAH: "Credit Life Accident And Health",
  GAP: "GAP",
  MAINT: "Maintenance",
  THEFT: "Theft Protection",
  VSC: "Vehicle Service Contract",
  EWT: "Excess Wear And Tear",
  LEASE: "Lease And Protection",
  PDR: "Paint Dent Repair",
  CLD: "Credit Insurance",
  SPOT: "Spot Insurance",
  DBT: "Debt Cancellation",
  HZRD: "Road Hazard",
  TWL: "Tire And Wheel",
  WSHLD: "Windshield",
  APAY: "Accelerated Payment",
  VPP: "VSC Payment Plan",
  CREDIT: "Credit Management",
  LOY: "Loyalty",
  RA: "Roadside Assistance",
  KEY: "Key Replacement",
  ETCH: "ETCH",
  ID: "ID Theft Protection",
  RECOV: "Theft Recovery System",
  VSCMAINT: "Vehicle Service And Maintenance",
  LWT: "Limited Warranty",
  PPM: "Pre-Paid Maintenance",
  FPP: "Interior Exterior Protection",
  OTHER: "Other",
  BUNDLE: "Bundle",
};

export const calculateCoverageRetailerPriceOfTerm = ({
  term,
  formula,
  isFormatted = false,
}) => {
  if (!term) return FormatNumberToPrice(0);

  const price = Number(term.retail_price);
  const retailerPrice =
    formula.type === "usd"
      ? formula.amount
      : mathOmit((formula.amount / 100) * price);
  let additionalPrice = mathOmit(retailerPrice + price);

  if (!!term.dynamic_surcharge) {
    additionalPrice += term.dynamic_surcharge
      .filter((i) => i.isSelected)
      .reduce((sum, i) => sum + Number(i.price), 0);
  }

  return isFormatted ? FormatNumberToPrice(additionalPrice) : additionalPrice;
};

export const calculateTotalPriceOfProtection = ({ coverage, categories }) => {
  const preparedCoverage = [];

  for (const category in coverage) {
    const _coverage = categories[category]?.coverage;

    if (!_coverage) return;

    const formula = {
      amount: _coverage.value,
      type: _coverage.type,
    };

    preparedCoverage.push({
      term: coverage[category],
      formula,
    });
  }

  const totalAmount = preparedCoverage.reduce((sum, { term, formula }) => {
    const total = calculateCoverageRetailerPriceOfTerm({
      term,
      formula,
    });

    return mathOmit(sum + total);
  }, 0);

  return {
    weekly: mathOmit(totalAmount / 52),
    monthly: mathOmit(totalAmount / 12),
    total: totalAmount,
  };
};

export const allowedCategoryNames = {
  VSC: "Vehicle Service Contract",
  GAP: "GAP",
  BUNDLE: "Bundle",
  TWL: "Tire And Wheel",
  KEY: "Key Replacement",
  PDR: "Paint Dent Repair",
  FPP: "Interior Exterior Protection",
  APP: "Appearance Protection",
};

export const getFormattedSelectedRebates = (rebates) => {
  if (!rebates) return [];

  const rebatesFromDealer =
    rebates.dealerRebates?.map(({ title, type, value, end_date }) => ({
      title,
      type,
      value: Number(value),
      endDate: moment(end_date).toISOString(),
    })) || [];
  const rebateForNewCar =
    rebates.rebatesForNewCar?.map(({ title, type, value, end_date }) => ({
      title,
      type,
      value: Number(value),
      endDate: moment(end_date).toISOString(),
    })) || [];
  const quotesWithRebates =
    rebates.quotesWithRebates?.map((r) => ({
      title: r.rebate_detail.rebate_entry.rebate_name,
      type: "usd",
      value: Number(r.rebate_detail.rebate_entry.rebate_value),
      endDate: moment(r.rebates_expiration_date).toISOString(),
    })) || [];
  const programs =
    rebates.programRebates?.map((p) => ({
      type: "usd",
      title: p.special_program_name,
      value: Number(p.cash),
      endDate: moment(p.stop_date).toISOString(),
    })) || [];

  return [
    ...rebatesFromDealer,
    ...rebateForNewCar,
    ...quotesWithRebates,
    ...programs,
  ];
};

export const convertToRGBA = ({ r, g, b }, opacity = 1) =>
  `rgba(${r},${g},${b},${opacity})`;

export const convertToRGBObject = (string) => {
  const values = string.match(/[\d]{1,3}/g);

  return {
    r: +values.at(0),
    g: +values.at(1),
    b: +values.at(2),
  };
};

export const convertFromObjectToHex = ({ r, g, b }) => {
  const hex = [r, g, b]
    .map((color) => {
      const converted = (color | (1 << 8)).toString(16).slice(1);

      return converted.length !== 1 ? converted : "0" + converted;
    })
    .join("");

  return "#" + hex;
};

export const convertHexToObject = (string) => {
  const values = string
    .match(/[a-z0-9]{2}/g)
    .map((channel) => parseInt(channel, 16));

  return {
    r: +values.at(0),
    g: +values.at(1),
    b: +values.at(2),
  };
};

export const changeColorOpacity = (rgba, opacity = 1) =>
  convertToRGBA(convertToRGBObject(rgba), opacity);
