import React, { Component } from "react";
import _ from "lodash";
import { Form, Modal } from "semantic-ui-react";
import { callOptions, contactOptions } from "../../helpers/general";

import Input from "../../global/Input";
import Dropdown from "../../global/DropDown";
import Section from "../../global/Section";
import Button from "../../global/Button";

import { ReactComponent as UserCardIcon } from "../../../images/user-card-icon.svg";
import { ReactComponent as InfoIcon } from "../../../images/info-icon.svg";

// IMPORT CSS STYLES
import "./buyerInformation.sass";

class BuyerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: props.customer,
    };
  }

  render() {
    return (
      <Modal
        size="small"
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={true}
        closeIcon
        onClose={this.props.close}
      >
        <Modal.Header>Buyer Contact Information</Modal.Header>

        <Modal.Content scrolling>
          <Form className={"contactForm customForm"}>
            <Section className={"buyerInfoSectionWrapper"}>
              <div className={"headerWrapper"}>
                <UserCardIcon />

                <h3>Contact</h3>
              </div>

              <Form.Group>
                <Form.Field width={8}>
                  <label>First Name</label>

                  <Input value={this.state.customer.first_name} fluid disabled />
                </Form.Field>

                <Form.Field width={8}>
                  <label>Last Name</label>

                  <Input
                    value={this.state.customer.last_name}
                    fluid
                    disabled
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={8}>
                  <label>Contact Number</label>

                  <Input
                    value={this.state.customer.contact_number}
                    fluid
                    disabled
                  />
                </Form.Field>

                <Form.Field width={8}>
                  <label style={{ display: "block", textAlign: "left" }}>
                    Phone Number
                  </label>

                  <Input
                    style={{ width: "85%" }}
                    label="+1"
                    value={this.state.customer.user.phone_number}
                    disabled
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={6}>
                  <label>E-mail Address</label>

                  <Input
                    value={this.state.customer.user.email}
                    fluid
                    disabled
                  />
                </Form.Field>

                <Form.Field width={5}>
                  <label>Zip Code</label>

                  <Input value={this.state.customer.zip_code} fluid disabled />
                </Form.Field>

                <Form.Field width={5}>
                  <label>Best time to connect</label>

                  <Dropdown
                    value={this.state.customer.time_call}
                    options={callOptions}
                    fluid
                    disabled
                  />
                </Form.Field>
              </Form.Group>
            </Section>

            <Section className={"buyerInfoSectionWrapper"}>
              <div className={"headerWrapper"}>
                <InfoIcon />

                <h3>Additional Info</h3>
              </div>

              <Form.Group>
                <Form.Field width={8}>
                  <label>Extension</label>

                  <Input value={this.state.customer.extension} fluid disabled />
                </Form.Field>
                <Form.Field width={8}>
                  <label>Preferred contact method</label>

                  <Dropdown
                    value={this.state.customer.contact_method}
                    options={contactOptions}
                    fluid
                    disabled
                  />
                </Form.Field>
              </Form.Group>
            </Section>
          </Form>
        </Modal.Content>

        <Modal.Actions className={"actionButtons"}>
          <Button
            className={"actionButton"}
            buttonStyle={"danger"}
            onClick={() => this.props.close()}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default BuyerInformation;
