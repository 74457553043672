import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Dimmer, Form, Grid, Header, Icon, Loader } from "semantic-ui-react";
import ModalComponent from "../alertModal/modal";
import { ConfirmModal } from "../confirmModal/ConfirmModal";
import { deleteVideo, postVideo } from "../requests";

import Input from "../../global/Input";
import TextArea from "../../global/TextArea";
import Button from "../../global/Button";

const defaultForm = {
  name: null,
  description: null,
  video: null,
};
const defaultErrors = defaultForm;

export const Section = (props) => {
  const { section, retailer } = props;
  const [modal, setModal] = useState(props.form);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(props.form);
  const [video, setVideo] = useState(null);
  const [errors, setErrors] = useState(defaultErrors);
  const [disabled, setDisabled] = useState(true);
  const [confirmModal, setConfirmModal] = useState({});
  const ref = useRef();
  const videoRef = useRef();

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    if (label === "video") {
      if (value.files && value.files[0]) {
        auxForm[label] = value.files[0];
        let render = new FileReader();
        render.onload = (e) => {
          setVideo(e.target.result);
        };
        render.readAsDataURL(value.files[0]);
        value = value.files[0];
      } else {
        value = null;
        setVideo(null);
        auxForm[label] = null;
      }
    } else {
      auxForm[label] = value;
    }
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors);
    setErrors({ ...auxErrors });
    setForm({ ...auxForm });
    validation(auxForm, auxErrors);
  };

  const validation = (auxForm, auxErrors) => {
    for (const key in defaultForm) {
      const element = auxForm[key];
      auxErrors = validateForm(element, key, auxErrors);
    }
    setDisabled(false);
    for (const key in auxErrors) {
      const element = auxErrors[key];
      if (element != null) {
        setDisabled(true);
      }
    }
  };

  const validateForm = (value, label, errors) => {
    switch (label) {
      case "name":
      case "description":
        errors[label] =
          !value || value.split(" ").join("") === ""
            ? "The field must be required"
            : null;
        break;
      case "video":
        errors.video = "The field must be required";
        if (value != null) {
          var fuData = value;
          var FileUploadPath = fuData.name;
          var Extension = FileUploadPath.substring(
            FileUploadPath.lastIndexOf(".") + 1
          ).toLowerCase();
          //The file uploaded is an video
          if (
            Extension === "webm" ||
            Extension === "mp4" ||
            Extension === "mkv"
          ) {
            let fileSize = fuData.size;
            let siezekiloByte = parseInt(fileSize / 1024);
            if (siezekiloByte > 15000) {
              errors[label] = "The size exceeds the allowed limit (15M)";
            } else {
              errors.video = null;
              let video = URL.createObjectURL(fuData);
              setVideo(video);
            }
          } else {
            errors[label] = "Logo must be a MP4, WEBM or MKV";
          }
        }
        break;
      default:
        break;
    }
    return errors;
  };

  // ----------------------------------- send data to backend
  const edit = () => {
    setIsLoading(true);
    let params = { ...form };
    delete params.url;
    delete params.deleted_at;
    delete params.user;
    delete params.section;
    delete params.updated_at;
    params["section_id"] = section.id;
    params["retailer_id"] = retailer;
    let formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }
    postVideo(formData)
      .then((response) => {
        ref.current.value = "";
        let auxForm = { ...form };
        auxForm.video = null;
        setForm({ ...auxForm });
        setDisabled(true);
        setIsLoading(false);
        setModal({
          status: 201,
          message: "The video was updated.",
          response: response.data,
        });
      })
      .catch((error) => {
        errorResponse(error);
      });
  };

  // ----------------------------------- remove video
  const remove = (flag) => {
    setConfirmModal(false);
    if (flag) {
      setIsLoading(true);
      deleteVideo(form.id)
        .then((response) => {
          ref.current.value = "";
          setDisabled(true);
          setIsLoading(false);
          setModal({
            status: 204,
            message: "The video was updated.",
            response: response.data,
          });
        })
        .catch((error) => {
          errorResponse(error);
        });
    }
  };

  // ---------------------------- manage error response
  const errorResponse = (error) => {
    setIsLoading(false);
    if (error.response) {
      console.log(error, error.response.status);
      if (error.response.status === 406) {
        setModal({ status: 406, message: error.response.data.message });
      } else if (error.response.status === 404) {
        setModal({ status: 406, message: "Sorry. Element was not found" });
      } else if (error.response.status === 422) {
        setModal({
          status: 422,
          message: "Sorry. Fields are missing to save the information",
        });
      } else {
        setModal({
          status: 500,
          message:
            "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support.",
        });
      }
    }
  };

  const hideAlertModal = () => {
    if (modal.status === 201 || modal.status === 204) {
      if (form.url) {
        videoRef.current.load();
      }
      props.updateVideo(modal.response, section.section);
    }
    setModal({});
  };

  useEffect(() => {
    setForm(props.form);
  }, [props]);

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={16} computer={6}>
        <div className="section">
          <img
            src={require("../../../images/" + section.section + "-section.png")}
            alt=""
          />
        </div>
      </Grid.Column>

      <Grid.Column mobile={16} tablet={16} computer={10}>
        <div className="information">
          <Header as={"h2"} textAlign="center">
            {section.name + " "}
            Section
          </Header>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="video">
                {form.url ? (
                  <div className="item">
                    <video src={form.url} controls ref={videoRef}></video>
                  </div>
                ) : (
                  <div className="clean-item">
                    <Icon name="play circle" />
                  </div>
                )}
                <Form style={{ paddingTop: "1em" }}>
                  <Form.Field>
                    <label>
                      {form.deleted_at != null ? "Delete" : "Upload"} Date:{" "}
                    </label>
                    <span>
                      {form.updated_at && moment(form.updated_at).format("LLL")}
                    </span>
                  </Form.Field>
                  <Form.Field>
                    <label>
                      {form.deleted_at != null ? "Deleted" : "Uploaded"} By:{" "}
                    </label>
                    {form.user && form.user.id && (
                      <span>{form.user.email}</span>
                    )}
                  </Form.Field>
                </Form>
              </div>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Form>
                <Form.Field width={16}>
                  <label>
                    Video Name:<span className="required-inut"> *</span>
                  </label>

                  <Input
                    onChange={(e) => onEditInput(e.target.value, "name")}
                    placeholder={"Video Name"}
                    maxLength={50}
                    value={form.name}
                    error={form.name || errors.name ? errors.name : null}
                  />
                </Form.Field>

                <Form.Field width={16}>
                  <label>
                    Video Description:
                    <span className="required-inut"> *</span>
                  </label>

                  <TextArea
                    onChange={(e) => onEditInput(e.target.value, "description")}
                    placeholder={"Video Description"}
                    value={form.description}
                    error={
                      form.description || errors.description
                        ? errors.description
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field
                  width={16}
                  error={form.video || errors.video ? errors.video : null}
                >
                  <label>
                    New Video:<span className="required-inut"> *</span>
                  </label>
                  <Input
                    onChange={(e) => onEditInput(e.target, "video")}
                    placeholder={"New Video"}
                    type="file"
                    accept="image/mp4,webm,mkv"
                    ref={ref}
                    error={
                      ((form.video && errors.video) || errors.video) &&
                      errors.video
                    }
                  />
                </Form.Field>

                <Grid textAlign="center">
                  <Button
                    className="btn-update"
                    onClick={edit}
                    disabled={disabled}
                  >
                    Update
                  </Button>

                  {form.id && form.deleted_at == null && (
                    <Button
                      buttonStyle={"danger"}
                      className="btn-remove"
                      onClick={() =>
                        setConfirmModal({
                          title: "Delete Video",
                          message: "Are you sure you want to delete the video?",
                        })
                      }
                    >
                      Delete
                    </Button>
                  )}
                </Grid>
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      </Grid.Column>

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
      {modal.message && (
        <ModalComponent modal={modal} hideModal={hideAlertModal} />
      )}
      {confirmModal.message && (
        <ConfirmModal modal={confirmModal} afterEvent={remove} />
      )}
    </Grid>
  );
};
