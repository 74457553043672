import React from "react";

import AttributeDescription from "./AttributeDescription";

import { FormatCashDownToPrice } from "../../../../helpers/general";
import { useTheme } from "../../../../helpers/theme";

import EmptyImage from "../../../../../images/no-image-available.png";

import "./AttributeInfo.sass";

const AttributeInfo = ({ item }) => {
  const { theme } = useTheme();
  return (
    <div className={"infoWrapper"}>
      <div className={"attributeImage"}>
        <img
          src={item.image_url}
          alt={"Attribute Photo"}
          onError={({ currentTarget }) => {
            currentTarget.src = EmptyImage;
          }}
        />
      </div>

      <div className={"descriptionWrapper"}>
        <h4>{item.name}</h4>

        <AttributeDescription
          withPrice={false}
          description={item.description}
        />

        <span
          className={"attributeCostValue"}
          style={{ color: theme?.typography?.secondary }}
        >
          {FormatCashDownToPrice(item.cost)}
        </span>
      </div>
    </div>
  );
};

export default AttributeInfo;
