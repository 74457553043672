import React, { useState } from "react";
import { Modal, Radio } from "semantic-ui-react";

import Button from "../../../global/Button";

import { useTheme } from "../../../helpers/theme";

import "./QuestionnaireModal.sass";

const QuestionnaireModal = ({ isPlanSelected, onClick, children }) => {
  const { theme } = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const open = () => isPlanSelected && setIsOpen(true);

  const close = () => setIsOpen(false);

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => {
          onClick();
          open();
        },
      })}

      <Modal open={isOpen} onClose={close} closeIcon>
        <Modal.Header className={"modalHeaderWrapper"}>
          Confirm your consent
        </Modal.Header>

        <Modal.Content>
          <Radio
            defaultChecked
            label="Decline protective coverage for now and keep shopping prequalified"
            className={"radioButton"}
          />
        </Modal.Content>

        <Modal.Actions className={"actionButtons"}>
          <Button buttonStyle={"danger"} onClick={close} theme={theme?.button}>
            Close
          </Button>

          <Button
            onClick={() => {
              close();
              onClick(true);
            }}
            theme={theme?.button}
          >
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default QuestionnaireModal;
