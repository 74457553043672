import React from "react";
import _ from "lodash";

import Button from "../../../../global/Button";

import {
  FormatCashDownToPrice,
  getRebatesTotal,
} from "../../../../helpers/general";
import { useTheme } from "../../../../helpers/theme";

import "./BottomBar.sass";

const BottomBar = ({ selectedRebates, car, onSave }) => {
  const { theme } = useTheme();

  const getTotal = () => {
    const total = getRebatesTotal({
      selectedRebates,
      car,
    });

    return total;
  };

  const handleOnSave = () => {
    onSave(_.pickBy(selectedRebates, (item) => !_.isEmpty(_.identity(item))));
  };

  return (
    <div className={"rebatesBottomBar"}>
      <Button
        buttonStyle={"secondary"}
        onClick={handleOnSave}
        theme={theme?.button}
      >
        Save
      </Button>

      <span
        className={"rebatesTotalPrice"}
        style={{ color: theme?.typography?.secondary }}
      >{`Total Rebates Amount: -${FormatCashDownToPrice(getTotal())}`}</span>
    </div>
  );
};

export default BottomBar;
