import React from "react";
import cn from "classnames";

import { DateInput as SemanticDateInput } from "semantic-ui-calendar-react";

import { defaultTheme } from "../../helpers/theme";

import "./styles.sass";

const DateInput = ({ error, className, isPreview = false, theme, ...rest }) => {
  return (
    <div
      className={cn(
        "dateInputWrapper",
        error && "danger",
        isPreview && "dateInputPreview",
        className
      )}
      style={{
        "--date-picker-border-color-default":
          theme?.default?.color || defaultTheme.input.default.color,
        "--date-picker-border-color-primary":
          theme?.focus?.color || defaultTheme.input.focus.color,
        "--date-picker-primary-shadow-color":
          theme?.focus?.shadow || defaultTheme.input.focus.shadow,
        "--date-picker-default-shadow-color":
          theme?.default?.shadow || defaultTheme.input.default.shadow,
        "--date-picker-border-radius":
          (theme?.borderRadius || defaultTheme.input.borderRadius) + "px",
      }}
    >
      <SemanticDateInput {...rest} />

      <p className={cn("errorMessage", error && "errorMessageVisible")}>
        {error}
      </p>
    </div>
  );
};

export default DateInput;
