import React, { Component } from "react";
import { Grid, Table, Dimmer, Loader, Button } from "semantic-ui-react";

// IMPORT COMPONENTS
import { customerMenu } from "../helpers/menus";
import UserMenu from "../utilities/menu/menu";
import UserHeader from "../utilities/userHeader/userHeader";
import Contact from "../utilities/contact/contact";
import ListQuotes from "../utilities/ListQuotes/listQuotes";
import ChangePassword from "../utilities/changePassword/changePassword";

// IMPORT IMAGES
import { getContactData, searchQuotesCustomer } from "../utilities/requests";
import { FormatDate } from "../helpers/general";

// IMPORT STYLES
import "./customer.sass";
import BankAccount from "../utilities/bankAccount/BankAccount";
import { LoanDetails } from "../utilities/loanDetails/LoanDetails";
import { QuotesApproval } from "../utilities/quotesApproval/QuotesApproval";
import { BackMenu } from "../utilities/menu/BackMenu";
import { Tutorial } from "../utilities/tutorial/Tutorial";

const customerMenuList = customerMenu;
class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeItem: "dashboard",
      carIdByUrl: null,
      contactData: {
        customer: { name: "", second_name: "" },
      },
      lastQuotes: [],
      quotes: {
        data: [],
        total: 0,
      },
      page: 1,
      menuOpened: false,
    };
    this.changeActiveItem = this.changeActiveItem.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeDefaultId = this.changeDefaultId.bind(this);
    this.showDetails = this.showDetails.bind(this);
  }

  changeActiveItem(selected) {
    this.setState({ activeItem: selected });
  }

  componentDidMount() {
    this.setState({ loading: true });

    getContactData()
      .then((result) => {
        let data = { ...result.data };
        delete data.id;
        delete data.user_id;

        const recent = result.data.quotes.data.slice(0, 7);
        const contactData = {
          id: result.data.id,
          user_id: result.data.user_id,
          email: result.data.email,
          phone_number: result.data.contact_number,
          customer: data,
        };
        if (data.ssn) {
          customerMenuList.push({
            name: "account",
            icon: "account",
            label: "Account",
          });
          customerMenuList.splice(2, 0, {
            name: "loan",
            icon: "loan",
            label: "Loan Approval",
          });
        }
        this.setState({
          loading: false,
          contactData,
          quotes: {
            ...result.data.quotes,
            data: result.data.quotes.data.map((quote) => {
              const car = quote.car || JSON.parse(quote.car_info);

              return {
                ...quote,
                car,
              };
            }),
          },
          lastQuotes: recent.map((quote) => ({
            ...quote,
            car: quote.car || JSON.parse(quote.car_info),
          })),
        });
      })
      .catch((error) => console.log(error));
  }

  changePage(page = 1, startDate = null, endDate = null, search = null) {
    let params = { page };
    if (startDate) params["startDate"] = startDate;
    if (endDate) params["endDate"] = endDate;
    if (search) params["search"] = search;
    this.setState({ loading: true });
    searchQuotesCustomer(params)
      .then((response) => {
        this.setState({
          loading: false,
          quotes: response.data,
          page: response.data.current_page,
        });
      })
      .catch((error) => {});
  }

  showDetails(id) {
    this.setState({ carIdByUrl: id, activeItem: "quotes" });
  }

  changeDefaultId(id) {
    this.setState({ carIdByUrl: id });
  }

  // ---------------------------- update customer from child component -------------------
  updateCustomer = (customer, email, phone_number) => {
    let contactData = this.state.contactData;
    contactData["customer"] = { ...contactData["customer"], ...customer };
    contactData["email"] = email;
    contactData["phone_number"] = phone_number;
    this.setState({ contactData });
  };

  // ---------------------------- update customer from bank component -------------------
  updateCustomerData = (customer, type) => {
    let contactData = this.state.contactData;
    contactData["customer"] = customer;
    if (
      contactData.customer &&
      contactData.customer.account &&
      contactData.customer.account.verified &&
      type === "dwolla"
    ) {
      this.setState({ activeItem: "quotes" });
    }
    this.setState({ contactData });
  };

  // ----------------------- update number of created quotes when status change pending to created ------------
  updateCreatedQuots = (status, id, pending) => {
    let quotes = this.state.quotes;
    for (let i = 0; i < quotes.data.length; i++) {
      if (quotes.data[i].id == id) {
        quotes.data[i]["status"] = status;
        break;
      }
    }
    let contactData = this.state.contactData;
    if (pending) {
      contactData["customer"]["quots_count"] =
        contactData["customer"]["quots_count"] + 1;
    }
    this.setState({ quotes, contactData });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <div className={"dashboard customer"}>
        <UserMenu
          handleClick={this.changeActiveItem}
          activeDefault={this.state.activeItem}
          elements={customerMenu}
          menuOpened={this.state.menuOpened}
          openMenu={() => this.setState({ menuOpened: false })}
        />
        <BackMenu
          menuOpened={this.state.menuOpened}
          openMenu={() => this.setState({ menuOpened: false })}
        />
        <div className={"dashboardContent"} style={{ background: "white" }}>
          {this.state.tutorial && (
            <Tutorial
              section={activeItem}
              step={(step) => this.setState({ activeItem: step })}
              close={() => this.setState({ tutorial: false })}
            />
          )}
          <UserHeader
            title={"SECTION TITLE"}
            name={
              this.state.contactData.customer.first_name +
              " " +
              this.state.contactData.customer.last_name
            }
            openMenu={() => this.setState({ menuOpened: true })}
          />
          {activeItem === "dashboard" && (
            <div className={"body"}>
              <Grid>
                <Grid.Column className={"generalInfo transparent"} width={16}>
                  <Button
                    secondary
                    onClick={() => this.setState({ tutorial: true })}
                  >
                    What is next?
                  </Button>
                </Grid.Column>
                <Grid.Column className={"lastQuotes overflow-x"} width={16}>
                  <Table className={"customTable"} unstackable>
                    <Table.Header>
                      <Table.Row className={"upperHeader"}>
                        <Table.HeaderCell colSpan={5}>
                          LAST DEALS
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"right"}>
                          <span
                            className={"viewAll"}
                            onClick={() => this.changeActiveItem("quotes")}
                          >
                            View all...
                          </span>
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row className={"standardHeader"}>
                        <Table.HeaderCell>Retailer Name</Table.HeaderCell>
                        <Table.HeaderCell>Car</Table.HeaderCell>
                        <Table.HeaderCell>Quotation Date</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Payment Plan
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.lastQuotes.map(
                        (
                          {
                            id,
                            retailer,
                            car,
                            created_at,
                            status,
                            customer,
                            payment_plan,
                          },
                          key
                        ) => (
                          <Table.Row key={key}>
                            <Table.Cell>{retailer.name}</Table.Cell>
                            <Table.Cell>
                              {`${car.make} ${car.model}`}
                            </Table.Cell>
                            <Table.Cell>{FormatDate(created_at)}</Table.Cell>
                            <Table.Cell>
                              {status == 0
                                ? " Pending"
                                : status == 1
                                ? " Created"
                                : status == 2
                                ? " Accepted"
                                : status == 3
                                ? " Cancelled"
                                : status == 4
                                ? " Expired"
                                : null}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {payment_plan ? (
                                <LoanDetails
                                  id={payment_plan?.id}
                                  quote={{ id, car }}
                                  customer={this.state.contactData}
                                  type={"customer"}
                                  retailer={retailer}
                                />
                              ) : !this.state.contactData.customer.account ? (
                                "Funding source is not added"
                              ) : this.state.contactData.customer.account
                                  .verified ? (
                                <LoanDetails
                                  id={payment_plan?.id}
                                  quote={{ id, car }}
                                  customer={this.state.contactData}
                                  type={"customer"}
                                  retailer={retailer}
                                />
                              ) : (
                                "Funding source is not verified"
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <span
                                className={"detailsQuote"}
                                onClick={() => this.showDetails(id)}
                              >
                                See more...
                              </span>
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                      <Table.Row></Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid>
            </div>
          )}
          {
            <ListQuotes
              role={"customer"}
              quotes={this.state.quotes.data}
              totalPages={this.state.quotes.last_page}
              changePage={this.changePage}
              defaultCar={this.state.carIdByUrl}
              user={this.state.contactData}
              changeDefault={this.changeDefaultId}
              isActive={activeItem === "quotes"}
              updateCreatedQuots={this.updateCreatedQuots}
            />
          }
          {
            <QuotesApproval
              quotes={this.state.quotes}
              user={this.state.contactData}
              isActive={activeItem === "loan"}
              updateCustomerData={this.updateCustomerData}
            />
          }
          {activeItem === "contact" && (
            <div className={"body"}>
              <Contact
                user={this.state.contactData}
                location={false}
                updateCustomer={this.updateCustomer}
              />
              <ChangePassword user={this.state.contactData} />
            </div>
          )}
          {activeItem === "account" && (
            <div className={"body"}>
              <BankAccount
                user={this.state.contactData}
                updateCustomerData={this.updateCustomerData}
              />
            </div>
          )}
        </div>
        {this.state.loading ? (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        ) : null}
      </div>
    );
  }
}

export default Customer;
