import React, { useEffect } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';

export const Calendar = ({ days }) => {

  useEffect(() => {
  }, [days])

  return (
    <div>
      <DateInput
        dateFormat="MM-DD-YYYY"
        inline={true}
        marked={days}
        disable={true}
        markColor={["green", "red"]}
        value={days[0]}
        minDate={days[0]}
        maxDate={days[days.length - 1]}
        disabled={true}
      />
    </div>
  );
};