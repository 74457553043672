import React from 'react';
import { Table } from 'semantic-ui-react';

export const BankInformation = ({ customer }) => {

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              BANK INFORMATION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row >
            <Table.Cell>
              Account Holder:
            </Table.Cell>
            <Table.Cell>
              {customer.name + " " + customer.second_name}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Bank Name:
            </Table.Cell>
            <Table.Cell>
              {/* {customer.account.bankName} */}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Account Type:
            </Table.Cell>
            <Table.Cell>
              {/* {customer.account.type} */}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Account Number:
            </Table.Cell>
            <Table.Cell>
              {/* {customer.account.accountNumber} */}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Routing Number:
            </Table.Cell>
            <Table.Cell>
              {/* {customer.account.routingNumber} */}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};