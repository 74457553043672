import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { Footer } from './Footer';

export const Page1 = ({ plan }) => {
  return (
    <div className='page-1' id="page2">
      <Grid columns={2} stackable>
        <Grid.Column>
          <img src={require("../../../../../images/MyPayPow.png")} style={{ width: "250px" }} />
          <Header as="h2" className='title'>My Payment Power&trade; Dealer Agreement</Header>
          <p>
            Whereas Dealership (“Dealer”) wishes to promote My Payment PowerTM, Dealer
            hereby acknowledges that the owner of My Payment PowerTM is US Equity
            Advantage, Inc. (“USEA”) located at 800 N. Magnolia Avenue, Suite 1275,
            Orlando, FL 32803 and that it is distributed by My Payment Power Automotive
            Distribution, LLC, and further, Dealer herby agrees to the following
            representations and warranties that USEA requires:
          </p>
          <Header as="h3">Dealer Obligations, Representations and Warranties</Header>
          <p>
            1. Dealer is at all times hereunder considered to be an Independent
            Contractor of USEA, and as such is responsible for all tax, licensing, and
            regulatory matters related to said legal status. Dealer personnel, if paid
            directly by USEA, are considered to be independent contractors as well.
            Nothing in this agreement shall create an employee/employer relationship,
            an agency, franchise or joint venture agreement between the parties.
          </p>
          <p>
            2. Dealer warrants that they will abide by all policies and procedures as
            defined by USEA and its distributor and shall not make any representations
            or warranties regarding My Payment Power except those set forth in the
            My Payment Power enrollment forms. Dealer also warrants to properly and fully
            disclose all fees and any net cost/savings to all consumers.
          </p>
          <p>
            3. Dealer shall not use any promotional materials other than those
            provided by, or approved by USEA in writing.
          </p>
          <p>
            4. Dealer warrants that they will not transmit any falsified information or
            signature in relation to My Payment Power, to either USEA or their processor and
            banking partners. Dealer shall maintain a copy of a properly executed
            My Payment Power authorization on each consumer enrolled for a minimum of
            24 months from the date of final payment as per the finance contract.
            Dealer shall maintain a copy of consumer's driver license with a valid
            signature for each My Payment Power sold. All My Payment Power enrollment
            documents are subject to annual audit sampling by USEA or their
            processors and banking partners. Any deficiencies identified may result in
            the immediate cancellation of Dealer's USEA service privileges.
          </p>
          <p>
            5. Dealer shall indemnify, and hold USEA harmless, as well as USEA
            processor and banking partners against any and all suits, claims, causes of
            action, liabilities, damages and costs, including but not limited to reasonable
            attorney's fees, incurred as a result of any negligence, misrepresentation,
            unauthorized warranty or breach of this agreement, by Dealer or their
            employees, associates, agents and/or sub-contractors.
          </p>
          <p>
            6. Dealer will notify USEA within one business day of any notice of
            wrongdoing or any customer complaint relating to My Payment Power.
          </p>
          <p>
            7. Dealer will not use the name of USEA or its distributor, processor and/
            or banking partners without the express written consent ofUSEA.
          </p>
          <p>
            8. Dealer will notify USEA within 1 business day, both orally and via email of
            any request for cancellation by a customer.
          </p>
          <Header as="h3">Term, Limitations, Termination</Header>
          <p>
            1. Subject to the rights of termination provided for in Section 2 immediately
            below, this Agreement: (a) shall be effective for an initial term of twelve (12)
            months from the date of acceptance by Dealer and USEA; and (b) shall
            automatically renew on each subsequent one•year anniversary for an
            additional one•year term.
          </p>
          <p>
            2. USEA or Dealer may terminate this agreement without cause at any time
            effective thirty (30) days after written notice or on a later date specified in the
            notice or with cause at any time effective fifteen (15) days after written notice
            or on a later date specified in the notice. Neither the expiration nor the earlier
            termination of this agreement shall release either party from any obligation
            which has accrued as of the date of termination, except that the automatic
            renewal provision of Section 1(b) immediately above is not an obligation that
            survives termination.
          </p>
        </Grid.Column>
        <Grid.Column>
          <p>
            3. USEA may, from time to time, give Dealer written notice of amendments to
            this agreement. Any such amendment will automatically become a part of this
            agreement no less than forty-five (45) days from the date of the notice unless
            Dealer terminates.
          </p>
          <Header as="h3">USEA Obligations, Representations and Warranties</Header>
          <p>
            1. USEA warrants that they have the full legal right and authority to provide
            My Payment Power, and that they will take all reasonable measures to insure the
            uninterrupted delivery and support of My Payment Power to Dealer.
          </p>
          <p>
            2. USEA and/or its assigns shall pay Dealer and dealership personnel
            according to the referral fee schedule incorporated herein as Schedule“A”.
          </p>
          <p>
            3. USEA shall indemnify, and hold Dealer harmless against any and all suits,
            claims, causes of action, liabilities, damages and costs, including but not
            limited to reasonable attorney's fees, incurred as a result of any negligence,
            misrepresentation, unauthorized warranty or breach of this agreement, by
            USEA or their employees, associates, agents and/or subcontractors.
          </p>
          <p>4. USEA is committed to protecting customer information which may be
            collected from the following sources:
          </p>
          <ul>
            <li>Enrollment or authorization forms containing name, address,
              social security number, depository account and loandetails.</li>
            <li>Transactions with the depositoryaccount.</li>
            <li>The lender or payee.</li>
            <li>A consumer reporting agency.</li>
          </ul>
          <p>
            USEA does not disclose or sell any nonpublic personal information pertaining
            to current or former customers to any nonaffiliated third parties. Some of this
            information may be shared with affiliated companies in connection with the
            enrollment, authorization, or implementation of My Payment Power or to inform the
            customer of other product and service opportunities.
          </p>
          <p>
            USEA restricts access to non-public personal customer information about
            customers to those of our employees, dealers, agents and other partieswho
            need to know information in order to provide the My Payment Power service to the
            consumer. To guard consumer information, USEA has put in place
            appropriate physical, electronic and managerial procedures to safeguard and
            secure the information in accordance with Federal standards.
          </p>
          <Header as="h3">General Provisions</Header>
          <p>
            1. Assignment. This agreement may not be assigned by either party without the
            prior written consent of the other party, which consent shall not be unreasonably
            withheld. No consent shall be required in connection with an assignment to an
            affiliate of any party or the acquirer of substantially all of the assets of a party.
            Any assignments hereunder shall not relieve the assigning party from its
            obligations under thisAgreement.
          </p>
          <p>
            2. Changes and Waiver. This Agreement may be amended or modified, and
            the terms and conditions of this agreement may be waived, only by the written
            consent of the Company and/or Distributor and Agent. The failure of any party
            at any time to require performance of any provision hereof shall in no manner
            affect its right at a later time to enforce the same, and no waiver of any nature
            by any party, whether by conduct or otherwise, shall be deemed to be a
            continuing waiver.
          </p>
          <p>
            3. Governing Law. This agreement shall be governed by and construed and
            enforced in accordance with the laws of the State of Florida, venue deemed to
            properly lay in Orange County, FL and both parties hereby submit to its
            jurisdiction. Both parties hereto waive any right they may have to a jury trial.
          </p>
          <p>
            4. Counterparts; Facsimile Signatures. This agreement may be executed in
            any number of separate counterparts, each of which shall, collectively and
            separately, constitute one agreement. Facsimile signatures will be accepted by
            both parties as execution originalsignatures.
          </p>
        </Grid.Column>
      </Grid>
      <Footer page={1}/>
    </div>
  );
};