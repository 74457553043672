import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Dimmer, Loader, Icon } from "semantic-ui-react";

import DropDown from "../../global/DropDown";
import Input from "../../global/Input";
import DateInput from "../../global/DateInput";
import Button from "../../global/Button";
import ColorPicker from "../../global/ColorPicker";
import ImageUpload from "./ImageUpload";

import { convertToRGBA } from "../../helpers/general";
import { defaultTheme, defaultImages } from "../../helpers/theme";

import {
  editRetailerAppearance,
  getPresignedUrl,
  uploadImageToS3,
  updateRetailerImages,
} from "../../utilities/requests";

import "./AppearanceConfigurator.sass";

const AppearanceConfigurator = ({ retailer }) => {
  const [images, setImages] = useState({});
  const [theme, setTheme] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChangeColor = (field, color) => {
    const _theme = { ...theme };

    _.set(_theme, field, color);

    setTheme(_theme);
  };

  const handleOnChangeRadius = (field) => (e) => {
    const _theme = { ...theme };
    const value = Number();

    if (Number.isNaN(value)) return;

    _.set(_theme, field, e.target.value);

    setTheme(_theme);
  };

  const handleOnSuccessUpload = (type) => (file) => {
    setImages((prev) => ({
      ...prev,
      [type]: file,
    }));
  };

  const handleOnSaveTheme = async () => {
    setIsLoading(true);

    try {
      const promises = [];
      const _images = [];

      for (const key in images) {
        if (typeof images[key] !== "string") {
          _images.push(images[key]);
          promises.push(getPresignedUrl(key));
        }
      }

      const data = await Promise.all(promises);

      const uploadFilePromises = data.map((response, index) =>
        uploadImageToS3(response.data.presignedUrl, _images[index])
      );

      const imageKeys = data.reduce((acc, response) => {
        const key = response.data.key.split("-").at(-1);

        return {
          ...acc,
          [key]: response.data.key,
        };
      }, {});

      await Promise.all(uploadFilePromises);
      await editRetailerAppearance(retailer.id, theme);
      await updateRetailerImages(retailer.id, {
        images: imageKeys,
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const init = () => {
    const uploadedImages = JSON.parse(retailer.images);
    const links = {
      ...defaultImages,
    };

    for (const key in uploadedImages) {
      links[key] = uploadedImages[key].link;
    }

    setTheme(JSON.parse(retailer.appearance) || defaultTheme);
    setImages(links);
  };

  useEffect(() => {
    init();
  }, []);

  const dropDownOptions = [
    {
      text: "Kia Sportage",
      value: "Kia Sportage",
    },
    {
      text: "Ford Fusion",
      value: "Ford Fusion",
    },
  ];

  return (
    <>
      {!_.isEmpty(theme) && (
        <>
          <div className={"appearanceConfiguratorPage"}>
            <h3>Widget Appearance Configuration</h3>

            <section className={"appearanceConfiguratorPageSection"}>
              <h4>Typography and Elements</h4>

              <div className={"appearanceTypography"}>
                <div className={"appearanceTypographyItem"}>
                  <div className="appearanceSectionLabel">
                    <span style={{ color: theme.typography.primary }}>
                      Primary Color
                    </span>
                  </div>

                  <ColorPicker
                    color={theme.typography.primary}
                    onChange={(color) =>
                      handleOnChangeColor(
                        "typography.primary",
                        convertToRGBA(color)
                      )
                    }
                  />
                </div>

                <div className={"appearanceTypographyItem"}>
                  <div className="appearanceSectionLabel">
                    <span style={{ color: theme.typography.secondary }}>
                      Secondary Color
                    </span>
                  </div>

                  <ColorPicker
                    color={theme.typography.secondary}
                    onChange={(color) =>
                      handleOnChangeColor(
                        "typography.secondary",
                        convertToRGBA(color)
                      )
                    }
                  />
                </div>

                <div className={"appearanceTypographyItem"}>
                  <div className="appearanceSectionLabel">
                    <span style={{ color: theme.typography.success }}>
                      Success Color
                    </span>
                  </div>

                  <ColorPicker
                    color={theme.typography.success}
                    onChange={(color) =>
                      handleOnChangeColor(
                        "typography.success",
                        convertToRGBA(color)
                      )
                    }
                  />
                </div>

                <div className={"appearanceTypographyItem"}>
                  <div className="appearanceSectionLabel">
                    <span style={{ color: theme.typography.negative }}>
                      Negative Color
                    </span>
                  </div>

                  <ColorPicker
                    color={theme.typography.negative}
                    onChange={(color) =>
                      handleOnChangeColor(
                        "typography.negative",
                        convertToRGBA(color)
                      )
                    }
                  />
                </div>
              </div>
            </section>

            <section className={"appearanceConfiguratorPageSection"}>
              <h4>Components</h4>

              <div className={"appearanceConfiguratorRow"}>
                <div className={"appearanceSectionLabel"}>
                  <label>Default</label>
                </div>

                <ColorPicker
                  color={theme.input?.default.color}
                  onChange={(color) => {
                    handleOnChangeColor(
                      "input.default.color",
                      convertToRGBA(color)
                    );
                    handleOnChangeColor(
                      "input.default.shadow",
                      convertToRGBA(color, 0)
                    );
                  }}
                />

                <div className={"appearanceConfiguratorRowCell"}>
                  <Input
                    placeholder={"Placeholder..."}
                    theme={{
                      ...theme.input,
                      default: {
                        ...theme.input?.default,
                        shadow: "transparent",
                      },
                    }}
                    isPreview
                  />

                  <DateInput
                    animation="none"
                    value={moment()}
                    format={"MM-DD-YYYY"}
                    theme={{
                      ...theme.input,
                      default: {
                        ...theme.input?.default,
                        shadow: "transparent",
                      },
                    }}
                    isPreview
                  />

                  <DropDown
                    options={dropDownOptions}
                    value={dropDownOptions[1].value}
                    theme={{
                      ...theme.input,
                      default: {
                        ...theme.input?.default,
                        shadow: "transparent",
                      },
                    }}
                    isPreview
                    selection
                  />
                </div>
              </div>

              <div className={"appearanceConfiguratorRow"}>
                <div className={"appearanceSectionLabel"}>
                  <label>Hover/Active</label>
                </div>

                <ColorPicker
                  color={theme.input?.focus.color}
                  onChange={(color) => {
                    handleOnChangeColor(
                      "input.focus.color",
                      convertToRGBA(color)
                    );
                    handleOnChangeColor(
                      "input.focus.shadow",
                      convertToRGBA(color, 0.3)
                    );
                  }}
                />

                <div className={"appearanceConfiguratorRowCell"}>
                  <Input
                    autofocus
                    placeholder={"Placeholder..."}
                    theme={{
                      ...theme.input,
                      default: theme.input?.focus,
                    }}
                    isPreview
                  />

                  <DateInput
                    animation="none"
                    value={moment()}
                    format={"MM-DD-YYYY"}
                    theme={{
                      ...theme.input,
                      default: theme.input?.focus,
                    }}
                    isPreview
                  />

                  <DropDown
                    options={dropDownOptions}
                    value={dropDownOptions[1].value}
                    open={true}
                    theme={{
                      ...theme.input,
                      default: theme.input?.focus,
                    }}
                    isPreview
                    selection
                  />

                  <div className={"emptyCell"} />
                  <div className={"emptyCell"} />
                  <div className={"emptyCell"} />
                </div>
              </div>

              <div className={"appearanceConfiguratorRow"}>
                <div className={"appearanceSectionLabel"}>
                  <label>Error</label>
                </div>

                <ColorPicker
                  color={theme.input?.error.color}
                  onChange={(color) => {
                    handleOnChangeColor(
                      "input.error.color",
                      convertToRGBA(color)
                    );
                    handleOnChangeColor(
                      "input.error.shadow",
                      convertToRGBA(color, 0.3)
                    );
                  }}
                />

                <div className={"appearanceConfiguratorRowCell"}>
                  <Input
                    placeholder={"Placeholder..."}
                    error={"Error message"}
                    theme={{
                      ...theme.input,
                      default: theme.input?.error,
                    }}
                    isPreview
                  />

                  <DropDown
                    options={dropDownOptions}
                    value={dropDownOptions[1].value}
                    error={"Error message"}
                    theme={{
                      ...theme.input,
                      default: theme.input?.error,
                    }}
                    selection
                  />
                </div>
              </div>

              <div className={"appearanceConfiguratorRow"}>
                <div className={"appearanceSectionLabel"}>
                  <label>Border Radius</label>
                </div>

                <Input
                  type={"number"}
                  min={0}
                  onChange={handleOnChangeRadius("input.borderRadius")}
                  value={theme.input.borderRadius}
                  className={"borderRadiusInput"}
                />
              </div>

              <div className={"appearanceConfigurationButtons"}>
                <div className={"appearanceConfigurationButtonsColumn"}>
                  <label>Primary</label>

                  <Button buttonStyle="skyblue" theme={theme.button}>
                    Click Me
                  </Button>

                  <div
                    className={"appearanceConfigurationButtonsColumnPickers"}
                  >
                    <div>
                      <label>Background Color</label>

                      <ColorPicker
                        color={theme.button?.primary.background}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.primary.background",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>

                    <div>
                      <label>Text Color</label>

                      <ColorPicker
                        color={theme.button?.primary.color}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.primary.color",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className={"appearanceConfigurationButtonsColumn"}>
                  <label>Secondary</label>

                  <Button buttonStyle="secondary" theme={theme.button}>
                    Click Me
                  </Button>

                  <div
                    className={"appearanceConfigurationButtonsColumnPickers"}
                  >
                    <div>
                      <label>Background Color</label>

                      <ColorPicker
                        color={theme.button?.secondary.background}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.secondary.background",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>

                    <div>
                      <label>Text Color</label>

                      <ColorPicker
                        color={theme.button?.secondary.color}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.secondary.color",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className={"appearanceConfigurationButtonsColumn"}>
                  <label>Success</label>

                  <Button buttonStyle="primary" theme={theme.button}>
                    Click Me
                  </Button>

                  <div
                    className={"appearanceConfigurationButtonsColumnPickers"}
                  >
                    <div>
                      <label>Background Color</label>

                      <ColorPicker
                        color={theme.button?.success.background}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.success.background",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>

                    <div>
                      <label>Text Color</label>

                      <ColorPicker
                        color={theme.button?.success.color}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.success.color",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className={"appearanceConfigurationButtonsColumn"}>
                  <label>Negative</label>

                  <Button buttonStyle="danger" theme={theme.button}>
                    Click Me
                  </Button>

                  <div
                    className={"appearanceConfigurationButtonsColumnPickers"}
                  >
                    <div>
                      <label>Background Color</label>

                      <ColorPicker
                        color={theme.button?.negative.background}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.negative.background",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>

                    <div>
                      <label>Text Color</label>

                      <ColorPicker
                        color={theme.button?.negative.color}
                        onChange={(color) =>
                          handleOnChangeColor(
                            "button.negative.color",
                            convertToRGBA(color)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className={"appearanceConfiguratorRow"}>
                  <div className={"appearanceSectionLabel"}>
                    <label>Border Radius</label>
                  </div>

                  <Input
                    type={"number"}
                    min={0}
                    onChange={handleOnChangeRadius("button.borderRadius")}
                    value={theme.button.borderRadius}
                    className={"borderRadiusInput"}
                  />
                </div>
              </div>
            </section>

            <section className={"appearanceConfiguratorPageSection"}>
              <div className={"appearanceWeeklyBoxWrapper"}>
                <div className="appearanceSectionLabel">
                  <div
                    style={{
                      color: theme.weekly.color,
                      backgroundColor: theme.weekly.background,
                      borderRadius: theme.weekly.borderRadius + "px",
                    }}
                    className={"weeklyBox"}
                  >
                    <Icon className="infoIcon" link name="info circle" />

                    <span>{"125$ Weekly"}</span>
                  </div>
                </div>

                <ColorPicker
                  color={theme.weekly.color}
                  onChange={(color) =>
                    handleOnChangeColor("weekly.color", convertToRGBA(color))
                  }
                />

                <ColorPicker
                  color={theme.weekly.background}
                  onChange={(color) =>
                    handleOnChangeColor(
                      "weekly.background",
                      convertToRGBA(color)
                    )
                  }
                />

                <Input
                  type={"number"}
                  min={0}
                  onChange={handleOnChangeRadius("weekly.borderRadius")}
                  value={theme.weekly.borderRadius}
                  className={"borderRadiusInput"}
                />
              </div>
            </section>

            <section>
              <h4>Widget Images</h4>

              <div className={"appearancePageImagesWrapper"}>
                <ImageUpload
                  title={"Trade-In"}
                  image={
                    typeof images.tradeIn === "string"
                      ? images.tradeIn
                      : URL.createObjectURL(images.tradeIn)
                  }
                  onSuccess={handleOnSuccessUpload("tradeIn")}
                />

                <ImageUpload
                  title={"Protective Coverage"}
                  image={
                    typeof images.protectiveCoverage === "string"
                      ? images.protectiveCoverage
                      : URL.createObjectURL(images.protectiveCoverage)
                  }
                  onSuccess={handleOnSuccessUpload("protectiveCoverage")}
                />

                <ImageUpload
                  title={"Personalized Attributes"}
                  image={
                    typeof images.attributes === "string"
                      ? images.attributes
                      : URL.createObjectURL(images.attributes)
                  }
                  onSuccess={handleOnSuccessUpload("attributes")}
                />

                <ImageUpload
                  title={"Rebates"}
                  image={
                    typeof images.rebates === "string"
                      ? images.rebates
                      : URL.createObjectURL(images.rebates)
                  }
                  onSuccess={handleOnSuccessUpload("rebates")}
                />
              </div>
            </section>
          </div>

          <div className={"appearanceConfiguratorBottomActions"}>
            <Button onClick={handleOnSaveTheme}>Save</Button>
          </div>
        </>
      )}

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </>
  );
};

export default AppearanceConfigurator;
