import React from "react";

import { Form, Header } from "semantic-ui-react";

import Input from "../../../global/Input";

const LoanLength = (props) => {
  return (
    <>
      <Header as={"h3"}>Loan Length</Header>

      <Form.Group>
        <Form.Field width={5}>
          <label>
            Minimum <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>{props.state.min_loan_length} mo</span>
          ) : (
            <div>
              <Input
                value={props.state.min_loan_length}
                placeholder={"Minimum"}
                onChange={(e) =>
                  props.onEditInput("min_loan_length", e.target.value)
                }
                label={{ basic: true, content: "mo" }}
                labelPosition="right"
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.min_loan_length.length > 0 &&
                  props.state.msnErrors.min_loan_length
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={5}>
          <label>
            Maximum <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>{props.state.max_loan_length} mo</span>
          ) : (
            <div>
              <Input
                value={props.state.max_loan_length}
                placeholder={"Maximum"}
                onChange={(e) =>
                  props.onEditInput("max_loan_length", e.target.value)
                }
                label={{ basic: true, content: "mo" }}
                labelPosition="right"
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.max_loan_length.length > 0 &&
                  props.state.msnErrors.max_loan_length
                }
              />
            </div>
          )}
        </Form.Field>

        <Form.Field width={6}>
          <label>
            Default <span className="required-inut">*</span>
          </label>

          {props.state.flagEdit ? (
            <span>{props.state.default_loan_length} mo</span>
          ) : (
            <div>
              <Input
                value={props.state.default_loan_length}
                placeholder={"Maximum"}
                onChange={(e) =>
                  props.onEditInput("default_loan_length", e.target.value)
                }
                label={{ basic: true, content: "mo" }}
                labelPosition="right"
                type="number"
                maxLength="50"
                error={
                  props.state.msnErrors.default_loan_length.length > 0 &&
                  props.state.msnErrors.default_loan_length
                }
              />
            </div>
          )}
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default LoanLength;
