import React, { useState, useCallback, useEffect } from "react";
import { Checkbox, Dimmer, Loader } from "semantic-ui-react";
import _ from "lodash";

import {
  getProfileSelector,
  setProfileSelector,
} from "../../utilities/requests";

import Slider from "rc-slider";

import "./CreditProfileSelector.sass";

const marks = {
  0: "100%",
  25: "75%",
  50: "50%",
  75: "75%",
  100: "100%",
};

const CreditProfileSelector = () => {
  const [data, setData] = useState({
    selfSelected: null,
    coOp: null,
    isRandom: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChangeSlider = (value) => {
    const updated = {
      selfSelected: value,
      coOp: 100 - value,
    };

    setData((prevState) => ({ ...prevState, ...updated }));
    save({ ...data, ...updated });
  };

  const handleOnChangeCheckbox = (e, value) => {
    const isRandom = value.checked;

    setData((prevState) => ({
      ...prevState,
      isRandom,
    }));
    save({ ...data, isRandom });
  };

  const handleDebouncedSave = async (dto) => {
    await setProfileSelector(dto);
  };

  const save = useCallback(_.debounce(handleDebouncedSave, 1500), []);

  const init = async () => {
    setIsLoading(true);

    try {
      const { data } = await getProfileSelector();

      setData({ ...data });
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className={"body profileSelector"}>
        <h3>Dealership's Consumer Credit Profile Selector</h3>

        <div className={"sliderWrapper"}>
          <div className={"sliderLabels"}>
            <span>Soft pull</span>

            <span>Self select</span>
          </div>

          <div className={"trackWrapper"}>
            <Slider
              dots={true}
              handleStyle={{
                backgroundColor: "white",
                marginBottom: 10,
              }}
              step={25}
              marks={marks}
              onChange={handleOnChangeSlider}
              value={data.selfSelected}
            />
          </div>
        </div>

        <Checkbox
          label={"Generate A Customer Flow Randomly"}
          checked={data.isRandom}
          onChange={handleOnChangeCheckbox}
        />
      </div>

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </>
  );
};

export default CreditProfileSelector;
