import React, { useEffect, useState } from "react";
import cn from "classnames";
import Autocomplete from "react-google-autocomplete";
import { Form } from "semantic-ui-react";
import {
  getCity,
  getCountry,
  getNumber,
  getPostalCode,
  getState,
  getStreet,
} from "../../helpers/googleMethods";

import { defaultTheme } from "../../helpers/theme";

// IMPORT CSS STYLE
import "./GoogleAutocomplete.sass";

export const GoogleAutocomplete = ({
  value = "",
  onChangeSelect,
  disabled,
  validateTyping = false,
  error,
  theme,
}) => {
  const [ubication, setUbication] = useState(value);

  // -------------------- save selected option ubication -----------------
  const onPlaceSelected = (place) => {
    const location = {
      locationCountry: "",
      locationState: "",
      locationCity: "",
    };
    if (place.address_components) {
      const address = place.formatted_address,
        addressArray = place.address_components,
        city = getCity(addressArray),
        area = getCountry(addressArray),
        postalCode = getPostalCode(addressArray),
        state = getState(addressArray);
      location.address =
        getNumber(addressArray) + " " + getStreet(addressArray);
      location.locationCountry = area ? area : "";
      location.locationState = state ? state : "";
      location.locationCity = city ? city : "";
      location.postalCode = postalCode ? postalCode : "";
    } else {
      location.address = "";
      location.locationCountry = "";
      location.locationState = "";
      location.locationCity = "";
      location.postalCode = "";
    }
    setUbication(location.address);
    onChangeSelect(location);
  };

  const onKey = (value) => {
    const location = {
      locationCountry: "",
      locationState: "",
      locationCity: "",
    };
    setUbication(value);
    if (validateTyping) {
      onChangeSelect(value, true);
    }
  };

  useEffect(() => {
    setUbication(value);
  }, [value]);

  return (
    <Form.Field
      style={{ width: "100%" }}
      className={cn(disabled && "googleDisabled")}
    >
      <label>
        Address <span className="required-inut">*</span>
      </label>

      <div
        style={{
          "--input-border-color-default":
            theme?.default?.color || defaultTheme.input.default.color,
          "--input-border-color-primary":
            theme?.focus?.color || defaultTheme.input.focus.color,
          "--input-border-color-danger":
            theme?.error?.color || defaultTheme.input.error.color,
          "--input-primary-shadow-color":
            theme?.focus?.shadow || defaultTheme.input.focus.shadow,
          "--input-danger-shadow-color":
            theme?.error?.shadow || defaultTheme.input.error.shadow,
          "--input-default-shadow-color":
            theme?.default?.shadow || defaultTheme.input.default.shadow,
          "--input-border-radius":
            (theme?.borderRadius || defaultTheme.input.borderRadius) + "px",
        }}
        className={cn(
          "googleAutocompleteWrapper",
          disabled && "ghost",
          error && "autocompleteDanger"
        )}
      >
        <Autocomplete
          apiKey="AIzaSyAbLPPXKcfqGs4Ku1gdEZLTCwc1ca2VZa0"
          onChange={(e) => onKey(e.target.value)}
          onPlaceSelected={onPlaceSelected}
          value={ubication}
          types={["address"]}
          componentRestrictions={{ country: "us" }}
          autoComplete={"off"}
        />

        <p className={cn("errorMessage", error && "errorMessageVisible")}>
          {error}
        </p>
      </div>
    </Form.Field>
  );
};
