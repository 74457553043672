import React, { useState } from "react";
import { Modal, Dimmer, Loader, Message } from "semantic-ui-react";
import _ from "lodash";

import Button from "../../../../global/Button";
import Dropdown from "../../../../global/DropDown";
import MultiSelect from "../../../../global/MultiSelect";

import { fetchAllProviders, registerProducts } from "../../../requests";

import "./ProvidersModal.sass";

const ProvidersModal = ({
  provider,
  enabled,
  retailer,
  onUpdate,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allProviders, setAllProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [search, setSearch] = useState("");

  const productsFromProvider = selectedProvider
    ? allProviders
        .find((p) => p.ex_1_provider_id === selectedProvider)
        .products.filter((p) => {
          return p.status ? p.status === "disabled" : true;
        })
        .map((p) => ({
          value: p.ex_1_product_id,
          key: p.ex_1_product_id,
          text: p.product_name,
        }))
    : [];

  const open = async () => {
    setIsOpen(true);
    setIsLoading(true);

    try {
      const all = await fetchAllProviders();

      setAllProviders(all);

      if (provider) setSelectedProvider(provider.ex_1_provider_id);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const close = () => {
    setIsOpen(false);
    setSelectedProvider(null);
    setSelectedProducts([]);
    setIsSuccess(false);
    setError(null);

    isSuccess && onUpdate();
  };

  const getAvailableProvidersOptions = () => {
    const diff = _.differenceBy(allProviders, enabled, "ex_1_provider_id");

    provider && diff.push(provider);

    const availableProvidersOptions = diff.map((p) => ({
      text: p.provider_name,
      value: p.ex_1_provider_id,
      key: p.ex_1_provider_id,
    }));

    return availableProvidersOptions;
  };

  const handleOnChangeProvider = (e, { value }) => {
    setSelectedProvider((prev) => {
      if (prev !== value) setSelectedProducts([]);

      return value;
    });

    if (value && selectedProducts.length) {
      setError(null);
    }
  };

  const handleOnChangeSelectedProducts = (e, { value }) => {
    setSelectedProducts(value);

    if (value.length > 0 && selectedProvider) {
      setError(null);
    }
  };

  const handleOnSubmit = async () => {
    if (!selectedProvider || _.isEmpty(selectedProducts)) {
      setError("Provider and selected products must not be empty");

      return;
    }

    setError(null);
    setIsLoading(true);

    const dto = {
      retailerId: retailer.id,
      products: selectedProducts.map((p) => ({
        product_id: p,
        provider_dealer_id: selectedProvider,
        contract_prefix: "MPP",
      })),
    };

    try {
      await registerProducts(dto);

      setIsSuccess(true);
    } catch (e) {
      setError("Something went wrong. Please, contact our support manager.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal open={isOpen} onClose={close} closeIcon style={{ maxWidth: 550 }}>
        <Modal.Header>Register New Products</Modal.Header>

        <Modal.Content>
          <div className={"providerModalInfo"}>
            <span>
              Select a provider from the list of available providers. Activation
              may take from 1 to 5 days
            </span>
          </div>

          <Dropdown
            selection
            value={selectedProvider}
            onChange={handleOnChangeProvider}
            autoComplete={"off"}
            placeholder="Provider name"
            search
            options={getAvailableProvidersOptions()}
            disabled={provider}
          />

          {selectedProvider && (
            <MultiSelect
              value={selectedProducts}
              onChange={handleOnChangeSelectedProducts}
              options={productsFromProvider || []}
              placeholder="Products"
            />
          )}

          {error && (
            <Message negative onDismiss={() => setError(null)}>
              <Message.Header>Error</Message.Header>

              <Message.Content>{error}</Message.Content>
            </Message>
          )}

          {isSuccess && (
            <Message positive>
              <Message.Header>Success</Message.Header>

              <Message.Content>
                Products will be available within 1-5 days. Otherwise, contact
                customer support
              </Message.Content>
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions className={"actionButtons"}>
          <Button buttonStyle="danger" onClick={close}>
            Close
          </Button>

          {!isSuccess && <Button onClick={handleOnSubmit}>Submit</Button>}
        </Modal.Actions>

        {isLoading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </Modal>
    </>
  );
};

export default ProvidersModal;
