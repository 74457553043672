import React, { useState } from 'react';
import { Button, Dimmer, Form, Header, Icon, Loader, Modal } from 'semantic-ui-react';
import { validateMonthsAtCurrentAddress, validateSpouse, validateTotalMonthsEmployed, validCodeNumber, validPhoneNumber } from '../../helpers/general';
import ModalComponent from '../alertModal/modal';
import { getCoApplicants, postLoanApplication } from '../requests';
import { PersonalInformation } from './PersonalInformation';
import { SelectCoApplicant } from './SelectCoApplicant';
import { StepsLoanApplication } from './StepsLoanApplication';
import { checkPropertyStates } from '../../helpers/usaStates';

const defualtForm = {
  monthsAtCurrentAddress: null, housingStatus: null, income: null, status: 0,
  mortgageOrRentAmount: null, employerName: null, occupation: null, occupationStatus: null, incomeFrequency: null,
  driverLicense: null, driverLicenseState: null, maritalStatus: null, spouseAgree: false, workPhone: null, totalMonthsEmployed: null
}

export const QuoteApproval = (props) => {
  const { quote, user } = props;
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0)
  const [form, setForm] = useState(defualtForm);
  const [errors, setErrors] = useState(defualtForm);
  const [disabled, setDisabled] = useState(true);
  const [formDisabled, setFormDisabled] = useState(false)
  const [allCoApplicants, setAllCoApplicants] = useState({ coApplicants: [], guarantors: [], drivers: [] });
  const [newOne, setNewOne] = useState({ coApplicant: false, guarantor: false, driver: false });

  // ---------------------------- update form and validate -----------------
  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    if (label === 'coApplicantId' || label === 'guarantorId' || label === 'driverId') {
      let flag = 'coApplicantSkip';
      if (step === 2) {
        flag = 'guarantorSkip';
      } else if (step === 3) {
        flag = 'driverSkip';
      }
      auxForm[flag] = false;
    }
    setForm(auxForm);
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors);
    setErrors({ ...auxErrors });
    validation(auxForm, auxErrors);
  }


  const validation = (auxForm, auxErrors, auxStep = step, auxNewOne = newOne) => {
    let arrayValidation = [];
    console.log(form.status);
    if (form.status === 1) {
      setDisabled(true);
      return;
    }
    if (auxStep === 2) {
      arrayValidation = ['employerName', 'occupation', 'occupationStatus', 'income', 'monthsAtCurrentAddress',
        'housingStatus', 'mortgageOrRentAmount', 'incomeFrequency', 'maritalStatus', 'spouseData', 'spouseAgree',
        'workPhone', 'totalMonthsEmployed'];
      for (const item of arrayValidation) {
        auxErrors = validateForm(auxForm[item], item, auxErrors);
      }
      console.log(auxErrors);
      setDisabled(false);
      for (const item of arrayValidation) {
        if (auxErrors[item] != null) {
          setDisabled(true);
          break;
        }
      }
      return auxErrors;
    }
  }

  const validateForm = (value, label, errors) => {
    switch (label) {
      case 'employerName':
      case 'occupation':
        errors[label] = !value || value.length < 3 ? "The field must be at least 3 characters long" : null;
        break;
      case 'occupationStatus':
      case 'housingStatus':
      case 'coApplicantId':
      case 'guarantorId':
      case 'driverId':
      case 'incomeFrequency':
        errors[label] = (value === '' || !value) ? 'The field must be required' : null;
        break;
      case 'monthsAtCurrentAddress':
        errors[label] = validateMonthsAtCurrentAddress(value, user.customer.birthDate);
        break;
      case 'mortgageOrRentAmount':
      case 'income':
        errors[label] = (!value && value !== 0) || isNaN(value) || value < 0 || value > 99999 ? 'The field must be a positive number maximum of 99999' : null;
        break;
      case 'spouseAgree':
        errors[label] = !value && form.maritalStatus === 'Married' && checkPropertyStates(form.customerState) ? 'The field must be required' : null;
        break;
      case 'maritalStatus':
        errors[label] = (value === '' || !value) ? 'The field must be required' : null;
        errors["spouseData"] = null;
        if (value === 'Married') {
          errors["spouseAgree"] = null;
          if (!form.spouseAgree && checkPropertyStates(form.customerState)) {
            errors["spouseAgree"] = 'The field must be required';
          }
          if (form.customerState === 'WI') {
            errors["spouseData"] = !validateSpouse(form.spouse) ? 'The field must be required' : null;
          }
        }
        break;
      case "workPhone":
        errors[label] = null;
        if (form.occupationStatus !== 'Unemployed') {
          errors[label] = !validPhoneNumber(value) ? 'The field must be 10 digits' : !validCodeNumber(value) ? 'Invalid phone number' : null;
        }
        break;
      case "totalMonthsEmployed":
        errors[label] = validateTotalMonthsEmployed(value, form.occupationStatus, user.customer.birthDate);
        break;
      default:
        break;
    }
    return errors;
  }

  // --------------------------------------------send data to backend
  const sendData = (type) => {
    console.log(form);
    let auxForm = { ...form };
    auxForm = { ...removeElement(auxForm, 'coApplicantSkip', 'coApplicant', 'coApplicantId') };
    // auxForm = { ...removeElement(auxForm, 'guarantorSkip', 'guarantor', 'guarantorId') };
    // auxForm = { ...removeElement(auxForm, 'driverSkip', 'driver', 'driverId') };
    auxForm['type'] = type;
    auxForm['status'] = type === 'save' ? 0 : 1;
    auxForm['quot_id'] = quote.id;
    if (form.maritalStatus !== 'Married' && form.customerState !== 'WI') {
      delete auxForm.spouse;
    }
    if (!checkPropertyStates(form.customerState) || form.maritalStatus !== 'Married') {
      auxForm.spouseAgree = false;
    }
    setIsLoading(true);
    postLoanApplication(auxForm).then(response => {
      setIsLoading(false);
      setModal({ status: 201, message: "Loan Application was " + (type === 'save' ? 'saved' : 'completed'), response: response.data });
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } if (error.response.status === 400) {
          console.log(JSON.stringify(error.response.data));
          setModal({ status: 406, message: "Sorry. It was not possible to complete the process, please verify your information" });
        } else if (error.response.status === 404) {
          setModal({ status: 404, message: "Sorry. Element was not found" });
        } else if (error.response.status === 422) {
          setModal({ status: 422, message: "Sorry. Fields are missing to save the information" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }


  const removeElement = (auxForm, flag, element, id) => {
    if (auxForm[flag]) {
      delete auxForm[id];
      delete auxForm[element]
    } else {
      if (newOne[element]) {
        delete auxForm[id];
      } else {
        delete auxForm[element]
      }
    }
    delete auxForm[flag];
    return auxForm;
  }

  // ---------------------------- add new co-applicant to drop-down selects and select it
  const finalStepCoApplicant = (response, action, type) => {
    let auxAllCoApplicants = { ...allCoApplicants }, key = type + "s";
    let array = [...auxAllCoApplicants[key]];
    array.unshift({ value: response.id, text: response.firstName + " " + response.lastName });
    auxAllCoApplicants[key] = array;
    setAllCoApplicants({ ...auxAllCoApplicants });
    onEditInput(type + "Id", response.id);
    showNewOne(type, false);
    setStep(step => step + 1);
  }


  // ------------------------------------------ skip button
  const skip = () => {
    let auxForm = { ...form };
    let flag = 'coApplicantSkip';
    if (step === 2) {
      flag = 'guarantorSkip';
    } else if (step === 3) {
      flag = 'driverSkip';
    }
    auxForm[flag] = true;
    setStep(step => step + 1)
    setForm(auxForm);
    validation(auxForm, { ...errors }, step + 1);
  }

  // ------------------------------------------- prevButton
  const prevButton = () => {
    setStep(step => step - 1);
    validation(form, { ...errors }, step - 1);
  }

  // ------------------------------------------- next button
  const nextButton = () => {
    setStep(step => step + 1);
    validation(form, { ...errors }, step + 1);
  }

  // ------------------------------------------- show new one
  const showNewOne = (label, flag) => {
    console.log(label, flag);
    let auxNewOne = { ...newOne };
    auxNewOne[label] = flag;
    if (!flag) {
      validation(form, { ...errors }, step, auxNewOne);
    }
    setNewOne({ ...auxNewOne })
  }

  // -------------------------------------------- open modal
  const openModal = () => {
    setIsLoading(true);
    getCoApplicants().then(response => {
      setIsLoading(false);
      let data = response.data;
      let auxAllCoApplicants = { ...allCoApplicants };
      auxAllCoApplicants.coApplicants = data.filter(item => item.type === 'coApplicant').map(item => { return { value: item.id, text: item.firstName + " " + item.lastName } });
      auxAllCoApplicants.coApplicants.unshift({ value: "", text: "" });
      auxAllCoApplicants.guarantors = data.filter(item => item.type === 'guarantor').map(item => { return { value: item.id, text: item.firstName + " " + item.lastName } });
      auxAllCoApplicants.guarantors.unshift({ value: "", text: "" });
      auxAllCoApplicants.drivers = data.filter(item => item.type === 'driver').map(item => { return { value: item.id, text: item.firstName + " " + item.lastName } });
      auxAllCoApplicants.drivers.unshift({ value: "", text: "" });
      setAllCoApplicants({ ...auxAllCoApplicants });
      setErrors(defualtForm);
      setStep(0);
      setOpen(true)
      let auxNewOne = { ...newOne };
      auxNewOne.coApplicant = auxAllCoApplicants.coApplicants.length === 0 ? true : false;
      auxNewOne.guarantor = auxAllCoApplicants.guarantors.length === 0 ? true : false;
      auxNewOne.driver = auxAllCoApplicants.drivers.length === 0 ? true : false;
      let auxForm = { ...defualtForm };
      if (user.customer.customerData) { // if the loan application is new or is edited, we display last edited customer data
        let customerData = user.customer.customerData;
        delete customerData.id;
        auxForm = { ...auxForm, ...customerData };
      }
      auxForm.customerState = user.customer.state;
      if (user.customer.spouse) { // if the loan application is new or is edited, we display last edited customer data
        let spouse = user.customer.spouse;
        auxForm.spouse = spouse;
        if (user.customer.customerData && user.customer.customerData.maritalStatus !== 'Married') {
          auxForm.spouse = null;
        }
      }
      if (quote.loan) {
        auxForm.coApplicantId = quote.loan.co_applicant_id;
        auxForm.guarantorId = quote.loan.guarantor_id;
        auxForm.driverId = quote.loan.driver_id;
        auxForm.status = quote.loan.status;
        auxForm.spouseAgree = quote.loan.spouseAgree;
        auxForm.id = quote.loan.id;
        if (quote.loan.status >= 1) {
          setFormDisabled(true);
          auxNewOne.coApplicant = false;
          auxNewOne.guarantor = false;
          auxNewOne.driver = false;
          auxForm.maritalStatus = quote.loan.maritalStatus;
          auxForm.spouseName = quote.loan.spouseName;
          auxForm.driverLicense = quote.loan.driverLicense;
          auxForm.driverLicenseState = quote.loan.driverLicenseState;
          setDisabled(true);
          // if the loan application is completed, we display the customer data saved in the loan application
          auxForm = { ...auxForm, ...quote.loan };
        }

      }
      console.log(auxForm);
      setNewOne(auxNewOne);
      setForm(auxForm);
      if (auxForm.status === 0) {
        let auxErrors = { ...errors };
        auxErrors = validation(auxForm, { ...errors }, 1);
        setErrors({ ...auxErrors });
      }
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }

  // ---------------------------- close alert modal
  const closeAlert = () => {
    if (modal.status === 201) {
      let auxCustomer = modal.response;
      auxCustomer.spouse = form.spouse;
      console.log(auxCustomer);
      props.updateQuote(auxCustomer);
      setOpen(false);
    }
    setModal({});
  }

  return (
    <div>
      <Modal open={open} closeOnEscape={false} closeOnDimmerClick={false}
        trigger={
          <span className={'detailsQuote'} onClick={openModal}>
            Loan Application
          </span>
        }>
        <Modal.Header>
          Loan Application
        </Modal.Header>
        <Modal.Content className='quote-approval'>
          {
            form.status === 1 && (
              <Header size="large" color='green'>Loan Application is completed</Header>
            )
          }
          <div className='ui-computer'>
            <StepsLoanApplication step={step} />
          </div>
          <Form>
            <div className={step !== 0 ? 'd-none' : 'padding-top'}>
              <Header size="medium">Personal Information</Header>
              <PersonalInformation formDisabled={formDisabled} errors={errors} form={form} onEditInput={onEditInput} nextButton={nextButton} />
            </div>
            <div className={step !== 1 ? 'd-none' : 'padding-top'}>
              <SelectCoApplicant form={form} options={allCoApplicants.coApplicants} newOne={newOne}
                type={"coApplicant"} formDisabled={formDisabled} showNewOne={showNewOne} onEditInput={onEditInput}
                finalStepCoApplicant={finalStepCoApplicant} prevButton={prevButton}
              />
            </div>
            {/* {
              step === 2 && (
                <SelectCoApplicant form={form} options={allCoApplicants.guarantors} newOne={newOne}
                  type={"guarantor"} formDisabled={formDisabled} showNewOne={showNewOne} onEditInput={onEditInput}
                  finalStepCoApplicant={finalStepCoApplicant}
                />
              )
            } */}
            {/* {
              step === 3 && (
                <SelectCoApplicant form={form} options={allCoApplicants.drivers} newOne={newOne}
                  type={"driver"} formDisabled={formDisabled} showNewOne={showNewOne} onEditInput={onEditInput}
                  finalStepCoApplicant={finalStepCoApplicant}
                />
              )
            } */}
            <div className={step !== 2 ? 'd-none final-step' : 'final-step'}>
              <Header size='large'>
                {
                  form.status === 0 ?
                    'We are almost finished, you can click on "Save" to edit the process after or click on "Complete" to finish the process.'
                    :
                    'You have already completed the process. The loan is waiting to be approved.'
                }
              </Header>
            </div>
            {
              // ((step === 1 && !newOne.coApplicant) || (step === 2 && !newOne.guarantor) || (step === 3 && !newOne.driver)) && (
              (step === 1 && !newOne.coApplicant) && (
                <div style={{ marginTop: "1em" }}>
                  <Button primary onClick={prevButton} type="button">Previous</Button>
                  <Button primary onClick={nextButton} type="button">Next</Button>
                </div>
              )
            }
            {
              // ((step === 1 || step === 2 || step === 3) && form.status === 0) && (
              (step === 1 && form.status === 0) && (
                <div style={{ display: "flex", justifyContent: "end", width: "100%", marginBottom: "1em" }}>
                  <Button secondary onClick={skip} type="button">Skip</Button>
                </div>
              )
            }
            {
              step === 2 && (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <Button primary onClick={prevButton} type="button">Previous</Button>
                  <div>
                    {
                      form.status === 0 && (
                        <Button primary onClick={() => sendData('save')}>Save</Button>
                      )
                    }
                    <Button positive onClick={() => sendData('complete')} disabled={disabled}>Complete</Button>
                  </div>
                </div>
              )
            }
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)} >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={closeAlert} />
      }
    </div>
  );
};