import React, { useContext } from "react";
import { useMediaPredicate } from "react-media-hook";

import InfoRecord from "../../InfoRecord";
import Title from "../../SectionTitle";

import { AgreementCtx } from "../../context";

import "./Customer.sass";

const Customer = () => {
  const { data } = useContext(AgreementCtx);
  const is768 = useMediaPredicate("(max-width: 768px)");
  const is576 = useMediaPredicate("(max-width: 576px)");

  return (
    <div className={"customerWrapper"}>
      <Title size={is768 ? 14 : 16}>Customer Information</Title>

      <div className={"detailsBox"}>
        {is576 ? (
          <>
            <InfoRecord label={"Buyer"} value={data.buyer_name} noMargin />

            <InfoRecord label={"Address"} value={data.buyer_address} noMargin />

            <InfoRecord label={"Phone"} value={data.buyer_phone} noMargin />

            <InfoRecord label={"Email"} value={data.buyer_email} />

            <InfoRecord label={"CoBuyer"} value={data.co_buyer_name} noMargin />

            <InfoRecord
              label={"Address"}
              value={data.co_buyer_address}
              noMargin
            />

            <InfoRecord label={"Phone"} value={data.co_buyer_phone} noMargin />

            <InfoRecord label={"Email"} value={data.co_buyer_email} />
          </>
        ) : (
          <>
            <InfoRecord label={"Buyer"} value={data.buyer_name} noMargin />

            <InfoRecord label={"CoBuyer"} value={data.co_buyer_name} noMargin />

            <InfoRecord label={"Address"} value={data.buyer_address} noMargin />

            <InfoRecord
              label={"Address"}
              value={data.co_buyer_address}
              noMargin
            />

            <InfoRecord label={"Phone"} value={data.buyer_phone} noMargin />

            <InfoRecord label={"Phone"} value={data.co_buyer_phone} noMargin />

            <InfoRecord label={"Email"} value={data.buyer_email} />

            <InfoRecord label={"Email"} value={data.co_buyer_email} />
          </>
        )}
      </div>

      {/* <div className={"detailsWrapper"}>
        <ul className={"list"}>
          <InfoRecord label={"Buyer"} value={data.buyer_name} />

          <InfoRecord label={"Address"} value={data.buyer_address} />

          <InfoRecord label={"Phone"} value={data.buyer_phone} />

          <InfoRecord label={"Email"} value={data.buyer_email} />
        </ul>

        <ul className={"list"}>
          <InfoRecord label={"CoBuyer"} value={data.co_buyer_name} />

          <InfoRecord label={"Address"} value={data.co_buyer_address} />

          <InfoRecord label={"Phone"} value={data.co_buyer_phone} />

          <InfoRecord label={"Email"} value={data.co_buyer_email} />
        </ul>
      </div> */}
    </div>
  );
};

export default Customer;
