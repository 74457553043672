import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Dimmer, Form, Header, Icon, Input, Label, Loader, Step, Progress } from 'semantic-ui-react';
import { validAddress, validateMonthsAtCurrentAddress, validateSpouse, validateSSN, validateTotalMonthsEmployed, validCodeNumber, validPhoneNumber, validZipCode } from '../../helpers/general';
import { checkPropertyStates, zipCodesStates } from '../../helpers/usaStates';
import Address from '../address/Address';
import { AddressInformation } from '../address/AddressInformation';
import ModalComponent from '../alertModal/modal';
import { CurrentEmployment } from '../currentEmployment/CurrentEmployment';
import { MaritalStatus } from '../quotesApproval/MaritalStatus';
import { postCoApplicant, putCoApplicant } from '../requests';

const defualtForm = {
  firstName: null, lastName: null, ssn: null, phoneNumber: null, monthsAtCurrentAddress: null, housingStatus: null, income: null,
  mortgageOrRentAmount: null, employerName: null, occupation: null, occupationStatus: null, address: null, city: null,
  zip_code: null, state: null, birthDate: null, incomeFrequency: null, status: 0, workPhone: null, totalMonthsEmployed: null
}

export const CoApplicantForm = (props) => {
  const { coApplicant, applyProcess, type } = props;
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ ...defualtForm, birthDate: moment().subtract(1, 'days').format("MM-DD-YYYY") })
  const [errors, setErrors] = useState(defualtForm)
  const [disabled, setDisabled] = useState(true);
  const [step, setStep] = useState(0);


  // ---------------------------- update form and validate -----------------
  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    let auxErrors = { ...errors };
    if (label === 'fullAddress') {
      auxForm.address = value.address;
      auxForm.state = value.state;
      auxForm.city = value.city;
      auxForm.zip_code = value.zip_code;
      auxErrors = validateForm(value.address, "address", auxErrors, auxForm);
      auxErrors = validateForm(value.state, "state", auxErrors, auxForm);
      auxErrors = validateForm(value.city, "city", auxErrors, auxForm);
      auxErrors = validateForm(value.zip_code, "zip_code", auxErrors, auxForm);
    } else if (label === 'birthDate') {
      auxForm[label] = value;
      auxErrors = validateForm(value, "birthDate", auxErrors, auxForm);
      auxErrors = validateForm(auxForm.monthsAtCurrentAddress, "monthsAtCurrentAddress", auxErrors, auxForm);
      auxErrors = validateForm(auxForm.totalMonthsEmployed, "totalMonthsEmployed", auxErrors, auxForm);
    }else {
      auxForm[label] = value;
      auxErrors = validateForm(value, label, auxErrors, auxForm);
    }
    setForm({ ...auxForm });
    setErrors({ ...auxErrors });
    validation(auxForm, auxErrors);
  }

  const validation = (auxForm, auxErrors, auxStep = step) => {
    let arrayValidation = [];
    arrayValidation = ['firstName', 'lastName', 'birthDate', 'ssn', 'phoneNumber',
      'address', 'state', 'city', 'zip_code', 'monthsAtCurrentAddress', 'housingStatus', 'mortgageOrRentAmount',
      'employerName', 'occupation', 'occupationStatus', 'income', 'incomeFrequency', 'maritalStatus', 'spouseData', 'spouseAgree', 'spouse', 
      'workPhone', 'totalMonthsEmployed'
    ]
    for (const item of arrayValidation) {
      auxErrors = validateForm(auxForm[item], item, auxErrors, auxForm);
    }
    setDisabled(false);
    for (const item of arrayValidation) {
      if (auxErrors[item] != null) {
        setDisabled(true);
      }
    }
  }

  const validateForm = (value, label, errors, form) => {
    switch (label) {
      case 'firstName':
      case 'lastName':
      case 'city':
      case 'employerName':
      case 'occupation':
        errors[label] = !value || value.length < 3 ? "The field must be at least 3 characters long" : null;
        break;
      case 'address':
        errors[label] = validAddress(value);
        break;
      case 'state':
        errors[label] = (value === '' || !value) ? 'The field must be required' : null;
        errors['zip_code'] = zipCodesStates(value, form.zip_code);
        break;
      case 'occupationStatus':
      case 'housingStatus':
      case 'incomeFrequency':
        errors[label] = (value === '' || !value) ? 'The field must be required' : null;
        break;
      case 'zip_code':
        errors[label] = !value || value.split(" ").join("") === "" || !validZipCode(value) ? 'The field must be 5 characters long' : zipCodesStates(form.state, value);
        break;
      case 'ssn':
        errors[label] = !validateSSN(value) ? 'The field must be 9 digits.' : null;
        break;
      case 'phoneNumber':
        errors[label] = !validPhoneNumber(value) ? 'The field must be 10 digits' : !validCodeNumber(value) ? 'Invalid phone number' : null;
        break;
      case 'monthsAtCurrentAddress':
        errors[label] = validateMonthsAtCurrentAddress(value,moment(form.birthDate, "MM-DD-YYYY").format("YYYY-MM-DD"));
        break;
      case 'mortgageOrRentAmount':
      case 'income':
        errors[label] = (!value && value !== 0) || isNaN(value) || value < 0 || value > 99999 ? 'The field must be a positive number maximum of 99999' : null;
        break;
      case 'birthDate':
        errors.birthDate = moment(value, 'MM-DD-YYYY', true).isValid() ? null : 'The field must be a date';
        break;
      case 'spouseAgree':
        errors[label] = !value && form.maritalStatus === 'Married' && checkPropertyStates(form.state) ? 'The field must be required' : null;
        break;
      case 'spouse':
        errors["spouseData"] = null;
        if (form.state === 'WI' && form.maritalStatus === 'Married') {
          errors["spouseData"] = !validateSpouse(value) ? 'The field must be required' : null;
        }
        break;
      case 'maritalStatus':
        errors["spouseAgree"] = null;
        errors["spouseData"] = null;
        errors[label] = (value === '' || !value) ? 'The field must be required' : null;
        if (value === 'Married') {
          errors["spouseAgree"] = null;
          if (!form.spouseAgree && checkPropertyStates(form.state)) {
            errors["spouseAgree"] = 'The field must be required';
          }
          if (form.state === 'WI') {
            errors["spouseData"] = !validateSpouse(form.spouse) ? 'The field must be required' : null;
          }
        }
        break;
      case "workPhone":
        errors[label] = null;
        if (form.occupationStatus !== 'Unemployed') {
          errors[label] = !validPhoneNumber(value) ? 'The field must be 10 digits' : !validCodeNumber(value) ? 'Invalid phone number' : null;
        }
        break;
      case "totalMonthsEmployed":
        errors[label] = validateTotalMonthsEmployed(value, form.occupationStatus, moment(form.birthDate, "MM-DD-YYYY").format("YYYY-MM-DD"));
        break;
      default:
        break;
    }
    return errors;
  }

  // ------------------------------------------- send data to backend
  const sendData = () => {
    let auxForm = { ...form };
    auxForm['type'] = type;
    if (form.maritalStatus !== 'Married' && form.state !== 'WI') {
      delete auxForm.spouse
    }
    if (!checkPropertyStates(form.state) || form.maritalStatus !== 'Married') {
      auxForm.spouseAgree = false;
    }
    const successResponse = (response) => {
      setIsLoading(false);
      if (applyProcess) {
        props.finalStep(response.data, 'add', type);
      } else {
        setModal({
          status: 201,
          message: "The " + (type === 'guarantor' ? 'guarantor' : type === 'driver' ? 'driver' : 'co-Applicant') + " was "
            + (!coApplicant ? "created." : "updated."), response: coApplicant && coApplicant.id ? auxForm : response.data
        });
      }
    };
    const errorResponse = (error) => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 404, message: "Sorry. Element was not found" });
        } else if (error.response.status === 422) {
          setModal({ status: 422, message: "Sorry. Fields are missing to save the information" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
        }
      }
    };
    setIsLoading(true);
    if (coApplicant && coApplicant.id) {
      putCoApplicant(auxForm).then(response => {
        successResponse(response);
      }).catch(error => {
        errorResponse(error);
      });
    } else {
      postCoApplicant(auxForm).then(response => {
        successResponse(response);
      }).catch(error => {
        errorResponse(error);
      });
    }
  }

  const closeAlert = () => {
    if (modal.status === 201) {
      props.finalStep(modal.response, coApplicant && coApplicant.id ? 'edit' : 'add', type);
    }
    setModal({});
  }

  const nextButton = () => {
    let auxStep = step + 1;
    setStep(auxStep);
    validation({ ...form }, { ...errors }, auxStep);
  }

  useEffect(() => {
    setErrors(defualtForm);
    setStep(0);
    if (coApplicant) {
      if (coApplicant.maritalStatus !== 'Married') {
        coApplicant.spouse = null;
      }
      setForm({ ...coApplicant, status: 0 });
      validation(coApplicant, defualtForm);
    } else {
      setForm({ ...defualtForm, birthDate: moment().subtract(1, 'days').format("MM-DD-YYYY") });
    }
  }, [])

  return (
    <div style={{ marginTop: "1rem" }}>
      <div className='ui-computer'>
        <Step.Group style={{ width: "100%", marginBottom: "1em" }} >
          <Step active={step === 0}>
            <Step.Content>
              <Step.Title>Personal Information</Step.Title>
            </Step.Content>
          </Step>
          <Step active={step === 1}>
            <Step.Content>
              <Step.Title>Address</Step.Title>
            </Step.Content>
          </Step>
          <Step active={step === 2}>
            <Step.Content>
              <Step.Title>Current Job</Step.Title>
            </Step.Content>
          </Step>
          <Step active={step === 3}>
            <Step.Content>
              <Step.Title>Marital Status</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      </div>
      <Form style={{ width: "100%" }}>
        {
          step === 0 && (
            <div>
              <Header size="medium">What is the personal information of the {type === 'coApplicant' ? 'co-applicant' : type === 'guarantor' ? 'guarantor' : 'driver'}?</Header>
              <Form.Group>
                <Form.Input
                  value={form.firstName}
                  label={<label>First Name <span className="required-inut">*</span></label>}
                  placeholder={'First Name'}
                  onChange={(e) => onEditInput('firstName', e.target.value)}
                  width={8}
                  fluid
                  maxLength={15}
                  error={form.firstName || errors.firstName ? errors.firstName : null}
                />
                <Form.Input
                  value={form.lastName}
                  label={<label>Last Name <span className="required-inut">*</span></label>}
                  placeholder={'Last Name'}
                  onChange={(e) => onEditInput('lastName', e.target.value)}
                  width={8}
                  fluid
                  maxLength={25}
                  error={form.lastName || errors.lastName ? errors.lastName : null}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field width={5}>
                  <label>Birthday <span className="required-inut">*</span></label>
                  <DateInput
                    dateFormat="MM-DD-YYYY"
                    maxDate={moment().subtract(1, 'days').format("MM-DD-YYYY")}
                    minDate={moment().subtract(100, 'years').format("MM-DD-YYYY")}
                    name="date"
                    className="readonly"
                    placeholder="Date"
                    value={form.birthDate}
                    iconPosition="left"
                    onChange={(event, { name, value }) => {
                      onEditInput('birthDate', value);
                    }}
                    error={form.birthDate || errors.birthDate ? errors.birthDate : null}
                    animation='none'
                  />
                </Form.Field>
                <Form.Input
                  id={'ssn'}
                  value={form.ssn}
                  label={<label>SSN <span className="required-inut">*</span></label>}
                  placeholder={'SSN'}
                  onChange={(e) => onEditInput('ssn', e.target.value)}
                  width={5}
                  fluid
                  error={form.ssn || errors.ssn ? errors.ssn : null}
                  maxLength="100"
                />
                <Form.Field width={6}>
                  <label>Phone Number <span className="required-inut">*</span></label>
                  <Input
                    label='+1'
                    value={form.phoneNumber}
                    placeholder='Phone Number'
                    onChange={(e) => onEditInput('phoneNumber', e.target.value)}
                  />
                  {
                    (form.phoneNumber && errors.phoneNumber) &&
                    <Label basic color='red' pointing>
                      {errors.phoneNumber}
                    </Label>
                  }
                </Form.Field>
              </Form.Group>
            </div>
          )
        }
        {
          step === 1 && (
            <div>
              <Header size="medium">Where does the {type === 'coApplicant' ? 'co-applicant' : type === 'guarantor' ? 'guarantor' : 'driver'} live?</Header>
              <Address address={form} onChangeState={onEditInput} msnErrors={errors} />
              <AddressInformation form={form} errors={errors} onEditInput={onEditInput} />
            </div>
          )
        }
        {
          step === 2 && (
            <div>
              <Header size="medium">What is the current job of the {type === 'coApplicant' ? 'co-applicant' : type === 'guarantor' ? 'guarantor' : 'driver'}?</Header>
              <CurrentEmployment onEditInput={onEditInput} form={form} errors={errors} />
            </div>
          )
        }
        {
          step === 3 && (
            <div>
              <Header size="medium" className='ui-mobile'>Marital Status</Header>
              <MaritalStatus form={form} errors={errors} onEditInput={onEditInput} loanProcess={false} />
            </div>
          )
        }
      </Form>
      <Progress color='blue' className='ui-mobile' percent={((step) * 33.33333)} />
      {
        step === 0 && applyProcess && (
          <Button primary onClick={props.prev}>Previous</Button>
        )
      }
      {
        step > 0 && (
          <Button primary onClick={() => setStep(step => step - 1)}>Previous</Button>
        )
      }
      {
        step < 3 && (
          <Button primary onClick={nextButton} >Next</Button>
        )
      }
      {
        step === 3 && (
          <Button color='green' onClick={sendData} disabled={disabled}>
            {applyProcess ? type === 'coApplicant' ? 'Add co-applicant' : type === 'guarantor' ? 'Add guarantor' : 'Add driver' : 'Accept'}
          </Button>
        )
      }
      {
        isLoading &&
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      }
      {
        modal.message &&
        <ModalComponent hideModal={closeAlert} modal={modal} />
      }
    </div>
  );
};