import React, { useEffect, useState } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import Dwolla from './Dwolla';

// IMPORT CSS STYLES
import './BankAccount.sass';
import { PaymentPlans } from '../paymentPlans/PaymentPlans';

const BankAccount = ({ user, updateCustomerData }) => {

  useEffect(() => {
  }, [user]);

  return (
    <div className="bankAccount">
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <Grid className="box" style={{ position: "relative" }}>
            <Dwolla {...user.customer} updateCustomerData={updateCustomerData} />
          </Grid>
        </Grid.Column>
        {/* <Grid.Column mobile={16} tablet={16} computer={8} >
          <Grid className="box">
          </Grid>
        </Grid.Column> */}
        <Grid.Column mobile={16} tablet={16} computer={16} >
          {
            user.customer?.dwolla_id && (
              <Grid className="box">
                <PaymentPlans customer={user} type={'customer'}/>
              </Grid>
            )

          }
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default BankAccount;