import React, { useState } from "react";

import CropModal from "./CropModal";
import Input from "../../../global/Input";

import "./ImageUpload.sass";

const ImageUpload = ({ image, title, onSuccess }) => {
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOnUploadPhoto = (e) => {
    e.persist();

    const _file = e.target.files[0];

    if (!_file) return;

    setOpen(true);

    setFile(_file);
  };

  return (
    <div className={"imageUploadWrapper"}>
      <label>{title}</label>

      <img src={image} className={"imageUploadImage"} />

      <Input
        type={"file"}
        accept={".webp, .svg, .png, .jpeg, .gif, .avif, .apng, .jpg"}
        onChange={handleOnUploadPhoto}
        miltiple={false}
      />

      <CropModal
        open={open}
        file={file && URL.createObjectURL(file)}
        onClose={() => setOpen(false)}
        onSuccess={onSuccess}
      />
    </div>
  );
};

export default ImageUpload;
