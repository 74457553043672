import * as yup from "yup";
import _ from "lodash";
import moment from "moment";

export const schema = yup.object().shape({
  title: yup.string().trim().required("This field is required"),
  description: yup.string().trim().required("This field is required"),
  value: yup
    .string()
    .trim()
    .test(
      "is-correct-number",
      "Amount field should be a correct number",
      (value) => {
        const post = value?.split(".")[1] || "";

        const valueAsANumber = Number(value);

        const isNaN = _.isNaN(valueAsANumber);
        const isNegativeNumber = _.isEqual(_.head(value), "-");
        const isIncorrectDecimalFormat = _.isEqual(_.last(value), ".");
        const isDecimalPartTooLong = post.length > 2;

        if (
          isNaN ||
          isIncorrectDecimalFormat ||
          isDecimalPartTooLong ||
          isNegativeNumber
        )
          return false;

        return true;
      }
    )
    .required("This field is required"),
  startDate: yup
    .string()
    .required("This field is required")
    .test(
      "is-before-end-date",
      "Start date must be before end date",
      (value, context) => {
        const start = moment(value, "DD-MM-YYYY");
        const end = context.parent.endDate;

        if (_.isUndefined(end)) return true;

        return start.isSameOrBefore(moment(end, "DD-MM-YYYY"));
      }
    ),
  endDate: yup.string().required("This field is required"),
});

export const valueOptions = [
  { key: "usd", text: "$", value: "usd" },
  { key: "percent", text: "%", value: "percent" },
];
