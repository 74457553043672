import React from "react";

import { Form } from "semantic-ui-react";
import Input from "../../../global/Input";

const  Contact = ({ state, onEditInput }) => {
  return (
    <>
      <Form.Group>
        <Form.Field width={8}>
          <label>
            Name <span className="required-inut">*</span>
          </label>

          <Input
            value={state.name}
            placeholder={"Name"}
            onChange={(e) => onEditInput("name", e.target.value)}
            fluid
            error={
              state.msnErrors.name.length > 0 ? state.msnErrors.name : null
            }
            maxLength="50"
            disabled={state.flagEdit}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>
            Dealer ID <span className="required-inut">*</span>
          </label>

          <Input
            value={state.dealer_id}
            placeholder={"Dealer ID"}
            onChange={(e) => onEditInput("dealer_id", e.target.value)}
            fluid
            error={
              state.msnErrors.dealer_id.length > 0
                ? state.msnErrors.dealer_id
                : null
            }
            maxLength="50"
            disabled={state.flagEdit}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={8}>
          <label>
            Email <span className="required-inut">*</span>
          </label>

          <Input
            value={state.email}
            placeholder={"Email"}
            onChange={(e) => onEditInput("email", e.target.value)}
            fluid
            error={
              state.msnErrors.email.length > 0 ? state.msnErrors.email : null
            }
            maxLength="50"
            disabled={state.flagEdit}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>
            Partner dealer ID <span className="required-inut">*</span>
          </label>

          <Input
            value={state.partnerdealerID}
            placeholder={"Partner dealer ID"}
            onChange={(e) => onEditInput("partnerdealerID", e.target.value)}
            fluid
            error={
              state.msnErrors.partnerdealerID
                ? state.msnErrors.partnerdealerID
                : null
            }
            maxLength="50"
            disabled={state.flagEdit}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={16}>
          <label>
            Phone Number<span className="required-inut"> *</span>
          </label>

          <Input
            value={state.phone_number}
            label="+1"
            placeholder="Phone Number"
            onChange={(e) => onEditInput("phone_number", e.target.value)}
            maxLength="15"
            min="12"
            disabled={state.flagEdit}
            error={
              state.msnErrors.phone_number.length > 0 &&
              state.msnErrors.phone_number
            }
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export default Contact;
