import React from "react";
import cn from "classnames";

import "./ListItem.sass";

const ListItem = ({ children, className, onClick }) => {
  return (
    <li className={cn("listItemWrapper", className)} onClick={onClick}>
      {children}
    </li>
  );
};

export default ListItem;
