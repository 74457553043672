import React, { useState, useEffect } from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Thumbs } from "swiper";
import _ from "lodash";
import { useMediaPredicate } from "react-media-hook";

import SwiperCard from "./SwiperCard";
import SwiperButton from "./SwiperCard/SwiperButton";

import "./Swiper.sass";
import "swiper/swiper.scss";

const Swiper = ({
  slides,
  selectedPlan,
  selectedProducts,
  onSelect,
  onChangeProductsList,
}) => {
  const [items, setItems] = useState([]);

  const is768 = useMediaPredicate("(max-width: 768px)");

  useEffect(() => {
    let dupedSlides = [];

    switch (slides.length) {
      case 0:
        dupedSlides = [];
        break;

      case 1:
        dupedSlides = slides;
        break;

      case 2:
        dupedSlides = _.fill(Array(4), slides).flat(1);
        break;

      case 3:
        dupedSlides = _.fill(Array(3), slides).flat(1);
        break;

      default:
        dupedSlides = _.fill(Array(2), slides).flat(1);
    }

    setItems(dupedSlides);
  }, [slides]);

  return (
    <div className={"swiperAligner"}>
      <div className={"swiperWrapper"}>
        <SwiperComponent
          loop={true}
          slidesPerView={is768 || slides.length === 1 ? 1 : 3}
          centeredSlides={true}
          grabCursor={true}
          effect={"coverflow"}
          coverflowEffect={{
            rotate: 0,
            slideShadows: false,
          }}
          modules={[EffectCoverflow, Thumbs]}
          onAfterInit={(swiper) => {
            if (selectedPlan) {
              const index = slides.findIndex(
                (item) => item.id === selectedPlan
              );

              swiper.slideTo(index);
            } else {
              swiper.slideTo(1);
            }
          }}
        >
          {is768 && <SwiperButton action={"prev"} />}

          {items.map((item, index) => {
            const products = selectedProducts[item.id];

            return (
              <SwiperSlide
                key={`card-${item.id}-${index}`}
                className={"slideWrapper"}
              >
                {({ isActive, isNext, isPrev }) => (
                  <SwiperCard
                    isActive={isActive}
                    item={item}
                    selectedProducts={products}
                    isPrev={isPrev}
                    isNext={isNext}
                    selectedPlan={selectedPlan}
                    isSelected={item.id === selectedPlan}
                    onClick={onSelect}
                    onChangeProductsList={onChangeProductsList}
                  />
                )}
              </SwiperSlide>
            );
          })}

          {is768 && <SwiperButton action={"next"} />}
        </SwiperComponent>
      </div>
    </div>
  );
};

export default Swiper;
