import React from "react";
import cn from "classnames";
import _ from "lodash";

import { ReactComponent as CheckIcon } from "../../../../images/checkIcon.svg";

import "./Step.sass";

const Step = ({
  children,
  index,
  isActive,
  isLast,
  isPrevious,
  isClickable,
  onClick,
  theme,
}) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    isClickable && onClick();
  };

  return (
    <button
      onClick={handleOnClick}
      className={cn("stepWrapper", isLast && "stepWrapperShort")}
      style={{
        "--nav-color": theme?.typography.secondary,
      }}
    >
      <div
        className={cn("navStepContent", isClickable && "stepWrapperClickable")}
      >
        <div className={"stepIndexWrapper"}>
          <div className={"stepIndexHelper"} />

          <div className={"stepIndicatorWrapper"}>
            <div
              className={cn(
                "stepIndex",
                isLast && "stepIndexWithoutLine",
                isActive && "activeStep",
                isPrevious && "stepIndexPrev"
              )}
            >
              {isPrevious && !isActive ? <CheckIcon /> : index}
            </div>
          </div>

          <div className={cn("stepIndexHelper", isLast && "overlap")} />
        </div>

        <div className={"stepName"}>
          <span>{children}</span>
        </div>
      </div>
    </button>
  );
};

export default Step;
