import React from "react";
import cn from "classnames";
import { Popup } from "semantic-ui-react";
import { useSwiper } from "swiper/react";
import _ from "lodash";

import Products from "./Products";

import { defaultTheme, useTheme } from "../../../../helpers/theme";

import Full from "../../../../../images/fullProtection.svg";
// import Base from "../../../../../images/baseProtection.svg";
// import Standard from "../../../../../images/standardProtection.svg";
import { ReactComponent as InfoIcon } from "../../../../../images/info-icon.svg";

import {
  formatterPrice,
  calculateTotalPriceOfProtection,
} from "../../../../helpers/general";

import "./SwiperCard.sass";

const SwiperCard = ({
  isActive,
  isPrev,
  isNext,
  isSelected,
  style,
  item,
  selectedProducts,
  selectedPlan,
  onClick,
  onChangeProductsList,
}) => {
  const { theme } = useTheme();

  const isNeighborСard = isPrev || isNext;
  const isVisible = isNeighborСard || isActive;
  const isAllEmpty = Object.values(item.categories).every((term) =>
    _.isEmpty(term.coverage)
  );

  const swiper = useSwiper();

  const { weekly, monthly, total } = calculateTotalPriceOfProtection({
    coverage: selectedProducts,
    categories: item.categories,
  });

  return (
    <div
      className={cn(
        "swiperCardWrapper",
        isVisible && "visible",
        !isActive && "decreased",
        isAllEmpty && "disabledToSelect",
        isSelected && "selected",
        selectedPlan && !isSelected && "notSelected"
      )}
      style={{
        ...style,
        "--card-color":
          theme?.typography?.secondary || defaultTheme.typography.secondary,
      }}
      onClick={() => {
        !isAllEmpty && onClick(isSelected ? null : item.id);

        isNext && swiper.slideNext();
        isPrev && swiper.slidePrev();
      }}
    >
      <div className={"cardContent"}>
        <div className={"cardHeader"}>
          <h3 className={"cardTitle"}>{item.title}</h3>

          <span className={"subtitle"}>coverage</span>

          <Popup
            disabled={isNext || isPrev}
            trigger={<InfoIcon className={"popupIcon"} />}
            position={"top right"}
            content={
              <div className={"popupContent"}>
                <span>
                  To select or disable Protective Coverage Plan, click on the
                  card.
                  <br />
                  Other options may be available. See dealer for details.
                </span>
              </div>
            }
          />
        </div>

        <div className={"cardDivider"} />

        <div className={"imageWrapper"}>
          <img src={Full} />
        </div>

        <Products
          categories={item.categories}
          selectedProducts={selectedProducts}
          packageId={item.id}
          onChangeProductsList={onChangeProductsList}
          isAllEmpty={isAllEmpty}
        />

        <div className={"cardDivider marginTop"} />

        <div className={"bottomActions"}>
          <div className={"paymentValueWrapper"}>
            <div className={"paymentValue monthly"}>
              {`Total price: ${formatterPrice.format(total)}`}
            </div>

            <div className={"paymentValue monthly"}>
              {formatterPrice.format(monthly)}

              <span>/month</span>
            </div>
          </div>

          <div className={"totalPrice"}>
            Only {formatterPrice.format(weekly)}
            <span>/week</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwiperCard;
