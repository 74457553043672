// @flow 
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Account } from './Account';
import { Contact } from './Contact';
import { Dashboard } from './Dashboard';
import { Deals } from './Deals';
import { Loan } from './Loan';

import "./Tutorial.sass"

export const Tutorial = ({ section, step, close }) => {

  return (
    <div className='tutorial'>
      <Button icon onClick={close} style={{zIndex: 50}}>
        <Icon name="close" />
      </Button>
      {
        section === 'dashboard' && (
          <Dashboard step={step} section={section}/>
        )
      }
      {
        section === 'quotes' && (
          <Deals step={step} section={section}/>
        )
      }
      {
        section === 'loan' && (
          <Loan step={step} section={section}/>
        )
      }
      {
        section === 'contact' && (
          <Contact step={step} section={section}/>
        )
      }
      {
        section === 'account' && (
          <Account step={step} section={section}/>
        )
      }
    </div>
  );
};