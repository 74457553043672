// IMPORT LIBRARIES/EXTERNAL COMPONENTS
import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import moment from "moment";

import Dashboard from "./Dashboard";
import Retailers from "./Retailers";

// IMPORT LOCAL COMPONENTS
import UserMenu from "../utilities/menu/menu";
import UserHeader from "../utilities/userHeader/userHeader";
import RetailerDetails from "../utilities/retailerDetails/retailerDetails";
import ListQuotes from "../utilities/ListQuotes/listQuotes";
import ListBuyers from "../utilities/listBuyers/listBuyers";

// IMPORT HELPERS
import { FormatChartData } from "../helpers/general";
import { adminMenu } from "../helpers/menus";

// IMPORT STYLES AND IMAGES
import "./admin.sass";

// REQUEST FUNCTIONS.
import {
  searchQuotes,
  getCustomersAdmin,
  statusQuotes,
  getRetailers,
  adminData,
} from "../utilities/requests";
import { Inventory } from "../utilities/inventory/Inventory";
import { BackMenu } from "../utilities/menu/BackMenu";
import { Videos } from "../utilities/videos/Videos";
import { Accounts } from "../utilities/accounts/Accounts";
import CreditProfileSelector from "./CreditProfileSelector";

class Admin extends Component {
  constructor(props) {
    super(props);

    const today = new Date();
    let start = new Date();
    start = new Date(start.setMonth(start.getMonth() - 6));
    this.state = {
      loading: false,
      activeItem: "dashboard",
      carIdByUrl: null,
      bestRetailers: [],
      chartDateStart: start,
      chartDateEnd: today,
      chartData: null,
      lastQuotes: [],
      retailers: [],
      retailersPage: 1,
      retailersTotal: 1,
      quotes: {
        data: [],
        total: 0,
        last_page: 1,
      },
      page: 1,
      totalPages: 1,
      last_week: 0,
      // -------------------------- show variables modal -----------------------
      variablesModal: false,
      retailer: {},
      // -------------------------- show retailer details ----------------------
      retailerDetail: false,
      // --------------------------- buyers -----------------------
      buyers: {
        data: [],
        total: 0,
      },
      menuOpened: false,
    };
    this.changeActiveItem = this.changeActiveItem.bind(this);
    this.onEditDate = this.onEditDate.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    adminData()
      .then((response) => {
        const recent = response.data.quotes.data.slice(0, 7);
        let chartData = FormatChartData(response.data.status_quotes);

        this.setState({
          quotes: {
            ...response.data.quotes,
            data: response.data.quotes.data.map((quote) => {
              const car = quote.car || JSON.parse(quote.car_info);

              return {
                ...quote,
                car,
              };
            }),
          },
          lastQuotes: recent,
          last_week: response.data.last_week,
          bestRetailers: response.data.best_retailers,
          retailers: response.data.retailers.data,
          retailersTotal: response.data.retailers.last_page,
          chartData: chartData,
          loading: false,
          buyers: response.data.customers,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  // ------------------------------------ get quote status --------------------
  searchStatusQuotes() {
    const params = {
      startDate: moment(this.state.chartDateStart).toISOString(),
      endDate: moment(this.state.chartDateEnd).toISOString(),
    };

    this.setState({ loading: true });

    statusQuotes(params)
      .then((response) => {
        let chartData = FormatChartData(response.data);
        this.setState({ chartData: chartData, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  onEditInput(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  onEditDate(date, id) {
    this.setState({ [id]: date }, this.searchStatusQuotes);
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  // Handle for the menu component.
  changeActiveItem(selected) {
    this.setState({ activeItem: selected });
  }

  changePage = (page = 1, startDate = null, endDate = null, search = null) => {
    let params = { page };
    if (startDate) params["startDate"] = startDate;
    if (endDate) params["endDate"] = endDate;
    if (search) params["search"] = search;
    this.setState({ loading: true });
    searchQuotes(params)
      .then((response) => {
        this.setState({
          quotes: response.data,
          // page:       response.data.current_page,
          // totalPages: response.data.last_page,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  defaultCars() {}

  // -------------------------------------- change retailers page ---------------------------------
  chageRetailerPage(page = 1) {
    let params = { page };
    this.setState({ loading: true });
    getRetailers({ page })
      .then((response) => {
        this.setState({
          retailers: response.data.data,
          retailersTotal: response.data.last_page,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  }

  // ---------------------------------------- show retailer variables ------------------------------
  showVariables = (retailer) => {
    this.setState({ retailer: retailer, variablesModal: true });
  };
  // ---------------------------------------- show retailer -----------------------------------------
  showRetailer = (retailer) => {
    this.setState({ retailer: retailer, retailerDetail: true });
  };

  updateRetailer = (retailer) => {
    let retailers = this.state.retailers;
    for (let i = 0; i < retailers.length; i++) {
      if (retailers[i].id == retailer.id) {
        retailers[i] = retailer;
        break;
      }
    }
    this.setState({ retailers });
  };

  addNewRetailer = (retailer) => {
    let retailers = this.state.retailers;
    retailers.push(retailer);
    this.setState({ retailers });
  };

  hideModal = (label, value) => {
    this.setState({ [label]: value });
  };
  // ------------------------------------- get buyers list paginate ------------------------------------------------
  changePageBuyers = (
    page = 1,
    startDate = null,
    endDate = null,
    search = null
  ) => {
    let params = { page };
    if (startDate) params["startDate"] = startDate;
    if (endDate) params["endDate"] = endDate;
    if (search) params["search"] = search;
    this.setState({ loading: true });
    getCustomersAdmin(params)
      .then((response) => {
        this.setState({
          buyers: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    // Active item for menu
    const { activeItem } = this.state;

    return (
      <div className={"dashboard"}>
        <UserMenu
          handleClick={this.changeActiveItem}
          activeDefault={this.state.activeItem}
          elements={adminMenu}
          menuOpened={this.state.menuOpened}
          openMenu={() => this.setState({ menuOpened: false })}
        />

        <BackMenu
          menuOpened={this.state.menuOpened}
          openMenu={() => this.setState({ menuOpened: false })}
        />

        <div className={"dashboardContent"} style={{ background: "white" }}>
          <UserHeader
            title={"SECTION TITLE"}
            name={"ADMIN"}
            admin
            openMenu={() => this.setState({ menuOpened: true })}
            addNewRetailer={this.addNewRetailer}
          />

          {activeItem === "dashboard" && (
            <>
              <Dashboard
                state={{ ...this.state }}
                onEditDate={this.onEditDate}
                changeActiveItem={this.changeActiveItem}
                showRetailer={this.showRetailer}
              />
            </>
          )}

          {activeItem === "inventory" && <Inventory userType={"admin"} />}

          {
            <ListBuyers
              buyers={this.state.buyers.data}
              totalPages={this.state.buyers.last_page}
              changePage={this.changePageBuyers}
              isActive={activeItem === "buyers"}
            />
          }

          {
            <ListQuotes
              role={"admin"}
              quotes={this.state.quotes.data}
              totalPages={this.state.quotes.last_page}
              changePage={this.changePage}
              defaultCar={this.state.carIdByUrl}
              isActive={activeItem === "quotes"}
            />
          }

          <Retailers
            activeItem={activeItem}
            state={{ ...this.state }}
            addNewRetailer={this.addNewRetailer}
            showVariables={this.showVariables}
            showRetailer={this.showRetailer}
            chageRetailerPage={this.chageRetailerPage}
            hideModal={this.hideModal}
            updateRetailer={this.updateRetailer}
          />

          {this.state.retailerDetail && (
            <RetailerDetails
              {...this.state.retailer}
              hideModal={this.hideModal}
              updateRetailer={this.updateRetailer}
            />
          )}

          {activeItem === "account" && <Accounts />}

          {activeItem === "videos" && (
            <Videos retailersList={this.state.retailers} />
          )}

          {activeItem === "selector" && <CreditProfileSelector />}
        </div>

        {this.state.loading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </div>
    );
  }
}

export default Admin;
