import React from "react";
import { useMediaPredicate } from "react-media-hook";
import cn from "classnames";

import "./InfoRecord.sass";

const InfoRecord = ({
  label,
  value,
  wrapOnBreakpoint = 1600,
  noMargin = false,
  className,
}) => {
  const wrapBreakpoint = useMediaPredicate(
    `(max-width: ${wrapOnBreakpoint}px)`
  );

  return (
    <li
      className={cn(
        "itemWrapper",
        wrapBreakpoint && "wrapped",
        noMargin && "noMarginBottom",
        className
      )}
    >
      <span>{label}</span>

      <span className={"value"}>{value || "N/A"}</span>
    </li>
  );
};

export default InfoRecord;
