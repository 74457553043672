import React from "react";
import { useMediaPredicate } from "react-media-hook";

import Steps from "../../../global/Steps";

import { useTheme } from "../../../helpers/theme";

// IMPORT CSS STYLES
import "./headerQuote.sass";

const HeaderQuote = (props) => {
  const { theme } = useTheme();
  const { Step } = Steps;

  const { onFirstStepClick, onSecondStepClick, onThirdStepClick } = props;

  const is768 = useMediaPredicate("(max-width: 768px)");

  return (
    <div className="header-chroma">
      <div className="images-grapper">
        <div className="logo">
          <div>
            <img src={require("../../../../images/MyPayPow.png")} />
          </div>
        </div>
      </div>

      <div className={"headerNavigation"}>
        <Steps currentStep={props.step}>
          <Step allowedSteps={[-1]} onClick={onFirstStepClick} theme={theme}>
            Get Started
          </Step>

          <Step
            allowedSteps={[0, "attributes", "individualPlans", "rebates"]}
            onClick={onSecondStepClick}
            theme={theme}
          >
            Pre Qualification Details
          </Step>

          <Step
            allowedSteps={[2, 2.5]}
            onClick={onThirdStepClick}
            theme={theme}
          >
            Repayment Plan Map
          </Step>

          <Step allowedSteps={[5, 6]} theme={theme}>
            {is768 ? "Submit, Print, Download" : "Submit/Print/Download"}
          </Step>
        </Steps>
      </div>
    </div>
  );
};

export default HeaderQuote;
