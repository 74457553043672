import React from "react";
import { Input as SemanticInput } from "semantic-ui-react";
import cn from "classnames";

import { defaultTheme } from "../../helpers/theme";

import "./styles.sass";

const Input = ({
  className,
  error,
  disabled,
  noMargin,
  theme,
  isPreview = false,
  ...rest
}) => {
  return (
    <div
      className={cn(
        "customInputWrapper",
        error && "inputDanger",
        disabled && "ghost",
        noMargin && "withoutMargin",
        isPreview && "isInputPreview",
        className
      )}
      style={{
        "--input-border-color-default":
          theme?.default?.color || defaultTheme.input.default.color,
        "--input-border-color-primary":
          theme?.focus?.color || defaultTheme.input.focus.color,
        "--input-border-color-danger":
          theme?.error?.color || defaultTheme.input.error.color,
        "--input-primary-shadow-color":
          theme?.focus?.shadow || defaultTheme.input.focus.shadow,
        "--input-danger-shadow-color":
          theme?.error?.shadow || defaultTheme.input.error.shadow,
        "--input-default-shadow-color":
          theme?.default?.shadow || defaultTheme.input.default.shadow,
        "--input-border-radius":
          (theme?.borderRadius || defaultTheme.input.borderRadius) + "px",
      }}
    >
      <SemanticInput disabled={disabled} {...rest} />

      <p className={cn("errorMessage", error && "errorMessageVisible")}>
        {error}
      </p>
    </div>
  );
};

export default Input;
