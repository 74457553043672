import React, { Component } from "react";
import { Breadcrumb, Grid, Pagination, Message } from "semantic-ui-react";
import QuotationDetails from "../quotationDetails/quotationDetails";
import QuotCard from "./QuotCard";
import { MessagesModal } from "../messages/MessagesModal";
import "./listQuotes.sass";
/*
 *  PROPS:
 *   - quotes:       List of quotes to show.
 *   - totalPages:   Total of the search pages.
 *   - changePage:   Function to change the current page.
 *
 * */

class ListQuotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuote: null,
      breadCrumbName: null,
    };
    this.showDetails = this.showDetails.bind(this);
    this.hideDetails = this.hideDetails.bind(this);
    this.setName = this.setName.bind(this);
  }

  componentDidMount() {
    if (this.props.defaultCar) {
      this.setState({
        selectedQuote: this.props.defaultCar,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.defaultCar !== null &&
      this.props.defaultCar !== this.state.selectedQuote
    ) {
      this.setState({
        selectedQuote: this.props.defaultCar,
      });
    }
  }

  showDetails(id, name) {
    this.setState({ selectedQuote: id, breadCrumbName: name });
  }

  hideDetails() {
    this.setState({ selectedQuote: null });
  }

  setName(name) {
    this.setState({ breadCrumbName: name });
    if (this.props.changeDefault) {
      this.props.changeDefault(null);
    }
  }

  updateCreatedQuots = (status, id, pending) => {
    this.props.updateCreatedQuots(status, id, pending);
  };

  render() {
    const { selectedQuote } = this.state;
    const { role } = this.props;

    return (
      <div
        className={"body quotes"}
        style={{ display: this.props.isActive ? "block" : "none" }}
      >
        <Grid>
          {!selectedQuote ? (
            <h3 className={"upperHeader"}>Deals List</h3>
          ) : (
            <Grid.Column width={16}>
              <Breadcrumb size={"large"} style={{ marginRight: "1rem" }}>
                <Breadcrumb.Section onClick={this.hideDetails} link>
                  Deals list
                </Breadcrumb.Section>

                <Breadcrumb.Divider icon="right chevron" />

                <Breadcrumb.Section active>
                  #{selectedQuote}: {this.state.breadCrumbName}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          )}

          {role === "customer" && (
            <Grid.Column width={16}>
              {
                <MessagesModal
                  role="customer"
                  unreadMessages={this.props.user.customer.messages_count || 0}
                />
              }
            </Grid.Column>
          )}
        </Grid>

        {selectedQuote ? (
          <div className="widthQuoteDetail">
            {this.props.role == "customer" || this.props.role == "retailer" ? (
              <QuotationDetails
                insideDashboard={true}
                quotId={selectedQuote}
                getName={this.setName}
                updateCreatedQuots={this.updateCreatedQuots}
              />
            ) : (
              <QuotationDetails
                adminUserList={true}
                quotId={selectedQuote}
                getName={this.setName}
              />
            )}
          </div>
        ) : (
          <div>
            {!!this.props.quotes.length ? (
              <>
                <ul className={"listWrapper"}>
                  {this.props.quotes.map((quot) => (
                    <QuotCard
                      quot={quot}
                      role={this.props.role}
                      key={quot.id}
                      onClick={() =>
                        this.showDetails(
                          quot.id,
                          `${quot.car.make} ${quot.car.model}`
                        )
                      }
                    />
                  ))}
                </ul>

                {!!this.props.totalPages && this.props.changePage && (
                  <div style={{ textAlign: "Center" }}>
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      defaultActivePage={1}
                      totalPages={this.props.totalPages}
                      onPageChange={(e, { activePage }) =>
                        this.props.changePage(activePage)
                      }
                    />
                  </div>
                )}
              </>
            ) : (
              <Message info>
                <Message.Header>Info</Message.Header>

                <Message.Content>The list of deals is empty</Message.Content>
              </Message>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ListQuotes;
