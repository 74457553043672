import React from "react";
import { Dropdown } from "semantic-ui-react";
import cn from "classnames";

import "./MultiSelect.sass";

const MultiSelect = ({ multiply, selection, className, ...rest }) => {
  return (
    <div className={cn("multiSelect", className)}>
      <Dropdown multiple selection fluid {...rest} />
    </div>
  );
};

export default MultiSelect;
