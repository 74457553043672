import * as yup from "yup";
import _ from "lodash";

export const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, "Name should contain at least 3 characters")
    .required("Name field is required"),
  cost: yup
    .string()
    .trim()
    .test(
      "is-correct-number",
      "Price field should be a correct number",
      (value) => {
        const post = value?.split(".")[1] || "";

        const valueAsANumber = Number(value);

        const isNaN = _.isNaN(valueAsANumber);
        const isNegativeNumber = _.isEqual(_.head(value), "-");
        const isIncorrectDecimalFormat = _.isEqual(_.last(value), ".");
        const isDecimalPartTooLong = post.length > 2;

        if (
          isNaN ||
          isIncorrectDecimalFormat ||
          isDecimalPartTooLong ||
          isNegativeNumber
        )
          return false;

        return true;
      }
    )
    .required("Price field is required"),
});

export const editorModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

export const editorFormats = ["header", "list", "bullet"];
