import React from "react";
import { Grid } from "semantic-ui-react";

import Payment from "./Payment";

import "./payment.sass";

const Payments = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const { car } = props;

  return props.onlyPayments ? (
    <Payment
      payment={props.paymentType}
      car={car}
      currentUser={currentUser}
      {...props}
    />
  ) : (
    <Grid className="grapperPayments">
      <Grid.Column tablet={5} computer={5}>
        <Payment
          {...props}
          payment={"monthly"}
          car={car}
          currentUser={currentUser}
        />
      </Grid.Column>

      <Grid.Column tablet={5} computer={5}>
        <Payment
          {...props}
          payment={"weekly"}
          car={car}
          currentUser={currentUser}
        />
      </Grid.Column>

      <Grid.Column tablet={5} computer={5}>
        <Payment
          {...props}
          payment={"weeklyPlus"}
          car={car}
          currentUser={currentUser}
        />
      </Grid.Column>
    </Grid>
  );
};

export default Payments;
