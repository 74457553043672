import moment from "moment";
import React, { useEffect } from "react";
import { Form, Grid, Message } from "semantic-ui-react";

import Input from "../../global/Input";
import DateInput from "../../global/DateInput";

import { useTheme } from "../../helpers/theme";

export const UniqueIdentifiers = (props) => {
  const { theme } = useTheme();
  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {
    if (!flagEdit) {
      document
        .querySelector(".readonly")
        .querySelector("input")
        .setAttribute("readonly", "true");
    }
  }, [form, msnErrors, flagEdit]);

  return (
    <>
      <Form.Group>
        {flagEdit ? (
          <Form.Field width={8}>
            <label>
              Birthday <span className="required-inut">*</span>
            </label>
            <Input value={form.birthDate} disabled fluid />
          </Form.Field>
        ) : (
          <Form.Field width={8}>
            <label>
              Birthday <span className="required-inut">*</span>
            </label>

            <DateInput
              dateFormat="MM-DD-YYYY"
              maxDate={moment().subtract(18, "years").format("MM-DD-YYYY")}
              minDate={moment().subtract(100, "years").format("MM-DD-YYYY")}
              name="date"
              className="readonly"
              placeholder="Date"
              value={form.birthDate}
              iconPosition="left"
              onChange={(event, { name, value }) => {
                props.onEditInput("birthDate", value);
              }}
              error={
                msnErrors.birthDate.length > 0 ? msnErrors.birthDate : null
              }
              disable={true}
              animation="none"
              theme={theme?.input}
            />
          </Form.Field>
        )}

        <Form.Field width={8}>
          <label>
            SSN <span className="required-inut">*</span>
          </label>

          <Input
            id={"ssn"}
            value={form.ssn}
            placeholder={"SSN"}
            onChange={(e) => props.onEditInput("ssn", e.target.value)}
            fluid
            error={msnErrors.ssn.length > 0 ? msnErrors.ssn : null}
            maxLength="100"
            disabled={flagEdit}
            theme={theme?.input}
          />
        </Form.Field>
      </Form.Group>

      {form.dwolla && (
        <Message hidden={flagEdit} info>
          <Message.Header>Update SSN</Message.Header>
          <p>
            If you want to update the ssn, you must enter it again. This is done
            for the purpose of protecting your data.
          </p>
        </Message>
      )}
    </>
  );
};
