import React from "react";
import { Popup } from "semantic-ui-react";
import cn from "classnames";
import { useMediaPredicate } from "react-media-hook";

import AttributeDescription from "../AttributeInfo/AttributeDescription";

import { FormatCashDownToPrice } from "../../../../helpers/general";
import { defaultTheme, useTheme } from "../../../../helpers/theme";

import EmptyImage from "../../../../../images/no-image-available.png";
import { ReactComponent as InfoIcon } from "../../../../../images/info-icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../images/plusCircleIcon.svg";
import { ReactComponent as PlusSelectedIcon } from "../../../../../images/plusCircleSelected.svg";

import "./AttributeCard.sass";

const AttributeCard = ({
  item,
  weeklyValue,
  isAdded,
  isView,
  onClickView,
  onClickAdd,
}) => {
  const { theme } = useTheme();
  const is768 = useMediaPredicate("(max-width: 768px)");

  return (
    <div
      style={{
        "--primary-color":
          theme?.typography?.primary || defaultTheme.typography.primary,
        "--secondary-color":
          theme?.typography?.secondary || defaultTheme.typography.secondary,
      }}
      className={cn(
        "attributeCardWrapper",
        isView && "attributeCardWrapperSelected"
      )}
    >
      <div className={"attributeCardPhotoWrapper"}>
        <img
          src={item.image_url}
          alt={"Attribute Photo"}
          onError={({ currentTarget }) => {
            currentTarget.scr = EmptyImage;
          }}
        />
      </div>

      <div className={"cardContent"}>
        <h5>{item.name}</h5>

        <div>
          {isView && is768 && (
            <AttributeDescription
              withPrice={false}
              description={item.description}
            />
          )}

          <div className={"cardInfoWrapper"}>
            <button onClick={onClickView} className={"infoButtonWrapper"}>
              <span>{isView ? "Hide " : "View "} info</span>

              <InfoIcon />
            </button>
          </div>

          <div className={"cardBottomSection"}>
            <div className={"paymentInfo"}>
              <span className={"attributeWeeklyPrice"}>
                {`Adds Just ${weeklyValue}/WK`}{" "}
                <Popup
                  content={
                    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed porro ipsam culpa placeat laudantium voluptate."
                  }
                  trigger={<InfoIcon className={"attributeWeeklyPriceIcon"} />}
                />
              </span>

              <span className={"attributePrice"}>
                {FormatCashDownToPrice(item.cost)}
              </span>
            </div>

            <button className={"addAttributeButton"} onClick={onClickAdd}>
              {isAdded ? (
                <PlusSelectedIcon />
              ) : (
                <PlusIcon className={"addAttributeIcon"} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttributeCard;
