import React, { useState } from 'react';
import { Button, Dimmer, Form, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import { validateBankNumber, validateRoutingNumber } from '../../helpers/general';
import ModalComponent from '../alertModal/modal';
import { AccountFields } from '../bankAccount/AccountFields';
import { postLender } from '../requests';

const defaultForm = {
  name: "", accountNumber: "", routingNumber: "", address: ""
};
const defaultErrors = defaultForm;

export const CreateLenderAccount = (props) => {
  const { lenders } = props;
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState(defaultErrors);
  const [disabled, setDisabled] = useState(true);

  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors);
    setErrors({ ...auxErrors });
    setForm({ ...auxForm });
    auxErrors = defaultErrors;
    validation(auxForm, auxErrors);
  }

  const validation = (auxForm, auxErrors, loanAmount) => {
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors, loanAmount);
      }
    }
    setDisabled(false);
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (element != null) {
          setDisabled(true);
        }
      }
    }
  }

  const validateForm = (value, label, errors, loanAmount = form.loanAmount) => {
    switch (label) {
      case 'name':
      case 'address':
        errors[label] = !value || value.split(" ").join("") === "" ? "The field must be required" : null;
        break;
      case 'accountNumber':
        errors[label] = !value || !validateBankNumber(value) ? "The field must be a numeric string of 4-17 digits" : null;
        break;
      case 'routingNumber':
        errors[label] = !value || !validateRoutingNumber(value) ? "The field must be 9 digits" : null;
        break;
      default:
        break;
    }
    return errors;
  }

  // ------------------------------------- send data to backend --------------------------
  const senData = () => {
    setIsLoading(true);
    postLender(form).then(response => {
      setIsLoading(false);
      let auxLenders = [...lenders];
      auxLenders.unshift(response.data);
      props.updateLenders(auxLenders);
      setModal({ status: 201, message: "The lender account was created." });
      openModal();
    }).catch(error => {
      setIsLoading(false);
      console.log(error, error.response.status);
      if (error.response) {
        if (error.response.status === 406) {
          setModal({ status: 406, message: error.response.data.message });
        } else if (error.response.status === 404) {
          setModal({ status: 406, message: "Sorry. Element was not found" });
        } else if (error.response.status === 422) {
          setModal({ status: 422, message: "Sorry. Fields are missing to save the information" });
        } else {
          setModal({ status: 500, message: "Sorry. An error occurred, please verify your information or try again later. If This Issue Continues, Contact Support." });
        }
      }
    });
  }

  const hideAlertModal = () => {
    if (modal.status === 201) {
      setOpen(false)
    }
    setModal({})
  }

  const openModal = () => {
    setOpen(true);
    setForm(defaultForm);
    setErrors(defaultErrors);
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button positive onClick={openModal}><Icon name='add' /> Lender Account</Button>
      <Modal size="tiny" open={open} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>
          Add Lender Account
        </Modal.Header>
        <Modal.Content className='loanDetails'>
          <div>
            <Form>
              <Form.Input
                label={<label>Lender Address:<span className="required-inut"> *</span></label>}
                onChange={(e) => onEditInput(e.target.value, "address")}
                placeholder={'Lender Address'}
                width={16}
                maxLength={50}
                value={form.address}
                error={form.address || errors.address ? errors.address : null}
              />
              <AccountFields onEditInput={onEditInput} errors={errors} />
            </Form>
          </div>
          {
            isLoading &&
            <Dimmer className="loading" active>
              <Loader />
            </Dimmer>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setOpen(false)} >
            Close
          </Button>
          <Button primary onClick={() => senData(false)} disabled={disabled}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      {
        modal.message &&
        <ModalComponent modal={modal} hideModal={hideAlertModal} />
      }
    </div>
  );
};