import React from "react";

import Avatar from "../../../global/Avatar";
import { ReactComponent as DealCount } from "../../../../images/handshake-icon.svg";
import { MessagesModal } from "../../messages/MessagesModal";

import "./BuyersCard.sass";

const BuyersCard = ({ buyer, role, onClick }) => {
  return (
    <li className={"buyerCardWrapper"} onClick={onClick}>
      <div className={"buyerInfoWrapper"}>
        <span>{`${buyer.first_name} ${buyer.last_name}`}</span>

        <div className={"emailWrapper"}>
          <span>{buyer.user.email}</span>
        </div>

        <div className={"dealsCountWrapper"}>
          <DealCount />

          <span>{buyer.quots_count}</span>
        </div>
      </div>

      <div className={"rightSideWrapper"}>
        <Avatar
          firstName={buyer.first_name}
          lastName={buyer.last_name}
          size={36}
        />

        {role === "retailer" && (
          <MessagesModal
            role="retailer"
            customer={{
              value: buyer.id,
              text: buyer.first_name + " " + buyer.last_name,
            }}
            unreadMessages={buyer.messages_count}
            buyer={buyer}
          />
        )}
      </div>
    </li>
  );
};

export default BuyersCard;
