import React from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';

export const Deals = ({ step, section }) => {
  return (
    <div className={section}>
      {/* <Icon name="arrow left" /> */}
      <div className='text'>
        <Segment>
          <h4>In this section you will see all your created deals.</h4>
          <ul>
            <li>In the button <a>See more...</a> you will able to see the deal detail</li>
            <li>In the button <img src={require("../../../images/tutorial/buttonPlan.png")} /> you will able to start a payment plan</li>
            <li>In the button <img src={require("../../../images/tutorial/buttonMsn.png")} /> you will able to send message to the dealersip(if applicable)</li>
          </ul>
        </Segment>
        <div>
          <Button onClick={() => step('dashboard')}>Previous</Button>
          <Button onClick={() => step('loan')}>Next</Button>
        </div>
      </div>
    </div>
  );
};