import * as yup from "yup";
import _ from "lodash";

export const schema = yup.object().shape({
  category: yup.string().required("Category name is required"),
  products: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("This field is required"),
        priority: yup.number(),
        value: yup
          .string()
          .trim()
          .test(
            "is-correct-number",
            "Amount field should be a correct number",
            (value) => {
              const post = value?.split(".")[1] || "";

              const valueAsANumber = Number(value);

              const isNaN = _.isNaN(valueAsANumber);
              const isNegativeNumber = _.isEqual(_.head(value), "-");
              const isIncorrectDecimalFormat = _.isEqual(_.last(value), ".");
              const isDecimalPartTooLong = post.length > 2;

              if (
                isNaN ||
                isIncorrectDecimalFormat ||
                isDecimalPartTooLong ||
                isNegativeNumber
              )
                return false;

              return true;
            }
          )
          .required("This field is required"),
        conditions: yup.object().shape({
          miles: yup.object().shape({
            from: yup
              .number()
              .typeError("The value must be a number")
              .required("This field is required"),
            to: yup
              .number()
              .typeError("The value must be a number")
              .required("This field is required")
              .test(
                "is-bigger-from",
                '"To" field must be greater than "From" field',
                (value, context) => {
                  const from = context.parent.from;

                  return _.isNumber(from) && value > from;
                }
              ),
          }),
          months: yup.object().shape({
            from: yup
              .number()
              .typeError("The value must be a number")
              .required("This field is required"),
            to: yup
              .number()
              .typeError("The value must be a number")
              .required("This field is required")
              .test(
                "is-bigger-from",
                '"To" field must be greater than "From" field',
                (value, context) => {
                  const from = context.parent.from;

                  return _.isNumber(from) && value > from;
                }
              ),
          }),
        }),
      })
    )
    .required(),
});

export const valueOptions = [
  { key: "usd", text: "$", value: "usd" },
  { key: "percent", text: "%", value: "percent" },
];
