import React from "react";
import { Grid } from "semantic-ui-react";
import Button from "../../../global/Button";

import { useTheme } from "../../../helpers/theme";

const JumpButton = (props) => {
  const { theme } = useTheme();

  const { step, noColumn, text } = props;

  return (
    <Grid.Column mobile={16} tablet={16} computer={16}>
      {noColumn ? (
        <Button
          className="btn-continue"
          buttonStyle="skyblue"
          onClick={() => {
            let objDiv = document.getElementById("hrefFocus");
            objDiv.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          theme={theme?.button}
        >
          <h3>
            {text ||
              `Below We've Mapped Weekly Payment Plans That Can Give You More
            Purchasing Power`}
          </h3>
        </Button>
      ) : (
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          ></Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Button
              className="btn-continue"
              onClick={() => {
                let objDiv = document.getElementById("hrefFocus");
                objDiv.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
              theme={theme?.button}
            >
              <h3>
                {step === 0
                  ? "NEXT: Review My Prequalification Terms"
                  : text ||
                    `Below We've Mapped Weekly Payment Plans That Can Give You More Purchasing Power`}
              </h3>
            </Button>
          </Grid.Column>
        </Grid>
      )}
    </Grid.Column>
  );
};

export default JumpButton;
