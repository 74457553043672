import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { useMediaPredicate } from "react-media-hook";

import { AgreementCtx } from "../../context";

import "./EffectiveENDTermsTable.sass";

const { Row, Column } = Grid;

const EffectiveENDTermsTable = () => {
  const { data } = useContext(AgreementCtx);

  const is976 = useMediaPredicate("(max-width: 976px)");
  const is768 = useMediaPredicate("(max-width: 768px)");

  return (
    <>
      <Grid className={"gridWrapper"}>
        <Row>
          <Column width={is976 ? (is768 ? 0 : 4) : 8} className={"noWrap"} />

          <Column
            width={is976 ? (is768 ? 16 : 12) : 8}
            align={"center"}
            className={"noWrap"}
          >
            <div className={"headerWrapper"}>
              <h3>Effective END Term Based on WEEKLY PAYMENTS</h3>
            </div>
          </Column>
        </Row>

        <Row>
          <Column width={is976 ? 6 : 4} className={"recordLabel noWrap"}>
            ACTUAL Monthly Term
          </Column>

          <Column width={is976 ? 2 : 4} align={"center"} className={"noWrap"} />

          <Column
            width={2}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_term_in_months_1}</span>
          </Column>

          <Column
            width={is976 ? 4 : 5}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_term_in_months_2}</span>
          </Column>

          <Column
            width={is976 ? 2 : 1}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_term_in_months_3}</span>
          </Column>
        </Row>

        <Row>
          <Column width={is976 ? 6 : 4} className={"recordLabel noWrap"}>
            Term Reduction
          </Column>

          <Column width={is976 ? 2 : 4} align={"center"} className={"noWrap"} />

          <Column
            width={2}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_term_reduction_1}</span>
          </Column>

          <Column
            width={is976 ? 4 : 5}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_term_reduction_2}</span>
          </Column>

          <Column
            width={is976 ? 2 : 1}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_term_reduction_3}</span>
          </Column>
        </Row>

        <Row className={"markedRow"}>
          <Column width={is976 ? 6 : 4} className={"recordLabel noWrap"}>
            Effective END Term
          </Column>

          <Column width={is976 ? 2 : 4} align={"center"} className={"noWrap"} />

          <Column
            width={2}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_end_term_1}</span>
          </Column>

          <Column
            width={is976 ? 4 : 5}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_end_term_2}</span>
          </Column>

          <Column
            width={is976 ? 2 : 1}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_end_term_3}</span>
          </Column>

          <div className={"highlightedRow"} />
        </Row>

        <Row>
          <Column width={is976 ? 5 : 3} className={"recordLabel noWrap"}>
            Down Payment
          </Column>

          <Column
            width={is976 ? 3 : 2}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"highlighted"}>{data.gtx_down_payment_1}</span>
          </Column>

          {!is976 && <Column width={3} align={"center"} className={"noWrap"} />}

          <Column
            width={2}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_down_payment_2}</span>
          </Column>

          <Column
            width={is976 ? 4 : 5}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_down_payment_3}</span>
          </Column>

          <Column
            width={is976 ? 2 : 1}
            align={"center"}
            verticalAlign="middle"
            className={"noWrap"}
          >
            <span className={"thinner"}>{data.gtx_down_payment_4}</span>
          </Column>
        </Row>
      </Grid>

      <div>
        <span className={"bottomCaption"}>
          Effective END Terms are calculated based on completion of the loan
          term. Term Reduction is acquired when customer makes weekly payments
          during the loan period. See Finance Manager regarding member
          enrollment benefits and fees
        </span>
      </div>
    </>
  );
};

export default EffectiveENDTermsTable;
