import React, { useEffect, useState } from "react";
import {
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Modal,
  Segment,
} from "semantic-ui-react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import Button from "../../global/Button";

export const CreditSolutionReport = ({ request }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resultCode = (code) => {
    const result = {
      A: "Consumer Found and Data Returned",
      F: "Below Automated Score Cutoff Approval",
      E: "Error or Consumer is a minor",
      X: "No hit, Consumer file not found",
      PC: "Process Complete",
      NR: "No Response",
      R: "Information on inquiry is reported as fraud",
      S: "Unable to Obtain Consumer File, SSN Required",
      T: "Consumer Found Score Not Returned, Thin File",
      Z: "Security Freeze on file",
    };
    return code ? result[code] : result.NR;
  };

  // ------------------------------ create pdf ----------------------------------
  const pdf = () => {
    setIsLoading(true);
  };

  const createPdf = () => {
    const input = document.getElementById("pdfCreated");
    html2canvas(input, {
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("P", "mm", "Letter");
      var width = pdf.internal.pageSize.getWidth();
      pdf.addImage(imgData, "PNG", 15, 15, width - 20, 0);
      pdf.save(request.name + ".pdf");
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (isLoading) {
      createPdf();
    }
  }, [isLoading]);

  return (
    <div>
      <Modal
        size="small"
        open={open}
        closeOnEscape={false}
        closeOnDimmerClick={true}
        closeIcon
        onClose={() => setOpen(false)}
        trigger={
          <Button buttonStyle={'skyblue'} className="creditButton" onClick={() => setOpen(true)}>
            Result
          </Button>
        }
        id="pdfCreated"
      >
        <Modal.Header>Credit Solution Result</Modal.Header>
        <Modal.Content className="credit-report" scrolling={!isLoading}>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Header as={"h3"}>
                Result:{" "}
                <strong>{resultCode(request.result?.ResultCode)}</strong>
              </Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Header as={"h3"}>
                Score: <span className="score">{request.score}</span>
              </Header>
              {request.result?.ScorecardName && (
                <small>Power by {request.result.ScorecardName}</small>
              )}
            </Grid.Column>
          </Grid>
          <Segment.Group>
            <Segment color="blue">
              <Header as={"h2"}>Consumer Information</Header>
            </Segment>
            <Segment>
              <p>
                Name: <strong>{request.name}</strong>
              </p>
              <p>
                Address:{" "}
                <strong>{`${request.address} ${request.city} ${request.state} ${request.zip_code}`}</strong>
              </p>
            </Segment>
          </Segment.Group>
          <Segment.Group>
            <Segment color="blue">
              <Header as={"h2"}>Auto Summary</Header>
            </Segment>
            <Segment>
              <Grid>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <p>
                    Available Revolving Credit:{" "}
                    <strong>
                      {request.result?.AutoTradeLines &&
                      request.result?.ResultCode === "A"
                        ? request.result.AutoTradeLines.AvailableRevolvingCredit
                        : "Not available"}
                    </strong>
                  </p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <p>
                    Auto Inquiries last 30 days:{" "}
                    <strong>
                      {request.result?.AutoTradeLines &&
                      request.result?.ResultCode === "A"
                        ? request.result.AutoTradeLines.AutoInquirieslast30days
                        : 0}
                    </strong>
                  </p>
                </Grid.Column>
              </Grid>
            </Segment>
            {request.result?.AutoTradeLines?.AutoTradeLines &&
              request.result?.ResultCode === "A" &&
              request.result.AutoTradeLines.AutoTradeLines.map((item, i) => (
                <Segment.Group style={{ margin: 0 }}>
                  <Segment color="green">
                    <Header as={"h2"}>Auto Trade Line {i + 1}</Header>
                  </Segment>
                  <Segment.Group horizontal className="trades">
                    <Segment>
                      <Grid>
                        <Grid.Column mobile={8} tablet={8} computer={8}>
                          <p>
                            Interest Rate: <strong>{item.InterestRate}</strong>
                          </p>
                          <p>
                            Percent Paid: <strong>{item.PercentPaid}</strong>
                          </p>
                          <p>
                            Loan Type: <strong>Loan</strong>
                          </p>
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={8} computer={8}>
                          <p>
                            Original Amount:{" "}
                            <strong>{item.OriginalAmount}</strong>
                          </p>
                          <p>
                            Estimated Payoff:{" "}
                            <strong>{item.EstimatedPayoff}</strong>
                          </p>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    <Segment>
                      <Grid>
                        <Grid.Column mobile={8} tablet={8} computer={8}>
                          <p>
                            Original Terms:{" "}
                            <strong>{item.OriginalTerms}</strong>
                          </p>
                          <p>
                            Remaining Terms:{" "}
                            <strong>{item.RemainingTerms}</strong>
                          </p>
                          <p>
                            Trade Status: <strong>{item.TradeStatus}</strong>
                          </p>
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={8} computer={8}>
                          <p>
                            No of Late Payments:{" "}
                            <strong>{item.Late24months}</strong>
                          </p>
                          <p>
                            Joint: <strong>{item.Joint}</strong>
                          </p>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    <Segment textAlign="center">
                      <p>Monthly Payment</p>
                      <Header as={"h3"} className="score">
                        {item.MonthlyPayment}
                      </Header>
                    </Segment>
                  </Segment.Group>
                </Segment.Group>
              ))}
          </Segment.Group>
        </Modal.Content>
        <Modal.Actions
          className={"actionButtons"}
          style={{ display: isLoading ? "none" : "flex" }}
        >
          <Button
            buttonStyle={"danger"}
            className="actionButton"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>

          <Button buttonStyle={"primary"} onClick={pdf}>
            Download PDF <Icon name="file pdf outline" />
          </Button>
        </Modal.Actions>
      </Modal>

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};
