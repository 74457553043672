import React, { useEffect } from "react";
import { Form, Grid } from "semantic-ui-react";

import Input from "../../global/Input";

import { useTheme } from "../../helpers/theme";

import "./UserInformation.sass";

export const UserInformation = (props) => {
  const { theme } = useTheme();
  const { form, msnErrors, flagEdit, register, noPadding } = props;

  useEffect(() => {}, [form, msnErrors, flagEdit, register]);

  return (
    <>
      {" "}
      <Form.Group className={"formGroup"}>
        <Form.Field width={8}>
          <label>
            E-mail Address <span className="required-inut">*</span>
          </label>

          <Input
            id={"email"}
            value={form.email}
            placeholder={"E-mail Address"}
            onChange={(e) => props.onEditInput("email", e.target.value)}
            fluid
            error={msnErrors.email.length > 0 ? msnErrors.email : null}
            maxLength="100"
            disabled={flagEdit}
            theme={theme?.input}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>
            Phone Number <span className="required-inut">*</span>
          </label>

          <Input
            value={form.phone_number}
            placeholder="Enter Phone Number"
            onChange={(e) => props.onEditInput("phone_number", e.target.value)}
            maxLength="100"
            min="12"
            width={16}
            disabled={flagEdit}
            error={msnErrors.phone_number.length > 0 && msnErrors.phone_number}
            theme={theme?.input}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group className={"formGroup"}>
        {register && (
          <Form.Field width={8}>
            <label>
              Password <span className="required-inut">*</span>
            </label>

            <Input
              className="form-register"
              id="password"
              value={form.password}
              type={"password"}
              placeholder={"Enter Password"}
              onChange={(e) => props.onEditInput("password", e.target.value)}
              error={msnErrors.password.length > 0 ? msnErrors.password : null}
              maxLength="50"
              theme={theme?.input}
            />
          </Form.Field>
        )}

        {register && (
          <Form.Field width={8}>
            <label>
              Password Confirmation <span className="required-inut">*</span>
            </label>

            <Input
              className="form-register"
              id="confirmPassword"
              value={form.confirmPassword}
              type={"password"}
              placeholder={"Password Confirmation"}
              onChange={(e) =>
                props.onEditInput("confirmPassword", e.target.value)
              }
              error={
                msnErrors.confirmPassword.length > 0
                  ? msnErrors.confirmPassword
                  : null
              }
              maxLength="50"
              theme={theme?.input}
            />
          </Form.Field>
        )}
      </Form.Group>
    </>
  );
};
