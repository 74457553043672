import React, { useState, useEffect } from "react";
import _ from "lodash";

import ListItem from "./ListItem";

import "./Products.sass";

const Products = ({
  categories,
  selectedProducts,
  packageId,
  isAllEmpty,
  onChangeProductsList,
}) => {
  const [sortedKeys, setSortedKeys] = useState([]);

  useEffect(() => {
    const _sorted = Object.entries(categories)
      .sort((prev) => {
        const prevValue = prev[1].coverage;

        return _.isEmpty(prevValue) ? 1 : -1;
      })
      .map((item) => item[0]);

    setSortedKeys(_sorted);
  }, [categories]);

  return (
    <div className={"cardListWrapper"}>
      <ul className={"cardList"}>
        {sortedKeys.map((key, index) => {
          const category = categories[key];
          const selectedProduct = selectedProducts[key];

          return (
            <ListItem
              key={`productCard-${key}-${index}`}
              label={category.custom_title}
              coverage={category.coverage}
              categoryKey={key}
              packageId={packageId}
              isSelected={!!selectedProduct}
              isAllEmpty={isAllEmpty}
              selectedProduct={selectedProduct}
              onCheck={onChangeProductsList}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Products;
