import React from "react";
import { useMediaPredicate } from "react-media-hook";
import cn from "classnames";

import { Header, Button, Grid } from "semantic-ui-react";

import Payments from "../../../customer/quotation/payments/Payments";
import { WeeklyTooltip } from "../../tooltips/WeeklyTooltip";
import Sliders from "../../../utilities/sliders/sliders";
import StylePayments from "../StylePayments";

import { FormatNumberToPrice } from "../../../helpers/general";

const PaymentInfo = (props) => {
  const is1180 = useMediaPredicate("(max-width: 1180px)");
  const is576 = useMediaPredicate("(max-width: 576px)");

  return (
    <>
      {props.state.editDisabled ? (
        <>
          <div className={"weeklyContainer"}>
            {props.state.finalQuots.monthly &&
              props.state.finalQuots.monthly.carid && (
                <Header
                  className="a2uWeekly"
                  style={props.state.reset ? null : { top: 0 }}
                >
                  <WeeklyTooltip
                    finalQuots={props.state.finalQuots}
                    offset={is1180 ? "0, 0" : "0, 50px"}
                    position={
                      is1180
                        ? is576
                          ? "top left"
                          : "top center"
                        : "left center"
                    }
                  />
                  Weekly:{" "}
                  {FormatNumberToPrice(props.state.finalQuots.weekly.payment)}
                </Header>
              )}

            {props.state.reset && (
              <Button
                className="btn-return"
                onClick={props.state.resetSilderValues}
              >
                Return to my <strong>Approved Pre-Qualification</strong>{" "}
                settings
              </Button>
            )}
          </div>

          <Grid className="edit-able">
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className={
                props.state.hideElementsPdf
                  ? "no-BoxShadow sliderWrapper"
                  : "sliderWrapper"
              }
            >
              <Sliders
                disabled={props.state.editDisabled}
                vertical={props.state.editDisabled}
                {...props.state}
                quotation={props.onChangeState}
                recalculateValues={props.recalculateValues}
              />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="borderFinal">
                <Payments
                  quots={props.state.finalQuots}
                  paymentType={props.state.paymentType}
                  onlyPayments
                  quoteDetail
                />
              </div>
            </Grid.Column>
          </Grid>
        </>
      ) : (
        <Grid className="edit-able">
          <StylePayments
            quots={props.state.finalQuots}
            state={{ ...props.state }}
            onChangeState={props.onChangeState}
          />

          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className={cn(
              "sliderWrapper",
              !props.stateEditDisabled && "slidersHorizontal"
            )}
          >
            <Sliders
              disabled={props.state.editDisabled}
              showPayment
              vertical={!props.state.editDisabled}
              {...props.state}
              quotation={props.onChangeState}
              recalculateValues={props.recalculateValues}
            />
          </Grid.Column>
        </Grid>
      )}
    </>
  );
};

export default PaymentInfo;
