import React, { useState } from "react";
import Video from "../video/Video";
import { Accordion, Popup } from "semantic-ui-react";
import cn from "classnames";

import { ReactComponent as ChevronIcon } from "../../../../images/chevronIcon.svg";
import { ReactComponent as CautionIcon } from "../../../../images/cautionIcon.svg";

import { isOptionsAdded } from "../../../helpers/general";
import { useTheme } from '../../../helpers/theme'

import "./SliderHeader.sass";

const SliderHeader = (props) => {
  const { theme } = useTheme()

  const [open, setOpen] = useState(false);

  const title = {
    1: "Your Prequalification Is Based On The Conditions Below",
    2: "Please Note: Changes made to the calculator below could change your prequalification status",
  };

  const renderVideo = () => {
    return (
      props.fromLanding &&
      props.title === 1 && (
        <Video flag={false} video={"retailer"} videos={props.videos} />
      )
    );
  };

  const handleOnClickContinue = () => {
    const target = document.getElementById("slidersWrapper");

    target.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const renderText = () => {
    if (props.title === 1 && props.isVisible) {
      return (
        <span>
          If you have a trade-in enter it here. Otherwise, hit{" "}
          <span className="redText" onClick={handleOnClickContinue}>
            Continue
          </span>{" "}
          to learn how you can afford more vehicle safety, performance features
          or a better vehicle protection package. We'll even show you how to
          eliminate late payment fees and build equity faster while paying less
          interest over the term of this loan.
        </span>
      );
    } else if (props.title === 2) {
      return (
        <span>
          Lowering your cash down, credit score or loan length may impact your
          loan eligibility. Call, text{" "}
          <span className="blue-text" style={{ color: theme?.typography.primary }}>{props.retailer.phone_number}</span> or
          initiate a{" "}
          <button className={"linkText"}>Dealer Help Live™ Video Chat</button>{" "}
          to be immediately connected to our dealer finance department.
        </span>
      );
    }
  };

  return (
    <div className="description-slider" id="hrefFocus" style={props.style}>
      <div className="ui-computer">
        {props.title === 2 ? (
          <Accordion className={"accordionWrapper"}>
            <Accordion.Title active={open} onClick={() => setOpen(!open)}>
              {props.isSlidersFlow ? "Adjust My Calculator Conditions" : "Your Prequalification Conditions"}

              <ChevronIcon
                className={cn("accordionIcon", open && "accordionIconRotated")}
              />
              {isOptionsAdded({
                attributes: props.personalizedAttributes,
                tradeIn: {
                  moneyOwed: props.money_owed,
                  trade: props.trade_in,
                },
              }) && (
                  <Popup
                    content={
                      "Your selected Trade-In, Protection and Personalization selections may require F&I Department review and approval for prequalification. You may update your selections or you may speak with a Finance Mgr at +34 923-24-7064"
                    }
                    trigger={
                      <CautionIcon
                        className={"cautionIcon"}
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                  />
                )}
            </Accordion.Title>

            <Accordion.Content active={open}>
              {props.title === 1 ? (
                <>{props.isVisible && <h2>{title[props.title]}</h2>}</>
              ) : (
                <h2 style={{ marginBottom: 0 }}>{title[props.title]}</h2>
              )}

              {renderVideo()}

              <h3 style={{ marginTop: props.title === 1 ? "1em" : 0 }}>
                {renderText()}
              </h3>
            </Accordion.Content>
          </Accordion>
        ) : (
          <>
            {props.title === 1 ? (
              <>{props.isVisible && <h2>{title[props.title]}</h2>}</>
            ) : (
              <h2 style={{ marginBottom: 0, marginTop: "1em" }}>
                {title[props.title]}
              </h2>
            )}

            {renderVideo()}

            <h3 style={{ marginTop: props.title === 1 ? "1em" : 0 }}>
              {renderText()}
            </h3>
          </>
        )}
      </div>

      <div className="ui-mobile">
        {props.title === 2 ? (
          <Accordion className={"accordionWrapper"}>
            <Accordion.Title active={open} onClick={() => setOpen(!open)}>
              <div className={"conditionsTitleWrapper"}>
                <span>{props.isSlidersFlow ? "Adjust My Calculator Conditions" : "Your Prequalification Conditions"}</span>

                {isOptionsAdded({
                  attributes: props.personalizedAttributes,
                  tradeIn: {
                    moneyOwed: props.money_owed,
                    trade: props.trade_in,
                  },
                }) && (
                    <Popup
                      content={
                        "Your selected Trade-In, Protection and Personalization selections may require F&I Department review and approval for prequalification. You may update your selections or you may speak with a Finance Mgr at +34 923-24-7064"
                      }
                      trigger={
                        <CautionIcon
                          className={"cautionIcon"}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                    />
                  )}
              </div>

              <ChevronIcon
                className={cn("accordionIcon", open && "accordionIconRotated")}
              />
            </Accordion.Title>

            <Accordion.Content active={open}>
              {props.title === 1 ? (
                <>{props.isVisible && <h2>{title[props.title]}</h2>}</>
              ) : (
                <h2 style={{ marginBottom: 0, marginTop: "1em" }}>
                  {title[props.title]}
                </h2>
              )}

              {renderVideo()}

              <h3 style={{ marginTop: props.title === 1 ? "1em" : 0 }}>
                {renderText()}
              </h3>
            </Accordion.Content>
          </Accordion>
        ) : (
          <>
            {props.title === 1 ? (
              <>{props.isVisible && <h2>{title[props.title]}</h2>}</>
            ) : (
              <h2 style={{ marginBottom: 0, marginTop: "1em" }}>
                {title[props.title]}
              </h2>
            )}

            {renderVideo()}

            <p style={{ marginTop: props.title === 1 ? "1em" : 0 }}>
              {renderText()}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default SliderHeader;
