import React, { useState } from "react";
import {
  Modal,
  Dimmer,
  Loader,
  Form,
  Message,
  Checkbox,
} from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import Input from "../../../global/Input";
import TextArea from "../../../global/TextArea";
import Dropdown from "../../../global/DropDown";
import DateInput from "../../../global/DateInput";
import MultiSelect from "../../../global/MultiSelect";
import Button from "../../../global/Button";

import {
  createRebate,
  deleteRebate,
  updateRebate,
} from "../../../utilities/requests";

import { schema, valueOptions } from "./config";

import "./RebateModal.sass";

const RebateModal = ({
  retailer,
  update,
  rebate,
  rebates = [],
  allRebates = [],
  children,
}) => {
  const DEFAULT_VALUE_TYPE = "usd";
  const DEFAULT_REBATE_VISIBILITY = true;
  const DEFAULT_APPLY_FOR_NEW_CAR_OPTION = false;

  const { Field } = Form;

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [stackableWith, setStackableWith] = useState([]);

  const stackableOptions = allRebates
    .filter((item) => (rebate ? item.id !== rebate.id : true))
    .map(({ title, id }) => ({
      key: id,
      text: title,
      value: id,
    }));

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const open = () => {
    reset();

    setValue("title", rebate?.title);
    setValue("description", rebate?.description);
    setValue("value", rebate?.value);
    setValue("type", rebate ? rebate.type : DEFAULT_VALUE_TYPE);
    setValue("active", rebate ? rebate.active : DEFAULT_REBATE_VISIBILITY);
    setValue(
      "forNew",
      rebate ? rebate.for_new : DEFAULT_APPLY_FOR_NEW_CAR_OPTION
    );
    setValue(
      "startDate",
      rebate
        ? moment(rebate.start_date).format("DD-MM-YYYY")
        : moment().format("DD-MM-YYYY")
    );
    setValue(
      "endDate",
      rebate
        ? moment(rebate.end_date).format("DD-MM-YYYY")
        : moment().add(3, "month").format("DD-MM-YYYY")
    );

    setStackableWith(rebate ? rebate.stackable_with : []);
    setIsError(false);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleOnChangeStackableWith = (e, { value }) => {
    setStackableWith(value);
  };

  const handleOnSubmit = async ({
    type,
    description,
    endDate,
    startDate,
    title,
    value,
    active,
    forNew: for_new,
  }) => {
    setIsLoading(true);

    try {
      const dto = {
        title,
        type,
        description,
        active,
        for_new,
        value: Number(value),
        end_date: moment(endDate, "DD-MM-YYYY").endOf("day").toISOString(),
        start_date: moment(startDate, "DD-MM-YYYY")
          .startOf("day")
          .toISOString(),
        stackable_with: stackableWith,
        retailerId: retailer.id,
      };

      if (rebate) {
        await updateRebate(rebate.id, dto);
      } else {
        await createRebate(dto);
      }

      close();
      update();
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDelete = async () => {
    setIsLoading(true);

    try {
      await deleteRebate(rebate.id);
      update();
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal open={isOpen} onClose={close} closeIcon>
        <Modal.Header>{rebate ? rebate.title : "Create Rebate"}</Modal.Header>

        <Form className={"rebateModalWrapper"}>
          <Modal.Content scrolling>
            <div className={"rebateModalColumn"}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Field required>
                    <label>Title</label>

                    <Input
                      error={errors.title && errors.title.message}
                      {...field}
                      fluid
                    />
                  </Field>
                )}
              />

              <div className={"rebateModalAmountInput"}>
                <Controller
                  name="value"
                  control={control}
                  render={({ field }) => (
                    <Field required>
                      <label>Amount</label>

                      <Input
                        {...field}
                        error={errors.value && errors.value.message}
                        label={
                          <Controller
                            name={"type"}
                            control={control}
                            render={({
                              field: { value, onChange, ...rest },
                            }) => (
                              <Dropdown
                                value={value}
                                onChange={(e, { value }) => {
                                  onChange(value);
                                }}
                                options={valueOptions}
                                {...rest}
                              />
                            )}
                          />
                        }
                        {...field}
                        fluid
                      />
                    </Field>
                  )}
                />
              </div>
            </div>

            <div className={"rebateModalColumn"}>
              <Controller
                name={"startDate"}
                control={control}
                render={({ field: { onChange, ...rest } }) => {
                  return (
                    <Field required>
                      <label>Active From</label>

                      <DateInput
                        animation={false}
                        popupPosition={"left center"}
                        onChange={(e, { value }) => {
                          onChange(value);
                        }}
                        error={errors.startDate && errors.startDate.message}
                        {...rest}
                      />
                    </Field>
                  );
                }}
              />

              <Controller
                name={"endDate"}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <Field required>
                    <label>Active Until</label>

                    <DateInput
                      animation={false}
                      popupPosition={"right center"}
                      onChange={(e, { value }) => {
                        onChange(value);
                      }}
                      error={errors.endDate && errors.endDate.message}
                      {...rest}
                    />
                  </Field>
                )}
              />
            </div>

            <Field>
              <label>Stackable With</label>

              <MultiSelect
                defaultValue={rebate ? stackableWith : []}
                options={stackableOptions}
                onChange={handleOnChangeStackableWith}
              />
            </Field>

            <Controller
              name={"description"}
              control={control}
              render={({ field }) => (
                <Field required>
                  <label>Description</label>

                  <TextArea
                    error={errors.description && errors.description.message}
                    {...field}
                  />
                </Field>
              )}
            />

            <div className={"rebateOptions"}>
              <Field className={"rebateModalToggleField"}>
                <label>Automatically Apply For New Cars</label>

                <Controller
                  name={"forNew"}
                  control={control}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <Checkbox
                      checked={value}
                      onChange={(e, { checked }) => onChange(checked)}
                      toggle
                      {...rest}
                    />
                  )}
                />
              </Field>

              {rebate && (
                <Field className={"rebateModalToggleField"}>
                  <label>Show rebate</label>

                  <Controller
                    name={"active"}
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <Checkbox
                        checked={value}
                        onChange={(e, { checked }) => onChange(checked)}
                        toggle
                        {...rest}
                      />
                    )}
                  />
                </Field>
              )}
            </div>

            {isError && (
              <Message negative style={{ marginBottom: "0.5rem" }}>
                <Message.Header>Error</Message.Header>

                <Message.Content>
                  Something went wrong. Please, try again later
                </Message.Content>
              </Message>
            )}
          </Modal.Content>

          <Modal.Actions className={"bottomActions"}>
            {rebate ? (
              <>
                <Button
                  type={"button"}
                  buttonStyle="danger"
                  onClick={handleOnDelete}
                >
                  Delete
                </Button>

                <Button onClick={handleSubmit(handleOnSubmit)}>Update</Button>
              </>
            ) : (
              <Button onClick={handleSubmit(handleOnSubmit)}>Create</Button>
            )}
          </Modal.Actions>
        </Form>

        {isLoading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </Modal>
    </>
  );
};

export default RebateModal;
