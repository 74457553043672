import React, { useState } from "react";
import { Dimmer, Icon, Loader, Modal } from "semantic-ui-react";
import ModalComponent from "../alertModal/modal";
import { getDocument, postDocument } from "../requests";
import { Editor } from "./editor/Editor";
import Button from "../../global/Button";

export const TermsConditions = ({ show }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pdf, setPdf] = useState(false);

  // ---------------------------- save html
  const save = () => {
    setIsLoading(true);
    postDocument({ type: "terms", html: text })
      .then((response) => {
        setIsLoading(false);
        setModal({ status: 201, message: "Document was updated" });
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          setModal({
            status: 500,
            message: "Sorry. An error occurred, try again later",
          });
        }
      });
  };

  // ---------------------------- get html
  const getText = () => {
    setIsLoading(true);
    getDocument("terms")
      .then((response) => {
        setIsLoading(false);
        setText(response.data.html);
        setOpen(true);
        setPdf(true);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status === 404) {
            setText("");
            setOpen(true);
          } else {
            setModal({
              status: 500,
              message: "Sorry. An error occurred, try again later",
            });
          }
        }
      });
  };

  const updateText = (value) => {
    setText(value);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Modal
        size="large"
        open={open}
        closeOnEscape={false}
        closeOnDimmerClick={true}
        closeIcon
        onClose={() => setOpen(false)}
        trigger={
          show ? (
            <a onClick={getText} href style={{ cursor: "pointer" }}>
              See Terms and Conditions
            </a>
          ) : (
            <Button buttonStyle={"skyblue"} onClick={getText}>
              Terms and Conditions
            </Button>
          )
        }
      >
        <Modal.Header>Terms and Conditions</Modal.Header>
        <Modal.Content className="editor">
          <Editor text={text} updateText={updateText} show={show} />
        </Modal.Content>
        <Modal.Actions className={"actionButtons"}>
          <Button
            className={"actionButton"}
            buttonStyle={"danger"}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>

          {!show ? (
            <>
              <Button onClick={save} className={"actionButton"}>
                Save
              </Button>

              {pdf && (
                <Button
                  role="a"
                  href="http://mypaymentyplan.s3-website-us-east-1.amazonaws.com/documents/TermsConditions.pdf"
                  target="_blank"
                  buttonStyle={"primary"}
                >
                  <Icon name="file pdf outline" /> PDF
                </Button>
              )}
            </>
          ) : (
            <Button
              role="a"
              href="http://mypaymentyplan.s3-website-us-east-1.amazonaws.com/documents/TermsConditions.pdf"
              target="_blank"
              buttonStyle={"skyblue"}
            >
              <Icon name="file pdf outline" /> PDF
            </Button>
          )}
        </Modal.Actions>
      </Modal>
      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
      {modal.message && (
        <ModalComponent modal={modal} hideModal={() => setModal({})} />
      )}
    </div>
  );
};
