import React from 'react';

const colors = ['#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
  '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
  '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'];

export const ToolBar = ({ show }) => {
  return (
    !show ?
      <div id="toolbarA2U">
        <span className='ql-formats'>
          <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option selected />
          </select>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className='ql-formats'>
          <select className="ql-color">
            <option selected />
            {
              colors.map(item => (
                <option value={item} />
              ))
            }
          </select>
          <select className="ql-background">
            <option selected value="#000000" />
            {
              colors.map(item => (
                <option value={item} />
              ))
            }
          </select>
        </span>
        <span className='ql-formats'>
          <button class="ql-script" value="super" />
          <button class="ql-script" value="sub" />
        </span>
        <span className='ql-formats'>
          <select className="ql-align">
            <option selected />
            <option value="center" />
            <option value="right" />
            <option value="justify" />
          </select>
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button class="ql-indent" value="-1" />
          <button class="ql-indent" value="+1" />
        </span>
        <span className='ql-formats'>
          <button class="ql-link" />
          <button class="ql-image" />
        </span>
        <span className='ql-formats'>
          <button class="ql-clean" />
        </span>
      </div>
      :
      <div id="toolbarA2U" className='no-toolbar'></div>
  );
};