import React from "react";
import { Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import {
  validAddress,
  validEmail,
  validPhoneNumber,
  validZipCode,
} from "../../helpers/general";
import { defaultTheme } from "../../helpers/theme";
import Address from "../address/Address";
import ModalComponent from "../alertModal/modal";
import { newRetailer, editRetailerAppearance } from "../requests";
import UserModal from "../userModal/userModal";
import Input from "../../global/Input";
import TextArea from "../../global/TextArea";
import Section from "../../global/Section";
import Button from "../../global/Button";

import "./retailerDetails.sass";

import EmptyImagePlaceholder from "../../../images/no-image-available.png";
import { ReactComponent as UserCardIcon } from "../../../images/user-card-icon.svg";
import { ReactComponent as LocationIcon } from "../../../images/location-pin.svg";
import { ReactComponent as TaxIcon } from "../../../images/tax-icon.svg";
import { ReactComponent as FileIcon } from "../../../images/file-icon.svg";
import { ReactComponent as CalendarIcon } from "../../../images/calendar-icon.svg";
import { ReactComponent as IncomeIcon } from "../../../images/income-icon.svg";
import { ReactComponent as RateIcon } from "../../../images/bar-icon.svg";
import { ReactComponent as CreditScoreIcon } from "../../../images/credit-score.svg";

const PROPERTIES = {
  name: "",
  zip_code: "",
  address: "",
  state: "",
  city: "",
  partnerdealerID: "",
  dealer_id: "",
  logo: "",
  email: "",
  phone_number: "",
  password: "",
  password_confirmation: "",
  tax_rate: "",
  dealer_fees: "",
  debit_fee_week: "",
  poor: "",
  fair: "",
  good: "",
  very_good: "",
  exceptional: "",
  min_loan_length: "",
  max_loan_length: "",
  min_down_payment: "",
  max_down_payment: "",
  annual_interest_rate_poor: "",
  annual_interest_rate_fair: "",
  annual_interest_rate_good: "",
  annual_interest_rate_very_good: "",
  annual_interest_rate_exceptional: "",
  quote_expiration: "",
  insurance_protection: "",
  text_insurance_protection: "",
  default_loan_length: "",
  default_down_payment: "",
  open: true,
};

export class AddRetailer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {},
      loading: false,
      ...PROPERTIES,
      quote_expiration: 30,
      default_loan_length: 84,
      default_down_payment: 20,
      img: null,
      msnErrors: PROPERTIES,
      msnErrorsAux: { ...PROPERTIES },
      completeForm: true,
    };
    this.onEditInput = this.onEditInput.bind(this);
    this.onSetFile = this.onSetFile.bind(this);
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    this.setState({ open: true });
  }

  // ----------------------- get form values
  onChangeState = (label, value) => {
    let msnErrors = this.state.msnErrors;
    if (label === "fullAddress") {
      msnErrors = this.switchLabel("address", value.address, msnErrors);
      msnErrors = this.switchLabel("state", value.state, msnErrors);
      msnErrors = this.switchLabel("city", value.city, msnErrors);
      msnErrors = this.switchLabel("zip_code", value.zip_code, msnErrors);
      this.setState(
        {
          address: value.address,
          state: value.state,
          city: value.city,
          zip_code: value.zip_code,
          msnErrors,
        },
        () => this.validateForm()
      );
    } else {
      msnErrors = this.switchLabel(label, value, msnErrors);
      this.setState({ [label]: value }, () => this.validateForm(msnErrors));
    }
  };

  onEditInput(e) {
    let msnErrors = this.state.msnErrors;
    if (e.target.id === "logo") {
      msnErrors = this.switchLabel("logo", e.target, msnErrors);
    } else {
      msnErrors = this.switchLabel(e.target.id, e.target.value, msnErrors);
      this.setState({ [e.target.id]: e.target.value }, () =>
        this.validateForm(msnErrors)
      );
    }
  }

  // --------------------------- validate inputs -----------------------------
  switchLabel(labels, value, msnErrors, label) {
    let annual_interest_rate_poor,
      annual_interest_rate_fair,
      annual_interest_rate_good,
      annual_interest_rate_very_good,
      annual_interest_rate_exceptional,
      max_loan_length,
      min_loan_length,
      max_down_payment,
      min_down_payment,
      poor,
      fair,
      good,
      very_good,
      exceptional;
    let val = 0;
    switch (labels) {
      case "name":
        msnErrors.name =
          value.length < 3 ? "Name must be at least 3 characters long" : "";
        break;
      case "address":
        msnErrors[labels] = validAddress(value);
        break;
      case "state":
      case "city":
      case "partnerdealerID":
        msnErrors[labels] =
          value === "" || value.split(" ").join("") === ""
            ? "The field must be required"
            : null;
        break;
      case "zip_code":
        msnErrors.zip_code = !validZipCode(value)
          ? "Zip Code must be 5 characters long"
          : null;
        break;
      case "password":
        msnErrors.password =
          value.length < 8 ? "Password must be at least 8 characters long" : "";
        break;
      case "password_confirmation":
        msnErrors.password_confirmation =
          value != this.state.password ? "Passwords do not match" : "";
        break;
      case "email":
        msnErrors.email = validEmail(value) ? "" : "Email is not valid";
        break;
      case "phone_number":
        msnErrors.phone_number = validPhoneNumber(value)
          ? ""
          : "Phone number must be 10 digits";
        break;
      case "dealer_id":
        msnErrors.dealer_id =
          value.length < 3
            ? "Dealer ID must be at least 3 characters long"
            : "";
        break;
      case "logo":
        if (value.value != "") {
          var fuData = value;
          var FileUploadPath = fuData.value;
          var Extension = FileUploadPath.substring(
            FileUploadPath.lastIndexOf(".") + 1
          ).toLowerCase();
          //The file uploaded is an image
          if (Extension == "png" || Extension == "jpeg" || Extension == "jpg") {
            msnErrors.logo = "";
            if (fuData.files && fuData.files[0]) {
              let img = URL.createObjectURL(fuData.files[0]);
              this.setState(
                {
                  [label]: FileUploadPath,
                  logoFile: fuData.files[0],
                  msnErrors,
                  img,
                },
                () => this.validateForm()
              );
            }
          } else {
            msnErrors.logo = "Logo must be a PNG, JPG or JPEG";
            this.setState({ [label]: "", msnErrors }, () =>
              this.validateForm()
            );
          }
        } else {
          msnErrors.logo = "";
          this.setState(
            { [label]: "", logoFile: null, msnErrors, img: "" },
            () => this.validateForm()
          );
        }
        break;

      case "dealer_fees":
        val = value;
        if (val != "") {
          msnErrors.dealer_fees =
            val < 0 || isNaN(val) || val == "e"
              ? "The field must be a positive number"
              : "";
        } else {
          msnErrors.dealer_fees = "";
        }
        break;
      case "tax_rate":
      case "insurance_protection":
      case "debit_fee_week":
        msnErrors[[labels]] =
          value <= 0 || isNaN(value) ? "The field must be greater than 0" : "";
        break;
      case "quote_expiration":
        msnErrors.quote_expiration =
          value <= 0 || value % 1 != 0
            ? "The field must be greater than 0 and integer number"
            : "";
        break;
      case "annual_interest_rate_poor":
        val =
          label == "annual_interest_rate_poor"
            ? value
            : parseFloat(this.state.annual_interest_rate_poor);
        annual_interest_rate_fair =
          label == "annual_interest_rate_fair"
            ? value
            : parseFloat(this.state.annual_interest_rate_fair);
        msnErrors.annual_interest_rate_poor =
          val <= 0 || val <= annual_interest_rate_fair || isNaN(val)
            ? "The field must be greater than 0 and greater than Rate Fair"
            : "";
        break;
      case "annual_interest_rate_fair":
        annual_interest_rate_good =
          label == "annual_interest_rate_good"
            ? value
            : parseFloat(this.state.annual_interest_rate_good);
        annual_interest_rate_poor =
          label == "annual_interest_rate_poor"
            ? value
            : parseFloat(this.state.annual_interest_rate_poor);
        val =
          label == "annual_interest_rate_fair"
            ? value
            : parseFloat(this.state.annual_interest_rate_fair);
        msnErrors.annual_interest_rate_fair =
          val <= annual_interest_rate_good ||
          val >= annual_interest_rate_poor ||
          isNaN(val)
            ? "The field must be greater than Rate Good and less than Rate Poor"
            : "";
        break;
      case "annual_interest_rate_good":
        annual_interest_rate_very_good =
          label == "annual_interest_rate_very_good"
            ? value
            : parseFloat(this.state.annual_interest_rate_very_good);
        annual_interest_rate_fair =
          label == "annual_interest_rate_fair"
            ? value
            : parseFloat(this.state.annual_interest_rate_fair);
        val =
          label == "annual_interest_rate_good"
            ? value
            : parseFloat(this.state.annual_interest_rate_good);
        msnErrors.annual_interest_rate_good =
          val <= annual_interest_rate_very_good ||
          val >= annual_interest_rate_fair ||
          isNaN(val)
            ? "The field must be greater than Rate Very Good and less than Rate Fair"
            : "";
        break;
      case "annual_interest_rate_very_good":
        annual_interest_rate_exceptional =
          label == "annual_interest_rate_exceptional"
            ? value
            : parseFloat(this.state.annual_interest_rate_exceptional);
        annual_interest_rate_good =
          label == "annual_interest_rate_good"
            ? value
            : parseFloat(this.state.annual_interest_rate_good);
        val =
          label == "annual_interest_rate_very_good"
            ? value
            : parseFloat(this.state.annual_interest_rate_very_good);
        msnErrors.annual_interest_rate_very_good =
          val <= annual_interest_rate_exceptional ||
          val >= annual_interest_rate_good ||
          isNaN(val)
            ? "The field must be greater than Rate Exceptional and less than Rate Very Good"
            : "";
        break;
      case "annual_interest_rate_exceptional":
        annual_interest_rate_very_good =
          label == "annual_interest_rate_very_good"
            ? value
            : parseFloat(this.state.annual_interest_rate_very_good);
        val =
          label == "annual_interest_rate_exceptional"
            ? value
            : parseFloat(this.state.annual_interest_rate_exceptional);
        msnErrors.annual_interest_rate_exceptional =
          val <= 0 || val >= annual_interest_rate_very_good || isNaN(val)
            ? "The field must be greater than 0 and less than Rate Very Good"
            : "";
        break;
      case "default_loan_length":
        val =
          label == "default_loan_length"
            ? value
            : parseFloat(this.state.default_loan_length);
        if (val != "" && !isNaN(val)) {
          min_loan_length =
            label == "min_loan_length"
              ? value
              : parseFloat(this.state.min_loan_length);
          max_loan_length =
            label == "max_loan_length"
              ? value
              : parseFloat(this.state.max_loan_length);
          msnErrors.default_loan_length =
            val < min_loan_length ||
            val > max_loan_length ||
            val % 1 != 0 ||
            isNaN(val)
              ? "The field must be and integer number, greater than or equal to Minimum Loan Length and less than or equal to Maximum Loan Length"
              : "";
        } else {
          msnErrors.default_loan_length =
            "The field must be and integer number, greater than or equal to Minimum Loan Length and less than or equal to Maximum Loan Length";
        }
        break;
      case "min_loan_length":
        val =
          label == "min_loan_length"
            ? value
            : parseFloat(this.state.min_loan_length);
        max_loan_length =
          label == "max_loan_length"
            ? value
            : parseFloat(this.state.max_loan_length);
        msnErrors.min_loan_length =
          val <= 0 || val >= max_loan_length || val % 1 != 0 || isNaN(val)
            ? "The field must be and integer number, greater than 0 and less than Maximum Loan Length"
            : "";
        break;
      case "max_loan_length":
        val =
          label == "max_loan_length"
            ? value
            : parseFloat(this.state.max_loan_length);
        min_loan_length =
          label == "min_loan_length"
            ? value
            : parseFloat(this.state.min_loan_length);
        msnErrors.max_loan_length =
          val <= min_loan_length || val % 1 != 0 || isNaN(val)
            ? "The field must be and integer number and greater than Minimum Loan Length"
            : "";
        break;
      case "default_down_payment":
        val =
          label == "default_down_payment"
            ? value
            : parseFloat(this.state.default_down_payment);
        min_down_payment =
          label == "min_down_payment"
            ? value
            : parseFloat(this.state.min_down_payment);
        max_down_payment =
          label == "max_down_payment"
            ? value
            : parseFloat(this.state.max_down_payment);
        if (val != "" && !isNaN(val)) {
          if (!isNaN(min_down_payment) && !isNaN(max_down_payment)) {
            msnErrors.default_down_payment =
              val < min_down_payment || val > max_down_payment || isNaN(val)
                ? "The field must be and number, greater than or equal to Minimum Down Payment and less than or equal to Maximum Down Payment"
                : "";
          } else {
            msnErrors.default_down_payment =
              isNaN(val) || val < 0 || val > 100
                ? "The field must be and number, greater than 0% and less than or equal to 100%"
                : "";
          }
        } else {
          if (!isNaN(min_down_payment) && !isNaN(max_down_payment)) {
            msnErrors.default_down_payment =
              "The field must be and number, greater than or equal to Minimum Down Payment and less than or equal to Maximum Down Payment";
          } else {
            msnErrors.default_down_payment =
              "The field must be and number, greater than 0% and less than or equal to 100%";
          }
        }
        break;
      case "min_down_payment":
        max_down_payment =
          label == "max_down_payment"
            ? value
            : parseFloat(this.state.max_down_payment);
        val =
          label == "min_down_payment"
            ? value
            : parseFloat(this.state.min_down_payment);
        if (max_down_payment || val) {
          msnErrors.min_down_payment =
            isNaN(val) || val === "" || val < 0 || val >= max_down_payment
              ? "The field must be greater than 0% and less than Maximum Down Payment"
              : "";
        } else {
          msnErrors.min_down_payment = "";
        }
        break;
      case "max_down_payment":
        min_down_payment =
          label == "min_down_payment"
            ? value
            : parseFloat(this.state.min_down_payment);
        val =
          label == "max_down_payment"
            ? value
            : parseFloat(this.state.max_down_payment);
        if (min_down_payment || val) {
          msnErrors.max_down_payment =
            val <= min_down_payment || isNaN(val) || val > 100
              ? "The field must be greater than Minimum Down Payment and less than or equal to 100%"
              : "";
        } else {
          msnErrors.max_down_payment = "";
        }
        break;
      case "poor":
        val = label == "poor" ? value : parseFloat(this.state.poor);
        fair = label == "fair" ? value : parseFloat(this.state.fair);
        msnErrors.poor =
          val <= 0 || val >= fair || isNaN(val)
            ? "The field must be less than Fair"
            : "";
        break;
      case "fair":
        val = label == "fair" ? value : parseFloat(this.state.fair);
        poor = label == "poor" ? value : parseFloat(this.state.poor);
        good = label == "good" ? value : parseFloat(this.state.good);
        msnErrors.fair =
          val <= poor || val >= good || isNaN(val)
            ? "The field must be greater than Poor and less than Good"
            : "";
        break;
      case "good":
        val = label == "good" ? value : parseFloat(this.state.good);
        fair = label == "fair" ? value : parseFloat(this.state.fair);
        very_good =
          label == "very_good" ? value : parseFloat(this.state.very_good);
        msnErrors.good =
          val <= fair || val >= very_good || isNaN(val)
            ? "The field must be greater than Fair and less than Very Good"
            : "";
        break;
      case "very_good":
        val = label == "very_good" ? value : parseFloat(this.state.very_good);
        good = label == "good" ? value : parseFloat(this.state.good);
        exceptional =
          label == "exceptional" ? value : parseFloat(this.state.exceptional);
        msnErrors.very_good =
          val <= good || val >= exceptional || isNaN(val)
            ? "The field must be greater than Good and less than Exceptional"
            : "";
        break;
      case "exceptional":
        val =
          label == "exceptional" ? value : parseFloat(this.state.exceptional);
        very_good =
          label == "very_good" ? value : parseFloat(this.state.very_good);
        msnErrors.exceptional =
          val <= very_good || isNaN(val)
            ? "The field must be greater than Very Good"
            : "";
        break;
      case "text_insurance_protection":
        val =
          label == "text_insurance_protection"
            ? value
            : this.state.text_insurance_protection;
        msnErrors.text_insurance_protection =
          !val || val == "" ? "The field is required" : "";
        break;
      default:
        break;
    }
    return msnErrors;
  }

  validateForm = (array = this.state.msnErrors) => {
    let valid = false;
    let msnErrors = array;
    let labels = [
      "name",
      "dealer_id",
      "email",
      "phone_number",
      "zip_code",
      "password",
      "password_confirmation",
      "annual_interest_rate_poor",
      "tax_rate",
      "dealer_fees",
      "insurance_protection",
      "debit_fee_week",
      "quote_expiration",
      "annual_interest_rate_fair",
      "annual_interest_rate_good",
      "annual_interest_rate_very_good",
      "annual_interest_rate_exceptional",
      "quote_expiration",
      "min_loan_length",
      "max_loan_length",
      "min_down_payment",
      "max_down_payment",
      "poor",
      "fair",
      "good",
      "very_good",
      "exceptional",
      "text_insurance_protection",
      "default_loan_length",
      "default_down_payment",
      "address",
      "city",
      "state",
      "partnerdealerID",
    ];
    for (let i = 0; i < labels.length; i++) {
      msnErrors = this.switchLabel(
        labels[i],
        !this.state[labels[i]] ? "" : this.state[labels[i]],
        msnErrors,
        labels[i]
      );
    }
    for (const i of labels) {
      if (msnErrors[i] !== "" && msnErrors[i] != null) {
        valid = true;
        break;
      }
    }
    this.setState({ completeForm: valid, msnErrors });
    return valid;
  };

  onSetFile(event) {
    //let nativeEvent = event.nativeEvent.target;
    let that = this;
    if (event.files && event.files[0]) {
      this.setState({ logo: event.files[0] });
      let render = new FileReader();
      render.onload = function (e) {
        that.setState({ img: e.target.result });
      };
      render.readAsDataURL(event.files[0]);
    } else {
      that.setState({ img: "", logo: "" });
    }
  }

  async send() {
    let params = { ...this.state };
    delete params.modal;
    delete params.loading;
    delete params.img;
    delete params.msnErrors;
    delete params.msnErrorsAux;
    delete params.completeForm;
    delete params.usaCities;
    let formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }
    this.setState({ loading: true });
    try {
      const response = await newRetailer(formData);
      const updateResponse = await editRetailerAppearance(
        response.data.id,
        defaultTheme
      );

      this.setState({
        loading: false,
        modal: {
          status: 201,
          message: "The retailer was created",
        },
      });
      let auxRetailer = updateResponse;
      auxRetailer["quots_count"] = 0;
      this.props.addNewRetailer(auxRetailer);
    } catch (e) {
      if (e.response) {
        if (e.response.status === 422) {
          this.setState({
            loading: false,
            modal: {
              status: 422,
              message: "Sorry. Field is missing to save the retailer",
            },
          });
        } else if (e.response.status === 406) {
          this.setState({
            loading: false,
            modal: {
              status: 422,
              message: e.response.data.message,
            },
          });
        } else {
          this.setState({
            loading: false,
            modal: {
              status: 500,
              message: "Sorry. An error occurred, please try again later",
            },
          });
        }
      }
    }
  }

  // ---------------------------- hide modal -----------------------------------
  hideModal = () => {
    if (this.state.modal.status === 201) {
      this.closeModal();
    }
    this.setState({ modal: {} });
  };

  closeModal = () => {
    this.setState({ open: false }, () => this.setState({ open: false }));
  };

  render() {
    return (
      <div className={"addRetailer"}>
        <UserModal
          title={"Add a new retailer"}
          size={"large"}
          open={this.state.open}
          setOpen={() => {
            this.setState({ open: true });
          }}
          actions={
            <>
              <Button
                buttonStyle={"danger"}
                className="actionButton"
                onClick={this.closeModal}
              >
                Close
              </Button>

              <Button
                buttonStyle={"primary"}
                onClick={this.send}
                disabled={this.state.completeForm}
                className={"actionButton"}
              >
                Save
              </Button>
            </>
          }
        >
          <Form className={"customForm"}>
            <Section>
              <div className={"headerWrapper"}>
                <UserCardIcon />

                <h3>Contact</h3>
              </div>

              <Grid className={"upperSection"}>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Form.Group>
                    <Form.Field width={8}>
                      <label>
                        Retailer name
                        <span className="required-inut"> *</span>
                      </label>

                      <Input
                        id={"name"}
                        onChange={this.onEditInput}
                        error={
                          this.state.name.length > 0 &&
                          this.state.msnErrors.name &&
                          this.state.msnErrors.name
                            ? this.state.msnErrors.name
                            : null
                        }
                      />
                    </Form.Field>

                    <Form.Field width={8}>
                      <label>
                        E-mail Address
                        <span className="required-inut"> *</span>
                      </label>

                      <Input
                        id={"email"}
                        onChange={this.onEditInput}
                        error={
                          this.state.email.length > 0 &&
                          this.state.msnErrors.email
                            ? this.state.msnErrors.email
                            : null
                        }
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group>
                    <Form.Field width={8}>
                      <label>
                        Password<span className="required-inut"> *</span>
                      </label>

                      <Input
                        id={"password"}
                        onChange={this.onEditInput}
                        type={"password"}
                        error={
                          this.state.password.length > 0 &&
                          this.state.msnErrors.password
                            ? this.state.msnErrors.password
                            : null
                        }
                      />
                    </Form.Field>

                    <Form.Field width={8}>
                      <label>
                        Repeat password
                        <span className="required-inut"> *</span>
                      </label>

                      <Input
                        id={"password_confirmation"}
                        onChange={this.onEditInput}
                        type={"password"}
                        error={
                          this.state.password_confirmation.length > 0 &&
                          this.state.msnErrors.password_confirmation
                            ? this.state.msnErrors.password_confirmation
                            : null
                        }
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group>
                    <Form.Field width={8}>
                      <label>
                        Dealer ID<span className="required-inut"> *</span>
                      </label>

                      <Input
                        id={"dealer_id"}
                        onChange={this.onEditInput}
                        error={
                          this.state.dealer_id.length > 0 &&
                          this.state.msnErrors.dealer_id
                            ? this.state.msnErrors.dealer_id
                            : null
                        }
                      />
                    </Form.Field>

                    <Form.Field width={8}>
                      <label>
                        Partner dealer ID
                        <span className="required-inut"> *</span>
                      </label>

                      <Input
                        id={"partnerdealerID"}
                        onChange={this.onEditInput}
                        error={
                          this.state.partnerdealerID.length > 0 &&
                          this.state.msnErrors.partnerdealerID
                            ? this.state.msnErrors.partnerdealerID
                            : null
                        }
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Field width={16}>
                    <label>
                      Phone number<span className="required-inut"> *</span>
                    </label>

                    <Input
                      className="form-register"
                      id={"phone_number"}
                      label="+1"
                      placeholder="Phone Number"
                      onChange={this.onEditInput}
                      maxLength="15"
                      min="12"
                      error={
                        this.state.phone_number.length > 0 &&
                        this.state.msnErrors.phone_number
                          ? this.state.msnErrors.phone_number
                          : null
                      }
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  className={"imageContainer"}
                >
                  <Grid.Column className={"newRetailerImage"}>
                    <img
                      src={this.state.img || EmptyImagePlaceholder}
                      alt={"current"}
                    />
                  </Grid.Column>

                  <Form.Field>
                    <label>Retailer logo</label>

                    <Input
                      id={"logo"}
                      type="file"
                      onChange={(e) => this.onSetFile(e.target)}
                      accept="image/png,image/jpg,image/jpeg"
                      className={"imageInput"}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Section>

            <Section className={"locationWrapper"}>
              <div className={"headerWrapper"}>
                <LocationIcon />

                <h3>Location</h3>
              </div>

              <Address
                address={{
                  address: this.state.address,
                  city: this.state.city,
                  zip_code: this.state.zip_code,
                  state: this.state.state,
                }}
                onChangeState={this.onChangeState}
                flagEdit={this.state.flagEdit}
                msnErrors={this.state.msnErrors}
              />
            </Section>

            <Section>
              <div className={"headerWrapper"}>
                <FileIcon />

                <h3>Insurance</h3>
              </div>

              <Form.Group>
                <Form.Field width={8}>
                  <label>
                    Insurance Protection (%)
                    <span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"insurance_protection"}
                    onChange={this.onEditInput}
                    type={"number"}
                    error={
                      this.state.insurance_protection.length > 0 &&
                      this.state.msnErrors.insurance_protection
                        ? this.state.msnErrors.insurance_protection
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field width={8}>
                  <label>
                    Insurance and Protection Text
                    <span className="required-inut"> *</span>
                  </label>

                  <TextArea
                    id={"text_insurance_protection"}
                    value={this.state.text_insurance_protection}
                    placeholder="Insurance and Protection Text"
                    onChange={this.onEditInput}
                    error={
                      this.state.text_insurance_protection.length > 0 &&
                      this.state.msnErrors.text_insurance_protection
                        ? this.state.msnErrors.text_insurance_protection
                        : null
                    }
                  />
                </Form.Field>
              </Form.Group>
            </Section>

            <Section>
              <div className={"headerWrapper"}>
                <TaxIcon />

                <h3>Tax</h3>
              </div>

              <Form.Group>
                <Form.Field width={8}>
                  <label>
                    Tax rate (%)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"tax_rate"}
                    onChange={this.onEditInput}
                    type={"number"}
                    error={
                      this.state.tax_rate.length > 0 &&
                      this.state.msnErrors.tax_rate
                        ? this.state.msnErrors.tax_rate
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field width={8}>
                  <label>Dealer Fees ($)</label>

                  <Input
                    id={"dealer_fees"}
                    onChange={this.onEditInput}
                    type={"number"}
                    error={
                      this.state.dealer_fees.length > 0 &&
                      this.state.msnErrors.dealer_fees
                        ? this.state.msnErrors.dealer_fees
                        : null
                    }
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={8}>
                  <label>
                    Debit fee week ($)
                    <span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"debit_fee_week"}
                    onChange={this.onEditInput}
                    type={"number"}
                    error={
                      this.state.debit_fee_week.length > 0 &&
                      this.state.msnErrors.debit_fee_week
                        ? this.state.msnErrors.debit_fee_week
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field width={8}>
                  <label>
                    Deal expiration (days)
                    <span className="required-inut"> *</span>
                  </label>
                  <Input
                    id={"quote_expiration"}
                    onChange={this.onEditInput}
                    type={"number"}
                    value={this.state.quote_expiration}
                    error={
                      this.state.quote_expiration.length > 0 &&
                      this.state.msnErrors.quote_expiration
                        ? this.state.msnErrors.quote_expiration
                        : null
                    }
                  />
                </Form.Field>
              </Form.Group>
            </Section>

            <Section>
              <div className={"headerWrapper"}>
                <CalendarIcon />

                <h3>Loan Length</h3>
              </div>

              <Form.Group widths={"equal"}>
                <Form.Field width={5}>
                  <label>
                    Minimum (mo)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"min_loan_length"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.min_loan_length
                        ? this.state.msnErrors.min_loan_length
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field width={5}>
                  <label>
                    Maximum (mo)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"max_loan_length"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.max_loan_length
                        ? this.state.msnErrors.max_loan_length
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field width={6}>
                  <label>
                    Default (mo)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"default_loan_length"}
                    type={"number"}
                    onChange={this.onEditInput}
                    value={this.state.default_loan_length}
                    error={
                      this.state.msnErrors.default_loan_length
                        ? this.state.msnErrors.default_loan_length
                        : null
                    }
                  />
                </Form.Field>
              </Form.Group>
            </Section>

            <Section>
              <div className={"headerWrapper"}>
                <IncomeIcon />

                <h3>Down Payment</h3>
              </div>

              <Form.Group widths={"equal"}>
                <Form.Field width={5}>
                  <label>Minimum (%)</label>

                  <Input
                    id={"min_down_payment"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.min_down_payment
                        ? this.state.msnErrors.min_down_payment
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field width={5}>
                  <label>Maximum (%)</label>

                  <Input
                    id={"max_down_payment"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.max_down_payment
                        ? this.state.msnErrors.max_down_payment
                        : null
                    }
                  />
                </Form.Field>

                <Form.Field width={6}>
                  <label>
                    Default (%)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"default_down_payment"}
                    type={"number"}
                    onChange={this.onEditInput}
                    value={this.state.default_down_payment}
                    error={
                      this.state.msnErrors.default_down_payment
                        ? this.state.msnErrors.default_down_payment
                        : null
                    }
                  />
                </Form.Field>
              </Form.Group>
            </Section>

            <Section>
              <div className={"headerWrapper"}>
                <CreditScoreIcon />

                <h3>Credit Score Range</h3>
              </div>

              <Form.Group widths={"equal"} className={"creditRange"}>
                <Form.Field>
                  <label>
                    Poor<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"poor"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.poor
                        ? this.state.msnErrors.poor
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Fair<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"fair"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.fair
                        ? this.state.msnErrors.fair
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Good<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"good"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.good
                        ? this.state.msnErrors.good
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Very Good<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"very_good"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.very_good
                        ? this.state.msnErrors.very_good
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Exceptional<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"exceptional"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.exceptional
                        ? this.state.msnErrors.exceptional
                        : null
                    }
                    fluid
                  />
                </Form.Field>
              </Form.Group>
            </Section>

            <Section>
              <div className={"headerWrapper"}>
                <RateIcon />

                <h3>Annual interest rate</h3>
              </div>

              <Form.Group widths={"equal"} className={"creditRange"}>
                <Form.Field>
                  <label>
                    Poor (%)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"annual_interest_rate_poor"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.annual_interest_rate_poor
                        ? this.state.msnErrors.annual_interest_rate_poor
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Fair (%)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"annual_interest_rate_fair"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.annual_interest_rate_fair
                        ? this.state.msnErrors.annual_interest_rate_fair
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Good (%)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"annual_interest_rate_good"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.annual_interest_rate_good
                        ? this.state.msnErrors.annual_interest_rate_good
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Very Good (%)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"annual_interest_rate_very_good"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.annual_interest_rate_very_good
                        ? this.state.msnErrors.annual_interest_rate_very_good
                        : null
                    }
                    fluid
                  />
                </Form.Field>

                <Form.Field>
                  <label>
                    Exceptional (%)<span className="required-inut"> *</span>
                  </label>

                  <Input
                    id={"annual_interest_rate_exceptional"}
                    type={"number"}
                    onChange={this.onEditInput}
                    error={
                      this.state.msnErrors.annual_interest_rate_exceptional
                        ? this.state.msnErrors.annual_interest_rate_exceptional
                        : null
                    }
                    fluid
                  />
                </Form.Field>
              </Form.Group>
            </Section>
          </Form>
        </UserModal>

        {this.state.loading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}

        {this.state.modal.message && (
          <ModalComponent hideModal={this.hideModal} modal={this.state.modal} />
        )}
      </div>
    );
  }
}
