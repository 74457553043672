import React, { useState, useEffect } from "react";
import { Modal, Dimmer, Loader, Popup } from "semantic-ui-react";
import axios from "axios";

import Button from "../../../global/Button";

import { ReactComponent as InfoIcon } from "../../../../images/infoCircle.svg";

import { FormatNumberToPrice } from "../../../helpers/general";

import "./CoxProgramPlanModal.sass";

const CoxProgramPlanModal = ({ requestData, onSelect, children }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [programs, setPrograms] = useState([]);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const fetchPrograms = async () => {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEzLCJlbWFpbCI6InJldGFpbGVyQGVtYWlsLmNvbSIsInBob25lX251bWJlciI6Ijk2NTEzMzUzMzQiLCJyb2xlIjoiUmV0YWlsZXIiLCJpYXQiOjE2OTIxMDI4MDksImV4cCI6MTY5NDY5NDgwOX0.cltcZUjN8x5srJLcpa5c7Rk8IExz6A4EX7SW2W6kaDU";

    setLoading(true);

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_NGROK_URL}/external/cox/program-quotes`,
        { params: requestData, headers: { Authorization: `Bearer ${token}` } }
      );

      setPrograms(data.program_quotes_results);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal open={isOpen} closeIcon onClose={close}>
        <Modal.Header>Select a program</Modal.Header>

        <Modal.Content scrolling>
          <ul className={"programsList"}>
            {programs.map((item) => {
              const quot = item.quotes[0].program;

              return (
                <li
                  key={item.item_caller_id}
                  className={"programsListItem"}
                  onClick={() => {
                    onSelect && onSelect();
                    close();
                  }}
                >
                  <h4 className={"lenderName"}>{quot.lender_long_name}</h4>

                  <div className={"record"}>
                    <div>{`Program Type: ${quot.program_type}`}</div>

                    <div className="withExtra">
                      {`Credit Tier: ${quot.credit_tier}`}{" "}
                      <Popup
                        position={"top right"}
                        trigger={<InfoIcon />}
                        content={<span>{quot.credit_tier_desc}</span>}
                      />
                    </div>
                  </div>

                  <div className={"record"}>
                    <div>
                      <span
                        className={"boldValue"}
                      >{`Maximum Advance: ${FormatNumberToPrice(
                        quot.max_advance
                      )} (${quot.max_advance_percent}%)`}</span>
                    </div>

                    <div>{`Term: ${quot.term} mo`}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Modal.Content>

        <Modal.Actions>
          <Button buttonStyle="danger" onClick={close}>
            Close
          </Button>
        </Modal.Actions>

        {loading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </Modal>
    </>
  );
};

export default CoxProgramPlanModal;
