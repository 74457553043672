import React from "react";
import cn from "classnames";
import { Button as SemanticButton } from "semantic-ui-react";

import { defaultTheme } from "../../helpers/theme";

import "./Button.sass";

const Button = ({
  buttonStyle = "primary",
  className,
  children,
  theme,
  ...rest
}) => {
  return (
    <div
      style={{
        "--button-primary-background":
          theme?.primary?.background || defaultTheme.button.primary.background,
        "--button-primary-color":
          theme?.primary?.color || defaultTheme.button.primary.color,
        "--button-secondary-background":
          theme?.secondary?.background ||
          defaultTheme.button.secondary.background,
        "--button-secondary-color":
          theme?.secondary?.color || defaultTheme.button.secondary.color,
        "--button-success-background":
          theme?.success?.background || defaultTheme.button.success.background,
        "--button-success-color":
          theme?.success?.color || defaultTheme.button.success.color,
        "--button-negative-background":
          theme?.negative?.background ||
          defaultTheme.button.negative.background,
        "--button-negative-color":
          theme?.negative?.color || defaultTheme.button.negative.color,
        "--button-border-radius":
          (theme?.borderRadius || defaultTheme.button.borderRadius) + "px",
      }}
      className={cn(
        "customButtonWrapper",
        buttonStyle === "skyblue" && "skyblue",
        buttonStyle === "secondary" && "secondary",
        buttonStyle === "primary" && "primary",
        buttonStyle === "danger" && "red",
        buttonStyle === "brown" && "brown",
        buttonStyle === "grey" && "grey",
        className
      )}
    >
      <SemanticButton {...rest}>{children}</SemanticButton>
    </div>
  );
};

export default Button;
