import React, { Component } from "react";
import _ from 'lodash';
import { Redirect, withRouter } from "react-router-dom";
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

// IMPORT CSS STYLES
import './NotFound.sass';

class NotFoundComponent extends Component {
  

    constructor(props) {
        super(props);
    }

    goBack = () => {
        this.props.history.push("login");
    }

    render() {
        return (
            <div className="grappper-notfound">
                <div>
                    <h2>404 NOT FOUND</h2>
                    <h3 onClick={this.goBack}><Icon name="arrow left"/>Go back to home</h3>
                </div>
            </div>
        );
    }
}

export default withRouter(NotFoundComponent);