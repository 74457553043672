import React from "react";
import { Checkbox, Icon } from "semantic-ui-react";
import _ from "lodash";
import cn from "classnames";

import ProductModal from "./ProductModal";

import "./ListItem.sass";

const ListItem = ({
  label,
  coverage,
  categoryKey,
  packageId,
  isSelected,
  selectedProduct,
  isAllEmpty,
  onCheck,
}) => {
  const isEmptyCoverages = _.isEmpty(coverage);
  const formula = !isEmptyCoverages && {
    amount: coverage.value,
    type: coverage.type,
  };

  const handleOnSubmit = ({ term, isDelete = false }) => {
    onCheck({ packageId, categoryKey, term, isDelete });
  };

  return (
    <li
      className={cn(
        "listItem",
        isEmptyCoverages && !isAllEmpty && "listItemEmpty"
      )}
    >
      <span>{label}</span>

      {isEmptyCoverages ? (
        <span>N/A</span>
      ) : (
        <div className={"itemActionsWrapper"}>
          {isSelected && (
            <ProductModal
              onSubmit={handleOnSubmit}
              terms={coverage.rates.coverage.term}
              categoryName={label}
              alreadySelectedTerm={selectedProduct}
              formula={formula}
            >
              <button className={"editSelectedProduct"}>
                <Icon name="cog" />
              </button>
            </ProductModal>
          )}

          <ProductModal
            onSubmit={handleOnSubmit}
            isChecked={isSelected}
            categoryName={label}
            terms={coverage.rates.coverage.term}
            formula={formula}
          >
            <Checkbox checked={isSelected} />
          </ProductModal>
        </div>
      )}
    </li>
  );
};

export default ListItem;
