import React from 'react';
import { Step } from 'semantic-ui-react';

export const StepsLoanApplication = (props) => {
  const { step } = props;
  return (
    <Step.Group style={{ width: "100%" }}>
      <Step active={step === 0}>
        <Step.Content>
          <Step.Title>Personal Information</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 1}>
        <Step.Content>
          <Step.Title>Co-Applicant</Step.Title>
        </Step.Content>
      </Step>
      {/* <Step active={step === 2}>
        <Step.Content>
          <Step.Title>Guarantor</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 3}>
        <Step.Content>
          <Step.Title>Driver</Step.Title>
        </Step.Content>
      </Step> */}
      <Step active={step === 2}>
        <Step.Content>
          <Step.Title>Complete Process</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};