import React, { useState, useEffect } from "react";
import { Header, Divider, Popup } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";

import RebateCard from "./RebateCard";
import BottomBar from "./BottomBar";

import { getRebateValue } from "../../../helpers/general";
import { useTheme } from "../../../helpers/theme";

import { ReactComponent as InfoCircleIcon } from "../../../../images/infoCircle.svg";

import "./Rebates.sass";

const Rebates = ({
  open,
  rebates,
  dealerRebates,
  appliedRebates,
  car,
  onSave,
}) => {
  const { theme } = useTheme();

  const [quotesWithRebates, setQuotesWithRebates] = useState([]);
  console.log(quotesWithRebates);
  const [specialsPrograms, setSpecialsPrograms] = useState([]);
  const [rebatesFromDealer, setRebatesFromDealer] = useState([]);
  const [rebatesFromDealerForNewCar, setRebatesFromDealerForNewCar] = useState(
    []
  );

  const [selectedProgramRebates, setSelectedProgramRebates] = useState([]);
  const [selectedUnstackbleRebates, setSelectedUnstackbleRebates] = useState(
    []
  );
  const [selectedRebatesFromDealer, setSelectedRebatesFromDealer] = useState(
    []
  );
  const [
    selectedRebatesFromDealerForNewCar,
    setSelectedRebatesFromDealerForNewCar,
  ] = useState([]);

  const scrollToTop = () => {
    const myDiv = document.getElementsByClassName("rebatePage")[0];

    if (!myDiv) return;

    myDiv.scrollTop = 0;
  };

  const handleOnSelectProgramRebate = (rebateProgramId) => {
    setSelectedProgramRebates(_.xor(selectedProgramRebates, [rebateProgramId]));
  };

  const handleOnSelectUnstackbleRebate = (rebateId) => {
    const updated = _.xor(selectedUnstackbleRebates, [rebateId]);

    setSelectedUnstackbleRebates(updated);

    if (!!updated.length) {
      setSelectedProgramRebates([]);
    }
  };

  const handleOnSelectDealerRebate = (rebateId) => {
    const updated = _.xor(selectedRebatesFromDealer, [rebateId]);

    setSelectedRebatesFromDealer(updated);
    setSelectedProgramRebates([]);
    setSelectedUnstackbleRebates([]);
  };

  const handleOnSelectRebateFromDealerForNewCars = (rebateId) => {
    setSelectedRebatesFromDealerForNewCar(
      _.xor(selectedRebatesFromDealerForNewCar, [rebateId])
    );
  };

  const checkCompatibilityWithDealerRebates = (rebate) => {
    if (selectedRebatesFromDealer.length === 0) return true;

    const compatibleWith = selectedRebatesFromDealer.reduce((arr, id) => {
      const _rebate = rebatesFromDealer.find((r) => r.id === id);

      return arr.length > 0
        ? _.intersection(arr, _rebate.stackable_with)
        : _rebate.stackable_with;
    }, []);

    return compatibleWith.length > 0
      ? compatibleWith.includes(rebate.id)
      : false;
  };

  const checkCompatibilityWithDealerRebatesForNewCars = (rebate) => {
    if (selectedRebatesFromDealerForNewCar.length === 0) return true;

    const compatibleWith = selectedRebatesFromDealerForNewCar.reduce(
      (arr, id) => {
        const _rebate = rebatesFromDealerForNewCar.find((r) => r.id === id);

        return arr.length > 0
          ? _.intersection(arr, _rebate.stackable_with)
          : _rebate.stackable_with;
      },
      []
    );

    return compatibleWith.length > 0
      ? compatibleWith.includes(rebate.id)
      : false;
  };

  const checkCompatibilityWithProgramRebates = (program) => {
    if (selectedProgramRebates.length === 0) return true;

    const compatibleWith = selectedProgramRebates.reduce((arr, id) => {
      const _rebate = specialsPrograms.find((r) => r.special_program_id === id);

      return arr.length > 0
        ? _.intersection(_rebate.compatible_programs, arr)
        : _rebate.compatible_programs;
    }, []);

    return compatibleWith.length > 0
      ? compatibleWith.includes(program.special_program_id)
      : false;
  };

  const getSelectedRebates = () => {
    const _programRebates = selectedProgramRebates.reduce((arr, id) => {
      const _rebate = specialsPrograms.find((r) => r.special_program_id === id);

      return [...arr, _rebate];
    }, []);
    const _dealerRebates = selectedRebatesFromDealer.reduce((arr, id) => {
      const _rebate = rebatesFromDealer.find((r) => r.id === id);

      return [...arr, _rebate];
    }, []);
    const _unstackbleRebates = selectedUnstackbleRebates.reduce((arr, id) => {
      const _rebate = quotesWithRebates.find(
        (quote) => quote.rebate_detail.rebate_entry.rebate_id === id
      );

      return [...arr, _rebate];
    }, []);
    const _rebatesForNewCar = selectedRebatesFromDealerForNewCar.reduce(
      (arr, id) => {
        const _rebate = rebatesFromDealerForNewCar.find((r) => r.id === id);

        return _rebate ? [...arr, _rebate] : arr;
      },
      []
    );

    const _selectedRebates = {
      programRebates: _programRebates,
      dealerRebates: _dealerRebates,
      quotesWithRebates: _unstackbleRebates,
      rebatesForNewCar: _rebatesForNewCar,
    };

    return _selectedRebates;
  };

  const getFormattedRebates = (rebates) =>
    rebates.reduce((acc, quote) => {
      const isArray = Array.isArray(quote.rebate_detail.rebate_entry);

      const item = isArray
        ? quote.rebate_detail.rebate_entry.map((rebate_entry) => ({
            ...quote,
            rebate_detail: {
              rebate_entry,
            },
          }))
        : [quote];

      return [...acc, ...item];
    }, []);

  useEffect(() => {
    scrollToTop();
  }, [open]);

  useEffect(() => {
    if (!rebates) return;

    setQuotesWithRebates(getFormattedRebates(rebates.quotes_with_rebates));
    setSpecialsPrograms(rebates.special_programs);

    // setQuotesWithRebates(
    //   rebates.quotes_with_rebates?.filter((quote) =>
    //     moment(quote.rebates_expiration_date).isBefore(moment())
    //   )
    // );
    // setSpecialsPrograms(
    //   rebates.special_programs?.filter((program) =>
    //     moment(program.stop_date).isBefore(moment())
    //   )
    // );
  }, [rebates]);

  useEffect(() => {
    if (!open) return;

    setRebatesFromDealer(
      dealerRebates.filter((rebate) => rebate.active && !rebate.for_new)
    );
    setRebatesFromDealerForNewCar(
      dealerRebates.filter((rebate) => rebate.active && rebate.for_new)
    );
  }, [open, dealerRebates]);

  useEffect(() => {
    if (!appliedRebates) return;

    const rebatesForNewCar = appliedRebates.rebatesForNewCar || [];
    const quotesWithRebates = appliedRebates.quotesWithRebates || [];
    const programRebates = appliedRebates.programRebates || [];
    const dealerRebates = appliedRebates.dealerRebates || [];

    setSelectedRebatesFromDealerForNewCar(rebatesForNewCar.map((r) => r.id));
    setSelectedUnstackbleRebates(
      quotesWithRebates.map((r) => r.rebate_detail.rebate_entry.rebate_id)
    );
    setSelectedProgramRebates(programRebates.map((r) => r.special_program_id));
    setSelectedRebatesFromDealer(dealerRebates.map((r) => r.id));
  }, [appliedRebates]);

  return (
    <>
      {open && (
        <div
          className={"rebatesPage"}
          id={"rebatePage"}
          style={{ "--secondary-color": theme?.typography?.secondary }}
        >
          <Header as={"h2"} className={"rebatesPageTitle"}>
            Rebates, Incentives & Special Offers
          </Header>

          <Header as={"h3"} className={"rebatesPageSubTitle"}>
            Do You Qualify For Additional Rebates, Incentives or Special Finance
            Offers?
          </Header>

          <p className={"rebatesPageCaption"}>
            Additional incentives may be available. Please, select from the
            offers below
          </p>

          <p>Are All Eligible Rebates:</p>

          {!!rebatesFromDealerForNewCar.length && (
            <>
              <ul className={"listOfRebates rebatesListStyleReset"}>
                {rebatesFromDealerForNewCar.map((rebate) => {
                  const isCompatible =
                    checkCompatibilityWithDealerRebatesForNewCars(rebate);
                  const isSelected =
                    selectedRebatesFromDealerForNewCar.includes(rebate.id);
                  const isDisabled =
                    !isCompatible &&
                    !selectedRebatesFromDealerForNewCar.includes(rebate.id);

                  return (
                    <RebateCard
                      key={rebate.id}
                      name={rebate.title}
                      details={rebate.description}
                      value={getRebateValue(rebate.value, rebate.type)}
                      id={rebate.id}
                      onClick={handleOnSelectRebateFromDealerForNewCars}
                      isSelected={isSelected}
                      isDisabled={isDisabled}
                    />
                  );
                })}
              </ul>

              <Divider />
            </>
          )}

          {quotesWithRebates.length > 0 && (
            <>
              <ul className={"listOfRebates rebatesListStyleReset"}>
                {quotesWithRebates.map((quote) => {
                  const rebateId = quote.rebate_detail.rebate_entry.rebate_id;
                  const rebateValue = Number(
                    quote.rebate_detail.rebate_entry.rebate_value
                  ).toFixed(0);
                  const isDisabled =
                    selectedProgramRebates.length > 0 ||
                    selectedRebatesFromDealer.length > 0;

                  return (
                    <RebateCard
                      key={rebateId}
                      name={quote.rebate_detail.rebate_entry.rebate_name}
                      value={getRebateValue(rebateValue)}
                      until={quote.rebates_expiration_date}
                      id={rebateId}
                      onClick={handleOnSelectUnstackbleRebate}
                      isSelected={selectedUnstackbleRebates.includes(rebateId)}
                      isDisabled={isDisabled}
                    />
                  );
                })}
              </ul>

              <Divider />
            </>
          )}

          {specialsPrograms.length > 0 && (
            <ul className={"listOfRebates rebatesListStyleReset"}>
              {specialsPrograms.map((program) => {
                const isCompatibleWithSelectedPrograms =
                  checkCompatibilityWithProgramRebates(program);
                const isDisabled =
                  selectedUnstackbleRebates.length > 0 ||
                  selectedRebatesFromDealer.length > 0 ||
                  (!isCompatibleWithSelectedPrograms &&
                    !selectedProgramRebates.includes(
                      program.special_program_id
                    ));

                return (
                  <RebateCard
                    name={program.special_program_name}
                    value={getRebateValue(program.cash)}
                    details={program.description}
                    until={program.stop_date}
                    id={program.special_program_id}
                    key={program.special_program_id}
                    onClick={handleOnSelectProgramRebate}
                    isSelected={selectedProgramRebates.includes(
                      program.special_program_id
                    )}
                    isDisabled={isDisabled}
                  />
                );
              })}
            </ul>
          )}

          {rebatesFromDealer.length > 0 && (
            <>
              <Header
                as={"h3"}
                className={
                  "rebatesPageSubTitle rebatePageTitleWithIcon rebatePageDealerRebatesTitle"
                }
              >
                Additional Lender Incentives For Which You May Qualify
                <Popup
                  content={
                    <span>
                      Based On Qualified Credit Approval or Personal Work
                      History
                    </span>
                  }
                  trigger={<InfoCircleIcon />}
                />
              </Header>

              <ul className={"listOfRebates rebatesListStyleReset"}>
                {rebatesFromDealer.map((rebate) => {
                  const isCompatibleWithSelectedDealerRebates =
                    checkCompatibilityWithDealerRebates(rebate);
                  const isDisabled =
                    selectedProgramRebates.length > 0 ||
                    selectedUnstackbleRebates.length > 0 ||
                    (!isCompatibleWithSelectedDealerRebates &&
                      !selectedRebatesFromDealer.includes(rebate.id));

                  return (
                    <RebateCard
                      key={rebate.id}
                      name={rebate.title}
                      details={rebate.description}
                      id={rebate.id}
                      value={getRebateValue(rebate.value, rebate.type)}
                      onClick={handleOnSelectDealerRebate}
                      isSelected={selectedRebatesFromDealer.includes(rebate.id)}
                      isDisabled={isDisabled}
                    />
                  );
                })}
              </ul>
            </>
          )}

          <BottomBar
            selectedRebates={getSelectedRebates()}
            car={car}
            onSave={onSave}
          />
        </div>
      )}
    </>
  );
};

export default Rebates;
