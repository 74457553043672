import React from 'react'
import cn from 'classnames'

import './styles.sass'

const Section = ({ children, className }) => {
  return (<div className={cn('sectionWrapper', className)}>{children}</div>)
}

export default Section
