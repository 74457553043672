import React, { useRef } from "react";
import { Modal } from "semantic-ui-react";
import Cropper from "react-cropper";

import Button from "../../../../global/Button";

import "./CropModal.sass";
import "cropperjs/dist/cropper.css";

const CropModal = ({ file, open, onClose, onSuccess }) => {
  const cropperRef = useRef();

  const handleOnCropImage = () => {
    const croppedImage = cropperRef.current.cropper.getCroppedCanvas();

    new Promise((resolve) => {
      croppedImage.toBlob((blob) => resolve(blob));
    }).then((blob) => {
      onSuccess(blob);
      onClose();
    });
  };

  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick={false} closeIcon>
      <Modal.Header>Crop Image</Modal.Header>

      <Modal.Content>
        <Cropper
          src={file}
          ref={cropperRef}
          aspectRatio={1}
          viewMode={1}
          dragMode={"move"}
          highlight={false}
          toggleDragModeOnDblclick={false}
          minCropBoxWidth={128}
          minCropBoxHeight={128}
          maxCropBoxWidth={1024}
          maxCropBoxHeight={1024}
          className={"cropper"}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button buttonStyle="danger" onClick={onClose}>
          Close
        </Button>

        <Button buttonStyle="skyblue" onClick={handleOnCropImage}>
          Apply
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CropModal;
