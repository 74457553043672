import React, { useState } from "react";
import { Checkbox, Modal } from "semantic-ui-react";
import _ from "lodash";

import Button from "../../../../../../../global/Button";
import Dropdown from "../../../../../../../global/DropDown";

import {
  FormatNumberToPrice,
  calculateCoverageRetailerPriceOfTerm,
} from "../../../../../../../helpers/general";
import { useTheme } from "../../../../../../../helpers/theme";

import "./ProductModal.sass";

const ProductModal = ({
  alreadySelectedTerm,
  categoryName,
  onSubmit,
  terms,
  formula,
  isChecked,
  children,
}) => {
  const { theme } = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState();
  const [patchedTerms, setPatchedTerms] = useState([]);

  const generateOptionsToSelect = () => {
    const options = terms
      .map((term) => {
        const isMiles = !!term.miles;

        if (isMiles) {
          return term.miles.map((m) => ({
            key: `${term.value}/${m.value}`,
            value: `${term.value}/${m.value}`,
            text: `${term.value} months/${m.value / 1000}K miles`,
          }));
        }

        return {
          key: term.value,
          value: term.value,
          text: `${term.value} months`,
        };
      })
      .flat(1);

    const updatedTerms = terms.map((term) => {
      const isMiles = !!term.miles;

      if (isMiles) {
        const _updatedMiles = term.miles.map((i) => {
          const deductibleTerm = i?.deductible.map((_deductibleTerm) => {
            const isSelectedTerm =
              alreadySelectedTerm?.months === term.value &&
              alreadySelectedTerm?.miles === i.value;

            if (isSelectedTerm) {
              return {
                ..._deductibleTerm,
                dynamic_surcharge: alreadySelectedTerm.dynamic_surcharge,
              };
            }

            return {
              ..._deductibleTerm,
              dynamic_surcharge: _deductibleTerm.dynamic_surcharge.map(
                (option) => ({
                  ...option,
                  id: _.uniqueId(),
                  isSelected: false,
                })
              ),
            };
          });

          return { ...i, deductible: deductibleTerm };
        });

        return { ...term, miles: _updatedMiles };
      }

      return term;
    });

    const term = getInfoBySelectedOption({
      option: options[0].value,
      updatedTerms,
    })[0];

    if (alreadySelectedTerm) {
      const months = alreadySelectedTerm.months;
      const miles = alreadySelectedTerm.miles;
      const option = miles ? months + "/" + miles : months;

      setSelectedOption(option);
      setSelectedTerm(alreadySelectedTerm);
    } else {
      setSelectedOption(options[0].value);
      setSelectedTerm(term);
    }

    setPatchedTerms(updatedTerms);

    setDropdownOptions(options);
  };

  const getInfoBySelectedOption = ({
    option = selectedOption,
    updatedTerms = terms,
  }) => {
    const splitted = option?.split("/");
    const month = splitted?.[0];
    const miles = splitted?.[1];

    const termByMonths = updatedTerms.find((item) => item.value === month);

    if (!!miles) {
      const termByMiles = termByMonths.miles.find(
        (item) => item.value === miles
      );
      const deductibleTerm = termByMiles?.deductible.find(
        (i) => Number(i.value) === 0
      );

      return [deductibleTerm];
    }

    return [termByMonths];
  };

  const open = (e) => {
    e.stopPropagation();

    if (isChecked) {
      onSubmit({ isDelete: true });

      return;
    }

    generateOptionsToSelect();
    setIsOpen(true);
  };

  const close = (e) => {
    e.stopPropagation && e.stopPropagation();

    setIsOpen(false);
  };

  const handleOnSubmit = (e) => {
    e.stopPropagation();

    const splitted = selectedOption?.split("/");
    const _term = {
      ...selectedTerm,
      months: splitted?.[0],
      miles: splitted?.[1] || null,
    };

    onSubmit({ term: _term });
    setIsOpen(false);
  };

  const handleOnChangeOption = (e, { value }) => {
    setSelectedOption(value);
    setSelectedTerm(
      getInfoBySelectedOption({ option: value, updatedTerms: patchedTerms })[0]
    );
  };

  const handleOnSelectAdditionalOption = (option, isSelected) => {
    setSelectedTerm((prev) => ({
      ...prev,
      dynamic_surcharge: prev.dynamic_surcharge.map((i) =>
        i.id === option.id ? { ...i, isSelected } : i
      ),
    }));
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal
        open={isOpen}
        onClose={close}
        style={{ maxWidth: 500 }}
        onClick={(e) => e.stopPropagation()}
        closeIcon
      >
        <Modal.Header>{categoryName}</Modal.Header>

        <Modal.Content>
          <div className={"productOptionsDropdown"}>
            <Dropdown
              options={dropdownOptions}
              value={selectedOption}
              onChange={handleOnChangeOption}
              theme={theme?.input}
            />
          </div>

          <div>
            {getInfoBySelectedOption({ updatedTerms: patchedTerms }).map(
              (term) => {
                const _id = term?.ex_1_rate_response_id;
                const isExtraOption = !!term?.dynamic_surcharge;

                if (!term) return <></>;

                return (
                  <div key={_id}>
                    {isExtraOption && (
                      <>
                        <span className={"optionalCoverageText"}>
                          Optional coverage:
                        </span>

                        <ul className={"productModalExtraList"}>
                          {term.dynamic_surcharge.map((item) => {
                            const selected =
                              selectedTerm.dynamic_surcharge.find(
                                (s) => s.id === item.id
                              );

                            return (
                              <li key={item.id}>
                                <div>
                                  <Checkbox
                                    checked={selected?.isSelected}
                                    onChange={(e, { checked }) =>
                                      handleOnSelectAdditionalOption(
                                        item,
                                        checked
                                      )
                                    }
                                  />

                                  <span>{item.description}</span>
                                </div>

                                <span>{FormatNumberToPrice(item.price)}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}

                    <div className={"coveragePrice"}>
                      <span>{`Price: ${calculateCoverageRetailerPriceOfTerm({
                        term: selectedTerm,
                        formula,
                        isFormatted: true,
                      })}`}</span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button buttonStyle="danger" onClick={close} theme={theme?.button}>
            Close
          </Button>

          <Button onClick={handleOnSubmit} theme={theme?.button}>
            {alreadySelectedTerm ? "Edit" : "Submit"}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProductModal;
