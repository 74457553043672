import React, { useEffect } from "react";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import { FormatNumberToPrice } from "../../../helpers/general";
import Car from "../../../utilities/car/Car";
import { MonthlyTooltip } from "../../../utilities/tooltips/MonthlyTooltip";
import { WeeklyTooltip } from "../../../utilities/tooltips/WeeklyTooltip";

import { useTheme } from "../../../helpers/theme";

const SimilarCar = (props) => {
  const { theme } = useTheme();

  const {
    similarCar,
    paymentCars,
    carType,
    formula,
    interestRage,
    credit_score,
  } = props;

  // ------------------------------ render search cars -----------------------------------------
  const renderCars = () => {
    return (
      <div className="car-search">
        <Header as="h2" className="btn-search">
          {carType === "good_car"
            ? "Good"
            : carType === "better_car"
            ? "Better"
            : "Best"}
        </Header>
        <h3 className="weekly-pay">
          {FormatNumberToPrice(similarCar.quot.weekly.payment)} Weekly
          <WeeklyTooltip
            finalQuots={similarCar.quot}
            iconStyle={{ color: theme?.typography?.primary }}
          />
        </h3>
        <h5 className="monthly-pay">
          {FormatNumberToPrice(similarCar.quot.monthly.payment)} Monthly
          <MonthlyTooltip
            finalQuots={similarCar.quot}
            annual_interest_rate={interestRage[credit_score]}
          />
        </h5>
        <span className="weekly-months">
          Term Reduction {similarCar.quot.weekly.term_reduction} Months
        </span>
        <span className="weekly-months">
          Contracted Term {similarCar.quot.weekly.contracted_term} Months
        </span>
        <span className="weekly-months bottom">
          Effective Loan End Term {similarCar.quot.weekly.effective_term} Months
        </span>
        <Car
          type={carType}
          car={similarCar}
          formula={formula}
          carContinue={(e) => props.carContinue(similarCar)}
        />
      </div>
    );
  };

  const renderCarsMobile = () => {
    return (
      <Grid.Column className="car" mobile={13} tablet={16}>
        <label>
          {similarCar.year + " " + similarCar.make + " " + similarCar.model}
        </label>
        <label>
          {FormatNumberToPrice(similarCar.quot.weekly.payment)} Weekly
          <WeeklyTooltip
            finalQuots={similarCar.quot}
            iconStyle={{ color: theme?.typography?.primary }}
          />
        </label>
        <small className="monthly-pay">
          {FormatNumberToPrice(similarCar.quot.monthly.payment)} Monthly
          <MonthlyTooltip
            finalQuots={similarCar.quot}
            annual_interest_rate={interestRage[credit_score]}
          />
        </small>
        <p>Term Reduction: {similarCar.quot.weekly.term_reduction} Months</p>
        <p>Contracted Term: {similarCar.quot.weekly.contracted_term} Months</p>
        <p>
          Effective Loan End Term: {similarCar.quot.weekly.effective_term}{" "}
          Months
        </p>
        <Button
          onClick={() => props.carContinue(similarCar, true)}
          className={
            paymentCars == carType ? "btn-det btn-det-click" : "btn-det"
          }
          icon="right arrow"
          labelPosition="right"
        >
          Reveal My Payment Power
        </Button>
      </Grid.Column>
    );
  };

  return (
    <Grid.Column
      mobile={16}
      tablet={5}
      computer={5}
      className="content-car"
      style={{
        "--primary-text-color": theme?.typography.primary,
        "--primary-button-background": theme?.button.primary.background,
        "--primary-button-color": theme?.button.primary.color,
        "--button-border-radius": theme?.button?.borderRadius + "px",
      }}
    >
      <div className="ui-mobile">
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Grid className="mobileCar">
            <Grid.Column className="title" mobile={3} tablet={16}>
              {carType == "good_car"
                ? "Good"
                : carType == "better_car"
                ? "Better"
                : "Best"}
            </Grid.Column>
            {renderCarsMobile()}
          </Grid>
        </div>
      </div>
      <div
        className="ui-computer"
        style={{
          opacity: paymentCars == "" || paymentCars == carType ? 1 : 0.5,
        }}
      >
        {renderCars()}
        <Button
          className={
            paymentCars == carType ? "btn-det btn-det-click" : "btn-det"
          }
          onClick={() => props.carContinue(similarCar, true)}
        >
          Reveal My Payment Power
        </Button>
        {paymentCars == carType ? (
          <div className="row-detail">
            <Icon link name="angle down" />
          </div>
        ) : null}
      </div>
    </Grid.Column>
  );
};

export default SimilarCar;
