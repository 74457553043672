import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Thumbs } from "swiper";
import { useMediaPredicate } from "react-media-hook";
import _ from "lodash";
import cn from "classnames";

import AttributeCard from "./AttributeCard";
import AttributeInfo from "./AttributeInfo";
import Button from "../../../global/Button";

import {
  getAttributeWeeklyPayment,
  FormatCashDownToPrice,
} from "../../../helpers/general";
import { useTheme } from "../../../helpers/theme";

import "./PersonalizedAttributes.sass";

const PersonalizedAttributes = ({
  open,
  retailer,
  items,
  quotes,
  selectedPersonalizedAttributes,
  onAddAttribute,
  onUpdate,
}) => {
  const { theme } = useTheme();
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [addedAttributes, setAddedAttributes] = useState([]);

  const is1180 = useMediaPredicate("(max-width: 1180px)");
  const is992 = useMediaPredicate("(max-width: 992px)");
  const is768 = useMediaPredicate("(max-width: 768px)");
  const is480 = useMediaPredicate("(max-width: 480px)");

  useEffect(() => {
    !open && setSelectedAttribute(null);
  }, [open]);

  useEffect(() => {
    setAddedAttributes(selectedPersonalizedAttributes.map((a) => a.id));
  }, [selectedPersonalizedAttributes]);

  return (
    <>
      {open && retailer && (
        <div className={"attributesPage"}>
          <Swiper
            spaceBetween={is1180 ? 20 : 28}
            slidesPerView={is1180 ? (is992 ? (is768 ? "auto" : 2) : 3) : 4}
            grabCursor={true}
            effect={"coverflow"}
            coverflowEffect={{
              depth: 0,
              rotate: 0,
              slideShadows: false,
            }}
            freeMode={{
              enabled: is480,
              momentum: false,
            }}
            modules={[EffectCoverflow, Thumbs]}
            className={cn("swiperWrapper")}
          >
            {items.map((item) => (
              <SwiperSlide key={item.id}>
                <AttributeCard
                  item={item}
                  isView={item.id === selectedAttribute}
                  isAdded={addedAttributes.includes(item.id)}
                  onClickView={() =>
                    setSelectedAttribute((prevState) =>
                      prevState === item.id ? null : item.id
                    )
                  }
                  onClickAdd={() => {
                    setAddedAttributes((prevState) => {
                      const isAlreadyAdded = prevState.includes(item.id);
                      const updated = isAlreadyAdded
                        ? prevState.filter((prevItem) => prevItem !== item.id)
                        : [...prevState, item.id];

                      return updated;
                    });
                    onAddAttribute(item);
                  }}
                  weeklyValue={FormatCashDownToPrice(
                    getAttributeWeeklyPayment(quotes.monthly, item.cost)
                  )}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={"updateButtonWrapper"}>
            <Button onClick={onUpdate} theme={theme?.button}>
              Update & View Prequalification Status
            </Button>
          </div>

          {_.isNumber(selectedAttribute) && !is768 && (
            <AttributeInfo
              item={items.find(
                (attribute) => attribute.id === selectedAttribute
              )}
            />
          )}

          <div />
        </div>
      )}
    </>
  );
};

export default PersonalizedAttributes;
