import React, { useState, useEffect } from "react";
import { Icon, Dimmer, Loader } from "semantic-ui-react";
import _ from "lodash";
import { useMediaPredicate } from "react-media-hook";

import AttributeCard from "./AttributeCard";
import AttributeModal from "./AttributeCard/AttributeModal";
import Button from "../../global/Button";

import { getAttributes } from "../../utilities/requests";

import "./PersonalizedAttributesSettings.sass";

const PersonalizedAttributesSettings = ({ retailer }) => {
  const [attributesData, setAttributesData] = useState({
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const is576 = useMediaPredicate("(max-width: 576px)");

  const init = () => {
    const user = JSON.parse(localStorage.getItem("currentUser"));

    if (_.isUndefined(user)) return;

    fetchAttributes();
  };

  const fetchAttributes = async () => {
    setIsLoading(true);

    const { data } = await getAttributes({ retailer_id: retailer.id });

    setAttributesData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={"contentAttributes"}>
      <div className={"topHeader"}>
        <h3 className={"header"}>Personalized Attributes</h3>

        <AttributeModal update={fetchAttributes} retailerId={retailer.id}>
          <Button>{is576 ? <Icon name="plus" /> : "Add Attribute"}</Button>
        </AttributeModal>
      </div>

      <ul className={"attributesList"}>
        {attributesData.data.map((item) => (
          <AttributeCard
            retailerId={retailer.id}
            item={item}
            key={item.id}
            update={fetchAttributes}
          />
        ))}
      </ul>

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};

export default PersonalizedAttributesSettings;
