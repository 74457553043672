import React from 'react';
import { Step } from 'semantic-ui-react';

export const StepsPersonalInformation = (props) => {
  const { step } = props;
  return (
    <Step.Group style={{ width: "100%" }}>
      <Step active={step === 0}>
        <Step.Content>
          <Step.Title>Current Job</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 1}>
        <Step.Content>
          <Step.Title>Address and Driver's License</Step.Title>
        </Step.Content>
      </Step>
      <Step active={step === 2}>
        <Step.Content>
          <Step.Title>Marital Status</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};