import React from "react";
import cn from "classnames";

import AgreementDetails from "./AgreementDetails";
import PricingRecap from "./PricingRecap";
import PaymentOptions from "./PaymentOptions";
import Section from "../global/Section";
import ProgramBuilder from "./ProgramBuilder";

import Button from "../global/Button";

import { AgreementCtx } from "./context";

import "./Agreement.sass";

const Agreement = ({ info, onBack }) => {
  const details = info.form_sheet_values;

  return (
    <AgreementCtx.Provider value={{ data: details, onBack }}>
      <div className={"agreementWrapper"}>
        <div className={"sections"}>
          <div className={"upperSections"}>
            <Section className={cn("section")}>
              <AgreementDetails />
            </Section>

            <div className={"rightSide"}>
              <Section className={cn("section")}>
                <PricingRecap />
              </Section>

              <Section className={cn("section")}>
                <PaymentOptions />
              </Section>
            </div>
          </div>

          <div>
            <Section className={cn("section")}>
              <ProgramBuilder />
            </Section>
          </div>
        </div>

        <div className={"agreementActions"}>
          <Button
            onClick={() => {
              window.open(info.form_url, "_blank");
            }}
          >
            Download PDF
          </Button>
        </div>
      </div>
    </AgreementCtx.Provider>
  );
};

export default Agreement;
