import React, { useState, useEffect } from "react";
import { RgbColorPicker, HexColorInput } from "react-colorful";
import { Popup } from "semantic-ui-react";

import {
  convertToRGBObject,
  convertFromObjectToHex,
  convertHexToObject,
} from "../../helpers/general";

import "./ColorPicker.sass";

const ColorPicker = ({ color = "rgba(255, 255, 255)", onChange }) => {
  const [selectedColor, setSelectedColor] = useState(convertToRGBObject(color));

  return (
    <Popup
      on={"click"}
      trigger={
        <div className={"colorPickerIndicatorWrapper"}>
          <div
            style={{ backgroundColor: convertFromObjectToHex(selectedColor) }}
            className={"colorPickerIndicator"}
          />

          <HexColorInput
            color={convertFromObjectToHex(selectedColor)}
            onChange={(color) => {
              if (color.length === 7) {
                setSelectedColor(convertHexToObject(color));
                onChange(convertHexToObject(color));
              }
            }}
            className={"colorPickerIndicatorInput"}
            prefixed
          />
        </div>
      }
      content={
        <RgbColorPicker
          color={selectedColor}
          onChange={(color) => {
            setSelectedColor(color);
            onChange(color);
          }}
        />
      }
    />
  );
};

export default ColorPicker;
