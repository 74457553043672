// IMPORT OBJECTS/LIBRARIES
import React, { Component } from "react";
import { Button, Grid, Header, Icon, Accordion } from "semantic-ui-react";
import CustomCarousel from "../carousel/carousel";
import { FormatNumberToPrice } from "../../helpers/general";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cn from "classnames";

// IMPORT COMPONENTS
import CarPrice from "./CarPrice";
import WeeklyHead from "./WeeklyHead";

import { ReactComponent as ChevronIcon } from "../../../images/chevronIcon.svg";

// IMPORT CSS STYLE
import "./Car.sass";
import { TradeModal } from "./TradeModal";

class Car extends Component {
  constructor(props) {
    super(props);

    this.state = {
      car: props.car,
      quots: props.quots,
      formula: props.formula,
      insurance_protection: props.insurance_protection,
      max_down_payment: props.max_down_payment,
      trade_in: props.trade_in,
      money_owed: props.money_owed,
      isDetail: false,
      tradeModal: false,
      isCollapsed: false,
      is768: window.matchMedia("(max-width: 768px)").matches,
      is576: window.matchMedia("(max-width: 576px)").matches,
    };

    this.resizeHandler = this.resizeHandler.bind(this);
  }

  resizeHandler() {
    const is768 = window.matchMedia("(max-width: 768px)").matches;
    const is576 = window.matchMedia("(max-width: 576px)").matches;

    if (is768 !== this.state.is768) {
      this.state = {
        is768,
      };
    }
    if (is576 !== this.state.is576) {
      this.state = {
        is576,
      };
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.car.id != prevProps.car.id ||
      (this.props.quots &&
        this.props.quots.monthly.id != prevProps.quots.monthly.id) ||
      this.props.trade_in != prevProps.trade_in ||
      this.props.money_owed != prevProps.money_owed
    ) {
      this.setState({
        car: this.props.car,
        quots: this.props.quots,
        formula: this.props.formula,
        interest: this.props.interest,
        trade_in: this.props.trade_in,
        money_owed: this.props.money_owed,
      });
    }
  }

  insuranceChange = (value, label) => {
    this.props.insuranceChange(value, label);
    this.setState({ [label]: value });
  };

  renderHeaderCar(style) {
    return (
      <div className={"carInfoWrapper"}>
        <Header className={"title"} as="h2" style={style}>
          <span className={"carInfoName"}>
            {this.state.car.year +
              " " +
              this.state.car.make +
              " " +
              this.state.car.model}
          </span>
        </Header>

        <div className={"milesAndNumber"}>
          <div className="stk">
            {this.state.car.miles.toLocaleString()} Miles
          </div>

          <div className="stk">STK #{this.state.car.stock_number}</div>
        </div>
      </div>
    );
  }

  renderPrice() {
    return (
      <CarPrice
        car={this.state.car}
        quots={this.state.quots}
        trade_in={this.state.trade_in}
        money_owed={this.state.money_owed}
        interest={this.props.interest}
        disabledTrade={this.props.disabledTrade}
        type={this.props.type}
        retailer={this.props.retailer}
        insurance_protection={this.state.insurance_protection}
        insuranceChange={(value, label) => this.insuranceChange(value, label)}
        openTredModal={() => this.setState({ tradeModal: true })}
        formula={this.props.formula}
        attributes={this.props.attributes}
        selectedRebates={this.props.selectedRebates}
        selectedProtection={this.props.selectedProtection}
      />
    );
  }

  renderWeekly = (props) => {
    return (
      <WeeklyHead
        quots={this.state.quots}
        interest={this.props.interest}
        isDetail={this.state.isDetail}
        nextStep={() =>
          this.props.nextStep ? this.props.nextStep() : undefined
        }
        abledNext={this.props.nextStep ? true : false}
        {...props}
      />
    );
  };

  renderSalePrice = (ui) => {
    return (
      <div className={ui + " prices-car"}>
        {this.state.car.msrp > 0 ? (
          <span className="prices">
            ORIGINAL PRICE: {FormatNumberToPrice(this.state.car.msrp)}
          </span>
        ) : null}
        <span className="prices">
          SALE PRICE: {FormatNumberToPrice(this.state.car.price)}
        </span>
      </div>
    );
  };

  render() {
    return (
      <div className={"carComponent"}>
        {this.props.type == "firstCar" ? (
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Grid.Column
              mobile={16}
              tablet={7}
              computer={7}
              className="columnGalery"
            >
              <CustomCarousel
                images={this.state.car.images}
                carId={this.state.car.id}
                theme={this.props.theme}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={9} computer={9}>
              {this.renderHeaderCar({
                "--name-color": this.props.theme?.typography.primary,
              })}
              {this.renderSalePrice("ui-mobile")}

              <div className="carDetails">{this.renderPrice()}</div>

              <div className="ui-mobile">
                <div
                  className={"accordionWrapper"}
                  style={{
                    "--accordion-color": this.props.theme?.typography.secondary,
                    "--name-color": this.props.theme?.typography.primary,
                  }}
                >
                  <Accordion>
                    <Accordion.Title
                      active={this.state.isCollapsed}
                      onClick={() =>
                        this.setState({ isCollapsed: !this.state.isCollapsed })
                      }
                    >
                      Price Details
                      <ChevronIcon
                        className={cn(
                          "accordionIcon",
                          this.state.isCollapsed && "accordionIconRotated"
                        )}
                      />
                    </Accordion.Title>

                    <Accordion.Content active={this.state.isCollapsed}>
                      <div style={{ textAlign: "center", padding: 5 }}>
                        {this.renderPrice()}
                      </div>
                    </Accordion.Content>
                  </Accordion>
                </div>

                {!this.state.isDetail ? null : (
                  <div style={{ textAlign: "center", padding: 5 }}>
                    {this.renderPrice()}
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid>
        ) : this.props.type == "car" ||
          this.props.type == "selectedCar" ||
          this.props.type === "selectedCarLanding" ? (
          <Grid>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={this.props.type == "selectedCar" ? 6 : 8}
              className="columnGalery"
            >
              <CustomCarousel
                images={this.state.car.images}
                carId={this.state.car.id}
                theme={this.props.theme}
              />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={this.props.type == "selectedCar" ? 10 : 8}
            >
              {this.renderHeaderCar({
                "--name-color": this.props.theme?.typography.primary,
              })}
              {this.renderSalePrice("ui-mobile")}
              {this.renderWeekly({
                tooltip: {
                  position: this.state.is768
                    ? this.state.is768
                      ? "top center"
                      : "top right"
                    : "left center",
                  offset: this.state.is768 ? "0, 0" : "0, 50px",
                },
              })}

              {this.props.type === "car" ? (
                <div>
                  <div className="carDetails">{this.renderPrice()}</div>
                  <div className="ui-mobile">
                    <div
                      className={"accordionWrapper"}
                      style={{
                        "--accordion-color":
                          this.props.theme?.typography.secondary,
                        "--name-color": this.props.theme?.typography.primary,
                      }}
                    >
                      <Accordion>
                        <Accordion.Title
                          active={this.state.isCollapsed}
                          onClick={() =>
                            this.setState({
                              isCollapsed: !this.state.isCollapsed,
                            })
                          }
                        >
                          <Icon name="dropdown" />
                          Price Details
                        </Accordion.Title>

                        <Accordion.Content active={this.state.isCollapsed}>
                          <div style={{ textAlign: "center", padding: 5 }}>
                            {this.renderPrice()}
                          </div>
                        </Accordion.Content>
                      </Accordion>
                    </div>

                    {!this.state.isDetail ? null : (
                      <div style={{ textAlign: "center", padding: 5 }}>
                        {this.renderPrice()}
                      </div>
                    )}
                  </div>

                  <div className={"acceptButton"}>
                    <Button
                      className="btn-continue"
                      onClick={(e) => this.props.carContinue(this.state.car)}
                    >
                      <div className="ui-computer">
                        <h3>Finance & Payment Options</h3>
                      </div>
                      <div className="ui-mobile">
                        <h4>Finance & Payment Options</h4>
                      </div>
                    </Button>
                  </div>
                </div>
              ) : null}

              {this.props.type == "selectedCar" ||
              this.props.type === "selectedCarLanding" ? (
                <div>
                  <div className="carDetails">{this.renderPrice()}</div>

                  <div className="ui-mobile">
                    <div
                      className={"accordionWrapper"}
                      style={{
                        "--accordion-color":
                          this.props.theme?.typography.secondary,
                        "--name-color": this.props.theme?.typography.primary,
                      }}
                    >
                      <Accordion>
                        <Accordion.Title
                          active={this.state.isCollapsed}
                          onClick={() =>
                            this.setState({
                              isCollapsed: !this.state.isCollapsed,
                            })
                          }
                        >
                          <Icon name="dropdown" />
                          Price Details
                        </Accordion.Title>

                        <Accordion.Content active={this.state.isCollapsed}>
                          <div style={{ textAlign: "center", padding: 5 }}>
                            {this.renderPrice()}
                          </div>
                        </Accordion.Content>
                      </Accordion>
                    </div>

                    {!this.state.isDetail ? null : (
                      <div style={{ textAlign: "center", padding: 5 }}>
                        {this.renderPrice()}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </Grid.Column>
          </Grid>
        ) : this.props.type == "good_car" ||
          this.props.type == "better_car" ||
          this.props.type == "best_car" ? (
          <Grid>
            <Grid.Column tablet={16} computer={16}>
              <CustomCarousel
                images={this.state.car.images}
                carId={this.state.car.id}
                theme={this.props.theme}
              />
              <div style={{ width: "100%", textAlign: "center" }}>
                {this.renderHeaderCar({
                  "--name-color": this.props.theme?.typography.primary,
                })}
              </div>
            </Grid.Column>
          </Grid>
        ) : this.props.type == "finishedCar" ||
          this.props.type == "detailQuot" ? (
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="columnGalery"
            >
              <CustomCarousel
                images={this.state.car.images}
                carId={this.state.car.id}
                theme={this.props.theme}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              {this.renderHeaderCar({
                "--name-color": this.props.theme?.typography.primary,
              })}
              {this.renderSalePrice("ui-mobile")}
              {this.renderWeekly({
                tooltip: {
                  position: this.state.is768
                    ? this.state.is768
                      ? "top center"
                      : "top right"
                    : "left center",
                  offset: this.state.is768 ? "0, 0" : "0, 50px",
                },
              })}
              <div className="carDetails">{this.renderPrice()}</div>
              <div className="ui-mobile">
                <div
                  className={"accordionWrapper"}
                  style={{
                    "--accordion-color": this.props.theme?.typography.secondary,
                    "--name-color": this.props.theme?.typography.primary,
                  }}
                >
                  <Accordion>
                    <Accordion.Title
                      active={this.state.isCollapsed}
                      onClick={() =>
                        this.setState({ isCollapsed: !this.state.isCollapsed })
                      }
                    >
                      <Icon name="dropdown" />
                      Price Details
                    </Accordion.Title>

                    <Accordion.Content active={this.state.isCollapsed}>
                      <div style={{ textAlign: "center", padding: 5 }}>
                        {this.renderPrice()}
                      </div>
                    </Accordion.Content>
                  </Accordion>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        ) : this.props.type == "cars" ? (
          <Grid>
            <Grid.Column
              mobile={16}
              tablet={4}
              computer={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomCarousel
                showThumbs
                images={this.state.car.images}
                carId={this.state.car.id}
                theme={this.props.theme}
              />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={12}
              computer={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <Header className={"title-car"} as="h3">
                  <span className="color-blue-ligth">
                    {FormatNumberToPrice(this.state.quots.weekly.payment) +
                      " Weekly - "}
                  </span>
                  {this.state.car.year +
                    " " +
                    this.state.car.make +
                    " " +
                    this.state.car.model}
                </Header>
                <div className="ui-computer">
                  {this.renderSalePrice("computer-prices")}
                  <h3 className="color-blue-ligth">
                    {FormatNumberToPrice(this.state.quots.monthly.payment) +
                      " Monthly"}
                  </h3>
                </div>
                <div className="ui-mobile">
                  {this.renderSalePrice("")}
                  <span className="color-blue-ligth">
                    {FormatNumberToPrice(this.state.quots.monthly.payment) +
                      " Monthly"}
                  </span>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        ) : this.props.type == "onlyDetailInfo" ? (
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              {this.renderHeaderCar({
                "--name-color": this.props.theme?.typography.primary,
              })}
              <div className="stk ui-computer">VIN {this.state.car.vin}</div>
              {this.renderSalePrice("ui-mobile")}
              {this.renderWeekly({
                tooltip: {
                  position: this.state.is768
                    ? this.state.is768
                      ? "top center"
                      : "top right"
                    : "left center",
                  offset: this.state.is768 ? "0, 0" : "0, 50px",
                },
              })}
              <div className="carDetails">{this.renderPrice()}</div>
              <div className="ui-mobile">
                <div
                  className={"accordionWrapper"}
                  style={{
                    "--accordion-color": this.props.theme?.typography.secondary,
                    "--name-color": this.props.theme?.typography.primary,
                  }}
                >
                  <Accordion>
                    <Accordion.Title
                      active={this.state.isCollapsed}
                      onClick={() =>
                        this.setState({ isCollapsed: !this.state.isCollapsed })
                      }
                    >
                      <Icon name="dropdown" />
                      Price Details
                    </Accordion.Title>

                    <Accordion.Content active={this.state.isCollapsed}>
                      <div style={{ textAlign: "center", padding: 5 }}>
                        {this.renderPrice()}
                      </div>
                    </Accordion.Content>
                  </Accordion>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        ) : null}

        {this.state.tradeModal && (
          <TradeModal
            trade_in={this.state.trade_in}
            money_owed={this.state.money_owed}
            changeTrade={this.changeTrade}
            tradeText={this.props.tradeText}
            closeTrade={(tradeText) => {
              this.setState({ tradeModal: false });
              this.props.insuranceChange(tradeText, "tradeText");
            }}
          />
        )}
      </div>
    );
  }
}
export default Car;
