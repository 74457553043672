import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

export const Text1 = () => {
  return (
    <div>
      <Header as="h2">Agreement Between User and US Equity Advantage®</Header>
      <p>
        This ACH Debit Agreement governs your use of the automated bill payment services (the “Service”) provided by US Equity Advantage, Inc.
        (“USEA”) under the name of My Payment Power, a registered Trademark of USEA. For the purpose of this Agreement, "USEA" and “My Payment Power” shall
        include, if applicable, any dba under which USEA may operate in your jurisdiction. You acknowledge and agree that you have received a copy,
        read and understood the terms of this ACH Debit Agreement and agree to be bound by its terms.
      </p>
      <Header as="h3" className='underline'>Definitions.</Header>
      <p className='block'>
        <span>The terms "we," "us," "our" and “My Payment Power” mean USEA, its agents, successors and assigns.</span>
        <span>
          The terms "you," "your," and "customer" mean the holder (or authorized representative thereof) of the Deposit Account specified above as Payor for
          bill payment services. "Deposit Account" means the US Dollar deposit account or such successor, replacement or other deposit account as
          you select, and we permit.
        </span>
        <span>
          “Deposit Institution” means the financial institution that provides the Deposit Account.
        </span>
        <span>“Rules” means the NACHA Operating Rules & Guidelines.</span>
        <span>THIS AGREEMENT IS NOT FINAL UNTIL WE HAVE VERIFIED ALL INFORMATION AND ACCEPTED YOU AS A CUSTOMER AT OUR SOLE DISCRETION IN OUR OFFICES IN ORLANDO, FL.</span>
        <span>
          Consumers are warned that fraud can and does occur. Please call USEA at (800) 894-5000 to report any incident of fraud or suspected fraud
          including suspected unauthorized transfers (one made without your permission.
        </span>
      </p>
      <Header as="h3">
        <span className='underline bold'>Terms and Conditions.</span> The Services provided to you by USEA are subject to the terms and conditions of this Agreement and to the
        following, which are incorporated herein by this reference:
      </Header>
      <ol type="A">
        <li>The terms or instructions appearing on the My Payment Power Customer Portal when enrolling for, activating, accessing, or using the Service;</li>
        <li>USEA's policies and procedures, as amended from time to time, that apply to the Service;</li>
        <li>Related 3rd Party Partner terms and conditions, as amended from time to time, that may apply to the Service or extensions thereof; and</li>
        <li>State and federal laws and regulations, as applicable.</li>
      </ol>
      <Header as="h3" className='underline'>General Description of Service.</Header>
      <p>
        Customer bank accounts are debited using the Federal Reserve System's Automatic Clearing House (“ACH”) process and funds are deposited in
        a FDIC Insured account at an accredited bank of our choice. The debit will consist of a regularly scheduled recurring amount based on the
        timeframe chosen by you. You authorize USEA to administer these funds to pay the specified loan(s) and collect disclosed fees. You further
        authorize USEA to debit any amount that you owe USEA without further notice in an amount up to 3 regularly scheduled debits plus $250.
        Payments to your lender (the “Payee”) may be in the form of check or electronic payment. Interest is not credited to you on your funds while
        waiting for transfer to your lender. The timing of said transfer will in all cases be at USEA's sole discretion. Loans are paid on a monthly basis
        unless otherwise stated. The initial debits will be applied to the Enrollment fee until paid in full. Should that result in a balance deficiency at time
        of Lender payment USEA will advance the funds on your behalf with no interest charged until you have a positive balance.
      </p>
      <p className='block'>
        <span>To use the Service, you must adhere to the following rules:</span>
        <span>(A) You warrant you will immediately notify us of any change to the information provided to us. Please ensure your account is open, has
          checking privileges and the account numbers are clearly displayed;</span>
        <span>(B) You may cancel this Agreement, without any penalty or obligation, within three business days from the above date;</span>
        <span>(C) You understand that USEA is servicing your loan(s) based solely on information provided by you that USEA cannot 100% verify. USEA will
          not be held responsible for errors in the information provided by you, though USEA will use best efforts to solve any problems that result from
          incorrect information provided by you.;</span>
        <span>(D) It is your responsibility to ensure that sufficient funds are available in your Deposit Account for any payment. Although we may credit your
          ledger account for a payment, the payment remains subject to clearing through the Deposit Institution. You authorize us to re-present to the
          Deposit Institution and debit the Deposit Account for payments that were previously dishonored without further notice to you. You understand
          that Payee may also consider your Payee Account to be in default under the terms of the Payee Loan, lease or other agreement in the event of any
          such failure of a payment; and</span>
        <span>(E) If a debit is returned a second time, no additional payments will be processed, and USEA reserves the right to stop payment on all payments
          in process until that debit is successfully re-presented and paid. Without limiting any other available remedies, if any ACH debit from your
          Deposit Account is returned because of insufficient funds (NSF) or any other reason, you must reimburse us for any corresponding payment
          amount immediately upon demand, plus attorney's fees and other costs of collection as allowed by law. In its discretion, USEA may set-off such
          amounts against debits received for other payments. You accept any liability you or USEA may incur which is caused by your payment
          transactions, and you shall be solely responsible for any penalties, charges, and other fees you or we incur as a result of a failure to maintain
          sufficient funds in the Deposit Account.</span>
      </p>
      <p>
        USEA may make appropriate reports to credit reporting agencies, financial institutions, tax agencies and law enforcement authorities, and
        cooperate with them in any resulting investigation or prosecution. USEA also reserves the right to debit a Payee's account for money paid to the
        Payee on your behalf if the ACH debit from the Payment Account is returned because of insufficient funds or any other reason. You shall be
        solely responsible for all penalties, interest charges, late payment fees and service fees resulting from such a debit to the Payee. In some cases, a
        Customer may reverse a transaction for up to 60 days after it settled, or a transaction may be invalidated for fraud or some other reason, in which
        case the funds may be removed from the Payee's Payment Account long after initial deposit. You agree at all times to maintain sufficient funds in
        your Deposit Account to satisfy all obligations including returns, reversals, and associated fees, and to add funds immediately if USEA notifies
        you that your funds are insufficient. USEA may, at its discretion, contact your Payees regarding any payments to be made, or payments made, to
        Payees on your behalf, for any reason.
      </p>
    </div>
  );
};