/*
 * COMPONENT TO USE "CHART.JS" BAR CHARTS IN AN EASY WAY
 * SEND THE DATASETS AS YOU WILL WITH THE ORIGINAL PACKAGE
 * EXAMPLE:
 * <BarChart data={dataChart}/>
 * WHERE "dataChart" VALUE IS:
 * {
 *    labels:   ["January", "February", "March", "April", "May"],
 *    datasets: [
 *        {
 *            label:            'Closed',
 *            backgroundColor:  'green',
 *            data:             [3, 7, 4, 10, 2]
 *        },
 *        {
 *            label:            'Canceled',
 *            backgroundColor:  'red',
 *            data:             [9, 12, 12, 8, 10]
 *        }
 *    ]
 *}
 * */

import React, { Component } from "react";
import Chart from "chart.js";

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.chartInsance = new Chart(this.chartRef.current, {
      type: "bar",
      data: this.props.data,
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          position: "bottom",
          align: "start",

          labels: {
            padding: 30,
            usePointStyle: true,
          },
        },
      },
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.chartInsance.data = this.props.data;
    this.chartInsance.update();
  }

  render() {
    return <canvas ref={this.chartRef} />;
  }
}

export default BarChart;
