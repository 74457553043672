import React from "react";
import { Form } from "semantic-ui-react";

import Input from "../../../global/Input";
import Address from "../../address/Address";
import { SimpleRegister } from "../../../session/register/SimpleRegister";

import { useTheme } from "../../../helpers/theme";

const PreQualifiedForm = ({
  form,
  onChangeState,
  errors,
  logged,
  openSession,
  CreditSolutionRequest,
  retailer,
  disabled,
  isFooterTextHidden,
  setNewCustomerId,
}) => {
  const { theme, defaultTheme } = useTheme();

  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              First name <span className="required-inut">*</span>
            </label>

            <Input
              fluid
              placeholder="First name"
              value={form.firstName}
              onChange={(event) =>
                onChangeState(event.target.value, "firstName")
              }
              error={
                form.firstName.length > 0 || errors.firstName
                  ? errors.firstName
                  : null
              }
              theme={theme?.input}
            />
          </Form.Field>

          <Form.Field>
            <label>
              Last name <span className="required-inut">*</span>
            </label>

            <Input
              fluid
              placeholder="Last name"
              value={form.lastName}
              onChange={(event) =>
                onChangeState(event.target.value, "lastName")
              }
              error={
                form.lastName.length > 0 || errors.lastName
                  ? errors.lastName
                  : null
              }
              theme={theme?.input}
            />
          </Form.Field>
        </Form.Group>

        <Address
          onChangeState={(label, value) => onChangeState(value, label)}
          address={{
            address: form.address,
            city: form.city,
            zip_code: form.postalCode,
            state: form.state,
          }}
          msnErrors={errors}
          theme={theme}
        />
        <br />
      </Form>

      <SimpleRegister
        data={form}
        logged={logged}
        openSession={openSession}
        CreditSolutionRequest={CreditSolutionRequest}
        retailer={retailer}
        disabled={disabled}
        isFooterTextHidden={isFooterTextHidden}
        setNewCustomerId={setNewCustomerId}
      />
    </>
  );
};

export default PreQualifiedForm;
