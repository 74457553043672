import React, { useEffect } from "react";
import { Form, Grid } from "semantic-ui-react";

import Input from "../../global/Input";

import { useTheme } from "../../helpers/theme";

export const BasicInformation = (props) => {
  const { theme } = useTheme();

  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {}, [form, msnErrors, flagEdit]);

  return (
    <Form.Group>
      <Form.Field width={8}>
        <label>
          First Name <span className="required-inut">*</span>
        </label>

        <Input
          id={"firstName"}
          value={form.firstName}
          placeholder={"First Name"}
          onChange={(e) => props.onEditInput("firstName", e.target.value)}
          fluid
          error={msnErrors.firstName.length > 0 ? msnErrors.firstName : null}
          maxLength="50"
          disabled={flagEdit}
          theme={theme?.input}
        />
      </Form.Field>

      <Form.Field width={8}>
        <label>
          Last Name <span className="required-inut">*</span>
        </label>

        <Input
          id={"lastName"}
          value={form.lastName}
          placeholder={"Last Name"}
          onChange={(e) => props.onEditInput("lastName", e.target.value)}
          fluid
          error={msnErrors.lastName.length > 0 ? msnErrors.lastName : null}
          maxLength="50"
          disabled={flagEdit}
          theme={theme?.input}
        />
      </Form.Field>
    </Form.Group>
  );
};
