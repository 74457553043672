import React from "react";
import ReactQuill from "react-quill";
import cn from "classnames";

import "./RichTextEditor.sass";

const RichTextEditor = ({ className, error, ...rest }) => {
  return (
    <div className={cn("richTextEditorWrapper", className)}>
      <ReactQuill {...rest} />

      <p
        className={cn(
          "richTextEditorError",
          error && "richTextEditorErrorVisible"
        )}
      >
        {error}
      </p>
    </div>
  );
};

export default RichTextEditor;
