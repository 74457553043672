import React, { useState } from "react";
import { Header, Icon } from "semantic-ui-react";

import { useTheme } from "../../../helpers/theme";

import "./video.sass";

const Video = (props) => {
  const { theme } = useTheme();

  const { video, videos } = props;
  const [open, setOpen] = useState(false);

  const findSection = () => {
    let url = "";
    if (videos) {
      let result = videos.find((item) => item.section.section === video);
      url = result ? result.url : "";
    }
    return url;
  };

  return (
    <div className="video-chroma">
      {props.flag ? (
        <h3>
          How{" "}
          <span
            className="brand"
            style={{
              color: theme?.typography.primary,
            }}
          >
            My Payment Power&trade;
          </span>{" "}
          Works
        </h3>
      ) : null}
      {!props.newVideos &&
        (video === "retailer" ? (
          <video
            src={
              findSection()
                ? findSection()
                : require("../../../../images/Step1.mp4")
            }
            controls
          ></video>
        ) : video === "search" ? (
          <video
            src={
              findSection()
                ? findSection()
                : require("../../../../images/Step2.mp4")
            }
            controls
          ></video>
        ) : video === "approval" ? (
          <video
            src={
              findSection()
                ? findSection()
                : require("../../../../images/Register_Login.mp4")
            }
            controls
          ></video>
        ) : (
          <iframe
            src="https://www.youtube.com/embed/C0DPdy98e4c"
            frameborder="0"
            allowfullscreen
          ></iframe>
        ))}
      {props.newVideos && (
        <div>
          <Header
            as="h2"
            className="btn-video"
            onClick={() => setOpen(true)}
            style={{
              color: theme?.typography.primary,
            }}
          >
            <Icon name="play circle outline" />
            {video === "prequalified"
              ? "Why Shop Prequalified?"
              : "Why use our weekly repayment plan?"}
          </Header>
          {open && (
            <div className="full-video">
              <div className="grapper-close">
                <Icon
                  link
                  name="close"
                  className="close"
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className="grapper-video">
                <video
                  src={findSection() ? findSection() : ""}
                  controls
                  autoPlay
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Video;
