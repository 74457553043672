import React, { useRef } from "react";
import _ from "lodash";

import { Grid, Icon, Header, Form, Popup } from "semantic-ui-react";
import Button from "../../../global/Button";

import AddProtectionCoverageModal from "../../../utilities/AddProtectionCoverageModal";

import {
  FormatCashDownToPrice,
  calculateTotalPriceOfProtection,
  getRebatesTotal,
} from "../../../helpers/general";

import ProtectionCoverage from "../../../../images/protectionPlaceholder.jpg";
import Attributes from "../../../../images/optionsPlaceholder.jpg";
import RebatesTurtle from "../../../../images/rebateTurtle.png";

import CoxProgramPlanModal from "../CoxProgramPlanModal";
import { TradeModal } from "../../car/TradeModal";

import "./AdditionalInfo.sass";

const AdditionalInfo = (props) => {
  const fileRef = useRef();

  const isEdit = !props.state.editDisabled;
  const isClient = props.state.role === "Customer";

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {isEdit ? (
        <div>
          <div>
            <div className={"preQualifiedOptionsWrapper additionalOptions"}>
              <TradeModal
                trade_in={props.state.trade_in}
                money_owed={props.state.money_owed}
                disabledTrade={props.state.editDisabled}
                tradeChange={props.onChangeState}
                detail={true}
              />

              <>
                <div className={"optionColumnWrapper"}>
                  <h5>Protection Coverage Added</h5>

                  <div>
                    <img src={ProtectionCoverage} alt="Protection Icon" />

                    <div className={"optionActionWrapper"}>
                      <span>
                        {FormatCashDownToPrice(
                          calculateTotalPriceOfProtection({
                            coverage:
                              props.state.quot.coverages?.selectedProducts,
                            categories: props.state.quot.coverages?.categories,
                          })?.total || 0
                        )}
                      </span>

                      <AddProtectionCoverageModal
                        car={props.state.car}
                        quotes={props.state.finalQuots}
                        loanLength={props.state.loan_length}
                        retailer={props.state.quot.retailer}
                        selectedProtectionCoverage={props.state.quot.coverages}
                        onClose={props.onCloseProtectiveCoverageModal}
                      >
                        <Button
                          className={"addOptionButtonWrapper"}
                          buttonStyle={"secondary"}
                        >
                          {_.isEmpty(
                            props.state.quot.coverages?.selectedProducts
                          )
                            ? "Edit"
                            : "Add"}
                        </Button>
                      </AddProtectionCoverageModal>
                    </div>
                  </div>
                </div>
              </>

              <div className={"optionColumnWrapper"}>
                <h5>Personalized Attributes Added</h5>

                <div>
                  <img src={Attributes} alt="Attributes Icon" />

                  <div className={"optionActionWrapper"}>
                    <span>
                      {FormatCashDownToPrice(
                        props.state.quot.items.reduce(
                          (sum, item) => sum + Number(item.cost),
                          0
                        )
                      )}
                    </span>

                    <Button
                      className={"addOptionButtonWrapper"}
                      buttonStyle={"secondary"}
                      onClick={props.onOpenAttributesPage}
                    >
                      {props.state.quot.items.length > 0 ? "Edit" : "Add"}
                    </Button>
                  </div>
                </div>
              </div>

              <div className={"optionColumnWrapper"}>
                <h5>Rebates</h5>

                <div>
                  <img src={RebatesTurtle} alt="Attributes Icon" />

                  <div className={"optionActionWrapper"}>
                    <span>
                      {_.isEmpty(props.state.quot.rebates)
                        ? FormatCashDownToPrice(0)
                        : `-${FormatCashDownToPrice(
                            getRebatesTotal({
                              selectedRebates: props.state.quot.rebates,
                              car: props.state.car,
                            })
                          )}`}
                    </span>

                    <Button
                      className={"addOptionButtonWrapper"}
                      buttonStyle={"secondary"}
                      onClick={props.onOpenRebatesPage}
                    >
                      {_.isEmpty(props.state.quot.rebates) ? "Add" : "Edit"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"dealActionButtonsWrapperForCreated"}>
            {props.state.editDisabled ? (
              props.state.role == "Customer" ? (
                <Button
                  className="btn-continue"
                  buttonStyle={"secondary"}
                  onClick={(e) => props.checkFormula()}
                >
                  Edit deal
                </Button>
              ) : null
            ) : props.state.role == "Customer" ? (
              <div className={"dealActionButtonsWrapper"}>
                <Button
                  buttonStyle={"skyblue"}
                  className="btn-cancel"
                  onClick={(e) => props.cancelEdit()}
                >
                  Cancel edit
                </Button>

                <Button
                  className="btn-continue"
                  onClick={(e) => props.editQuote()}
                >
                  Save deal
                </Button>
              </div>
            ) : null}

            {props.state.role == "Customer" && (
              <Button
                buttonStyle={"danger"}
                className="btn-cancel-quote"
                onClick={(e) => props.changeQuotStatus(3)}
              >
                Cancel deal
              </Button>
            )}

            {props.state.role == "Retailer" && props.state.quot.status == 1 && (
              <Button
                buttonStyle={"danger"}
                className="btn-cancel-quote"
                onClick={(e) => props.changeQuotStatus(3)}
              >
                Cancel deal
              </Button>
            )}

            {props.state.role == "Retailer" && props.state.quot.status == 1 && (
              <CoxProgramPlanModal
                requestData={{
                  dealer_id: props.state.car.dealer_id,
                  vin: props.state.car.vin,
                  zip_code: props.state.customer.zip_code,
                  car_price: props.state.car.price,
                  loan_term: props.state.loan_length,
                  cox_price: props.state.finalQuots.monthly.coxPrice,
                  trade_in: props.state.trade_in,
                  money_owed: props.state.money_owed,
                }}
                onSelect={props.handleOnSelectProgram}
              >
                <Button
                  className="btn-accept-quote"
                  // onClick={(e) => props.changeQuotStatus(2)}
                >
                  Accept deal
                </Button>
              </CoxProgramPlanModal>
            )}
          </div>
        </div>
      ) : (
        <Grid.Column
          mobile={16}
          tablet={10}
          computer={10}
          className={"actions"}
        >
          <div>
            {/* {props.state.showPdf && !props.state.hideElementsPdf && (
              <a className="iconPdf" onClick={props.pdf}>
                Download PDF <Icon name="file pdf outline" />
              </a>
            )} */}

            {!isClient && (
              <div className={"contractDetailsActions"}>
                <input
                  type={"file"}
                  hidden
                  ref={fileRef}
                  onChange={props.onUploadContractDetails}
                />

                <Button
                  onClick={() => {
                    fileRef.current.click();
                  }}
                >
                  Upload Contract
                  <Popup
                    content={
                      "Make sure the images in your file are in *.png, *.jpeg format. Otherwise, the images may not be displayed correctly"
                    }
                    trigger={
                      <Icon
                        link
                        name="info circle"
                        style={{ paddingLeft: 8 }}
                      />
                    }
                  />
                </Button>

                {props.state.quot.quote_info && (
                  <Button
                    onClick={props.onViewContractDetails}
                    buttonStyle={"skyblue"}
                  >
                    View Details
                  </Button>
                )}

                {props.state.quot.form_url && (
                  <Button
                    onClick={() => {
                      window.open(props.state.quot.form_url, {
                        target: "_blank",
                      });
                    }}
                    buttonStyle={"skyblue"}
                  >
                    Download PDF
                  </Button>
                )}
              </div>
            )}

            <h3 className="quote-status">
              Deal status:
              <span>
                {props.state.quot.status == 0
                  ? " Pending"
                  : props.state.quot.status == 1
                  ? " Created"
                  : props.state.quot.status == 2
                  ? " Accepted"
                  : props.state.quot.status == 3
                  ? " Cancelled"
                  : props.state.quot.status == 4
                  ? " Expired"
                  : null}
              </span>
            </h3>
          </div>

          <div>
            {!props.state.hideElementsPdf ? (
              <div>
                {!props.state.editDisabled ? (
                  props.state.quot.retailer.credit_range
                    .insurance_protection ? (
                    <div className="insurance-protection">
                      <Form.Checkbox
                        className="agree-check"
                        checked={props.state.insurance_protection}
                        label={"Insurance and protection"}
                        onChange={(e, value) =>
                          props.onChangeState(
                            value.checked,
                            "insurance_protection"
                          )
                        }
                      />

                      <Popup
                        content={
                          props.state.retailer_credit_range
                            .text_insurance_protection
                        }
                        trigger={<Icon link name="info circle" />}
                      />
                    </div>
                  ) : null
                ) : props.state.quot.retailer.credit_range
                    .insurance_protection ||
                  props.state.quot.insurance_protection ? (
                  <Header className="monthlyPayment" as="h4">
                    {props.state.insurance_protection ? (
                      <Icon name="check" />
                    ) : (
                      <Icon name="close" />
                    )}
                    Insurance and protection
                  </Header>
                ) : null}

                <div className={"preQualifiedOptionsWrapper additionalOptions"}>
                  <TradeModal
                    trade_in={props.state.trade_in}
                    money_owed={props.state.money_owed}
                    disabledTrade={props.state.editDisabled}
                    tradeChange={props.onChangeState}
                    detail={true}
                  />
                </div>

                <div className={"dealActionButtonsWrapper"}>
                  {props.state.quot.status == 0 ||
                    (props.state.quot.status == 1 && (
                      <div className={"dealActionButtonsWrapperForCreated"}>
                        {props.state.editDisabled ? (
                          props.state.role == "Customer" ? (
                            <Button
                              className="btn-continue"
                              buttonStyle={"secondary"}
                              onClick={(e) => props.checkFormula()}
                            >
                              Edit deal
                            </Button>
                          ) : null
                        ) : props.state.role == "Customer" ? (
                          <div className={"dealActionButtonsWrapper"}>
                            <Button
                              buttonStyle={"skyblue"}
                              className="btn-cancel"
                              onClick={(e) => props.cancelEdit()}
                            >
                              Cancel edit
                            </Button>

                            <Button
                              className="btn-continue"
                              onClick={(e) => props.editQuote()}
                            >
                              Save deal
                            </Button>
                          </div>
                        ) : null}

                        {props.state.role == "Customer" && (
                          <Button
                            buttonStyle={"danger"}
                            className="btn-cancel-quote"
                            onClick={(e) => props.changeQuotStatus(3)}
                          >
                            Cancel deal
                          </Button>
                        )}

                        {props.state.role == "Retailer" &&
                          props.state.quot.status == 1 && (
                            <Button
                              buttonStyle={"danger"}
                              className="btn-cancel-quote"
                              onClick={(e) => props.changeQuotStatus(3)}
                            >
                              Cancel deal
                            </Button>
                          )}

                        {props.state.role == "Retailer" &&
                          props.state.quot.status == 1 && (
                            <CoxProgramPlanModal
                              requestData={{
                                dealer_id: props.state.car.dealer_id,
                                vin: props.state.car.vin,
                                zip_code: props.state.customer.zip_code,
                                car_price: props.state.car.price,
                                loan_term: props.state.loan_length,
                                cox_price:
                                  props.state.finalQuots.monthly.coxPrice,
                                trade_in: props.state.trade_in,
                                money_owed: props.state.money_owed,
                              }}
                              onSelect={props.handleOnSelectProgram}
                            >
                              <Button
                                className="btn-accept-quote"
                                // onClick={(e) => props.changeQuotStatus(2)}
                              >
                                Accept deal
                              </Button>
                            </CoxProgramPlanModal>
                          )}
                      </div>
                    ))}

                  <div className={"buyerInformationButtonWrapper"}>
                    {props.state.role == "Admin" ||
                      (props.state.role == "Retailer" && (
                        <Button
                          className="btn-continue"
                          onClick={props.onOpenBuyerModal}
                          buttonStyle={"skyblue"}
                        >
                          Buyer Information
                        </Button>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <Header className="monthlyPayment" as="h4">
                {props.state.insurance_protection ? (
                  <Icon name="check" />
                ) : (
                  <Icon name="close" />
                )}
                Insurance and protection
              </Header>
            )}
          </div>
        </Grid.Column>
      )}
    </Grid>
  );
};

export default AdditionalInfo;
