import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Popup, Table } from 'semantic-ui-react';
import { FormatNumberToPrice, paymentDate } from '../../helpers/general';

export const PaymentSchedule = ({ paymentPlan, payments, result, disabled, fee, getPaymentList }) => {
  const [open, setOpen] = useState(false);
  const [pays, setPays] = useState([]);

  const validateWeekly = (pay, sum_of_installments, payment) => {
    let flag = false;
    if (pay >= sum_of_installments) {
      payment = sum_of_installments - (pay - payment);
      flag = true;
    }
    return { flag, payment };
  }

  const validateLenderError = (index) => {
    if (paymentPlan.paymentNumber === 2 && index === paymentPlan.payments.length - 2 && paymentPlan.payments[index].type === "lender" && paymentPlan.payments[index].status !== "processed") {
      return true;
    }
    return false;
  }

  const validateLenderBalanceError = (index) => {
    if (paymentPlan.paymentNumber === 1 && ((index === paymentPlan.payments.length - 2 && paymentPlan.payments[index].type === "lender" && paymentPlan.payments[index].status !== "processed") || (index === paymentPlan.payments.length - 1 && paymentPlan.payments[index].type === "balance" && paymentPlan.payments[index].status !== "processed"))) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    let auxPayDay = paymentDate(paymentPlan);
    let i = 1;
    let allPayments = [];
    let date = auxPayDay[0].date, final = false;
    let pay = 0, monthlyPay = 0, sum_of_installments = 0;
    if (paymentPlan.status > 0 && paymentPlan.amortization && paymentPlan.amortization.total) {
      sum_of_installments = paymentPlan.amortization.total.sum_of_installments;
    } else if (result.sum_of_installments) {
      sum_of_installments = result.sum_of_installments;
    }
    // ---------------------------- get total of made payments
    if (paymentPlan.payments != null) {
      let index = 0;
      for (const item of paymentPlan.payments) {
        let status = item.status;
        // ---------------------- only get total of processed payments for status 1
        if (paymentPlan.paymentDate === null && (status === 'processed' || (status !== 'processed' && index === paymentPlan.payments.length - 1) || validateLenderError(index) || validateLenderBalanceError(index))) {
          if (item.type === 'lender') monthlyPay += item.payment;
          if (item.type === 'balance') pay += item.payment - fee;
          if (item.final) final = true;
          i++;
        } else {
          status = 'failed';
        }
        // ---------------------- only get total of processed payments for restart
        if (paymentPlan.paymentDate !== null && item.status === 'processed') {
          if (item.type === 'lender') monthlyPay += item.payment;
          if (item.type === 'balance') pay += item.payment - fee;
          if (item.final) final = true;
          i++;
        }
        // ------------------------------ show fail status for status 3 and after restar withno payments
        if (paymentPlan.status === 3 || paymentPlan.status === 4 || paymentPlan.status === 5 || paymentPlan.paymentDate !== null) {
          status = item.status !== 'processed' ? 'failed' : item.status;
        }
        allPayments.push({ pay: item.type === 'balance' ? item.payment - fee : item.payment, date: item.created_at, type: item.type === 'balance' ? 'weekly' : 'monthly', status, final: item.final });
        index++;
      }
      if (paymentPlan.paymentDate !== null && paymentPlan.paymentNumber === 1) { // add lender payment if it only fails
        let weekly = null, monthly = null;
        if (paymentPlan.payments.length > 0) {
          for (let i = paymentPlan.payments.length - 1; i >= paymentPlan.payments.length - 2; i--) {
            if (paymentPlan.payments[i].type === 'balance') weekly = paymentPlan.payments[i];
            if (paymentPlan.payments[i].type === 'lender') monthly = paymentPlan.payments[i];
          }
          if (weekly.status === 'processed' && monthly.status === 'pending') { // validate lender payment
            allPayments.push({ pay: monthly.payment, date: paymentPlan.paymentDate, type: 'monthly' });
            monthlyPay += monthly.payment;
          }
        }
      }
      if (paymentPlan.payments.length > 0) { // get last payment date
        date = paymentPlan.paymentDate === null ? paymentPlan.payments[paymentPlan.payments.length - 1].created_at : moment(paymentPlan.paymentDate).subtract(7, 'days');
        if (paymentPlan.paymentDate === null && paymentPlan.payments[paymentPlan.payments.length - 1].type === 'lender') {
          date = moment(paymentPlan.payments[paymentPlan.payments.length - 1].created_at).subtract(7, 'days')
        }
        if (paymentPlan.status === 3 || paymentPlan.status === 4 || paymentPlan.status === 5) {
          allPayments[allPayments.length - 1].cancelled = true;
        }
      }
    }
    if (paymentPlan.payment != null && paymentPlan.payment > 0 && sum_of_installments && sum_of_installments > 0) {
      if (!final && paymentPlan.status !== 3) {
        let flagNext = false;
        // ------------------- add the new payments
        do {
          let payment = i % 5 === 0 ? +paymentPlan.loanMonthlyPayment : +paymentPlan.payment - fee;
          let type = i % 5 === 0 ? 'monthly' : 'weekly';
          date = i === 1 ? date : moment(date).add(7, 'days');
          if (type === 'weekly') {
            pay += (payment);
            let result = validateWeekly(pay, sum_of_installments, payment);
            if (result.flag) payment = result.payment
            if (payment > 0) allPayments.push({ pay: payment, date: date, type: type, next: !flagNext ? true : false });
            flagNext = true;
            if (result.flag) break;
          } else {
            let diff = pay - monthlyPay;
            if ((i + 1) % 26 === 0 && diff >= payment * 2) { // add extra payment in 26th weekly
              payment = payment * 2;
            }
            monthlyPay += (+paymentPlan.loanMonthlyPayment);
            allPayments.push({ pay: payment, date: date, type });
            pay += (+paymentPlan.payment - fee);
            let result = validateWeekly(pay, sum_of_installments, +paymentPlan.payment - fee);
            payment = result.flag ? result.payment : +paymentPlan.payment - fee;
            allPayments.push({ pay: payment, date: date, type: 'weekly', next: !flagNext ? true : false });
            flagNext = true;
            if (result.flag) break;
            i++;
          }
          i++;
        } while (paymentPlan.payment != null && paymentPlan.payment > 0);
      }
      // ----------------------------- add monthly payment if last was weekly payment
      if (paymentPlan.status !== 3 && allPayments.length > 0 && allPayments[allPayments.length - 1].type === 'weekly') {
        monthlyPay = 0; pay = 0;
        for (const item of allPayments) {
          if (item.type === 'monthly' && item.status !== 'failed') monthlyPay += item.pay;
          if (item.type === 'weekly' && item.status !== 'failed') pay += item.pay;
        }
        allPayments.push({ pay: pay - monthlyPay, date: moment(allPayments[allPayments.length - 1].date).add(7, 'days'), type: 'monthly', final });
      }
      if (paymentPlan.status !== 3 && paymentPlan.paymentDate != null && allPayments[allPayments.length - 1].type === "monthly" && allPayments[allPayments.length - 1].final) {
        allPayments.push({ pay: allPayments[allPayments.length - 1].pay, date: paymentPlan.paymentDate, type: 'monthly', final });
      }
      // --------------------------- combine npm tabla result with all monthly payments
      i = 1;
      let tot = 0, wto = 0;
      let auxPayments = payments;
      if (auxPayments.length > 0) {
        for (let item of allPayments) {
          if (item.type === 'monthly' && auxPayments[i] && ((item.status !== 'failed' && paymentPlan.status !== 3) || paymentPlan.status === 3 || paymentPlan.status === 4 || paymentPlan.status === 5)) {
            tot += item.pay;
            item['rate'] = auxPayments[i][1];
            item['installment'] = auxPayments[i][2];
            item['reduction'] = auxPayments[i][3];
            item['interest'] = auxPayments[i][4];
            item['principal'] = auxPayments[i][5];
            i++;
          }
          if (item.type === 'weekly' && item.status !== 'failed') wto += item.pay
        }
      }
      console.log(tot, wto, sum_of_installments);
      setPays(allPayments);
      if (!paymentPlan.status) {
        getPaymentList(allPayments);
      }
    }
  }, [result])


  return (
    <Modal size="large" open={open} closeOnEscape={false} closeOnDimmerClick={false}
      trigger={
        <Button color="green" onClick={() => setOpen(true)} disabled={disabled}>Payment Schedule</Button>
      }>
      <Modal.Header>
        Payment Schedule
      </Modal.Header>
      <Modal.Content className='payment-schedule' scrolling>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
              <Table.HeaderCell>Installment</Table.HeaderCell>
              <Table.HeaderCell>Reduction</Table.HeaderCell>
              <Table.HeaderCell>Interest</Table.HeaderCell>
              <Table.HeaderCell>Principal</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              pays.map(item => (
                item.next || item.cancelled || item.final ?
                  <Popup content={item.next ? 'Next Payment' : item.cancelled ? 'Payment plan stopped' : 'Final ' + item.type + " payment"} trigger={
                    <Table.Row className={item.next ? 'nextPayment' : item.cancelled ? 'cancelledPayment' : item.final ? 'finalPayment' : ''}>
                      <Table.Cell>{moment(item.date).format('dddd, MMMM D, YYYY')}</Table.Cell>
                      <Table.Cell>{item.rate}</Table.Cell>
                      <Table.Cell>{FormatNumberToPrice(item.pay)}</Table.Cell>
                      <Table.Cell>{FormatNumberToPrice(item.reduction) === '$NaN' ? '-' : FormatNumberToPrice(item.reduction)}</Table.Cell>
                      <Table.Cell>{FormatNumberToPrice(item.interest) === '$NaN' ? '-' : FormatNumberToPrice(item.interest)}</Table.Cell>
                      <Table.Cell>{FormatNumberToPrice(item.principal) === '$NaN' ? '-' : FormatNumberToPrice(item.principal)}</Table.Cell>
                      <Table.Cell>{item.type === 'weekly' ? 'Weekly' : 'Monthly'}</Table.Cell>
                      <Table.Cell className={item.status === 'processed' ? 'finalPayment': !item.status ? '' : 'cancelledPayment'}>{item.status}</Table.Cell>
                    </Table.Row>
                  } />
                  :
                  <Table.Row >
                    <Table.Cell>{moment(item.date).format('dddd, MMMM D, YYYY')}</Table.Cell>
                    <Table.Cell>{item.rate}</Table.Cell>
                    <Table.Cell>{FormatNumberToPrice(item.pay)}</Table.Cell>
                    <Table.Cell>{FormatNumberToPrice(item.reduction) === '$NaN' ? '-' : FormatNumberToPrice(item.reduction)}</Table.Cell>
                    <Table.Cell>{FormatNumberToPrice(item.interest) === '$NaN' ? '-' : FormatNumberToPrice(item.interest)}</Table.Cell>
                    <Table.Cell>{FormatNumberToPrice(item.principal) === '$NaN' ? '-' : FormatNumberToPrice(item.principal)}</Table.Cell>
                    <Table.Cell>{item.type === 'weekly' ? 'Weekly' : 'Monthly'}</Table.Cell>
                    <Table.Cell className={item.status === 'processed' ? 'finalPayment': !item.status ? '' : 'cancelledPayment'}>{item.status}</Table.Cell>
                  </Table.Row>
              ))
            }
            {
              !disabled && (
                <Table.Row>
                  <Table.Cell>Total</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>{FormatNumberToPrice(result.sum_of_installments)}</Table.Cell>
                  <Table.Cell>{FormatNumberToPrice(result.sum_of_reductions)}</Table.Cell>
                  <Table.Cell>{FormatNumberToPrice(result.sum_of_interests)}</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary className='close' onClick={() => setOpen(false)} ><h3>Close</h3></Button>
      </Modal.Actions>
    </Modal>
  );
};