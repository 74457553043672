import React, { useState } from "react";
import { Form, Grid } from "semantic-ui-react";
import Slider, { Range } from "rc-slider";
import moment from "moment";

import DateInput from "../../global/DateInput";
import Input from "../../global/Input";
import Dropdown from "../../global/DropDown";
import Button from "../../global/Button";

const options = [
  { value: "today", text: "Today" },
  { value: "month", text: "This month" },
  { value: "last_month", text: "Last month" },
  { value: "range", text: "Date range" },
];

const Handle = Slider.Handle;
const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Handle value={value} {...restProps}>
      <div className="inner">
        <div className={`wdc-tooltip${dragging ? " active" : ""}`}>
          <span className="wdc-tooltip-inner">{value}</span>
        </div>
      </div>
    </Handle>
  );
};

export const CreditSolutionFilter = ({ filter }) => {
  const [form, setForm] = useState({
    startDate: "",
    endDate: "",
    option: "",
    score: [0, 2000],
    key: "",
  });
  // ---------------------------- update form and validate -----------------
  const onEditInput = (value, label) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    setForm({ ...auxForm });
  };

  return (
    <Form className="search-form">
      <Form.Group>
        <Form.Field width={8}>
          <label>Search by name or address</label>

          <Input
            onChange={(e) => onEditInput(e.target.value, "key")}
            placeholder={"Search by name or address"}
            value={form.key}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>Credit Score Range:</label>

          <div style={{ padding: "0 1rem", marginBottom: "2rem" }}>
            <Range
              min={0}
              max={2000}
              step={1}
              value={form.score}
              onChange={(value) => onEditInput(value, "score")}
              handleStyle={{
                backgroundColor: "white",
                marginBottom: 10,
              }}
              handle={handle}
              tipProps={{
                placement: "bottom",
                overlayClassName: "foo",
                prefixCls: "rc-slider-tooltip",
              }}
              marks={{
                [0]: 0,
                [2000]: 2000,
              }}
            />
          </div>
        </Form.Field>
      </Form.Group>

      <Form.Group className={"filterSection"}>
        <Form.Field width={form.option === "range" ? 4 : 5}>
          <label>Filter by:</label>

          <Dropdown
            onChange={(e, { value }) => onEditInput(value, "option")}
            placeholder={"Filter by"}
            options={options}
            value={form.option}
            clearable
            selection
          />
        </Form.Field>

        {form.option === "range" && (
          <Form.Field width={9} className={"datesWrapper"}>
            <label>Date range:</label>

            <Grid style={{ display: "contents" }}>
              <Grid.Column width={8} style={{ padding: 0 }}>
                <DateInput
                  dateFormat="MM-DD-YYYY"
                  onChange={(event, { name, value }) => {
                    onEditInput(value, "startDate");
                  }}
                  value={form.startDate}
                  popupPosition={"right center"}
                  animation="none"
                  clearable
                  closable
                  timeFormat="AMPM"
                />
              </Grid.Column>

              <Grid.Column width={8} style={{ padding: 0 }}>
                <DateInput
                  dateFormat="MM-DD-YYYY"
                  onChange={(event, { name, value }) => {
                    onEditInput(value, "endDate");
                  }}
                  value={form.endDate}
                  popupPosition={"right center"}
                  animation="none"
                  clearable
                  closable
                  maxDate={moment()}
                  timeFormat="AMPM"
                />
              </Grid.Column>
            </Grid>
          </Form.Field>
        )}
        <Form.Field
          width={form.option === "range" ? 3 : 4}
          className="btn-filter"
        >
          <Button
            buttonStyle={'skyblue'}
            onClick={() => filter(form)}
            disabled={
              form.option === "range" &&
              (!moment(form.startDate, "MM-DD-YYYY", true).isValid() ||
                !moment(form.endDate, "MM-DD-YYYY", true).isValid())
            }
          >
            Filter
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
