import React, { createContext, useContext } from "react";

import ProtectionCoverage from "../../images/protectionPlaceholder.jpg";
import Attributes from "../../images/optionsPlaceholder.jpg";
import RebatesTurtle from "../../images/rebateTurtle.png";
import TradeIn from "../../images/tradeInPlaceholder.jpg";

export const defaultTheme = {
  input: {
    default: {
      color: "rgba(215, 215, 215, 1)",
      shadow: "rgba(215, 215, 215, 0)",
    },
    focus: {
      color: "rgba(65, 174, 255, 1)",
      shadow: "rgba(65, 174, 255, 0.3)",
    },
    error: {
      color: "rgba(219, 40, 40, 1)",
      shadow: "rgba(219, 40, 40, 0.3)",
    },
    borderRadius: 10,
  },
  typography: {
    primary: "rgba(65, 174, 255, 1)",
    secondary: "rgba(78, 85, 253, 1)",
    success: "rgba(1, 148, 73, 1)",
    negative: "rgba(219, 40, 40, 1)",
  },
  button: {
    primary: {
      background: "rgba(65, 174, 255, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      background: "rgba(78, 85, 253, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    success: {
      background: "rgba(1, 148, 73, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    negative: {
      background: "rgba(219, 40, 40, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    borderRadius: 10,
  },
  weekly: {
    color: "rgba(255, 255, 255, 1)",
    background: "rgba(24, 0, 115, 1)",
    borderRadius: 10,
  },
};

export const defaultImages = {
  tradeIn: TradeIn,
  protectiveCoverage: ProtectionCoverage,
  rebates: RebatesTurtle,
  attributes: Attributes,
};

const ThemeCtx = createContext({
  theme: {},
});

export const ThemeContextProvider = ({ value, children }) => (
  <ThemeCtx.Provider value={value}>{children}</ThemeCtx.Provider>
);

export const useTheme = () => {
  const context = useContext(ThemeCtx);

  return context;
};
