import React, { Component } from "react";
import { Modal, Grid } from "semantic-ui-react";

import Button from "../../global/Button";

import "./userModal.sass";

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.modalState = this.modalState.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && prevState.modal) {
      this.setState({ modal: false });
      this.props.setOpen(true);
    }
  }

  modalState(state) {
    this.setState({ modal: state });
  }

  render() {
    return (
      <Modal
        closeIcon
        open={this.state.modal}
        onClose={() => this.modalState(false)}
        onOpen={() => this.modalState(true)}
        closeOnEscape={false}
        trigger={<Button positive>+ Add Retailers</Button>}
        size={this.props.size ? this.props.size : "small"}
        className={"userModal"}
      >
        <Modal.Header>{this.props.title}</Modal.Header>

        <Modal.Content className={"customModal"} scrolling={true}>
          <div className={"modalContent"}>
            <Grid stackable>
              <Grid.Column width={16}>{this.props.children}</Grid.Column>
            </Grid>
          </div>
        </Modal.Content>
        <Modal.Actions>{this.props.actions}</Modal.Actions>
      </Modal>
    );
  }
}

export default UserModal;
