import React, { Component } from "react";
import { Form, Grid, Dimmer, Loader } from "semantic-ui-react";
import { editCreditRange } from "../requests";

import LoanLength from "./LoanLength";
import DownPayment from "./DownPayment";
import CreditRangeScore from "./CreditScoreRange";
import InsuranceAndProtection from "./InsuranceAndProtection";

// IMPORT COMPONENTS
import ModalError from "../alertModal/modal";
import Button from "../../global/Button";

import "./rangeValues.sass";

class rangeValues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {},
      completeForm: true,
      loading: false,
      flagEdit: true,
      retailer: props.retailer,
      min_loan_length: props.retailer.credit_range.min_loan_length,
      max_loan_length: props.retailer.credit_range.max_loan_length,
      default_loan_length: props.retailer.credit_range.default_loan_length,
      default_down_payment: props.retailer.credit_range.default_down_payment,
      min_down_payment:
        props.retailer.credit_range.min_down_payment ||
        props.retailer.credit_range.min_down_payment == 0
          ? props.retailer.credit_range.min_down_payment
          : null,
      max_down_payment: props.retailer.credit_range.max_down_payment
        ? props.retailer.credit_range.max_down_payment
        : null,
      poor: props.retailer.credit_range.poor,
      fair: props.retailer.credit_range.fair,
      good: props.retailer.credit_range.good,
      very_good: props.retailer.credit_range.very_good,
      exceptional: props.retailer.credit_range.exceptional,
      text_insurance_protection:
        props.retailer.credit_range.text_insurance_protection,
      insurance_protection: props.retailer.credit_range.insurance_protection,
      msnErrors: {
        default_loan_length: "",
        min_loan_length: "",
        max_loan_length: "",
        default_down_payment: "",
        min_down_payment: "",
        max_down_payment: "",
        poor: "",
        fair: "",
        good: "",
        very_good: "",
        exceptional: "",
        text_insurance_protection: "",
      },
      labels: [
        "default_loan_length",
        "min_loan_length",
        "max_loan_length",
        "default_down_payment",
        "min_down_payment",
        "max_down_payment",
        "poor",
        "fair",
        "good",
        "very_good",
        "exceptional",
        "text_insurance_protection",
      ],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.retailer &&
      this.props.retailer.id !== prevProps.retailer.id
    ) {
      this.setState(
        {
          retailer: this.props.retailer,
          default_loan_length:
            this.props.retailer.credit_range.default_loan_length,
          min_loan_length: this.props.retailer.credit_range.min_loan_length,
          max_loan_length: this.props.retailer.credit_range.max_loan_length,
          default_down_payment:
            this.props.retailer.credit_range.default_down_payment,
          min_down_payment: this.props.retailer.credit_range.min_down_payment
            ? this.props.retailer.credit_range.min_down_payment
            : null,
          max_down_payment: this.props.retailer.credit_range.max_down_payment
            ? this.props.retailer.credit_range.max_down_payment
            : null,
          poor: this.props.retailer.credit_range.poor,
          fair: this.props.retailer.credit_range.fair,
          good: this.props.retailer.credit_range.good,
          very_good: this.props.retailer.credit_range.very_good,
          exceptional: this.props.retailer.credit_range.exceptional,
          text_insurance_protection:
            this.props.retailer.credit_range.text_insurance_protection,
          insurance_protection:
            this.props.retailer.credit_range.insurance_protection,
        },
        () => this.validateInitial()
      );
    }
  }

  onEditInput = (label, value) => {
    let msnErrors = this.state.msnErrors;
    let labels = this.state.labels;
    for (const i of labels) {
      msnErrors = this.switchLabel(i, value, msnErrors, label);
    }
    this.setState({ [label]: value, msnErrors }, () => this.validateForm());
  };

  // --------------------------- validate inputs -----------------------------
  switchLabel(labels, value, msnErrors, label) {
    let max_loan_length,
      min_loan_length,
      max_down_payment,
      min_down_payment,
      poor,
      fair,
      good,
      very_good,
      exceptional;
    let val = 0;
    switch (labels) {
      case "default_loan_length":
        val =
          label == "default_loan_length"
            ? value
            : parseFloat(this.state.default_loan_length);
        if (val != "" && !isNaN(val)) {
          min_loan_length =
            label == "min_loan_length"
              ? value
              : parseFloat(this.state.min_loan_length);
          max_loan_length =
            label == "max_loan_length"
              ? value
              : parseFloat(this.state.max_loan_length);
          msnErrors.default_loan_length =
            val < min_loan_length ||
            val > max_loan_length ||
            val % 1 != 0 ||
            isNaN(val)
              ? "The field must be and integer number, greater than or equal to Minimum Loan Length and less than or equal to Maximum Loan Length"
              : "";
        } else {
          msnErrors.default_loan_length =
            "The field must be and integer number, greater than or equal to Minimum Loan Length and less than or equal to Maximum Loan Length";
        }
        break;
      case "min_loan_length":
        val =
          label == "min_loan_length"
            ? value
            : parseFloat(this.state.min_loan_length);
        max_loan_length =
          label == "max_loan_length"
            ? value
            : parseFloat(this.state.max_loan_length);
        msnErrors.min_loan_length =
          val <= 0 || val >= max_loan_length || val % 1 != 0 || isNaN(val)
            ? "The field must be and integer number, greater than 0 and less than Maximum Loan Length"
            : "";
        break;
      case "max_loan_length":
        val =
          label == "max_loan_length"
            ? value
            : parseFloat(this.state.max_loan_length);
        min_loan_length =
          label == "min_loan_length"
            ? value
            : parseFloat(this.state.min_loan_length);
        msnErrors.max_loan_length =
          val <= min_loan_length || val % 1 != 0 || isNaN(val)
            ? "The field must be and integer number and greater than Minimum Loan Length"
            : "";
        break;
      case "default_down_payment":
        val =
          label == "default_down_payment"
            ? value
            : parseFloat(this.state.default_down_payment);
        min_down_payment =
          label == "min_down_payment"
            ? value
            : parseFloat(this.state.min_down_payment);
        max_down_payment =
          label == "max_down_payment"
            ? value
            : parseFloat(this.state.max_down_payment);
        if (val != "" && !isNaN(val)) {
          if (!isNaN(min_down_payment) && !isNaN(max_down_payment)) {
            msnErrors.default_down_payment =
              val < min_down_payment || val > max_down_payment || isNaN(val)
                ? "The field must be and number, greater than or equal to Minimum Down Payment and less than or equal to Maximum Down Payment"
                : "";
          } else {
            msnErrors.default_down_payment =
              isNaN(val) || val < 0 || val > 100
                ? "The field must be and number, greater than 0% and less than or equal to 100%"
                : "";
          }
        } else {
          if (!isNaN(min_down_payment) && !isNaN(max_down_payment)) {
            msnErrors.default_down_payment =
              "The field must be and number, greater than or equal to Minimum Down Payment and less than or equal to Maximum Down Payment";
          } else {
            msnErrors.default_down_payment =
              "The field must be and number, greater than 0% and less than or equal to 100%";
          }
        }
        break;
      case "min_down_payment":
        max_down_payment =
          label == "max_down_payment"
            ? value
            : parseFloat(this.state.max_down_payment);
        val =
          label == "min_down_payment"
            ? value
            : parseFloat(this.state.min_down_payment);
        if (max_down_payment || val) {
          msnErrors.min_down_payment =
            isNaN(val) || val === "" || val < 0 || val >= max_down_payment
              ? "The field must be greater than 0% and less than Maximum Down Payment"
              : "";
        } else {
          msnErrors.min_down_payment = "";
        }
        break;
      case "max_down_payment":
        min_down_payment =
          label == "min_down_payment"
            ? value
            : parseFloat(this.state.min_down_payment);
        val =
          label == "max_down_payment"
            ? value
            : this.state.max_down_payment != ""
            ? parseFloat(this.state.max_down_payment)
            : "";
        if (min_down_payment || val) {
          msnErrors.max_down_payment =
            val <= min_down_payment || isNaN(val) || val > 100
              ? "The field must be greater than Minimum Down Payment and less than or equal to 100%"
              : "";
        } else {
          msnErrors.max_down_payment = "";
        }
        break;
      case "poor":
        val = label == "poor" ? value : parseFloat(this.state.poor);
        fair = label == "fair" ? value : parseFloat(this.state.fair);
        msnErrors.poor =
          val <= 0 || val >= fair || isNaN(val)
            ? "The field must be less than Fair"
            : "";
        break;
      case "fair":
        val = label == "fair" ? value : parseFloat(this.state.fair);
        poor = label == "poor" ? value : parseFloat(this.state.poor);
        good = label == "good" ? value : parseFloat(this.state.good);
        msnErrors.fair =
          val <= poor || val >= good || isNaN(val)
            ? "The field must be greater than Poor and less than Good"
            : "";
        break;
      case "good":
        val = label == "good" ? value : parseFloat(this.state.good);
        fair = label == "fair" ? value : parseFloat(this.state.fair);
        very_good =
          label == "very_good" ? value : parseFloat(this.state.very_good);
        msnErrors.good =
          val <= fair || val >= very_good || isNaN(val)
            ? "The field must be greater than Fair and less than Very Good"
            : "";
        break;
      case "very_good":
        val = label == "very_good" ? value : parseFloat(this.state.very_good);
        good = label == "good" ? value : parseFloat(this.state.good);
        exceptional =
          label == "exceptional" ? value : parseFloat(this.state.exceptional);
        msnErrors.very_good =
          val <= good || val >= exceptional || isNaN(val)
            ? "The field must be greater than Good and less than Exceptional"
            : "";
        break;
      case "exceptional":
        val =
          label == "exceptional" ? value : parseFloat(this.state.exceptional);
        very_good =
          label == "very_good" ? value : parseFloat(this.state.very_good);
        msnErrors.exceptional =
          val <= very_good || isNaN(val)
            ? "The field must be greater than Very Good"
            : "";
        break;
      case "text_insurance_protection":
        val =
          label == "text_insurance_protection"
            ? value
            : this.state.text_insurance_protection;
        msnErrors.text_insurance_protection =
          !val || val == "" ? "The field is required" : "";
        break;
      default:
        break;
    }
    return msnErrors;
  }

  validateForm = () => {
    let array = this.state.labels;
    let valid = true;
    let msnErrors = this.state.msnErrors;
    for (let i = 0; i < array.length; i++) {
      if (msnErrors[array[i]].length > 0) {
        valid = false;
      }
    }
    this.setState({ completeForm: valid });
    return valid;
  };

  // -------------------------------- update ranges -------------------------------
  updateRanges = () => {
    this.setState({ loading: true });
    let data = {
      min_loan_length: +this.state.min_loan_length,
      max_loan_length: +this.state.max_loan_length,
      min_down_payment: +this.state.min_down_payment,
      max_down_payment: +this.state.max_down_payment,
      poor: +this.state.poor,
      fair: +this.state.fair,
      good: +this.state.good,
      very_good: +this.state.very_good,
      exceptional: +this.state.exceptional,
      default_loan_length: +this.state.default_loan_length,
      default_down_payment: +this.state.default_down_payment,
      text_insurance_protection: this.state.text_insurance_protection,
      insurance_protection: this.state.insurance_protection,
    };
    editCreditRange(this.state.retailer.credit_range.id, data)
      .then((response) => {
        let retailer = { ...this.state.retailer };
        retailer["credit_range"] = response.data;
        this.setState({
          loading: false,
          retailer,
          modal: {
            status: 200,
            message: "The widget values were updated",
          },
          flagEdit: true,
        });
        this.props.updateRetailer(retailer);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            this.setState({
              loading: false,
              modal: {
                status: 422,
                message: "Sorry. Field is missing to save the widget values",
              },
            });
          } else {
            this.setState({
              loading: false,
              modal: {
                status: 500,
                message: "Sorry. An error occurred, please try again later",
              },
            });
          }
        }
      });
  };

  // ---------------------------- hide modal -----------------------------------
  hideModal = () => {
    this.setState({ modal: {} });
  };

  render() {
    return (
      <div className="body content-ranges">
        {this.state.retailer.id && (
          <div>
            <Grid>
              <Form style={{ width: "100%" }}>
                <LoanLength
                  state={{ ...this.state }}
                  onEditInput={this.onEditInput}
                />

                <DownPayment
                  state={{ ...this.state }}
                  onEditInput={this.onEditInput}
                />

                <CreditRangeScore
                  state={{ ...this.state }}
                  onEditInput={this.onEditInput}
                />
              </Form>
            </Grid>

            <Grid>
              <Form style={{ width: "100%" }}>
                <InsuranceAndProtection
                  state={{ ...this.state }}
                  onEditInput={this.onEditInput}
                />
              </Form>
            </Grid>

            {this.state.flagEdit ? (
              <div className={"actionButtonsWrapper"}>
                <Button
                  buttonStyle={"secondary"}
                  onClick={() => this.setState({ flagEdit: false })}
                >
                  Edit
                </Button>
              </div>
            ) : (
              <div className={"actionButtonsWrapper"}>
                <Button
                  buttonStyle={"danger"}
                  onClick={() => this.setState({ flagEdit: true })}
                >
                  Cancel
                </Button>

                <Button
                  disabled={!this.state.completeForm}
                  onClick={() => this.updateRanges()}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        )}

        {this.state.loading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}

        {this.state.modal.message && (
          <ModalError hideModal={this.hideModal} modal={this.state.modal} />
        )}
      </div>
    );
  }
}

export default rangeValues;
