import React from "react";

import Step from "./Step";

import "./Steps.sass";

const Steps = ({ currentStep, children }) => {
  const stepsCount = React.Children.count(children);

  return (
    <div className={"stepsWrapper"}>
      {React.Children.map(children, (_children, index) => {
        const currentlyActiveIndexOfStep = React.Children.toArray(
          children
        ).findIndex((item) => {
          const { allowedSteps } = item.props;

          return allowedSteps.includes(currentStep);
        });

        const { allowedSteps, onClick } = _children.props;
        const isLast = stepsCount === index + 1;
        const isActive = allowedSteps.includes(currentStep);
        const isPrevious = currentlyActiveIndexOfStep >= index;
        const isClickable = isPrevious && onClick && !isActive;
        const childrenProps = {
          ..._children?.props,
          index: index + 1,
          isLast,
          isActive,
          isPrevious,
          isClickable,
          key: index,
        };

        return React.cloneElement(_children, {
          ...childrenProps,
        });
      })}
    </div>
  );
};

export default Steps;

Steps.Step = Step;
