import React, { useEffect, useState } from "react";
import { Icon, Dimmer, Loader, Divider, Header } from "semantic-ui-react";
import { useMediaPredicate } from "react-media-hook";

import RebatesList from "./RebatesList";
import RebateModal from "./RebateModal";
import Button from "../../global/Button";

import { getRebates } from "../../utilities/requests";

import "./Rebates.sass";

const Rebates = ({ retailer }) => {
  const [activeRebates, setActiveRebates] = useState([]);
  const [inactiveRebates, setInactiveRebates] = useState([]);
  const [allRebates, setAllRebates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const is576 = useMediaPredicate("(max-width: 576px)");

  const fetchRebates = async () => {
    if (!retailer) return;

    setIsLoading(true);

    try {
      const { data } = await getRebates(retailer.id);

      setActiveRebates(data.filter((rebate) => rebate.active));
      setInactiveRebates(data.filter((rebate) => !rebate.active));
      setAllRebates(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRebates();
  }, []);

  return (
    <div>
      <div className={"topActions"}>
        <h3 className={"header"}>Rebates</h3>

        <RebateModal
          rebates={activeRebates}
          retailer={retailer}
          update={fetchRebates}
          allRebates={allRebates}
        >
          <Button>{is576 ? <Icon name="plus" /> : "Add Rebate"}</Button>
        </RebateModal>
      </div>

      {inactiveRebates.length > 0 && (
        <Header as={"h4"} className={"rebateListTitle"}>
          Visible Rebates:
        </Header>
      )}

      <RebatesList
        listOfAllRebates={allRebates}
        rebates={activeRebates}
        update={fetchRebates}
        retailer={retailer}
      />

      {inactiveRebates.length > 0 && (
        <>
          <Divider />

          <Header as={"h4"} className={"rebateListTitle"}>
            Inactive Rebates:
          </Header>

          <RebatesList
            listOfAllRebates={allRebates}
            rebates={inactiveRebates}
            update={fetchRebates}
            retailer={retailer}
          />
        </>
      )}

      {isLoading && (
        <Dimmer className="loading" active>
          <Loader />
        </Dimmer>
      )}
    </div>
  );
};

export default Rebates;
