import React from "react";

import { Header, Form } from "semantic-ui-react";

import TextArea from "../../../global/TextArea";

const InsuranceAndProtection = (props) => {
  return (
    <>
      <Header as={"h3"} style={{ marginTop: "1rem" }}>
        Insurance and Protection
      </Header>

      <Form.Group>
        <Form.Field className={"disabled-check"}>
          <label>Check box</label>

          <Form.Checkbox
            toggle
            disabled={props.state.flagEdit}
            checked={props.state.insurance_protection}
            onChange={(e, value) =>
              props.onEditInput("insurance_protection", value.checked)
            }
            label={props.state.insurance_protection ? "Enabled" : "Disabled"}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        {props.state.flagEdit ? (
          <Form.Field>
            <label>Text</label>

            <span>{props.state.text_insurance_protection}</span>
          </Form.Field>
        ) : (
          <Form.Field width={16}>
            <label>Text</label>

            <TextArea
              label="Text"
              value={props.state.text_insurance_protection}
              placeholder="Insurance and Protection"
              onChange={(e) =>
                props.onEditInput("text_insurance_protection", e.target.value)
              }
              error={
                props.state.msnErrors.text_insurance_protection.length > 0 &&
                props.state.msnErrors.text_insurance_protection
              }
            />
          </Form.Field>
        )}
      </Form.Group>
    </>
  );
};

export default InsuranceAndProtection;
