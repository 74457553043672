import React from "react";
import { Header } from "semantic-ui-react";
import {
  FormatCashDownToPrice,
  FormatNumberToPrice,
} from "../../helpers/general";
import { MonthlyTooltip } from "../tooltips/MonthlyTooltip";
import { WeeklyTooltip } from "../tooltips/WeeklyTooltip";
import { useMediaPredicate } from "react-media-hook";
import { defaultTheme, useTheme } from "../../helpers/theme";

const WeeklyHead = (props) => {
  const { theme } = useTheme();

  const is1180 = useMediaPredicate("(max-width: 1180px)");
  const is576 = useMediaPredicate("(max-width: 576px)");

  return (
    <div
      style={{
        "--weekly-head-radius":
          (theme?.button?.borderRadius || defaultTheme.button.borderRadius) +
          "px",
      }}
    >
      <Header className="payment" as="h3">
        <div
          className="pointer"
          onClick={() => props.nextStep && props.nextStep()}
          style={{
            "--weekly-color": theme?.weekly?.color || defaultTheme.weekly.color,
            "--weekly-background":
              theme?.weekly?.background || defaultTheme.weekly.background,
            "--weekly-border-radius":
              (theme?.weekly?.borderRadius ||
                defaultTheme.weekly.borderRadius) + "px",
            cursor: props.abledNext ? "pointer" : "default",
          }}
        >
          <WeeklyTooltip
            finalQuots={props.quots}
            offset={is1180 ? "0, 0" : "0, 50px"}
            position={
              is1180 ? (is576 ? "top left" : "top center") : "left center"
            }
            {...props.tooltip}
          />

          <span>{FormatNumberToPrice(props.quots.weekly.payment)} Weekly</span>
        </div>
      </Header>

      <div className="ui-mobile">
        {!props.isDetail ? (
          <span className="chas-down">
            {FormatCashDownToPrice(parseInt(props.quots.monthly.down_payment))}{" "}
            DOWN
          </span>
        ) : null}
      </div>

      <Header
        className="monthlyPayment"
        as="h4"
        style={{ display: "flex", justifyContent: "center", gap: "16px" }}
      >
        <span>
          {FormatNumberToPrice(props.quots.monthly.payment)} Monthly
          <MonthlyTooltip
            finalQuots={props.quots}
            annual_interest_rate={props.interest}
          />
        </span>
        <span>For {props.quots.monthly.contracted_term} Months</span>
      </Header>
    </div>
  );
};

export default WeeklyHead;
