import React, { useEffect } from "react";
import { Form, Grid } from "semantic-ui-react";
import { callOptions, contactOptions } from "../../helpers/general";

import Input from "../../global/Input";
import Dropdown from "../../global/DropDown";

import { useTheme } from "../../helpers/theme";

export const ContactInformation = (props) => {
  const { theme } = useTheme();

  const { form, msnErrors, flagEdit, noPadding } = props;

  useEffect(() => {}, [form, msnErrors, flagEdit]);

  return (
    <>
      <Form.Group>
        <Form.Field width={8}>
          <label>Contact Number</label>

          <Input
            id={"contactNumber"}
            value={form.contactNumber}
            placeholder={"Contact Number"}
            onChange={(e) => props.onEditInput("contactNumber", e.target.value)}
            fluid
            error={
              msnErrors.contactNumber.length > 0
                ? msnErrors.contactNumber
                : null
            }
            maxLength="50"
            disabled={flagEdit}
            theme={theme?.input}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>Extension</label>

          <Input
            id={"extension"}
            value={form.extension}
            placeholder={"Extension"}
            onChange={(e) => props.onEditInput("extension", e.target.value)}
            fluid
            maxLength="50"
            disabled={flagEdit}
            theme={theme?.input}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group>
        <Form.Field width={8}>
          <label>Best time to connect</label>

          <Dropdown
            id={"bestTimeCall"}
            value={form.bestTimeCall}
            placeholder={"Choose one"}
            options={callOptions}
            onChange={(e, { value }) =>
              props.onEditInput("bestTimeCall", value)
            }
            fluid
            disabled={flagEdit}
            selection
            theme={theme?.input}
          />
        </Form.Field>

        <Form.Field width={8}>
          <label>
            Preferred contact method <span className="required-inut">*</span>
          </label>

          <Dropdown
            id={"contactMethod"}
            value={form.contactMethod}
            placeholder={"choose one"}
            options={contactOptions}
            onChange={(e, { value }) =>
              props.onEditInput("contactMethod", value)
            }
            fluid
            selection
            disabled={flagEdit}
            error={!msnErrors.contactMethod ? "Select a option" : null}
            theme={theme?.input}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};
