import React, { useState } from "react";
import { Modal, Dimmer, Loader, Message } from "semantic-ui-react";
import _ from "lodash";

import Swiper from "./Swiper";
import QuestionnaireModal from "./QuestionnaireModal";
import Button from "../../global/Button";
import Dropdown from "../../global/DropDown";

import { fetchRanges, fetchProtectionPlans } from "../../utilities/requests";

import { defaultTheme, useTheme } from "../../helpers/theme";
import { changeColorOpacity } from "../../helpers/general";

import { ReactComponent as CrossIcon } from "../../../images/crossIcon.svg";

import "./AddProtectionCoverageModal.sass";

const AddProtectionCoverageModal = ({
  retailer,
  quotes,
  car,
  loanLength,
  selectedProtectionCoverage,
  onClose,
  children,
}) => {
  const { theme } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [protectivePackages, setProtectivePackages] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedMiles, setSelectedMiles] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [options, setOptions] = useState({
    months: [],
    miles: [],
  });

  const getRangesOptions = (data) => {
    const conditionsFromProducts = data.reduce((container, plan) => {
      const conditionsFromPlan = [];

      for (const category in plan) {
        conditionsFromPlan.push(
          plan[category].products.map((p) => p.conditions)
        );
      }

      return [...container, ...conditionsFromPlan];
    }, []);

    const allConditions = conditionsFromProducts.flat(1);
    const minMonthValue = _.min(
      allConditions.map((condition) => condition.months.from)
    );
    const maxMonthValue = _.max(
      allConditions.map((condition) => condition.months.to)
    );
    const minMilesValue = _.min(
      allConditions.map((condition) => condition.miles.from)
    );
    const maxMilesValue = _.max(
      allConditions.map((condition) => condition.miles.to)
    );
    const dropdownOptions = {
      miles: _.range(
        minMilesValue || minMilesValue + 10000,
        maxMilesValue + 10000,
        10000
      ).map((v) => ({
        key: v,
        value: v,
        text: `${v / 1000}K miles`,
      })),
      months: _.range(minMonthValue, maxMonthValue + 3, 3).map((v) => ({
        k: v,
        value: v,
        text: `${v} mo`,
      })),
    };

    setOptions(dropdownOptions);

    return dropdownOptions;
  };

  const getPackages = async (filter) => {
    setIsLoading(true);

    try {
      const ranges = await fetchRanges(retailer.id);

      if (!ranges.length) return;

      const options = getRangesOptions(ranges);
      const termStart = options.months.at(0).value;
      const termEnd = options.months.at(-1).value;
      const milesEnd = options.miles.at(-1).value;
      const coverageTerm = filter?.months || termEnd;
      const coverageMiles = filter?.miles || milesEnd;

      const dto = {
        vin: car.vin,
        msrp: car.msrp || quotes.monthly.purchasing_power,
        purchase: quotes.monthly.purchasing_power,
        amount_financed: quotes.monthly.amount,
        odometer: car.miles,
        condition: car.condition,
        termStart,
        termEnd: coverageTerm,
        financedTerm: loanLength,
      };

      const packages = await fetchProtectionPlans({
        ...dto,
        coverageTerm,
        coverageMiles,
      });
      const ids = {};

      for (const p of packages) {
        if (
          selectedProtectionCoverage?.selectedPlan === p.id &&
          _.isEqual(filter, selectedProtectionCoverage?.filter)
        ) {
          ids[p.id] = selectedProtectionCoverage?.selectedProducts;
        } else {
          ids[p.id] = {};
        }
      }

      setSelectedMiles(coverageMiles);
      setSelectedTerm(coverageTerm);
      setSelectedProducts(ids);
      setProtectivePackages(packages);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const open = async () => {
    const filters = selectedProtectionCoverage
      ? selectedProtectionCoverage.filter
      : {};

    setIsOpen(true);
    await getPackages(filters);
    setSelectedPlan(selectedProtectionCoverage?.selectedPlan || null);
  };

  const close = (withoutSelectedPlan = false) => {
    if (!_.isNull(selectedPlan) || withoutSelectedPlan) {
      setIsOpen(false);
      onClose({
        selectedPlan,
        filter: { months: selectedTerm, miles: selectedMiles },
        selectedProducts,
        categories: protectivePackages.find((p) => p.id === selectedPlan)
          ?.categories,
      });
    }
  };

  const handleOnChangeProducts = ({
    packageId,
    categoryKey,
    term,
    isDelete,
  }) => {
    if (isDelete) {
      setSelectedProducts((prev) => {
        const data = { ...prev };

        delete data[packageId][categoryKey];

        return data;
      });
    } else {
      setSelectedProducts((prev) => ({
        ...prev,
        [packageId]: {
          ...prev[packageId],
          [categoryKey]: term,
        },
      }));
    }
  };

  const handleOnChangeTerm = async (e, { value: months }) => {
    await getPackages({ months, miles: selectedMiles });
  };

  const handleOnChangeMiles = async (e, { value: miles }) => {
    await getPackages({ miles, months: selectedTerm });
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal open={isOpen} onClose={close} className={"protectionModal"}>
        <Modal.Content
          style={{
            "--modal-background": changeColorOpacity(
              theme?.typography?.secondary || defaultTheme.typography.secondary,
              0.3
            ),
            "--dropdown-border-radius":
              (theme?.input?.borderRadius || defaultTheme.input.borderRadius) +
              "px",
          }}
        >
          <div className={"modalContent"}>
            {!!protectivePackages.length && (
              <div className={"packagesFiltersWrapper"}>
                <Dropdown
                  value={selectedTerm}
                  options={options.months}
                  onChange={handleOnChangeTerm}
                  theme={theme?.input}
                />

                <Dropdown
                  value={selectedMiles}
                  options={options.miles}
                  onChange={handleOnChangeMiles}
                  theme={theme?.input}
                />
              </div>
            )}

            <QuestionnaireModal
              isPlanSelected={_.isNull(selectedPlan)}
              onClick={close}
            >
              <Button icon className={"crossIcon"}>
                <CrossIcon />
              </Button>
            </QuestionnaireModal>

            {!!protectivePackages.length ? (
              <Swiper
                selectedProducts={selectedProducts}
                slides={protectivePackages}
                selectedPlan={selectedPlan}
                onSelect={setSelectedPlan}
                onChangeProductsList={handleOnChangeProducts}
              />
            ) : (
              <>
                {!isLoading && (
                  <div className={"emptyPackagesWrapper"}>
                    <Message info>
                      <Message.Header>Info</Message.Header>

                      <div>
                        The list of protective packages is empty. Protection may
                        be available. Please, contact your dealer
                      </div>
                    </Message>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Content>

        {isLoading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </Modal>
    </>
  );
};

export default AddProtectionCoverageModal;
