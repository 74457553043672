import React from "react";
import cn from "classnames";

import { ReactComponent as PlusIcon } from "../../../../../images/plusIcon.svg";

import "./Package.sass";

const Package = ({ item, isNew = false, categories, onCreate, onSelect }) => {
  return (
    <>
      {isNew ? (
        <button
          className={cn(
            "protectivePackageCard",
            isNew && "createProtectivePackageCard"
          )}
          onClick={onCreate}
        >
          <li>
            <PlusIcon className={"createProtectivePackageCardIcon"} />
            Add New Package
          </li>
        </button>
      ) : (
        <button
          className={"protectivePackageCard"}
          onClick={() => onSelect(item)}
        >
          <li className={"protectivePackageCardTitle"}>{item.title}</li>
        </button>
      )}
    </>
  );
};

export default Package;
