import React, { useState } from "react";
import { Form, Header, Icon, Popup } from "semantic-ui-react";
import {
  calculateTotalPriceOfProtection,
  FormatCashDownToPrice,
  FormatNumberToPrice,
  getRebatesTotal,
  getRebatesNames,
  mathOmit,
} from "../../helpers/general";
import { useTheme } from "../../helpers/theme";
import _ from "lodash";

import { ReactComponent as InfoIcon } from "../../../images/infoCircle.svg";

import "./CarPrice.sass";

const CarPrice = (props) => {
  const { theme } = useTheme();

  const { formula, attributes, selectedRebates, selectedProtection } = props;
  const [incentives, setIncentives] = useState(false);

  const rebatesTotal = getRebatesTotal({
    selectedRebates,
    car: props.car,
  });

  return (
    <div>
      {props.car.msrp > 0 && props.car.condition === "new" && (
        <div className="ui-computer">
          <span className="prices">
            <span>MSRP: </span>
            <span className="value">
              {FormatNumberToPrice(props.car.msrp)}
            </span>{" "}
          </span>
        </div>
      )}

      {props.car.condition === "new" && (
        <div className="ui-computer">
          <span className="prices">
            <span>
              {(formula?.incentives?.totalDealerCash > 0 ||
                formula?.incentives?.totalRebate > 0) && (
                <Icon
                  link
                  color="black"
                  onClick={() => setIncentives((incentives) => !incentives)}
                  name={!incentives ? "chevron down" : "chevron up"}
                />
              )}
              Rebates and Incentives:
            </span>
            <span className="value">
              {FormatNumberToPrice(
                (formula?.incentives?.totalDealerCash > 0
                  ? formula?.incentives?.totalDealerCash
                  : 0) +
                  (formula?.incentives?.totalRebate > 0
                    ? formula?.incentives?.totalRebate
                    : 0)
              )}
            </span>
          </span>
          {incentives && (
            <div className="rebates">
              {formula?.incentives?.totalDealerCash > 0 && (
                <span className="prices">
                  Total Dealer Cash
                  <span className="value">
                    {FormatNumberToPrice(formula?.incentives?.totalDealerCash)}
                  </span>
                </span>
              )}
              {formula?.incentives?.totalRebate > 0 && (
                <span className="prices">
                  Total Rebate
                  <span className="value">
                    {FormatNumberToPrice(formula?.incentives?.totalRebate)}
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      )}

      <div className="ui-computer">
        <span className="prices">
          <span>Sale Price: </span>
          <span className="value">{FormatNumberToPrice(props.car.price)}</span>
        </span>
      </div>

      {!_.isEmpty(selectedRebates) && (
        <span className="prices">
          <span>
            Additional Dealer Rebates & Incentives:{" "}
            <Popup
              trigger={<InfoIcon className={"rebateTooltipIcon"} style={{ fill: theme?.typography?.primary }} />}
              position="bottom right"
              content={
                <div className={"rebateTooltipContent"}>
                  {getRebatesNames(selectedRebates).map((rebate, index) => (
                    <div
                      className={"rebateTooltipRecord"}
                      key={rebate.name + index}
                    >
                      <span>{rebate.title}</span>

                      <span>{rebate.amount}</span>
                    </div>
                  ))}
                </div>
              }
            />
          </span>

          <span className="value">
            {rebatesTotal
              ? `-${FormatNumberToPrice(rebatesTotal)}`
              : FormatNumberToPrice(0)}
          </span>
        </span>
      )}

      {props.car.cost > 0 && (
        <span className="prices">
          <span>Cost:</span>{" "}
          <span className="value">{FormatNumberToPrice(props.car.cost)}</span>
        </span>
      )}

      <span className="prices">
        <span>Trade Equity: </span>
        <span
          className="value"
          style={{
            color:
              props.trade_in - props.money_owed < 0
                ? theme?.typography?.negative || "red"
                : theme?.typography?.secondary || "#4E55FD",
          }}
        >
          {props.trade_in - props.money_owed < 0
            ? "(" +
              FormatNumberToPrice(-(props.trade_in - props.money_owed)) +
              ")"
            : FormatNumberToPrice(props.trade_in - props.money_owed)}
        </span>
      </span>

      {props.car.adjusted_price > 0 && (
        <span className="prices">
          <span>Adjusted Price: </span>
          <span className="value">
            {FormatNumberToPrice(props.car.adjusted_price)}
          </span>
        </span>
      )}

      <span className="prices">
        <span>Tax, Title, & Reg.: </span>
        <span className="value">
          {FormatNumberToPrice(props.quots.monthly.tax)}
        </span>
      </span>

      <span className="prices">
        <span>Dealer Fees: </span>
        <span className="value">
          {FormatNumberToPrice(parseInt(props.quots.monthly.dealer_fees))}
        </span>
      </span>

      <span className="prices">
        <span>Cash Down: </span>
        <span className="value">
          {FormatCashDownToPrice(parseInt(props.quots.monthly.down_payment))}
        </span>
      </span>

      <span className="prices">
        <span>APR: </span>
        <span className="value">{props.interest}%</span>
      </span>

      <span className="prices">
        <span>Trade-In:</span>

        <span className="value">
          {props.trade_in - props.money_owed < 0
            ? "(" +
              FormatNumberToPrice(-(props.trade_in - props.money_owed)) +
              ")"
            : FormatNumberToPrice(props.trade_in - props.money_owed)}
        </span>
      </span>

      <span className="prices">
        <span>Protective Coverage:</span>

        <span className="value">
          {FormatNumberToPrice(
            calculateTotalPriceOfProtection({
              coverage: selectedProtection?.selectedProducts,
              categories: selectedProtection?.categories,
            })?.total || 0
          )}
        </span>
      </span>

      <span className="prices">
        <span>Personalized Attributes:</span>

        <span className="value">
          {FormatNumberToPrice(
            attributes
              ? attributes.reduce((sum, item) => sum + Number(item.cost), 0)
              : 0
          )}
        </span>
      </span>

      {(formula?.incentives?.totalDealerCash > 0 ||
        formula?.incentives?.totalRebate > 0) && (
        <span className="prices">
          <span>Amount Financed Before Incentives: </span>
          <span className="value">
            {FormatNumberToPrice(props.quots.monthly.amountNoIncentives)}
          </span>
        </span>
      )}

      <span className="prices">
        <span>Estimated Amount Financed: </span>
        <span className="value">
          {FormatNumberToPrice(
            props.quots.monthly.amount -
              mathOmit(getRebatesTotal({ selectedRebates, car: props.car }))
          )}
        </span>
      </span>

      {(props.type === "selectedCar" ||
        props.type === "firstCar" ||
        props.type === "selectedCarLanding") &&
        props.retailer.credit_range.insurance_protection !== 0 &&
        props.retailer.credit_range.insurance_protection && (
          <div className="insurance-protection">
            <Form.Checkbox
              className="agree-check"
              checked={props.insurance_protection}
              label={"Insurance and protection"}
              onChange={(e, value) =>
                props.insuranceChange(value.checked, "insurance_protection")
              }
            />
            <Popup
              content={props.retailer.credit_range.text_insurance_protection}
              trigger={<Icon link name="info circle" />}
            />
          </div>
        )}

      {props.type === "finishedCar" &&
        props.retailer.credit_range.insurance_protection !== 0 &&
        props.retailer.credit_range.insurance_protection && (
          <Header className="monthlyPayment insurance-text" as="h4">
            <span>Insurance and protection</span>
            {props.insurance_protection ? (
              <Icon name="check" />
            ) : (
              <Icon name="close" />
            )}
          </Header>
        )}
    </div>
  );
};

export default CarPrice;
