import React, { useState } from "react";
import { Dimmer, Loader, Modal, Form } from "semantic-ui-react";
import Input from "../../../global/Input";
import ModalComponent from "../../alertModal/modal";
import { putInventory } from "../../requests";
import Button from "../../../global/Button";

import "./UpdateInventory.sass";

export const UpdateInventory = ({
  retailer,
  polls,
  poll,
  updatePolls,
  inventoryRetailer,
  updateInventoryCallBack,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const closeModal = () => {
    setIsVisible(false);
    setFile(null);
    setError(null);
  };

  const update = () => {
    if (!file) {
      setError("The field is required. Please, upload a file");

      return;
    }

    if (error) return;

    setIsLoading(true);

    const formData = new FormData();

    formData.append("pollId", poll.id);
    formData.append("file", file);

    putInventory(retailer.id, formData)
      .then((response) => {
        setIsLoading(false);
        // ----------------- update polls list -------------------------
        let auxPolls = [...polls].map((item) => {
          return item.id === poll.id ? response.data : item;
        });
        updatePolls(auxPolls, "edit");
        if (inventoryRetailer === retailer.dealer_id) {
          updateInventoryCallBack();
        } else {
          setModal({ status: 200, message: "Inventory was updated" });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status === 406) {
            setModal({ status: 406, message: error.response.data.message });
          } else if (error.response.status === 404) {
            if (error.response.data.path) {
              setModal({
                status: 406,
                message:
                  error.response.data.message + ": " + error.response.data.path,
              });
            } else {
              setModal({
                status: 406,
                message: "Sorry. Element was not found",
              });
            }
          } else {
            setModal({
              status: 500,
              message:
                "Sorry. An error occurred, please try again later. If This Issue Continues, Contact Support.",
            });
          }
        }
      });
  };

  const handleOnChangeFile = (e) => {
    e.persist();

    const _file = e.nativeEvent.target.files[0];
    const extension = _file.name.split(".")[1];

    setError(
      extension !== "csv"
        ? "Allowed the only .csv file extension. Please, make sure you have uploaded the right file."
        : null
    );
    setFile(_file);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        buttonStyle={"skyblue"}
        style={{ minWidth: "100px" }}
        onClick={() => setIsVisible(true)}
      >
        Update Inventory
      </Button>

      <Modal
        open={isVisible}
        onClose={closeModal}
        style={{ width: 460 }}
        closeIcon
      >
        <Modal.Header>Update Inventory</Modal.Header>

        <Modal.Content>
          <p className={"updateInventoryParagraph"}>
            Please, upload a file of inventory here
          </p>

          <Form>
            <Input
              type={"file"}
              accept={".csv"}
              onChange={handleOnChangeFile}
              error={error}
              className={"updateInventoryInput"}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions className={"actionButtons"}>
          <Button buttonStyle="danger" onClick={closeModal}>
            Close
          </Button>

          <Button buttonStyle="primary" onClick={update}>
            Update
          </Button>
        </Modal.Actions>

        {isLoading && (
          <Dimmer className="loading" active>
            <Loader />
          </Dimmer>
        )}
      </Modal>

      {modal.message && (
        <ModalComponent
          modal={modal}
          hideModal={() => {
            setModal({});
            closeModal();
          }}
        />
      )}
    </div>
  );
};
