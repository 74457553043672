import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';

export const BuyerInformation = ({ customer, empty }) => {

  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' textAlign='center'>
              {empty ? 'ACCOUNT HOLDER' : 'JOINT ACCOUNT HOLDER'}/BUYER INFORMATION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row >
            <Table.Cell>
              Name
            </Table.Cell>
            <Table.Cell>
              {empty ? null : customer.name + " " + customer.second_name}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              SSN:
            </Table.Cell>
            <Table.Cell>
              {empty ? null : "*****" + customer.ssn}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              DOB:
            </Table.Cell>
            <Table.Cell>
              {empty ? null : moment(customer.birthDate, "YYYY-MM-DD").format("MM-DD-YYYY")}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Address:
            </Table.Cell>
            <Table.Cell>
              {empty ? null : customer.address}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              City, State, Zip:
            </Table.Cell>
            <Table.Cell>
              {empty ? null : customer.city + ", " + customer.state + ", " + customer.zip_code}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Home Phone:
            </Table.Cell>
            <Table.Cell>
              {empty ? null : customer.contact_number}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Cell Phone:
            </Table.Cell>
            <Table.Cell>
              {empty ? null : customer.phone_number}
            </Table.Cell>
          </Table.Row>
          <Table.Row >
            <Table.Cell>
              Email Address:
            </Table.Cell>
            <Table.Cell>
              {empty ? null : customer.email}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};