import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { CreditSolutionRequests } from "./CreditSolutionRequests";

import Button from "../../global/Button";

export const CreditSolutionModal = ({ retailer }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      size="large"
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={true}
      onClose={() => setOpen(false)}
      closeIcon
      trigger={
        <Button buttonStyle={"skyblue"} onClick={() => setOpen(true)}>
          Credit Solution Requests
        </Button>
      }
    >
      <Modal.Header>Credit Solution Requests</Modal.Header>
      <Modal.Content
        className="height-credit-table"
        scrolling={true}
        style={{ overflowY: "auto", height: "auto" }}
      >
        <CreditSolutionRequests
          retailer={retailer}
          adminSection={true}
          callBackError={() => setOpen(false)}
        />
      </Modal.Content>
      <Modal.Actions className={"actionButtons"}>
        <Button
          buttonStyle={"danger"}
          className="actionButton"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
