import React from "react";
import { Popup } from "semantic-ui-react";

const DisabledTooltip = ({ isVisible, children }) => {
  return (
    <>
      {isVisible ? (
        <Popup
          trigger={children}
          position={'top center'}
          content={
            <span>
              This cannot be combined with the other rebates you selected.
              Please unselect the other rebates or incentives to apply this
              offer.
            </span>
          }
        />
      ) : (
        children
      )}
    </>
  );
};

export default DisabledTooltip;
