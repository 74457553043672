import React, { Component, createRef } from "react";
import { Image, Menu } from "semantic-ui-react";
import cn from "classnames";

import "./menu.sass";

import { ReactComponent as DashIcon } from "../../../images/dashboard.svg";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import { ReactComponent as RetailerIcon } from "../../../images/retailers.svg";
import { ReactComponent as LogOutIcon } from "../../../images/logout.svg";
import { ReactComponent as QuotesIcon } from "../../../images/quotes.svg";
import { ReactComponent as RangesIcon } from "../../../images/settings-money.svg";
import { ReactComponent as BuyersIcon } from "../../../images/buyer-blue.svg";
import { ReactComponent as GroupBank } from "../../../images/Grupo-bank.svg";
import { ReactComponent as Inventory } from "../../../images/Group-car-inventory.svg";
import { ReactComponent as Loan } from "../../../images/group-loan.svg";
import { ReactComponent as Video } from "../../../images/Group-up-video.svg";
import { ReactComponent as CreditRequests } from "../../../images/Group-credit.svg";
import { ReactComponent as SelectorIcon } from "../../../images/selector.svg";
import { ReactComponent as CogsIcon } from "../../../images/cogs.svg";
import { ReactComponent as RebatesIcon } from "../../../images/discount.svg";
import { ReactComponent as ProtectiveCoverageSettings } from "../../../images/protectionCoverageSettings.svg";
import { ReactComponent as AppearanceConfigurator } from "../../../images/brush.svg";

// import { logOut } from "../requests";
import { TermsConditions } from "../termsConditions/TermsConditions";

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.activeDefault,
    };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.deleteToken = this.deleteToken.bind(this);
    this.logOut = this.logOut.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.menuRef = createRef();
  }

  handleClickOutside(event) {
    if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
      this.props.menuOpened && this.props.openMenu();
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.activeDefault !== this.state.activeItem) {
      this.setState({ activeItem: this.props.activeDefault });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleItemClick = (e, { name }) => {
    this.props.handleClick(name);
    this.props.openMenu();
    //this.setState({ activeItem: name }, ()=> );
  };

  deleteToken() {
    localStorage.removeItem("currentUser");
    window.location.replace(`${window.location.origin}/login`);
  }

  logOut() {
    this.deleteToken();
  }

  renderIcon(icon) {
    switch (icon) {
      case "dashboard":
        return <DashIcon />;
      case "quotes":
        return <QuotesIcon />;
      case "retailers":
        return <RetailerIcon />;
      case "buyers":
        return <BuyersIcon />;
      case "contact":
        return <UserIcon />;
      case "ranges":
        return <RangesIcon />;
      case "logOut":
        return <LogOutIcon />;
      case "account":
        return <GroupBank />;
      case "inventory":
        return <Inventory />;
      case "loan":
        return <Loan />;
      case "videos":
        return <Video />;
      case "creditRequests":
        return <CreditRequests />;
      case "selector":
        return <SelectorIcon />;
      case "attributes":
        return <CogsIcon />;
      case "rebates":
        return <RebatesIcon />;
      case "protectionCoverageSettings":
        return <ProtectiveCoverageSettings />;
      case "appearance":
        return <AppearanceConfigurator />;
      default:
        return <div>404</div>;
    }
  }

  render() {
    const { activeItem } = this.state;
    return (
      <div
        className={cn("dashboardMenu", !this.props.menuOpened && "close-menu")}
        ref={this.menuRef}
      >
        <div className={"dashImage"}>
          <Image src={require("../../../images/icon.png")} />
        </div>
        <Menu
          className={"customMenu"}
          compact
          size={"massive"}
          borderless
          vertical
        >
          {this.props.elements.map(({ name, label }, k) => (
            <Menu.Item
              name={name}
              active={activeItem === name}
              onClick={this.handleItemClick}
              key={k}
            >
              <div className={"svgContainer"}>{this.renderIcon(name)}</div>
              <span>{label}</span>
            </Menu.Item>
          ))}

          <div className={"bottomMenu"}>
            <Menu.Item
              active={false}
              position={"right"}
              className={"termCondictions"}
            >
              <TermsConditions show={true} />
            </Menu.Item>

            <Menu.Item
              name="logOut"
              active={false}
              onClick={this.logOut}
              position={"right"}
              className={"logOut"}
            >
              <div className={"svgContainer"}>{this.renderIcon("logOut")}</div>
              <span>Log out</span>
            </Menu.Item>
          </div>
        </Menu>
      </div>
    );
  }
}

export default UserMenu;
