import React, { useContext } from "react";
import { useMediaPredicate } from "react-media-hook";

import InfoRecord from "../../InfoRecord";
import Title from "../../SectionTitle";
import SignInput from "../../SignInput";

import { AgreementCtx } from "../../context";

import { formatterPrice } from "../../../helpers/general";

import "./Trade.sass";

const Trade = () => {
  const is768 = useMediaPredicate("(max-width: 768px)");

  const { data } = useContext(AgreementCtx);

  return (
    <div className={"tradeInfo"}>
      <Title size={is768 ? 14 : 16}>Trade Information</Title>

      <div className={"detailsWrapper carDetails"}>
        <ul className={"list"}>
          <InfoRecord
            label={"Year"}
            wrapOnBreakpoint={1180}
            value={data.trade_year}
          />

          <InfoRecord
            label={"Make"}
            wrapOnBreakpoint={1180}
            value={data.trade_make}
          />

          <InfoRecord
            label={"Model"}
            wrapOnBreakpoint={1180}
            value={data.trade_model}
          />
        </ul>

        <ul className={"list"}>
          <InfoRecord
            label={"Color"}
            wrapOnBreakpoint={1180}
            value={data.trade_color}
          />

          <InfoRecord
            label={"Miles"}
            wrapOnBreakpoint={1180}
            value={data.trade_miles}
          />
        </ul>
      </div>

      <div className={"divider"} />

      <div className={"detailsWrapper lowerSection"}>
        <ul className={"list"}>
          <InfoRecord
            label={"PayOff"}
            value={data.trade_payoff}
            wrapOnBreakpoint={1180}
          />

          <li>
            <SignInput
              popup={
                <div className={"popupContent"}>
                  Customer agrees to pay difference if payoff verified amount is
                  incorrect
                </div>
              }
            />
          </li>
        </ul>

        <ul className={"list"}>
          <InfoRecord
            label={"VIN"}
            wrapOnBreakpoint={1180}
            value={data.trade_vin}
          />

          <InfoRecord
            label={"Lien Holder"}
            value={data.trade_lien_holder}
            wrapOnBreakpoint={1180}
          />
        </ul>
      </div>
    </div>
  );
};

export default Trade;
