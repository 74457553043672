import React, { useState } from "react";
import { Modal, Form, Header, Divider, Message } from "semantic-ui-react";
import _ from "lodash";

import Login from "../../../../session/login/login";
import ModalComponent from "../../../../utilities/alertModal/modal";
import { UserInformation } from "../../../../utilities/contact/UserInformation";
import { TermsConditions } from "../../../../utilities/termsConditions/TermsConditions";
import CodeModal from "../../../../utilities/codeModal/codeModal";
import Button from "../../../../global/Button";

import { signUp } from "../../../../utilities/requests";

import { useTheme } from "../../../../helpers/theme";

import {
  validEmail,
  validPhoneNumber,
  validCodeNumber,
} from "../../../../helpers/general";

const defaultForm = {
  email: "",
  phone_number: "",
  password: "",
  confirmPassword: "",
  agreement: "false",
};

const AccountModal = ({
  open,
  data,
  retailer,
  CreditSolutionRequest,
  updateUser,
  isSlidersFlow,
  onClose,
  isEnabled = true,
}) => {
  const { theme } = useTheme();
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState(defaultForm);
  const [error, setError] = useState({});
  const [modal, setModal] = useState({});
  const [user, setUser] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState(false);
  const [validCode, setvalidCode] = useState(false);
  const [isRegister, setIsRegister] = useState(true);
  const [disabledForm, setDisabledForm] = useState(true);

  const signUpButton = async () => {
    if (isLoading) return;
    let auxForm = { ...form, ...data };

    auxForm["password_confirmation"] = auxForm.confirmPassword;

    delete auxForm.confirmPassword;

    auxForm["retailerId"] = retailer.id;
    setIsLoading(true);
    setDisabledForm(true);

    signUp(
      validCode
        ? {
            id: user.id,
            code: form.code,
            retailerId: retailer.id,
            ...{
              ..._.omit(auxForm, ["firstName", "lastName"]),
              first_name: auxForm.firstName,
              last_name: auxForm.lastName,
            },
          }
        : {
            ..._.omit(auxForm, ["firstName", "lastName"]),
            first_name: auxForm.firstName,
            last_name: auxForm.lastName,
          }
    )
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "Code was sent") {
          setvalidCode(true);
          setUser(response.data);
        } else {
          setvalidCode(false);
          CreditSolutionRequest(response.data);
          updateUser(response.data);
          localStorage.setItem("currentUser", JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDisabledForm(false);
        if (error.response) {
          if (error.response.status === 422) {
            setError({
              message: "Sorry. Fields are missing to complete the registration",
            });
          } else if (error.response.status === 406) {
            setError({ message: error.response.data.message });
          } else if (error.response.status === 400) {
            setModal({ message: "The code is invalid", status: 400 });
          } else {
            setError({
              message: "Sorry. An error occurred, please try again later",
            });
          }
        }
      });
  };

  const validation = (auxForm, auxErrors) => {
    for (const key in auxForm) {
      if (auxForm.hasOwnProperty.call(auxForm, key)) {
        const element = auxForm[key];
        auxErrors = validateForm(element, key, auxErrors, auxForm);
      }
    }
    setDisabledForm(false);
    for (const key in auxErrors) {
      if (auxErrors.hasOwnProperty.call(auxErrors, key)) {
        const element = auxErrors[key];
        if (
          (key !== "agreement" && element !== "") ||
          (key === "agreement" && (!element || element === "false"))
        ) {
          setDisabledForm(true);
        }
      }
    }
  };

  const validateForm = (value, label, errors, auxForm = form) => {
    switch (label) {
      case "email":
        errors.email = validEmail(value) ? "" : "Email is not valid";
        break;
      case "phone_number":
        errors.phone_number = !validPhoneNumber(value)
          ? "Phone number must be 10 digits"
          : !validCodeNumber(value)
          ? "Invalid phone number"
          : "";
        break;
      case "password":
        errors.password =
          value.length < 8 ? "Password must be at least 8 characters long" : "";
        errors.confirmPassword = "Passwords do not match";
        if (auxForm.confirmPassword === value) {
          errors.confirmPassword = "";
        }
        break;
      case "confirmPassword":
        errors.confirmPassword =
          value !== auxForm.password ? "Passwords do not match" : "";
        break;
      case "agreement":
        errors.agreement = value;
        break;
      default:
        break;
    }
    return errors;
  };

  const onEditInput = (label, value) => {
    let auxForm = { ...form };
    auxForm[label] = value;
    let auxErrors = { ...errors };
    auxErrors = validateForm(value, label, auxErrors);
    setForm({ ...auxForm });
    setErrors({ ...auxErrors });
    validation(auxForm, auxErrors);
  };

  return (
    <>
      <Modal
        className="simple-register"
        onClose={onClose}
        open={open}
        size="tiny"
      >
        <Modal.Content scrolling>
          <div className="radio-form">
            <Form>
              <Form.Group>
                <Form.Field
                  label={
                    <Header>
                      <p>Save my Vehicle & build my Vault</p>{" "}
                      <small>
                        (Avoids need for future Vehicle Vault login)
                      </small>
                    </Header>
                  }
                  value={true}
                  control="input"
                  type="radio"
                  onChange={() => setIsRegister(true)}
                  name="register"
                  checked={isRegister}
                  className="radio-input"
                  width={16}
                />

                {isEnabled && (
                  <Form.Field
                    label={
                      <Header>
                        <p>
                          {isSlidersFlow
                            ? "Skip saving & keep me shopping"
                            : "Skip saving & keep shopping prequalified"}
                        </p>
                      </Header>
                    }
                    value={false}
                    control="input"
                    type="radio"
                    checked={!isRegister}
                    onChange={() => setIsRegister(false)}
                    name="register"
                    className="radio-input"
                    width={16}
                  />
                )}
              </Form.Group>
            </Form>
          </div>

          {isRegister && (
            <Form>
              <Divider />

              {!login ? (
                <div>
                  <h2
                    className="upperTitle"
                    style={{
                      cursor: "default",
                      color: theme?.typography.primary,
                    }}
                  >
                    Sign Up
                  </h2>

                  {error.message && (
                    <Message
                      error
                      visible
                      header="Login Failed"
                      content={error.message}
                    />
                  )}

                  <UserInformation
                    form={form}
                    register={true}
                    msnErrors={errors}
                    onEditInput={onEditInput}
                  />
                  <Form.Group>
                    <Form.Checkbox
                      className="agree-check"
                      id={"agreement"}
                      value={
                        form.agreement === "false" ? false : form.agreement
                      }
                      label={
                        <label>
                          I agree to the terms and conditions and to receive
                          text messages and phone calls from Advantage2U™ and
                          Ewald Kia. I understand that no consent to texting or
                          calling is required for a purchase of a product or a
                          service. Please <TermsConditions show={true} /> of
                          Use.{" "}
                        </label>
                      }
                      onChange={(e, value) =>
                        onEditInput("agreement", value.checked)
                      }
                      error={
                        !errors.agreement
                          ? "Please, accept the terms and conditions"
                          : null
                      }
                    />
                  </Form.Group>

                  <div
                    style={{ width: "100%", textAlign: "center" }}
                    className={"continueButtonWrapper"}
                  >
                    <Button
                      disabled={disabledForm}
                      onClick={signUpButton}
                      theme={theme?.button}
                    >
                      Get Pre-Approved & Create My Vault
                    </Button>
                  </div>
                  <h2 className="title-or">OR</h2>
                  <h2
                    className="title"
                    onClick={() => setLogin(true)}
                    style={{ color: theme?.typography.primary }}
                  >
                    <p>Log-in with your</p>{" "}
                    <p>Current MyPaymentPower Profile</p>
                  </h2>
                </div>
              ) : (
                <Login
                  prequalified
                  updateUser={updateUser}
                  back={() => setLogin(false)}
                  getUser={CreditSolutionRequest}
                  theme={theme}
                />
              )}
            </Form>
          )}

          {validCode && (
            <CodeModal
              register={true}
              email={form.email}
              password={form.password}
              phone={form.phone_number}
              updateCode={onEditInput}
              action={signUpButton}
              hideModalCode={() => {
                setvalidCode(false);
                setDisabledForm(false);
              }}
              disabled={disabledForm}
              theme={theme}
            />
          )}
          {modal.message && (
            <ModalComponent
              hideModal={() => {
                setModal({ modal: {} });
                setDisabledForm(false);
              }}
              modal={modal}
            />
          )}
        </Modal.Content>

        <Modal.Actions className={"actionButtons"}>
          <Button
            buttonStyle={"danger"}
            className="actionButton"
            onClick={onClose}
            theme={theme?.button}
          >
            Close
          </Button>

          {!isRegister && (
            <Button
              className="continueButton"
              onClick={() => CreditSolutionRequest(isRegister)}
              theme={theme?.button}
            >
              Continue
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AccountModal;
