import api from "./apiMethods";
import axios from "axios";
import _ from "lodash";

// LOGIN FUNCTIONS
export const login = (body) => api.post("auth/login", body); // TESTED
export const signUp = (body) => api.post("auth/signup", body); //TESTED
export const logOut = () => api.get("auth/logout");

// USER DATA
export const userData = () => api.get("user");
// CHANGE PASSWORD
export const editPassword = (body) => api.put("auth/update-password", body); //TESTED
// RESEND SMS
export const resendSMSAuth = (body) => api.put("auth/sms-auth", body); //TESTED
export const resendSMS = () => api.put("sms"); //TESTED

// RESEND SMS
export const checkPhone = (body) => api.put("auth/check-phone", body); //TESTED
export const resetPassword = (body) => api.put("auth/reset-password", body); //TESTED

// ADMIN FUNCTIONS
// GET
export const adminData = () => api.get("admin/admin"); //TESTED
export const getRetailers = (params) => api.get("admin/retailers", params); //TESTED
export const getRetailer = (id) => api.get(`admin/retailer/${id}`); //TESTED
export const getRetailerFormula = (id) => api.get(`admin/formula/${id}`); //TESTED
export const searchQuotes = (params) => api.get("admin/quotes", params);
// export const bestRetailers =     () => api.get('admin/bestRetailers');
export const statusQuotes = (params) => api.get("admin/status-quotes", params);
export const getCustomersAdmin = (params) => api.get("admin/customers", params); //TESTED
export const getRetailersList = () => api.get("admin/retailers/list"); //TESTED
export const getPolls = () => api.get("admin/polls");
export const getVideos = () => api.get("admin/videos");
export const putInventory = (id, body) =>
  api.put(`admin/inventory/${id}`, body);
export const getAccounts = (params) => api.get(`admin/accounts`, params);

// POST
export const newRetailer = (body) => api.post("admin/retailers", body); //TESTED
export const editRetailer = (id, body) =>
  api.put(`admin/retailers/${id}`, body);
export const addRetailerFormula = (body) => api.put("admin/formulas", body); //TESTED
export const postPoll = (body) => api.post("admin/polls", body);
export const postVideo = (body) => api.post("admin/video", body);
export const postDocument = (body) => api.post("admin/documents", body);

// PUT
export const editRetailerFormula = (id, body) =>
  api.put(`admin/formula/${id}`, body); //TESTED
export const editRetailerQuoteExpiration = (id, body) =>
  api.put(`admin/quote-expiration/${id}`, body); //TESTED
export const putPoll = (id, body) => api.put(`admin/polls/${id}`, body);

// DELETE
export const deleteRetailer = (id) => api.delete(`admin/formula/${id}`);
export const deletePoll = (id) => api.delete(`admin/polls/${id}`);
export const deleteVideo = (id) => api.delete(`admin/video/${id}`);

// RETAILER FUNCTIONS
// GET
export const retailerData = () => api.get("retailers/retailer-data"); //TESTED
export const searchQuotesRetailer = (params) =>
  api.get("retailer/quotes", params); //TESTED
// export const getQuotRetailer =      (id) => api.get(`retailer/quot/${id}`); //TESTED
export const getCustomers = (params) => api.get("retailer/customers", params); //TESTED
export const getCreditRange = () => api.get("retailer/creditRange"); //TESTED
export const retailerStatusQuotes = (params) =>
  api.get("retailers/status-quotes", params);

// POST
export const postNewCreditRange = (body) =>
  api.post("retailer/creditRange", body); //TESTED

// PUT
export const editCreditRange = (id, body) =>
  api.put(`retailers/credit-range/${id}`, body); //TESTED
export const editQuotStatusRetailer = (id, body) =>
  api.put(`retailers/quotes/status/${id}`, body);

// CUSTOMER FUNCTIONS
//GET
export const searchQuotesCustomer = (params) =>
  api.get("customer/quotes", params);
// export const getQuotCustomer =      (id) => api.get(`customer/quot/${id}`);
export const getCar = (vin) => api.get(`cars/${vin}`);
export const getContactData = () => api.get("customers/contacts");
export const getRetailerQuote = (retailer) => api.get(`retailer/${retailer}`);
export const getCarsQuote = (params) => api.get(`cars`, params);
export const getCoxAPR = (params) => api.get(`coxAPR`, params);
export const get700Credit = (params) => api.get(`external/credit-700`, params);
export const getLenders = () => api.get("customer/lenders");
export const getCoApplicants = (params = {}) =>
  api.get("customer/coApplicants", params);
export const getDealRetailers = (params = {}) =>
  api.get("customer/retailers", params);
export const getPaymentPlans = (params) =>
  api.get(
    params.type === "customer"
      ? "customer/paymentPlan"
      : `admin/payment-plans/${params.id}`,
    _.omit(params, "id")
  );
export const getPayments = (params, id, type) =>
  api.get(`${type === "global" ? "admin" : type}/payments/${id}`, params);
export const getEvents = (params) => api.get("admin/events", params);
export const getPlaidToken = () => api.get("customer/plaidToken");

//POST
export const postNewQuot = (body) => api.post("customer/quot", body); //TESTED
export const postContactData = (body) => api.post("customers/contacts", body); //TESTED
export const createDwollaProfile = (body) => api.post("customer/profile", body);
export const createFundingAccount = (body) =>
  api.post("customer/funding", body);
export const postPaymentPlan = (body) => api.post("paymentPlan", body);
export const postLender = (body) => api.post("customer/lender", body);
export const postCoApplicant = (body) => api.post("customer/coApplicant", body);
export const postLoanApplication = (body) => api.post("customer/loan", body);

// PUT
export const editQuot = (id, body) => api.patch(`customers/quotes/${id}`, body); //TESTED
export const editQuotStatusCustomer = (id, body) =>
  api.put(`customers/quotes/status/${id}`, body);
export const editStatusQuotClient = (id, body) =>
  api.put(`customer/quot/status/${id}`, body); //TESTED
export const editContactData = (body) => api.put("customers/contacts", body); //TESTED
export const verifyFundingAccount = (body) => api.put("customer/funding", body);
export const stopPaymentPlan = (id) =>
  api.put(`customer/paymentPlan/stop/${id}`);
export const restartPaymentPlan = (id) =>
  api.put(`customer/paymentPlan/restart/${id}`);
export const putCoApplicant = (body) => api.put("customer/coApplicant", body);

// DELETE
export const deleteFundingAccount = () => api.delete("customer/funding");
export const deleteLender = (id) => api.delete(`customer/lender/${id}`);
export const deleteCoApplicant = (id) =>
  api.delete(`customer/coApplicant/${id}`);

// QUOTE FUNCTIONS
export const getQuot = (id) => api.get(`quotes/${id}`);

// routes for retailer and customer
export const getPaymentPlan = (body) => api.get(`paymentPlan/${body.id}`, body);
export const putPaymentPlan = (body) => api.put(`paymentPlan/${body.id}`, body);
export const getLendersPlan = (body = {}) => api.get("lenders", body);
export const getMessages = (params) =>
  api.get(`${params.role}s/messages/${params.id}`);
export const postMessage = (params) =>
  api.post(`${params.role}s/messages`, params);

// routes for retailer and admin
export const putCar = (body) => api.put(`car/${body.id}`, body);
export const getInventory = (params) => api.get(`inventory`, params);
export const getCreditRequests = (params) =>
  params.admin
    ? api.get(
        `admin/credit-scores/${params.retailer_id}`,
        _.omit(params, ["admin"])
      )
    : api.get(
        "retailers/credit-scores",
        _.omit(params, ["retailer_id", "admin"])
      );
export const getMessageCategories = (params) =>
  api.get("messageCategories", params);

// routes for every user
export const getDocument = (id) => api.get(`documents/${id}`);

export const getProfileSelector = () => api.get("admin/flow-probability");

export const setProfileSelector = (body) =>
  api.post("admin/flow-probability", body);

export const getAttributes = (params) => api.get("items", params);

export const setAttributes = (retailerId, body) =>
  api.post(`items?retailer_id=${retailerId}`, body);

export const deleteAttribute = (id) => api.delete(`items/${id}`);

export const updateAttribute = (id, body) => api.patch(`items/${id}`, body);

export const createRebate = (dto) => api.post("rebates", dto);

export const getRebates = (retailerId) =>
  api.get(`rebates?retailerId=${retailerId}`);

export const deleteRebate = (id) => api.delete(`rebates/${id}`);

export const updateRebate = (id, dto) => api.patch(`rebates/${id}`, dto);

export const fetchAllProviders = async () => {
  const { data } = await api.get("external/fandi/providers");

  return data;
};

export const fetchEnabledProviders = async (retailerId) => {
  const { data } = await api.get(
    `external/fandi/enabled-providers?retailerId=${retailerId}`
  );

  return data;
};

export const fetchCategories = async () => {
  const { data } = await api.get("external/fandi/categories");

  return data;
};

export const fetchPackages = async (retailerId) => {
  const { data } = await api.get(`packages?retailerId=${retailerId}`);

  return data;
};

export const createPackage = async (dto) => {
  await api.post("packages", dto);
};

export const updatePackage = async (id, dto) => {
  await api.patch(`packages/${id}`, dto);
};

export const deletePackage = async (id) => {
  await api.delete(`packages/${id}`);
};

export const registerProducts = async (dto) => {
  await api.post("external/fandi/register-products", dto);
};

export const getLoanInformation = async (dto) =>
  await api.get("loan-information", dto);

export const getOLRebates = async (dto) =>
  api.get("external/offerlogix/rebates", dto);

export const getDealerRebates = async (dto) =>
  api.get("rebates/mpp-rebates", dto);

export const fetchProtectionPlans = async ({
  vin,
  purchase,
  amount_financed,
  odometer,
  condition,
  dealType = "loan",
  termStart,
  termEnd,
  financedTerm,
  coverageTerm,
  coverageMiles,
  msrp,
}) => {
  const dto = {
    vin,
    purchase,
    amount_financed,
    odometer,
    condition,
    dealType,
    termStart,
    termEnd,
    financedTerm,
    coverageTerm,
    coverageMiles,
    msrp,
  };
  const { data } = await api.get("packages/coverages", dto);

  return data;
};

export const fetchRanges = async (retailerId) => {
  const { data } = await api.get("packages/coverage-ranges", { retailerId });

  return data;
};

export const sendDealToAutoPayPlus = async (dto) =>
  await api.post("external/autopay/navigate-to-vehicle", dto);

export const editRetailerAppearance = async (id, dto) =>
  await api.put(`retailers/appearance/${id}`, {
    appearance: JSON.stringify(dto),
  });

export const getPresignedUrl = async (fileName) =>
  await api.get(
    `aws/presigned-url?fileName=${fileName}&folderName=retailers/images`
  );

export const uploadImageToS3 = async (url, blob) => await axios.put(url, blob);

export const updateRetailerImages = async (id, dto) =>
  await api.put(`retailers/appearance/${id}`, dto);

export const uploadContractDetails = async (file) =>
  await api.post("approval-form", file);
